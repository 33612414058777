// A div that gets its background color from the dominant color of an image.

import React from 'react';
import ClassNames from 'classnames';

import ColorThief from 'components/color-thief.js';
import Utils from 'components/Utils';

class AdaptiveBackground extends React.Component {

    constructor (props) {
        super(props);

        this.state = { };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.image !== this.props.image) {
            this.setState({ backgroundColor: undefined, textColor: undefined }, this.componentDidMount);
        }
    }

    // Helper function to calculate the luminance - http://en.wikipedia.org/wiki/YIQ
    getY(rgb) {
        return ((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000;
    }

    rgbToYuv(rgb) {
        return [
            (299 * rgb[0] + 587 * rgb[1] + 114 * rgb[2]) / 1000,
            (-147 * rgb[0] - 289 * rgb[1] + 436 * rgb[2]) / 1000,
            (615 * rgb[0] - 515 * rgb[1] - 100 * rgb[2]) / 1000
        ];
    }

    clamp(v) {
        if (v < 0) {
            return 0;
        }
        else if (v > 255) {
            return 255;
        }

        return v;
    }

    yuvToRgb(yuv) {

        var b = this.clamp(Math.round(yuv[0] + yuv[1] / 0.493));
        var r = this.clamp(Math.round(yuv[0] + yuv[2] / 0.877));
        var g = this.clamp(Math.round(yuv[0] - 0.39393 * yuv[1] - 0.58081 * yuv[2]));

        return [r, g, b];
    }

    getTextColor(rgb) {
        return this.getY(rgb) >= 128 ? "rgb(51,51,51)" : "rgb(205,205,205)";
    }

    imageLoaded(img) {

        // Workaround for iOS.
        if (img.src.indexOf("/Images/no-poster-185.png") >= 0) {
            img.src = this.props.image;
            return;
        }

        var colorThief = new ColorThief();

        var dominant = colorThief.getColor(img);

        var yuv = this.rgbToYuv(dominant);

        // Reduce the luminance to end up with a dark color
        if (yuv[0] > 64) {
            yuv[0] = 64;
            dominant = this.yuvToRgb(yuv);
        }

        var y = yuv[0];

        //// Always choose a dark color
        //var palette = colorThief.getPalette(img);
        //var dominant, y;
        //for (var i = 0; i < palette.length - 1; i++)
        //{
        //    if (dominant == undefined)
        //    {
        //        y = this.getY(palette[i]);
        //        if (y < 128) {
        //            dominant = palette[i];
        //        }
        //    }
        //};

        //// If not possible just chose the first one
        //if (dominant == undefined) {
        //    dominant = palette[0];
        //    y = this.getY(palette[0]);
        //}

        var bgColor = "rgb(" + dominant + ")";

        this.setState({ backgroundColor: bgColor, textColor: this.getTextColor(dominant), isDark: y <= 128 });
    }

    componentDidMount()
    {
        if (!this.state.backgroundColor) {

            var img = new Image();
            img.crossOrigin = "Anonymous";
            img.onload = this.imageLoaded.bind(this, img);
            img.src = this.props.image;

            if (img.complete) {
                if (img.width === 0) {
                    // This is a workaround for iOS.
                    // When the image is taken from the cache, no loaded event is fired at all AND the image has a
                    // width and height of 0.
                    // The only way to work around this seems to switch to another image and switch back after that
                    // one was loaded.
                    img.src = "/Images/no-poster-185.png";
                } else {
                    this.imageLoaded(img);
                }
            }
        }
    }

    render() {

        var className = this.props.className;
        var style = Object.assign({}, this.props.style);
        
        var isLight;
        var isDark;
        
        style.transition = "background-color 0.75s ease";

        if (Utils.runningInBrowser() && this.state.backgroundColor) {
            style.backgroundColor = this.state.backgroundColor;
            style.color = this.state.textColor;

            isDark = this.state.isDark;
            isLight = !isDark;
        }
        else {
            style.backgroundColor = "rgb(32,32,32)";
            style.color = "rgb(205,205,205)";
            isDark = true;
            isLight = false;
        }

        var classes = {
            "ab-light": isLight,
            "ab-dark": isDark
        };

        className = className + " " + ClassNames(classes);

        return (
            <div className={className} style={style}>
                {this.props.children}
            </div>
        );
    }
}

export default AdaptiveBackground;