import React from 'react';

import Raty from 'components/Raty';

class ActionList extends React.PureComponent {
    render() {
        var item = this.props.item;

        var actions = item.actions.slice(0,1).map(function(action) {

            var btnClass = undefined;
            var icon = undefined;
            var text  = undefined;
            var elems = [];
            if (action==="known" || action ==="collected" || action === "watchlist" || action === "watched" || action === "favorite") {
                btnClass = "green_selected";
                icon = "fa fa-check";
                text = "";
                if (item.actorInfo && item.actorInfo.rating > 0) {
                    elems.push(
                        <span key="rating" className="pull-right kk-nf-star">
                            <Raty score={item.actorInfo.rating} readOnly={true} narrow={true}/>
                        </span>
                    );
                }
            }

            /*
            if (action === "collected") {
                btnClass = "brown_selected";
                icon = "fa fa-folder-open-o";
                text = "Gesammelt";
            }
            else if (action === "watchlist") {
                btnClass = "green_selected";
                icon = "fa fa-bookmark-o";
                text = "Merkzettel";
            }
            else if (action === "favourite") {
                btnClass = "red_selected";
                icon = "fa fa-heart-o";
                text = "Liebling";
            }
            else if (action === "watched") {
                btnClass = "pink_selected";
                icon = "fa fa-eye";
                text = "Gesehen";

                if (item.actorInfo && item.actorInfo.rating > 0) {
                    elems.push(
                        <span key="rating" className="pull-right kk-nf-star">
                            <Raty score={item.actorInfo.rating} readOnly={true} narrow={true}/>
                        </span>
                    );
                }
            }
            */
            else if (action === "commented") {
                btnClass = "white_selected";
                icon = "fa fa-pencil";
                text = "Neues Review";
            }
            else if (action === "votedForReview") {
                btnClass = "white_selected";
                icon = "fa fa-pencil";
                text = "Vote für Review";
            }
            else if (action === "commentedOnReview") {
                btnClass = "white_selected";
                icon = "fa fa-pencil";
                text = "Kommentar zu Review";
            }
            else if (action === "joined") {
                btnClass = "orange_selected";
                icon = "fa fa-user-plus";
                text = "Beigetreten";
            }
            else if (action === "createdList") {
                btnClass = "white_selected";
                icon = "fa fa-bars";
                text = "Neue Liste";
            }
            else if (action === "likesList") {
                btnClass = "red_selected";
                icon = "fa fa-heart";
                text = "Mag Liste";
            }
            else if (action === "addedMovieToList") {
                btnClass = "white_selected";
                icon = "fa fa-bars";
                text = "Zu Liste hinzugefügt";
            }
            else if (action === "votedOnList") {
                btnClass = "white_selected";
                icon = "fa fa-bars";
                text = "Auf Liste abgestimmt";
            }

            return (
                <div key={action} className="kk-nf-action">
                    <span className={"kk-nf-action-icon actionbutton_xs " + btnClass}><span className={icon}></span></span>
                    <span className="kk-nf-action-text">{text}</span>
                    {elems}
                </div>
            );
        });

        return (
            <div className="gradient-bot kk-nf-actions">
                {actions}
            </div>
        );
    }
}

export default ActionList;