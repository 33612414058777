import React from 'react';
import PropTypes from 'prop-types';

import RegistrationForm from 'components/RegistrationForm';
import { connect } from 'components/StoresContext';

import { registerWithGoogle, registerWithFacebook } from 'actions/register';

import signInFacebook from 'images/signin_facebook.png';
import signInGoogle from 'images/signin_google.png';

class PendingTab_ extends React.PureComponent {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <h3>Bitte bestätige deine eMail-Adresse</h3>
                        <p>Wir haben eine Mail an {this.props.emailaddr} geschickt. Dein Account steht nach der Bestätigung der eMail-Adresse zur Verfügung.</p>
                    </div>
                </div>
            </div>
        );
    }
}

const PendingTab = connect(PendingTab_, state => ({
        emailaddr: state.registerForm.emailaddr
}));


class RegisterTab extends React.PureComponent {

    constructor(props) {
        super(props);

        this._registerWithGoogle = this._registerWithGoogle.bind(this);
        this._registerWithFacebook = this._registerWithFacebook.bind(this);
    }

    _registerWithGoogle(e) {
        e.stopPropagation();

        this.props.registerWithGoogle(() => this.context.router.history.goBack());
    }

    _registerWithFacebook(e) {
        e.stopPropagation();

        this.props.registerWithFacebook(() => this.context.router.history.goBack());
    }

    render() {
        return (
            <div className="container">
                <div className="row" style={{display:"flex", alignItems:"center"}}>
                    <div className="col-md-6">
                        <h3>Registrieren</h3>
                        <RegistrationForm showButton showLoginLink/>
                    </div>
                    <div className="col-md-6 hidden-xs text-center" >
                        <img src="/Images/logo/Logo-128x128.png" alt=""/>
                    </div>
                </div>
                <div className="row" style={{paddingBottom:"20px"}}>
                    <div className="col-md-6">
                        <h3>Oder registrieren mit</h3>
                        <img src={signInFacebook} alt="" style={{ cursor: "pointer" }} onClick={this._registerWithFacebook}/>
                        <img src={signInGoogle} alt="" style={{ cursor: "pointer", marginLeft:"15px" }}  onClick={this._registerWithGoogle}/>
                    </div>
                </div>
            </div>
        );
    }
}  


class Register extends React.PureComponent {

    render() {
        return (
            <div>
                { this.props.pending && <PendingTab/> }
                { !this.props.pending && <RegisterTab {...this.props}/> }
            </div>
        );
    }
}

// Needed for the login button to work.
Register.contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.object.isRequired,
    }),
  };

export default connect(Register, state => ({
    pending: state.registerForm.pending
}), dispatch => ({
    registerWithGoogle: callback => dispatch(registerWithGoogle(callback)),
    registerWithFacebook: callback => dispatch(registerWithFacebook(callback)),
}));
