import React from 'react';
import {components} from 'react-select';
import AsyncSelect from 'react-select/async';

import api from 'components/Api';

// import 'react-select/dist/react-select.css';

function tmdbImage(path, size) {

    if (!path) {
        return "/Images/no-poster-"+size+".png";
    }

	return path.startsWith("/") ? 'https://image.tmdb.org/t/p/w'+size+path : 'https://image.tmdb.org/t/p/w'+size+'/'+path;
}

class MovieValue extends React.PureComponent{
	
	render() {
		var item = JSON.parse(this.props.children);
		return (
			<div className="Select-value" title={item.title}>
				<span className="Select-value-label">
					{item.title}
				</span>
		</div>)
	}
}


class MovieOption extends React.PureComponent{
	handleMouseDown (event) {
		event.preventDefault();
		event.stopPropagation();
		this.props.onSelect(this.props.option, event);
	}

	handleMouseEnter (event) {
		this.props.onFocus(this.props.option, event);
	}

	handleMouseMove (event) {
		if (this.props.isFocused) return;
		this.props.onFocus(this.props.option, event);
	}

	render() {
		var item = JSON.parse(this.props.children);
		return (
			<div className={this.props.className}
				onMouseDown={this.handleMouseDown.bind(this)}
				onMouseEnter={this.handleMouseEnter.bind(this)}
				onMouseMove={this.handleMouseMove.bind(this)}
				title={this.props.option.title}>
				<div className="media">
					<div className="mr-3">
						<img src={tmdbImage(item.poster,45)} alt="" style={{width:"45px"}}/>
					</div>
					<div className="media-body">
						{item.title}&nbsp;{item.year && (<span>({item.year})</span>)}
					</div>
				</div>
			</div>)
	}
}

const Option = props => {
	return (
		<components.Option {...props}>
			<div className="media">
				<div className="mr-3">
					<img src={tmdbImage(props.data.poster,45)} alt="" style={{width:"45px"}}/>
				</div>
				<div className="media-body">
					{props.label}&nbsp;{props.data.year && (<span>({props.data.year})</span>)}
				</div>
			</div>
		</components.Option>
	);
  };

class MovieListBox extends React.Component{

	constructor(props) {
        super(props);

		this.state = { value: null };
		this.handleChange = this.handleChange.bind(this);
    }
	
	handleChange(value) {
		if (this.props.onSelected) {
			this.props.onSelected(value);
		} else {
			this.setState({ value: value });
		}
	}

	renderOption(option) {
		return (
			<div>{option.title}</div>
		);
	}

	getMovies (input) {
		
		//	return new Promise.resolve( [{value:"eins", label:"eins"}, {value:"zwei", label:"zwei"}] );
		
		return api.postJson("_search", { "query": input, "skip": 0, "take": 10 })
			.then(json => { return json.hits.map (i => { return { 
				value: i.id, id: i.id, title: i.title, year: i.year, poster: i.poster, backdrop: i.backdrop, label: i.title } } ) } );
	}

	render () {
		return (
				<AsyncSelect 
					value={this.state.value}
					components={{ Option }}
					loadOptions={this.getMovies} 
					placeholder={this.props.placeholder}
					onChange={this.handleChange} />
		);
	}
};

export default MovieListBox;