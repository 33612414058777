import React from 'react';

class Image extends React.PureComponent {
    render() {
        return <img className={this.props.className} src={this.props.src} alt=""/>;
    }
}

Image.updateViewport = function () {}

export default Image;
