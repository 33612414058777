import React from 'react';
import { withRouter } from 'react-router-dom';

import Lists from 'components/MovieLists';
import RequireLogin from 'components/RequireLogin';

import { connect } from 'components/StoresContext';
import { createMovieList } from 'actions/movieList';


class MovieLists extends React.PureComponent {

    constructor(props) {
        super(props);

        this._handleCreateList = this._handleCreateList.bind(this);
    }

    _handleCreateList() {

        RequireLogin.do(() => {
            var movie = this.props.movie;

            this.props.createList({ id: movie.id, poster: movie.poster, title: movie.title, year: movie.release_date, backdrop: movie.backdrop  },
                url => this.props.history.push(url));
        });
    }

    render() {
        if (!this.props.items) {
            return null;
        }

        return <Lists className="col-sm-12" lists={this.props.items} showHeader onCreateList={this._handleCreateList} />
    }
}

export default withRouter(connect(MovieLists, store => ({
    items: store.movieStore.lists,
    movie: store.movieStore.movieInfo
}), dispatch => ({
    createList: (movie, callback) => dispatch(createMovieList(movie, callback))
})));