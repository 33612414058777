export const LOGIN_INPUT = "LOGIN_INPUT";
export const LOGIN_CLEAR = "LOGIN_CLEAR";

export function loginInput(field, value) {
    return { type: LOGIN_INPUT, field, value };
}

export function clearLoginForm() {
    return { type: LOGIN_CLEAR };
}
