import * as React from "react";

import { withMeasure } from 'components/Measure';
import CssScaler from 'components/CssScaler';

import MovieResultItem from "./MovieResultItem";

function calculateTiles(availableWidth, targetWidth, spacing)
{
    // The number of tiles per row
    const n = Math.round((availableWidth + spacing) / (targetWidth + spacing));

    const tileWidth = (availableWidth - (n-1) * spacing) / n;

    return { tilesPerRow: n, tileWidth: tileWidth, scaleFactor: tileWidth / targetWidth };
}

class Items extends React.PureComponent {

    constructor(props) {
        super(props);

        this._scaler = new CssScaler({
            ".movie-result-mobile": { width: 92, height: 132 },
            ".movie-result-mobile img": { width: 92, height: 132 }
        });
    }

    componentWillUnmount() {
        this._scaler.destroy();
    }

    render() {

        var tiling = calculateTiles(this.props.containerWidth, 92, 5);

        this._scaler.scale(tiling.scaleFactor);

        var items = this.props.result.hits.map((movie) => {
            return <MovieResultItem mobile key={movie.id} id={movie.id} title={movie.title} year={movie.year} poster={movie.poster} description={movie.description} cast={movie.cast} crew={movie.crew} users={movie.users} rating={movie.rating} rating_count={movie.rating_count}/>
        });

        return (
            <div style={{ marginRight: -6 }}>
                {items}
            </div>
        );
    }
}

var MeasuredItems = withMeasure(Items);

class MobileResults extends React.PureComponent {

    render() {
        return (
            <div className="col-12">
                <MeasuredItems {...this.props}/>
            </div>
        );
    }
}

export default MobileResults;