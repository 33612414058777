import React from 'react';
import { Link } from 'react-router-dom';
//import Highlight from 'react-highlighter';

import Toggles from 'components/MovieDetails/Toggles';
import Rating from 'components/MovieDetails/Rating';
import Raty from 'components/Raty';
import Movie from 'components/Movie';

import './MovieResultItem.css';

class CastMember extends React.Component {

    render() {
        let name = this.props.item.name;
        let character = this.props.item.character;

        var characterElem = undefined;
        if (character && character !== "?") {
            characterElem = <span>&nbsp;({character})</span>;
        }

        var separator = undefined;
        if (!this.props.isFirst) {
            separator = <span>, </span>;
        }

        return <span>{separator}<Link to={'/Search/' + name}>{name}</Link>{characterElem}</span>;
    }
}

function shortenString(maxWords, input) 
{
    var sentences = input.split(".");
    var words = 0;
    var currentSentence = 0;
    var result = [];

    while (words < maxWords && currentSentence < sentences.length)  
    {
        // Increase one sentence after the other
        result.push( sentences[currentSentence] );
        
        words = words + sentences[currentSentence].split(" ").length;
        currentSentence++;
    }

    return result.join(".");

}

class MovieResultItem extends React.Component {

    renderCast() {
        if (!this.props.cast || this.props.cast.length === 0) {
            return <div></div>;
        }

        var cast; 
        cast = (this.props.cast.length > 5) ? this.props.cast.slice(0,4) : this.props.cast;


        return (
            <div>
                Mit: {cast.map((item, index) => <CastMember key={index} item={item} isFirst={index === 0}/>)}
            </div>
        );
    }
  
    render() {
  
        var props = this.props;
        var releaseYear = undefined;
        var movieUrl = Movie.url({ id: props.id, title: props.title });
  
        if (props.year) {
            releaseYear = "(" + props.year + ")";
        }
  
        if (this.props.mobile) {
            return (
                <div className="movie-result-mobile">
                    <Link to={movieUrl} style={{ display: "inline-block", color: "inherit" }}>
                        <img src={Movie.poster({ poster: props.poster }, 92)} alt=""/>
                    </Link>
                </div>
            )
        }
        else
        {
            let hasUsers = this.props.users > 0;
            let hasRating = this.props.rating_count > 0;

            return (
                <div className="media" style={{marginBottom:"20px"}}>
                    <div className="mr-3">
                        <Link to={movieUrl} style={{ display: "inline-block", color: "inherit" }}>
                            <img style={{ width: 185, height: 265 }} src={Movie.poster({ poster: props.poster }, 185)} alt=""/>
                        </Link>
                    </div>
                    <div className="media-body" style={{paddingLeft:"5px", paddingRight:"5px"}}>
                        <div className="media-heading">
                        <h5 style={{marginTop: "0px"}}>
                            <Link to={movieUrl}>
                                <span dangerouslySetInnerHTML={{__html:props.title}}></span> <span>{releaseYear}</span>
                            </Link>
                        </h5>
                        {hasUsers &&
                        <div style={{display:"inline-block"}}>
                            <span className="fa fa-user"/>
                            <span style={{marginLeft:"5px"}}>{props.users} Nutzer</span>
                        </div>
                        }
                        {hasRating && 
                            <div style={{display:"inline-block", marginLeft:"10px"}}>
                            <Raty score={this.props.rating} narrow={true} readOnly={true}/>
                            <span style={{marginLeft:"5px"}}> &Oslash; von {this.props.rating_count} Nutzern</span>
                            </div>
                            }
                        </div>     
                        <div>
                            <span dangerouslySetInnerHTML={{ __html: shortenString(50,this.props.description) }} />
                        </div>
                        {this.renderCast()}
                    </div>
                    <div className="mr-3">
                        <div style={{width:"185px", paddingTop:"55px"}} className="text-center ab-light">
                        <Toggles movieId={props.id} title={props.title} poster={props.poster}/>
                        <div className="text-center" style={{ fontSize: "24px", letterSpacing: "-1px"}}>
                            <Rating movieId={props.id} title={props.title} poster={props.poster}/>
                        </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
  }

  export default MovieResultItem;