import { LOGIN_INPUT, LOGIN_CLEAR } from "actions/login";
import { USER_SIGNIN_SUCCESS, USER_SIGNIN_FAILURE } from "actions/user";

const initialState = {
    username: "",
    password: "",
    errors: null
};



function loginFormReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_INPUT:
            state = { ...state };
            state[action.field] = action.value;
            break;
        case LOGIN_CLEAR:
            state = { ...state, username: "", password: "", errors: null };
            break;      
        case USER_SIGNIN_SUCCESS:
            state = { ...state, username: "", password: "", errors: null };
            break;
        case USER_SIGNIN_FAILURE:
            state = { ...state, password: "", errors: action.errors };
            break;
    }

    return state;
}

export default loginFormReducer;
