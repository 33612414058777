import Api from 'components/Api';
import { completeSignIn } from './user';

export const CONFIRM_PASSWORD_INPUT = "CONFIRM_PASSWORD_INPUT";
export const CONFIRM_PASSWORD_SUCCESS = "CONFIRM_PASSWORD_SUCCESS";
export const CONFIRM_PASSWORD_FAILURE = "CONFIRM_PASSWORD_FAILURE";

export function confirmNewPassword(userId, token, callback) {
    return function (dispatch, getState) {
        let newpassword = getState().confirmPasswordForm.password;

        var errors = [];
        if (!newpassword || newpassword === "") {
            errors.push("Passwort fehlt.");
        }
        
        if (errors.length > 0) {
            dispatch({ type: CONFIRM_PASSWORD_FAILURE, errors });
        } else {
            Api.postForm("Token/confirm_new_password", { userId, token, newpassword }).then(reply => {
                if (reply.errors && reply.errors.length > 0) {
                    dispatch({ type: CONFIRM_PASSWORD_FAILURE, errors: reply.errors });
                } else {
                    dispatch({ type: CONFIRM_PASSWORD_SUCCESS, errors: reply.errors });
                    dispatch(completeSignIn(reply.token, reply.user));
                    callback();
                }
            }).catch(errors => {
                dispatch({ type: CONFIRM_PASSWORD_FAILURE, errors: errors });
            });
        }
    }
}