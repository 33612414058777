// Helper functions for displaying users

import Api from 'components/Api';

class User {

    shortName(user) {
        if (!user) {
            return null;
        }

        if (user.name.length > 10) {
            var spaceIndex = user.name.indexOf(" ");
            if (spaceIndex > 0) {
                return user.name.substring(0, spaceIndex);
            }
        }
    
        return user.name;
    }

    // Returns a image URL for the given user.
    img(user) {

        if (!user) {
            return "/Images/unknown.png";
        }

        if (user.image) {
            return Api.getProfileImageUrl(user.image);    
        }

        return Api.getProfileImageUrl(user.img);
    }

    // Returns the URL for the given user.
    url(user) {

        if (!user) {
            return undefined;
        }

        return `/Nutzer/${user.id}`;
    }
}

export default new User();
