import React from 'react';

import Api from 'components/Api';


class CommentForm extends React.Component {

    constructor(props) {
        super(props);

        this._handleSubmit = this._handleSubmit.bind(this);
    }

    _handleSubmit(e) {
        e.preventDefault();

        var comment = this._commentField.value.trim();

        // Empty comment box for the next comment
        this._commentField.value = '';

        // Ignore empty comments
        if (!comment) {
            return;
        }

        // Inform movie list control of new comment (there the server request will take place)
        if (this.props.onSubmitted) {
            this.props.onSubmitted(comment);
        }
    }

    render() {

        var user = this.props.currentUser;

        var profileImgUrl = ((user) ? Api.getProfileImageUrl(user.image) : "/Images/unknown.png");
        var prompt = (this.props.prompt !== null && this.props.prompt !== undefined) ? this.props.prompt : "Dein Kommentar...";
        return (
            <div className="item media">
                <div className="mr-3">
                    <img src={profileImgUrl} alt="" className="img-circle img-small"/>
                </div>
                <div className="media-body">
                    <form className="commentForm form form-inline" style={{ marginTop:"3px", padding: "0px", color: "#111" }} onSubmit={this._handleSubmit}>
                        <input style={{margin: "0px", borderRadius:"15px", border:"none", backgroundColor:"rgb(228,220,220)", color: "#111"}} ref={e => this._commentField = e} type="text" className="col-12" placeholder={prompt}/>
                    </form>
                </div>
            </div>
        );
    }
}

export default CommentForm;

