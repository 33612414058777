import React, { Component } from 'react';

class AGB extends Component {
    render() {
      return (
      <div className="container">
          <div className="row">
              <div className="col-md-12">
                  <h1>Allgemeine Geschäftsbedingungen (AGB) <br />für KinoKicks.de</h1>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  <b>
                                      Stand:
                                      26.11.2013
                                  </b>
                              </font>
                          </font>
                      </font>
                  </p>
                  <p></p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  Die
                                  folgenden Gesch&auml;ftsbedingungen definieren die Bedingungen, unter
                                  denen die Benutzung des Services auf www.kinokicks.de, Andreas
                                  Kohlmaier, Peter Schrey, Siegmar Bu&szlig; KinoKicks GbR,
                                  Klara-Ziegler-Bogen 169, 81739 M&uuml;nchen (im Folgenden
                                  &bdquo;KinoKicks&ldquo;) betrieben wird. Es gelten ausschlie&szlig;lich
                                  diese Allgemeinen Gesch&auml;ftsbedingungen. Abweichende
                                  Gesch&auml;ftsbedingungen des Nutzers werden nicht akzeptiert.
                              </font>
                          </font>
                      </font>
                  </p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  <b>
                                      1.
                                      Beschreibung des Leistungsumfangs
                                  </b>
                              </font>
                          </font>
                      </font>
                  </p>
                  <ol>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          KinoKicks
                                          ist eine offene Plattform und Community rund um das Thema Filme. Sie
                                          erm&ouml;glicht den Benutzern, ihre Filmsammlungen zu verwalten und
                                          mit anderen zu teilen, Filme zu bewerten und Kurzkritiken zu
                                          erfassen. Da die Nutzer ihre Inhalte selbst zusammenstellen, hat
                                          KinoKicks hierauf keinen Einfluss. Filmbeschreibungen und -plakate
                                          werden von dem Dienst TMDB zur Verf&uuml;gung gestellt. Rein
                                          vorsorglich distanziert sich KinoKicks von s&auml;mtlichen Inhalten
                                          sowohl der Nutzer, der TMDB, als auch von sonstigen Dritten, die auf
                                          KinoKicks ver&ouml;ffentlicht werden.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Der
                                          Nutzer kann &uuml;ber die Werbebanner auf der Website und den
                                          Newslettern Produkte bei Herstellern und Shops erwerben. Auf den
                                          zwischen ihm und dem Hersteller bzw. Shop abzuschlie&szlig;enden
                                          Vertrag hat KinoKicks keinen Einfluss und distanziert sich deshalb
                                          vom Inhalt dieser Vertr&auml;ge. Das Angebot von KinoKicks ist
                                          kostenlos. Der Nutzer wird darauf hingewiesen, dass die Hersteller
                                          und Shops im Rahmen von Affilate-Programmen gegebenenfalls Cookies
                                          verwenden, &uuml;ber die die Kaufvermittlung durch KinoKicks
                                          festgestellt wird. Auf diese Cookies hat KinoKicks keinen Einfluss.
                                          Die jeweiligen Hersteller und Shops haben ihre eigenen
                                          Nutzungsbedingungen, auf die KinoKicks ebenfalls keinen Einfluss
                                          hat.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Der
                                          Nutzer erlaubt KinoKicks ausdr&uuml;cklich den Zugriff auf seine
                                          allgemeinen Informationen, E-Mail-Adresse, seine
                                          Profilinformationen, wie Beschreibung, Geburtstag, Heimatstadt und
                                          Webseite, sowie benutzerdefinierte Freundeslisten bei Facebook.
                                          KinoKicks verwendet diese Informationen, um dem Nutzer seinen Dienst
                                          in voller Funktionalit&auml;t anbieten zu k&ouml;nnen, insbesondere
                                          damit der Nutzer seine Beitr&auml;ge mit Freunden teilen kann und
                                          Benachrichtigungen &uuml;ber von Freunden hinzugef&uuml;gte
                                          Filmkritiken und Sammlungen bekommt.
                                      </font>
                                  </font>
                              </font>
                          </p>
                        </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Um
                                          den KinoKicks-Service in vollem Umfang nutzen zu k&ouml;nnen, muss
                                          der Nutzer jeweils die neusten (Browser-)Technologien verwenden bzw.
                                          deren Verwendung auf seinem Computer erm&ouml;glichen (z.B.
                                          Aktivierung von Java Skript, Cookies). Bei Benutzung &auml;lterer
                                          oder nicht allgemein gebr&auml;uchlicher Technologien kann es sein,
                                          dass der Nutzer die Leistungen von KinoKicks nur eingeschr&auml;nkt
                                          nutzen kann.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          KinoKicks
                                          &uuml;bernimmt keine Gew&auml;hrleistung einer Verf&uuml;gbarkeit
                                          des Angebotes und der Erreichbarkeit des Servers.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                  </ol>
                  <p></p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  <b>
                                      2.
                                      Zugang / Vertragsabschluss
                                  </b>
                              </font>
                          </font>
                      </font>
                  </p>
                  <ol>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Die
                                          Nutzung von KinoKicks ist f&uuml;r den Nutzer kostenfrei.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Der
                                          Zugang zu den auf KinoKicks angebotenen Inhalten setzt keine
                                          Anmeldung des Nutzers voraus. Mit seiner Nutzung der auf KinoKicks
                                          angebotenen Inhalte willigt der Nutzer aber in die Geltung dieser
                                          AGB ein. In jedem Fall erfordert KinoKicks eine Benutzeranmeldung,
                                          wenn der Benutzer Beitr&auml;ge verfasst oder er Fotos, Videos oder
                                          andere Inhalte auf KinoKicks einstellt. In dem wird KinoKicks zudem
                                          die IP-Adresse speichern.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Bedingung
                                          f&uuml;r die Registrierung ist, dass der Nutzer vollj&auml;hrig ist
                                          und darf nur von Privatpersonen in Anspruch genommen werden.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Nach
                                          der Anmeldung erh&auml;lt der Nutzer eine Mitteilung per
                                          E-Mail-Nachricht, die den Vertragsabschluss zur kostenlosen
                                          Mitgliedschaft best&auml;tigt.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                  </ol>
                  <p></p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  <b>
                                      3.
                                      Datennutzung, Datenweitergabe und Datenweitervermittlung
                                  </b>
                              </font>
                          </font>
                      </font>
                  </p>
                  <ol>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          KinoKicks
                                          beachtet beim Betrieb seines Services stets die gesetzlichen
                                      </font>
                                  </font>
                              </font><font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Datenschutzbestimmungen,
                                          insbesondere das Bundesdatenschutzgesetz (BDSG) und das
                                          Telemediengesetz (TMG). Es wird darauf hingewiesen, dass die
                                          KinoKicks-Server auch au&szlig;erhalb Deutschlands liegen k&ouml;nnen
                                          und deshalb auch die gesetzlichen Bestimmungen anderer Staaten
                                          anwendbar sein k&ouml;nnen. Jedenfalls werden die Daten der Nutzer
                                          nicht an Dritte verkauft oder weitergegeben, wenn nicht gesetzliche
                                          Bestimmungen dies erfordern.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font face="Verdana, serif">
                                  <font size="2">
                                      Der Nutzer t&auml;tigt
                                      Bestellungen von Produkten unmittelbar mit dem von ihm ausgew&auml;hlten
                                      Hersteller oder Shop. KinoKicks hat hierauf keinen Einfluss.
                                      KinoKicks gibt die Daten nicht an die H&auml;ndler und Hersteller
                                      weiter. Es werden nur Informationen weitergegeben, die im Cookie des
                                      Herstellers und H&auml;ndlers gespeichert wurden. Auf die von
                                      Herstellern und Shops zur Kaufabwicklung verlangten Schritte hat
                                      KinoKicks keinen Einfluss und distanziert sich hiervon rein
                                      vorsorglich.<br/>Die f&uuml;r die Gesch&auml;ftsabwicklung
                                      notwendigen Daten werden ausschlie&szlig;lich vom Verk&auml;ufer
                                      gespeichert und nur im Rahmen der Abrechnung der Affiliate-Erl&ouml;se
                                      an KinoKicks bzw. an mit KinoKicks verbundene Unternehmen oder
                                      Dienstleistungspartner, die als Auftragsdatenverarbeiter im Sinne
                                      des Bundesdatenschutzgesetzes (BDSG) f&uuml;r KinoKicks t&auml;tig
                                      werden, weitergegeben.
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Soweit
                                          KinoKicks &uuml;ber Cookies Affiliate-Einnahmen von seinen Partnern
                                          erwirtschaftet, stimmt der Nutzer dieser Nutzung, Weitergabe und
                                          Verarbeitung seiner Daten zu.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font face="Verdana, serif">
                                  <font size="2">
                                      &Uuml;ber die oben
                                      beschriebenen Zwecke hinaus, verwendet KinoKicks die &uuml;berlassenen
                                      Daten f&uuml;r eigene Werbezwecke, wenn der Nutzer den kostenlosen
                                      Newsletter abonniert hat. Der Nutzer kann jederzeit der weiteren
                                      Verwendung seiner Daten f&uuml;r diesen Zweck durch einfache
                                      Mitteilung an KinoKicks widersprechen.
                                  </font>
                              </font>
                          </p>
                      </li>
                  </ol>
                  <p></p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  <b>
                                      4.
                                      Vertr&auml;ge mit Dritten, Ausland
                                  </b>
                              </font>
                          </font>
                      </font>
                  </p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  Die
                                  von den Nutzern auf KinoKicks ver&ouml;ffentlichten Beitr&auml;ge,
                                  Empfehlungen und Kommentare beziehen sich auch auf Angebote und
                                  Unternehmen, die ggf. im Ausland liegen und mit denen Nutzer Vertr&auml;ge
                                  schlie&szlig;en k&ouml;nnen. Gegebenfalls sind Pass-, Visa-, Devisen-
                                  und/ oder Gesundheitsbestimmungen zu beachten, au&szlig;erdem gelten
                                  f&uuml;r die Vertragsbeziehungen zu den beschriebenen m&ouml;glichen
                                  Einrichtungen und Gesch&auml;ften die jeweils landesgesetzlichen
                                  Bestimmungen. Die Nutzer sind f&uuml;r die Einhaltung dieser
                                  Bestimmungen selbst verantwortlich. Es wird dringend empfohlen, sich
                                  auch &uuml;ber die jeweiligen Allgemeinen Gesch&auml;ftsbedingungen
                                  und sonstigen Vertragsbedingungen der genannten Einrichtungen und
                                  Gesch&auml;fte vor Abschluss von Gesch&auml;ften vor Ort selbst zu
                                  informieren. KinoKicks kann keine Gew&auml;hr f&uuml;r die
                                  Vollst&auml;ndigkeit, Richtigkeit und Aktualit&auml;t der auf diesen
                                  Seiten angebotenen Informationen &uuml;ber diese Gesch&auml;fte und
                                  Einrichtungen &uuml;bernehmen.
                              </font>
                          </font>
                      </font>
                  </p>
                  <p></p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  <b>
                                      5.
                                      Abmeldung/ Ende der Leistungen von KinoKicks
                                  </b>
                              </font>
                          </font>
                      </font>
                  </p>
                  <ol>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Das
                                          Recht beider Parteien zur au&szlig;erordentlichen K&uuml;ndigung der
                                          kostenlosen Mitgliedschaft bleibt unber&uuml;hrt. F&uuml;r KinoKicks
                                          liegt ein au&szlig;erordentlicher K&uuml;ndigungsgrund insbesondere
                                          dann vor, wenn der Nutzer bei der Anmeldung und/oder sp&auml;terer
                                          &Auml;nderung seiner Daten vors&auml;tzlich Falschangaben macht oder
                                          gegen diese Allgemeinen Gesch&auml;ftsbedingungen wiederholt
                                          verst&ouml;&szlig;t.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                  </ol>
                  <p></p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  <b>
                                      6.
                                      Gew&auml;hrleistung
                                  </b>
                              </font>
                          </font>
                      </font>
                  </p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  1.
                                  Die Beitr&auml;ge, Empfehlungen und Kommentare seiner Nutzer dienen
                                  dem Erfahrungsaustausch &uuml;ber Filme. Weder KinoKicks noch die
                                  ver&ouml;ffentlichenden Nutzer k&ouml;nnen die Richtigkeit,
                                  Aktualit&auml;t und die Erheblichkeit der Beitr&auml;ge, Kommentare
                                  und Empfehlungen garantieren.
                              </font>
                          </font>
                      </font>
                  </p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  2.
                                  Genauso kann KinoKicks nicht garantieren, dass die vom Nutzern zu
                                  erwerbenden Produkte die vom Hersteller oder Shop versprochenen
                                  Eigenschaften und Merkmale auch wirklich erf&uuml;llen.
                              </font>
                          </font>
                      </font>
                  </p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  3.
                                  Die auf KinoKicks ver&ouml;ffentlichten Beitr&auml;ge, Empfehlungen
                                  und Kommentare einschlie&szlig;lich der zugeh&ouml;rigen Fotos und
                                  Grafiken spiegeln nur die subjektiven Erfahrungen des jeweiligen
                                  Autors wieder. KinoKicks distanziert sich von diesen Inhalten und
                                  &uuml;bernimmt keine Garantie oder Gew&auml;hrleistung f&uuml;r diese
                                  Inhalte.
                              </font>
                          </font>
                      </font>
                  </p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  4.
                                  KinoKicks distanziert sich auch von den auf der Seite geschalteten
                                  Werbeanzeigen und &uuml;bernimmt keine Garantie oder Gew&auml;hrleistung
                                  f&uuml;r die darauf zu findenden Angebote.
                              </font>
                          </font>
                      </font>
                  </p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  5.
                                  Dem Nutzern ist bewusst, dass er Vertr&auml;ge &uuml;ber den Erwerb
                                  von Produkten ausschlie&szlig;lich mit dem jeweiligen Hersteller oder
                                  Shop seiner Wahl schlie&szlig;t, und KinoKicks mit diesem Vertrag
                                  rechtlich nichts zu tun hat. KinoKicks kann deshalb nicht f&uuml;r
                                  Gew&auml;hrleistungs- oder Haftungsanspr&uuml;che des Nutzern
                                  gegen&uuml;ber dem Vertragspartner einstehen. F&uuml;r diese
                                  Anspr&uuml;che muss sich der Nutzer direkt an den Hersteller oder
                                  Shop wenden.
                              </font>
                          </font>
                      </font>
                  </p>
                  <p></p>
                  <p>
                      <font color="#262626"><font face="Verdana, serif"><font size="2"><b>7.</b></font></font></font><font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                              </font>
                          </font>
                      </font><font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  <b>
                                      Haftung
                                  </b>
                              </font>
                          </font>
                      </font>
                  </p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  1.
                                  KinoKicks ist hinsichtlich der Angaben &uuml;ber Filme und
                                  Empfehlungen auf die von den Nutzern und der TMDB erhaltenen
                                  Informationen angewiesen. KinoKicks hat keine M&ouml;glichkeit, diese
                                  Informationen auf ihre Richtigkeit, Vollst&auml;ndigkeit und
                                  Aktualit&auml;t zu &uuml;berpr&uuml;fen. Das Gleiche gilt f&uuml;r
                                  die Angaben und Bedingungen der geschalteten Werbebanner. KinoKicks
                                  gibt deshalb hinsichtlich all dieser Informationen keinerlei Garantie
                                  oder Zusicherung hinsichtlich der Richtigkeit, Vollst&auml;ndigkeit
                                  oder Aktualit&auml;t ab. Rein vorsorglich distanziert sich KinoKicks
                                  auch von s&auml;mtlichen Beitr&auml;gen, Empfehlungen und Kommentaren
                                  der Nutzer wie auch von s&auml;mtlichen Aussagen der werbenden
                                  Hersteller und Shops. F&uuml;r diese &Auml;u&szlig;erungen &uuml;bernimmt
                                  KinoKicks keine Haftung.
                              </font>
                          </font>
                      </font>
                  </p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  2.
                                  KinoKicks haftet im Rahmen des Vertrags dem Grunde nach nur f&uuml;r
                                  Sch&auml;den des Nutzers, (1) die KinoKicks oder seine gesetzlichen
                                  Vertreter oder Erf&uuml;llungsgehilfen vors&auml;tzlich oder grob
                                  fahrl&auml;ssig herbeigef&uuml;hrt haben, (2) die auf der Verletzung
                                  des Lebens, des K&ouml;rpers oder der Gesundheit, die auf einer
                                  Pflichtverletzung von KinoKicks oder eines seiner gesetzlichen
                                  Vertreter oder Erf&uuml;llungsgehilfen beruhen oder (3) die durch die
                                  Verletzung einer Verpflichtung, deren Erf&uuml;llung die
                                  ordnungsgem&auml;&szlig;e Durchf&uuml;hrung des Vertrages &uuml;berhaupt
                                  erst erm&ouml;glicht und auf deren Einhaltung der Nutzer regelm&auml;&szlig;ig
                                  vertraut und vertrauen darf (Kardinalpflicht), entstanden sind.
                              </font>
                          </font>
                      </font>
                  </p>
                  <ol start="2">
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          KinoKicks
                                          haftet in den F&auml;llen der Ziffer 7 Abs.2 (1) und (2) der H&ouml;he
                                          nach unbegrenzt. In den F&auml;llen der Ziffer 7 Abs.2 (3) wird der
                                          Schadensersatzanspruch auf den vorhersehbaren, vertragstypischen
                                          Schaden begrenzt.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          In
                                          anderen als den in Ziffer 7 Abs.2 genannten F&auml;llen ist die
                                          Haftung von KinoKicks &ndash; unabh&auml;ngig vom Rechtsgrund &ndash;
                                          ausgeschlossen. KinoKicks haftet insbesondere weder f&uuml;r
                                          Sch&auml;den, die dem Nutzern aufgrund eines Kaufes entstehen, da
                                          hierf&uuml;r ausschlie&szlig;lich der jeweilige Vertragspartner des
                                          Nutzern in Frage kommt, noch weil sich Beschreibungen anderer Nutzer
                                          &uuml;ber Segelreviere als unrichtig, unvollst&auml;ndig oder nicht
                                          mehr aktuell erweisen.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          KinoKicks
                                          &uuml;bernimmt auch keine Haftung f&uuml;r verlinkten Inhalte
                                          Dritter.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Die
                                          vorstehenden Haftungsbeschr&auml;nkungen finden entsprechende
                                          Anwendung f&uuml;r alle Organe, Gesellschafter, Angestellten und
                                          Erf&uuml;llungsgehilfen von KinoKicks.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                  </ol>
                  <p></p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  <b>
                                      8.
                                      Rechts&uuml;bertragung/ Verantwortlichkeit und Pflichten des Nutzern
                                      / Verbotene Verhaltensweisen / Freistellung
                                  </b>
                              </font>
                          </font>
                      </font>
                  </p>
                  <ol>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Der
                                          Nutzer ist f&uuml;r den Inhalt seines KinoKicks-Profils und damit
                                          f&uuml;r die Informationen, die er &uuml;ber sich bereitstellt,
                                          allein verantwortlich. Genauso ist er alleine verantwortlich f&uuml;r
                                          die Informationen, die er durch seine Beitr&auml;ge, Empfehlungen
                                          und Kommentare der &Ouml;ffentlichkeit bereitstellt.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Der
                                          Nutzer versichert, dass er das geltende Urheberrecht und
                                          insbesondere die folgenden Regeln bei der Erstellung seiner
                                          Beitr&auml;ge, Empfehlungen und Kommentare einhalten wird:
                                      </font>
                                  </font>
                              </font>
                          </p>
                          <ul>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  &uuml;bertr&auml;gt KinoKicks an den von ihm erstellten Beitr&auml;gen,
                                                  Kommentaren und Empfehlungen (einschlie&szlig;lich Texten,
                                                  Grafiken, Fotos und Videos) das nicht exklusive Nutzungsrecht zur
                                                  &ouml;ffentlichen Zug&auml;nglichmachung, Vervielf&auml;ltigung und
                                                  Verbreitung zeitlich, &ouml;rtlich und inhaltlich unbeschr&auml;nkt.
                                                  Der Nutzer will mit der Zurverf&uuml;gungstellung seiner Inhalte
                                                  einen Beitrag zu der Plattform leisten und dadurch weiteren
                                                  Austausch erm&ouml;glichen. Er ist angesichts des Programmierungs-
                                                  und Betriebsaufwands f&uuml;r KinoKicks damit einverstanden, dass
                                                  er f&uuml;r seine Rechts&uuml;bertragung an seinen Beitr&auml;gen
                                                  auf KinoKicks keine Verg&uuml;tung erh&auml;lt und h&auml;lt dies
                                                  hinsichtlich der ihm zur Verf&uuml;gung stehenden anderen Beitr&auml;ge
                                                  f&uuml;r angemessen.
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Der
                                                  Nutzer wird urheberrechtlich schutzf&auml;hige Werke von Dritten,
                                                  wie insbesondere Fotos, Texte, Grafiken, Videos usw. nur dann in
                                                  seine Beitr&auml;ge, Kommentare und Empfehlungen auf KinoKicks
                                                  einpflegen, wenn er auch f&uuml;r diese Werke die oben
                                                  beschriebenen Rechte auf KinoKicks &uuml;bertragen kann.
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird insbesondere keine Kopien von offensichtlich rechtswidrig
                                                  hergestellten oder offensichtlich rechtswidrig &ouml;ffentlich
                                                  zug&auml;nglich gemachten Vorlagen verwenden.
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Dem
                                                  Nutzer ist bekannt, dass eine Verletzung dieser Bestimmungen zu
                                                  urheberrechtlichen Unterlassungs- und Schadensersatzforderungen der
                                                  Rechtsinhaber gegen ihn f&uuml;hren kann.
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                          </ul>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Der
                                          Nutzer versichert dar&uuml;ber hinaus, dass er das allgemein
                                          geltende Recht und insbesondere die folgenden Regeln bei der
                                          Gestaltung seiner Beitr&auml;ge, Empfehlungen und Kommentare
                                          einhalten wird:
                                      </font>
                                  </font>
                              </font>
                          </p>
                          <ul>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird keine eigenen Affiliate-Links in seine Inhalte einf&uuml;gen.
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird sein KinoKicks Passwort streng geheim halten.
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird bei der Erstellung seiner Beitr&auml;ge und deren &ouml;ffentlicher
                                                  Zug&auml;nglichmachung nicht im Auftrag eines Unternehmens im Sinne
                                                  des UWG handeln. Er wird auf KinoKicks nicht zugunsten des eigenen
                                                  oder eines fremden solchartigen Unternehmens handeln.
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird keine unwahren Tatsachen &uuml;ber Filme, Produkte andere
                                                  Personen oder sonstiges behaupten.
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird keine rufsch&auml;digenden Werturteile, Beleidigungen oder
                                                  Formalbeleidigungen &auml;u&szlig;ern.
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird nicht vors&auml;tzlich die Daten dritter Personen
                                                  (einschlie&szlig;lich E-Mail-Adresse) als seine eigenen ausgeben.
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird nicht personenbezogene Informationen, wie insbesondere Namen,
                                                  Telefon- und Faxnummern, Wohn-, E-Mail-Adressen, Foto-/Videodateien
                                                  und/oder URLs ohne Zustimmung ihres jeweiligen Berechtigten
                                                  erheben, nutzen, verarbeiten und insbesondere Dritten zug&auml;nglich
                                                  machen;
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird nicht diffamierendes, anst&ouml;&szlig;iges oder in sonstiger
                                                  Weise rechtswidriges Material oder solche Informationen verbreiten,
                                                  wie insbesondere Inhalte sexueller, gewaltverherrlichender oder
                                                  rechtsradikaler Natur;
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird andere Personen nicht bedrohen, bel&auml;stigen oder die
                                                  Rechte (einschlie&szlig;lich Pers&ouml;nlichkeitsrechte) Dritter
                                                  verletzen;
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird nicht Daten hochladen, die einen Virus enthalten (infizierte
                                                  Software);
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird den Dienst nicht in einer Art und Weise nutzen, welche die
                                                  Verf&uuml;gbarkeit der Angebote f&uuml;r andere Nutzern nachteilig
                                                  beeinflusst;
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird nicht E-Mails abfangen oder versuchen, sie abzufangen;
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird nicht mit KinoKicks Werbung f&uuml;r bestimmte Produkte,
                                                  Hersteller oder Shops zu betreiben;
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird nicht Kettenbriefe oder &ndash;Mails versenden;
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird in der Personenbeschreibung nicht (Profil) Namen, Adressen,
                                                  Telefon- oder Faxnummern, E-Mail-Adressen, Benutzernamen bzw.
                                                  sonstige Kontaktdaten von Messengerdiensten oder anderen
                                                  Internetservices nennen;
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                              <li>
                                  <p>
                                      <font color="#262626">
                                          <font face="Verdana, serif">
                                              <font size="2">
                                                  Er
                                                  wird nicht Logins und pers&ouml;nliche Passw&ouml;rter an Dritte
                                                  weitergeben oder mit Dritten gemeinsam nutzen und seine Facebook
                                                  Logindaten nicht an Dritte herausgeben oder fahrl&auml;ssig oder
                                                  vors&auml;tzlich Dritten Zugriff auf seinen KinoKicks Account
                                                  gew&auml;hren.
                                              </font>
                                          </font>
                                      </font>
                                  </p>
                              </li>
                          </ul>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Um
                                          die Integrit&auml;t des KinoKicks-Services zu gew&auml;hrleisten,
                                          versichert der Nutzer, dass er diesen ausschlie&szlig;lich in der
                                          Absicht nutzt, sich mit anderen zum Thema Filme und allem, was dazu
                                          geh&ouml;rt, auszutauschen.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Der
                                          Nutzer versichert, dass er im Zusammenhang mit seiner Mitgliedschaft
                                          keine gewerblichen und/oder gesch&auml;ftlichen Absichten verfolgt.
                                          Er verpflichtet sich, KinoKicks nicht gewerblich oder gesch&auml;ftlich
                                          zu nutzen.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Verletzt
                                          der Nutzer eine Pflicht aus dem Nutzungsverh&auml;ltnis, kann
                                          KinoKicks nach den gesetzlichen Bestimmungen Ersatz hieraus
                                          entstehender Sch&auml;den bzw. erforderlicher Aufwendungen
                                          verlangen. Dies gilt nicht, wenn der Nutzer eine Pflichtverletzung
                                          nicht zu
                                      </font>
                                  </font>
                              </font><font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          vertreten
                                          hat. Insbesondere verpflichtet sich der Nutzer, KinoKicks von
                                          jeglicher Haftung und von allen Verpflichtungen, Aufwendungen und
                                          Anspr&uuml;chen freizustellen, die sich f&uuml;r KinoKicks aus den
                                          beschriebenen unzul&auml;ssigen Verhaltensweisen des Nutzern wie z.
                                          B. aus &uuml;bler Nachrede, Beleidigung, Verletzung von
                                          Pers&ouml;nlichkeitsrechten, Verletzung von Urheberrechten,
                                          gewerblichen Schutzrechten oder sonstiger Rechte Dritter oder dem
                                          Ausfall von Dienstleistungen f&uuml;r andere Nutzern ergeben.
                                      </font>
                                  </font>
                              </font>
                          </p>
  
                      </li>
                  </ol>
                  <p></p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  <b>
                                      9.
                                      Sperrung bei Verdacht
                                  </b>
                              </font>
                          </font>
                      </font>
                  </p>
                  <ol>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          KinoKicks
                                          ist berechtigt, im Falle der begr&uuml;ndeten Beschwerde eines
                                          Dritten, des Verdachts einer Rechtsverletzung z.B. aufgrund
                                          Hinweises eines anderen Nutzers, oder eines Versto&szlig;es gegen
                                          diese Allgemeinen Gesch&auml;ftsbedingungen die Inhalte des Nutzern
                                          zu sperren, welche diese Beschwerde oder diesen Verdacht ausl&ouml;sen.
                                          Das Recht zur au&szlig;erordentlichen K&uuml;ndigung (Ziffer 5 Abs.
                                          2) bleibt hiervon unber&uuml;hrt.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Wenn
                                          eine vorherige Abmahnung f&uuml;r KinoKicks zumutbar ist, bedarf die
                                          Sperrung der vorherigen Abmahnung, um dem Nutzern Gelegenheit zu
                                          geben, den Verdacht auszur&auml;umen oder Abhilfe zu schaffen. Ist
                                          eine vorherige Abmahnung nicht zumutbar, z. B. weil die Sperrung
                                          erforderlich ist, um einen etwaigen Schaden von KinoKicks, einem
                                          Hersteller, Shop oder einem anderen Nutzern abzuwenden, wird
                                          KinoKicks den Nutzern unverz&uuml;glich im Nachhinein &uuml;ber die
                                          Sperrung informieren und ihm dann Gelegenheit zur Stellungnahme und
                                          Abhilfe geben.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          KinoKicks
                                          kann den Inhalt jedoch stets l&ouml;schen bzw. gesperrt halten, wenn
                                          KinoKicks hierzu durch ein Gericht oder durch staatliche Beh&ouml;rden
                                          aufgefordert wird oder das Nutzungsverh&auml;ltnis beendet ist.
                                          Dasselbe gilt, wenn die L&ouml;schung oder Sperrung erforderlich
                                          ist, um einen drohenden Schaden von KinoKicks, einem Hersteller,
                                          Shop oder einem anderen Nutzern abzuwenden.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                  </ol>
                  <p></p>
                  <p>
                      <font color="#262626">
                          <font face="Verdana, serif">
                              <font size="2">
                                  <b>
                                      10.
                                      Schlussbestimmungen
                                  </b>
                              </font>
                          </font>
                      </font>
                  </p>
                  <ol>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Es
                                          gilt deutsches Recht unter Ausschluss des UN-Kaufrechts und der
                                          Kollisionsnormen.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          M&uuml;ndliche
                                          oder schriftliche Nebenabreden bestehen nicht.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                      <li>
                          <p>
                              <a name="_GoBack"></a><font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          Sollten
                                          einzelne Bestimmungen dieser Vereinbarung ganz oder teilweise
                                          unwirksam sein
                                      </font>
                                  </font>
                              </font><font color="#262626">
                                  <font face="Verdana, serif">
                                      <font size="2">
                                          oder
                                          werden, so bleibt der Vertrag im &Uuml;brigen wirksam.
                                      </font>
                                  </font>
                              </font>
                          </p>
                      </li>
                  </ol>
              </div>
          </div>
      </div>
      
      
      )
    }
  }
  
  export default AGB;