import Cookies from 'js-cookie';

import Api from 'components/Api';
import GoogleAnalytics from 'GoogleAnalytics';
import OAuth from 'components/OAuth';

import { reloadMovieUserList } from './movie';

import { VIEW_INITIALIZE } from './view';

export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAILURE = "USER_SIGNIN_FAILURE";
export const USER_SIGNOUT = "USER_SIGNOUT";

export const UPDATE_MOVIE_INFO = "UPDATE_MOVIE_INFO";
export const UPDATE_MOVIE_TAGS = "UPDATE_MOVIE_TAGS";

export const USER_REGISTRATION_STARTED = "USER_REGISTRATION_STARTED";
export const USER_REGISTRATION_SUCCESS = "USER_REGISTRATION_SUCCESS";
export const USER_REGISTRATION_FAILURE = "USER_REGISTRATION_FAILURE";

export const CONFIRM_REGISTRATION_FAILURE = "CONFIRM_REGISTRATION_FAILURE";

export const USER_PROFILE_LOAD_SUCCESS = "USER_PROFILE_LOAD_SUCCESS";
export const USER_SET_PROFILE_IMAGE = "USER_SET_PROFILE_IMAGE";
export const USER_SETTINGS_UPDATE = "SETTINGS_UPDATE";

export const USER_LOAD_MOVIES = "USER_LOAD_MOVIES";
export const USER_LOAD_MOVIES_SUCCESS = "USER_LOAD_MOVIES_SUCCESS";
export const USER_LOAD_MOVIES_FAILURE = "USER_LOAD_MOVIES_FAILURE";

export const USER_LOAD_LISTS = "USER_LOAD_LISTS";
export const USER_LOAD_LISTS_SUCCESS = "USER_LOAD_LISTS_SUCCESS";
export const USER_LOAD_LISTS_FAILURE = "USER_LOAD_LISTS_FAILURE";

export const USER_LOAD_REVIEWS = "USER_LOAD_REVIEWS";
export const USER_LOAD_REVIEWS_SUCCESS = "USER_LOAD_REVIEWS_SUCCESS";
export const USER_LOAD_REVIEWS_FAILURE = "USER_LOAD_REVIEWS_FAILURE";

export const USER_LOAD_FRIENDS = "USER_LOAD_FRIENDS";
export const USER_LOAD_FRIENDS_SUCCESS = "USER_LOAD_FRIENDS_SUCCESS";
export const USER_LOAD_FRIENDS_FAILURE = "USER_LOAD_FRIENDS_FAILURE";

export const USER_LOAD_FRIEND_REQUESTS = "USER_LOAD_FRIEND_REQUESTS";
export const USER_LOAD_FRIEND_REQUESTS_SUCCESS = "USER_LOAD_FRIEND_REQUESTS_SUCCESS";
export const USER_LOAD_FRIEND_REQUESTS_FAILURE = "USER_LOAD_FRIEND_REQUESTS_FAILURE";

export const USER_LOAD_TAGS = "USER_LOAD_TAGS";
export const USER_LOAD_TAGS_SUCCESS = "USER_LOAD_TAGS_SUCCESS";
export const USER_LOAD_TAGS_FAILURE = "USER_LOAD_TAGS_FAILURE";

export const USER_ADD_MOVIE = "USER_ADD_MOVIE";



export function completeSignIn(token, user) {
    return function (dispatch, getState) {
        Cookies.set("KinoKicks_AccessToken", token);
        Cookies.set("KinoKicks_UserInfo", user);

        Api.setAccessToken(token);

        let currentUser = getState().user.currentUser;
        if (currentUser && user.id === currentUser.id) {
            // The user is already signed in (maybe the SSR did that).
            return Promise.resolve();
        }

        GoogleAnalytics.event({ category: "action", action: "sign-in" });

        dispatch({ type: USER_SIGNIN_SUCCESS, user, token });

        let profileTask = dispatch(loadProfile());
        let moviesTask = dispatch(loadMovies());
        let listsTask = dispatch(loadLists());
        let reviewsTask = dispatch(loadReviews());
        let friendsTask = dispatch(loadFriends());
        let friendRequestsTask = dispatch(loadFriendRequests());
        let tagsTask = dispatch(loadTags());

        dispatch(reloadMovieUserList());

        return Promise.all([ profileTask, moviesTask, listsTask, reviewsTask, friendsTask, friendRequestsTask, tagsTask ]);
    }
}

export function autoSignIn(dispatch, getState) {
    var tokenCookie = Cookies.get("KinoKicks_AccessToken");
    var userCookie = Cookies.getJSON("KinoKicks_UserInfo");
    if (tokenCookie && userCookie) {
        dispatch(completeSignIn(tokenCookie, userCookie));
    }
}

// Signs in a user with password.
export function signIn(username, password, callback) {

    return function (dispatch, getState) {
        Api.postForm('Token', { username, password }).then(data => {
            if (data.token) {
                dispatch(completeSignIn(data.token, data.user));
                callback();
            } else {
                dispatch({ type: USER_SIGNIN_FAILURE, errors: data.errors });
            }
        }).catch(errors => {
            dispatch({ type: USER_SIGNIN_FAILURE, errors })
        });
    }
}

export function signInWithGoogle(callback) {
    return function (dispatch, getState) {
        OAuth.signIn("google")
            .then(result => {
                dispatch(completeSignIn(result.token, result.user));
                callback();
            }).catch(errors => {
                dispatch({ type: USER_SIGNIN_FAILURE, errors: errors });
            });
    }
}

export function signInWithFacebook(callback) {
    return function (dispatch, getState) {
        OAuth.signIn("facebook")
            .then(result => {
                dispatch(completeSignIn(result.token, result.user));
                callback();
            }).catch(errors => {
                dispatch({ type: USER_SIGNIN_FAILURE, errors: errors });
            });
    }
}

export function signOut() {
    return function (dispatch, _) {
        Api.setAccessToken(null);

        Cookies.remove("KinoKicks_AccessToken");
        Cookies.remove("KinoKicks_UserInfo");

        GoogleAnalytics.event({ category: "action", action: "sign-out" });

        dispatch({ type: USER_SIGNOUT });

        dispatch(reloadMovieUserList());
    }
}

export function register(username, emailaddr, password, allowEmails) {
    return function (dispatch, _) {
        dispatch({ type: USER_REGISTRATION_STARTED });
        Api.postForm('Token/register', { userName: username, emailAddr: emailaddr, password, newsletter: allowEmails }).then(data => {
            if (!data.errors || data.errors.length == 0) {
                dispatch({ type: USER_REGISTRATION_SUCCESS });
            } else {
                dispatch({ type: USER_REGISTRATION_FAILURE, errors: data.errors });
            }
        }).catch (errors => {
            dispatch({ type: USER_REGISTRATION_FAILURE, errors });
        });
    }
}

export function confirmRegistration(userid, token) {
    return function (dispatch, _) {
        Api.postForm('Token/confirm_registration', { userid, token }).then(data => {
            if (data.token) {
                dispatch(completeSignIn(data.token, data.user));
            } else {
                dispatch({ type: CONFIRM_REGISTRATION_FAILURE, errors: data.errors });
            }
        }).catch(errors => { 
            dispatch({ type: CONFIRM_REGISTRATION_FAILURE, errors });
        });
    }
}

export function updateMovieInfo(movieId, title, poster, update) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "action", action: "update-movie-info" });
        
        if (typeof update.known !== "undefined" && !update.known) {
            update.rating = 0;
        }
        if (typeof update.rating !== "undefined" && update.rating > 0) {
            update.known = true;
        }

        Api.putJson(`Movie/${movieId}/user`, update);
        dispatch({ type: UPDATE_MOVIE_INFO, movieId, title, poster, update });
    }
}

export function addMovie(movie) {
    return function (dispatch, getState) {
        GoogleAnalytics.event({ category: "action", action: "update-movie-info" });
        var update = { known: true };
        var userId = getState().user.currentUser.id;
        var movieInfo = { ...movie, known: true, tags: [], genre: [] };
        let movieId = movie.id; 
        Api.putJson(`Movie/${movie.id}/user`, update);
      
        dispatch({ type: USER_ADD_MOVIE, movieInfo, movieId, update, userId });
       }
}

export function updateMovieTags(movieId, dimensionId, values) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "action", action: "update-tags" });

        dispatch({ type: UPDATE_MOVIE_TAGS, movieId, dimensionId, values });

        var updates = [];
        updates.push({ dimension: dimensionId, values });
        Api.putJson(`Movie/${movieId}/tags`,updates);
    }
}

export function loadMovies() {
    return function (dispatch, getState) {
        dispatch({ type: USER_LOAD_MOVIES });
        return Api.get(`User/${getState().user.currentUser.id}/movies`).then(movies => {
            // Restore empty lists to simplify access
            movies.forEach(m => {
                if (!m.tags) { m.tags = []; }
                if (!m.genre) { m.genre = []; }
                if (!m.title) { m.title = 'Fehler'; }
            });

            movies.sort((a, b) => a.title.localeCompare(b.title));

            dispatch({ type: USER_LOAD_MOVIES_SUCCESS, movies });
        }).catch(_ => { 
            dispatch({ type: USER_LOAD_MOVIES_FAILURE });
        });
    }
}

function loadLists() {
    return function (dispatch, getState) {
        dispatch({ type: USER_LOAD_LISTS });
        return Api.get(`User/${getState().user.currentUser.id}/lists`).then(lists => {
            dispatch({ type: USER_LOAD_LISTS_SUCCESS, lists });
        }).catch(_ => {
            dispatch({ type: USER_LOAD_LISTS_FAILURE });
        });
    }
}

function loadReviews() {
    return function (dispatch, getState) {
        dispatch({ type: USER_LOAD_REVIEWS });
        return Api.get(`User/${getState().user.currentUser.id}/reviews`).then(reviews => {
            dispatch({ type: USER_LOAD_REVIEWS_SUCCESS, reviews });
        }).catch(_ => {
            dispatch({ type: USER_LOAD_REVIEWS_FAILURE });
        });
    }
}

function loadFriends() {
    return function (dispatch, getState) {
        dispatch({ type: USER_LOAD_FRIENDS });
        Api.get(`User/${getState().user.currentUser.id}/friends`).then(friends => {
            dispatch({ type: USER_LOAD_FRIENDS_SUCCESS, friends });
        }).catch(_ => {
            dispatch({ type: USER_LOAD_FRIENDS_FAILURE });
        });
    }
}

function loadFriendRequests() {
    return function (dispatch, getState) {
        dispatch({ type: USER_LOAD_FRIEND_REQUESTS });
        return Api.get(`User/${getState().user.currentUser.id}/friendrequests`).then(requests => {
            dispatch({ type: USER_LOAD_FRIEND_REQUESTS_SUCCESS, requests });
        }).catch(_ => {
            dispatch({ type: USER_LOAD_FRIEND_REQUESTS_FAILURE });
        });
    }
}

function loadTags() {
    return function (dispatch, getState) {
        dispatch({ type: USER_LOAD_TAGS });
        Api.get(`User/${getState().user.currentUser.id}/tags`).then(tags => {
            dispatch({ type: USER_LOAD_TAGS_SUCCESS, tags });
        }).catch(_ => {
            dispatch({ type: USER_LOAD_TAGS_FAILURE });
        });
    }
}


export function loadProfile() {
    return function (dispatch, getState) {
        var userId = getState().user.currentUser.id;
        return Api.get(`User/${getState().user.currentUser.id}`).then(data => {
            dispatch({ type: USER_PROFILE_LOAD_SUCCESS, data});
            dispatch({ type: VIEW_INITIALIZE, userId });
        });
    }
}

export function setProfileImage(image) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "action", action: "set-profile-image" });
        dispatch({ type: USER_SET_PROFILE_IMAGE, image });
        Api.putJson('User/ProfileImage', image);
    }
}

export function updateSettings(collectionVisibility, tagVisibility, wantNewsletter) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "action", action: "settings-update" });
        Api.putJson('Token/Settings', { collection: collectionVisibility, tags: tagVisibility, newsletter: wantNewsletter });
        dispatch({ type: USER_SETTINGS_UPDATE, collectionVisibility, tagVisibility, wantNewsletter });
    }
}


export function getMovieInfo(state, movieId) {
    let movieInfo = null;

    if (state.user.movies) {
        movieInfo = state.user.movies.find(m => m.id == movieId);
    }

    if (!movieInfo) {
        movieInfo = { id: movieId, rating: 0, tags: [], genre: [] };
    }

    return movieInfo;
}
