import React from 'react';
import { Link } from 'react-router-dom';
import Movie from 'components/Movie';


class Cast extends React.PureComponent {

    render() {

        var cast = this.props.cast;
        var imageSize = this.props.small ? 45 : 92;
        var height = this.props.small ? 68 : 138;

        if (!cast) {
            return null;
        }

        var castElems = [];
        cast.forEach((elem, i) => {
          
            castElems.push(
                <React.Fragment key={`n${i}`}>

                    <div className="text-center" style={{display:"inline-block", textAlign:"center", whiteSpace:"normal", verticalAlign:"top", width: imageSize + 50}}>
                        <Link to={`/Search/${elem.people.name}`}>     
                    
                    
                            <img src={Movie.profile(elem.people,imageSize)} style={{height:height}}/> 
                            {this.props.small &&
                                <h6 style={{marginTop:"5px"}}>
                                    {elem.people.name} <br/>
                                    <small>{elem.character}</small>
                                </h6>
                            }
                            {!this.props.small &&
                                <h5 style={{marginTop:"5px"}}>
                                    {elem.people.name} <br/>
                                    <small>{elem.character}</small>
                                </h5>
                            }
                          
                        </Link>
                    </div>
                
                </React.Fragment>
            );
        });

        return (
            <div style={{overflowX:"scroll", whiteSpace:"nowrap"}}> 
              {castElems}
            </div>
        );
    }
}

export default Cast;