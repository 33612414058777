import React from 'react';

import { connect } from 'components/StoresContext';

import SearchBox from "./SearchBox";
import PagerContainer from "./PagerContainer";
import MovieResults from './MovieResults';
import { setSearchInput, setSearchPageSize } from 'actions/search';

class SearchPage extends React.PureComponent
{
    componentDidMount() {
        this._updatePageSize();

        if (this.props.query)
        {
            this.props.setInput(this.props.query);
        }
    }

    componentDidUpdate() {
        this._updatePageSize();
    }

    _updatePageSize() {
        // Display more result in mobile mode since icons are smaller
        if (this.props.mobile) {
            this.props.setPageSize(100);
        } else {
            this.props.setPageSize(10);
        }
    }

    render() {
        return (
            <div className="container" style={{ paddingTop: 10, paddingBottom: 10 }}>
                <SearchBox/>
                <MovieResults/>
                <PagerContainer/>
            </div>
        );
    }
}

export default connect(SearchPage, state => ({
    mobile: state.windowSize.mobile
 }), (dispatch) => ({
    setPageSize: pageSize => dispatch(setSearchPageSize(pageSize)),
    setInput: input => dispatch(setSearchInput(input))
}));
