// **************************************************************************
//   Some generic utility functions.
// **************************************************************************


// Determine if we are running in a browser or not.
function runningInBrowser() {
    return typeof window !== 'undefined' && window.document;
}

export default { runningInBrowser: runningInBrowser };
