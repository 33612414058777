import GoogleAnalytics from 'GoogleAnalytics';

import Api from 'components/Api';

export const LIST_LOAD = "LIST_LOAD";
export const LIST_LOAD_SUCCESS = "LIST_LOAD_SUCCESS";
export const LIST_LOAD_SUGGESTIONS_SUCCESS = "LIST_LOAD_SUGGESTIONS_SUCCESS";

export const LIST_LIKE = "LIST_LIKE";
export const LIST_UNLIKE = "LIST_UNLIKE";
export const LIST_CREATE_SUCCESS = "LIST_CREATE_SUCCESS";
export const LIST_DELETE = "LIST_DELETE";
export const LIST_EDIT = "LIST_EDIT";
export const LIST_END_EDIT = "LIST_END_EDIT";
export const LIST_EDIT_FIELD = "LIST_EDIT_FIELD";
export const LIST_UPDATE_HEADER = "LIST_UPDATE_HEADER";
export const LIST_PUBLISH = "LIST_PUBLISH";
export const LIST_ADD_MOVIE = "LIST_ADD_MOVIE";
export const LIST_MOVE_MOVIE = "LIST_MOVE_MOVIE";
export const LIST_VOTE_MOVIE = "LIST_VOTE_MOVIE";
export const LIST_REMOVE_MOVIE = "LIST_REMOVE_MOVIE";
export const LIST_COMMENT_MOVIE = "LIST_COMMENT_MOVIE";



export function loadList(listId, refresh) {
    return function (dispatch, getState) {

        let state = getState().listStore;
        if (!refresh && state.list && state.list.id === listId) {
            // This list is already loaded. We're done.
            return Promise.resolve({ list: state.list, suggestions: state.suggestions });
        }

        dispatch({ type: LIST_LOAD, listId });
        var listData = Api.get(`Lists/${listId}`).then(data => {
            dispatch({ type: LIST_LOAD_SUCCESS, data });
        }).catch(_ => {});

        var suggestionsData = Api.get(`Lists/${listId}/suggestions`).then(data =>  {
            dispatch({ type: LIST_LOAD_SUGGESTIONS_SUCCESS, data });
        }).catch(_ => {});

        return Promise.all([ listData, suggestionsData ]).then(x => {
            let state = getState().listStore;
            return { list: state.list, suggestions: state.suggestions };
        });
    }
}

export function likeList() {
    return function (dispatch, getState) {
        GoogleAnalytics.event({ category: "action", action: "list-like" });
        let listId = getState().listStore.list.id;
        Api.postJson(`Lists/${listId}/likes`).catch(_ => {});
        dispatch({ type: LIST_LIKE, listId });
    }
}

export function unlikeList() {
    return function (dispatch, getState) {
        GoogleAnalytics.event({ category: "action", action: "list-unlike" });
        let listId = getState().listStore.list.id;
        Api.deleteJson(`Lists/${listId}/likes`).catch(_ => {});
        dispatch({ type: LIST_UNLIKE, listId });
    }
}

export function createMovieList(movie, callback) {
    return function (dispatch, getState) {
        GoogleAnalytics.event({ category: "action", action: "list-create-new-list" });

        let serverArgs = {
            name: "Neue Liste",
            description: "",
            movieIds: []
        };

        if (movie) {
            serverArgs.movieIds.push({ id: movie.id });
        }

        Api.postJson('Lists', serverArgs).then(listId => {

            let listData = {
                id: listId,
                name: "Neue Liste",
                description: "",
                owner: getState().user.currentUser,
                movies: [],
                featured: false,
                users_can_add_movies: true,
                is_published: false,
                created_at: new Date(),
                can_edit: true,
                is_liked_by_me: false,
                stats: {
                    total_movies: 0,
                    total_votes: 0,
                    total_participants: 1,
                    total_comments: 0,
                    total_likes: 0
                }
            };

            if (movie) {
                listData.movies.push({ pos: 0, orig_pos: 0, movie, added_by: getState().user.currentUser, votes: 0, score: 0, vote: 0, upvoted_by: [], comments: [] });
            }

            dispatch({ type: LIST_CREATE_SUCCESS, list: listData });

            if (callback) {
                callback(`/Listen/${listId}`);
            }
        }).catch(_ => { });
    }
}

export function publish() {
    return function (dispatch, getState) {
        let listId = getState().listStore.list.id;
        Api.postJson(`Lists/${listId}/_publish`, { }).catch(_ => {});

        dispatch({ type: LIST_PUBLISH, listId });
    }
}

export function deleteList() {
    return function (dispatch, getState) {
        let listId = getState().listStore.list.id;
        Api.deleteJson(`Lists/${listId}`).catch(_ => {});
        dispatch({ type: LIST_DELETE, listId });
    }
}

export function editList() {
    return { type: LIST_EDIT };
}

export function endEditList() {
    return { type: LIST_END_EDIT };
}

export function updateListField(field, value) {
    return { type: LIST_EDIT_FIELD, field, value };
}

export function updateHeader() {
    return function (dispatch, getState) {
        GoogleAnalytics.event({ category: "action", action: "list-update-list" });

        var state = getState().listStore;
        var update = {};
        if (state.list.name !== state.edit.name) {
            update.name = state.edit.name;
        }
        if (state.list.description !== state.edit.description) {
            update.description = state.edit.description;
        }
        if (state.list.users_can_add_movies !== state.edit.users_can_add_movies) {
            update.users_can_add_movies = state.edit.users_can_add_movies;
        }

        Api.patchJson(`Lists/${state.list.id}`, update).catch(_ => {});

        dispatch({ type: LIST_UPDATE_HEADER, listId: state.list.id, update });
    }
}

export function addMovieToList(movieId, poster, title, year, backdrop) {
    return function (dispatch, getState) {
        if (getState().listStore.list.movies.find(x => x.movie.id === movieId)) {
            return;
        }

        GoogleAnalytics.event({ category: "action", action: "add-movie-to-list" });
        let listId = getState().listStore.list.id;
        Api.postJson(`Lists/${listId}/movies`, movieId).catch(_ => {});
        dispatch({ type: LIST_ADD_MOVIE, listId, movie: { id: movieId, poster, title, year, backdrop }, user: getState().user.currentUser });
    }
}

export function moveMovieOnList(movieId, direction) {
    return function (dispatch, getState) {
        let listId = getState().listStore.list.id;
        Api.postJson(`Lists/${listId}/movies/${movieId}/_move`, direction).catch(_ => {});

        dispatch({ type: LIST_MOVE_MOVIE, listId, movieId, direction });
    }
}

export function voteMovieOnList(movieId, vote) {
    return function (dispatch, getState) {
        let listId = getState().listStore.list.id;
        Api.postJson(`Lists/${listId}/votes`, { movieId, vote });
        dispatch({ type: LIST_VOTE_MOVIE, listId, movieId, vote, user: getState().user.currentUser });
    }
}

export function removeMovieFromList(movieId) {
    return function (dispatch, getState) {
        GoogleAnalytics.event({ category: "action", action: "remove-movie-from-list" });
        let listId = getState().listStore.list.id;
        Api.deleteJson(`Lists/${listId}/movies/${movieId}`);
        dispatch({ type: LIST_REMOVE_MOVIE, listId, movieId });
    }
}

export function commentMovieOnList(movieId, text) {
    return function (dispatch, getState) {
        GoogleAnalytics.event({ category: "action", action: "create-list-movie-comment" });
        let listId = getState().listStore.list.id;
        Api.postJson(`Lists/${listId}/movies/${movieId}/comments`, text).then(id => {
            dispatch({ type: LIST_COMMENT_MOVIE, listId, movieId, text, user: getState().user.currentUser, id });
        });
    }
}