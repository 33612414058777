import React from 'react';

class VoteUp extends React.Component {

    constructor(props) {
        super(props);

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick(e) {
        e.stopPropagation();
        e.preventDefault();

        if (this.props.onVote) {
            this.props.onVote(1);
        }
    }

    render() {

        var style = {
            fontSize: "48px",
        };
        var handler = undefined;

        if (this.props.voting === 1) {
            style.color = "#13ba00";
        }
        else if (this.props.voting === -1) {
            style.color = "rgb(238,238,238)";
            style.cursor = "pointer";
            handler = this._handleClick;
        }
        else {
            style.cursor = "pointer";
            handler = this._handleClick;
        }

        return (
            <span className="fa fa-caret-up" style={style} onClick={handler}></span>
        );
    }
}

class VoteDown extends React.Component {

    constructor(props) {
        super(props);

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick(e) {

        e.stopPropagation();
        e.preventDefault();

        if (this.props.onVote) {
            this.props.onVote(-1);
        }
    }

    render() {

        var style = {
            fontSize: "48px",
        };
        var handler = undefined;

        if (this.props.voting === -1) {
            style.color = "#f00";
        }
        else if (this.props.voting === 1) {
            style.color = "rgb(238,238,238)";
            style.cursor = "pointer";
            handler = this._handleClick;
        }
        else {
            style.cursor = "pointer";
            handler = this._handleClick;
        }

        return (
            <span className="fa fa-caret-down" style={style} onClick={handler}></span>
        );
    }
}

class VoteBox extends React.Component {

    render() {

        var score = this.props.score;

        return (
            <div className="media-right text-center" style={{paddingLeft:"10px"}}>
                <VoteUp voting={this.props.voting} onVote={this.props.onVote}/><br/>
                <span style={{fontSize:"24px"}}>{score}</span><br/>
                <VoteDown voting={this.props.voting} onVote={this.props.onVote}/>
            </div>
        );
    }
}

export default VoteBox; 

export {VoteBox, VoteDown, VoteUp }