// A common reducer for the review lists.
//
// Reviews are shown for the current user, other user and per movie.


import { REVIEW_ADD_COMMENT, REVIEW_CREATE, REVIEW_VOTE, REVIEW_DELETE } from "actions/reviews";

function compareStr(a, b) {
    return a < b ? 1 : (a > b ? -1 : 0);
}

function sortReviews(reviews) {
    reviews.sort((a, b) => {
        if (a.score === b.score) {
            return compareStr(a.created_at, b.created_at);
        } else {
            return b.score - a.score;
        }
    });
}

export default function reduce(state, action, filter) {
    switch (action.type) {

        case REVIEW_CREATE: {
            if (!state.reviews) {
                break;
            }

            if (filter && !filter(action.review)) {
                break;
            }

            let reviews = state.reviews.slice();
            reviews.splice(0, 0, action.review);

            sortReviews(reviews);

            state = { ...state, reviews };
            break;
        }
        case REVIEW_ADD_COMMENT: {

            if (!state.reviews) {
                break;
            }

            let reviewIndex = state.reviews.findIndex(x => x.id === action.reviewId);

            if (reviewIndex >= 0) {
                let review = { ...state.reviews[reviewIndex] };
                review.comments = review.comments.slice();
                review.comments.push(action.comment);
        
                let reviews = state.reviews.slice();
                reviews.splice(reviewIndex, 1, review);

                sortReviews(reviews);

                state = { ...state, reviews };
            }
            break;
        }
        case REVIEW_VOTE: {
            // Params:
            //   reviewId - ID of the review that was voted for
            //   vote     - The new vote value

            if (!state.reviews) {
                break;
            }

            let reviewIndex = state.reviews.findIndex(x => x.id === action.reviewId);

            if (reviewIndex >= 0) {
                let review = { ...state.reviews[reviewIndex] };

                // Change score and voting
                review.score = review.score + action.vote;
                review.vote = review.vote + action.vote;

                let reviews = state.reviews.slice();
                reviews.splice(reviewIndex, 1, review);

                sortReviews(reviews);

                state = { ...state, reviews };
            }
            break;
        }
        case REVIEW_DELETE: {

            if (!state.reviews) {
                break;
            }

            let reviewIndex = state.reviews.findIndex(x => x.id === action.reviewId);

            if (reviewIndex >= 0) {

                let reviews = state.reviews.slice();
                reviews.splice(reviewIndex, 1);

                state = { ...state, reviews };
            }
            break;
        }
        default:
    }

    return state;
}