import React from 'react';

import './ToggleMovieInfoButton.css';

class ToggleMovieInfoButton extends React.Component {

    constructor(props) {
        super(props);

        this._handleClicked = this._handleClicked.bind(this);
    }

    _handleClicked(evt) {
        evt.stopPropagation();
        evt.preventDefault();

        this.props.onToggle(!this.props.checked);
    }

    render() {

        var iconClass = "fa fa-fw " + this.props.icon;
        var btnClass = "btn btn-circle " + this.props.btnSize + " " + this.props.btnColor;
        var capClass = "togglebtn-caption";

        if (this.props.checked) {
            if (this.props.btnActiveColor) {
                btnClass = "btn btn-circle " + this.props.btnSize + " " + this.props.btnActiveColor;
            }
            btnClass = btnClass + " active";
            if (this.props.activeIcon) {
                iconClass = "fa "+this.props.activeIcon;
            } 
            else {
                iconClass = "fa fa-check";
            }
            
            capClass = capClass + " active";
        }

        var caption;
        if (this.props.captionLineBreak) {
            caption = this.props.caption.split(" ").map(function (line, i) { return (<span key={i}>{ line }<br/></span> ) });
        } else {
            caption = this.props.caption;
        }

        return (
            <div className="text-center" style={{ display: "inline-block" }}>
                <button type="button" className={btnClass} onClick={this._handleClicked} style={{padding:"0px"}}>
                    <i className={iconClass}></i>
                </button><br />
                <div className={capClass} style={{ lineHeight: "100%" }}>
                    {caption}
                </div>
            </div>
        );
    }
}

export default ToggleMovieInfoButton;
