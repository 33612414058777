import React from 'react';

import { connect } from 'components/StoresContext';

import Api from 'components/Api';
import MovieDetails from 'components/MovieDetails';
import { loadMovie } from 'actions/movie';

class Film extends React.Component {

    componentWillMount() {
        var movieId = Api.getNumericId(this.props.match.params.id);
        this.props.loadMovie(movieId);
    }

    render() {
        return (
            <MovieDetails/>
        );
    }
}

export default connect(Film, _ => ({ }), dispatch => ({
    loadMovie: m => dispatch(loadMovie(m))
}));