import { LOAD_MOVIE, LOAD_MOVIE_USERS_SUCCESS, LOAD_MOVIE_INFO_SUCCESS, LOAD_MOVIE_LISTS_SUCCESS, LOAD_MOVIE_REVIEWS_SUCCESS, LOAD_MOVIE_TRAILER_SUCCESS } from 'actions/movie';
import { USER_SIGNIN_SUCCESS, USER_SIGNOUT, UPDATE_MOVIE_INFO } from 'actions/user';

import reduceReviewActions from './reviewList';

const initialState = {
    movieId: null,
    movieInfo: null,
    trailer: null,
    lists: null,
    users: null,
    reviews: null,

    currentUserId: null
}

function compareDates(a, b) {
    if (!a && !b) {
        return 0;
    }

    if (!a) {
        return -1;
    }

    if (!b) {
        return 1;
    }

    return new Date(a) - new Date(b);
}

function updateUserInfo(movieInfo, update) {

    movieInfo = Object.assign({}, movieInfo);

    if (typeof update.known != "undefined") {
        movieInfo.known = update.known;
    }
    if (typeof update.collected !== "undefined") {
        movieInfo.collected = update.collected;
    }
    if (typeof update.viewed !== "undefined") {
        movieInfo.viewed = update.viewed;
    }
    if (typeof update.favorite !== "undefined") {
        movieInfo.favorite = update.favorite;
    }
    if (typeof update.watchlist !== "undefined") {
        movieInfo.watchlist = update.watchlist;
    }
    if (typeof update.rating !== "undefined") {
        movieInfo.rating = update.rating;
    }

    movieInfo.changed = new Date().toISOString();

    return movieInfo;
}

function isUserInfoEmpty(userInfo) {
    return !userInfo.collected && !userInfo.viewed && !userInfo.favorite && !userInfo.watchlist && (!userInfo.rating || userInfo.rating === 0);
}

export default function reducer(state = initialState, action) {

    switch (action.type) {

        case LOAD_MOVIE:
            state = { ...state, movieId: action.movieId, movieInfo: { id: action.movieId }, trailer: null, lists: null, users: null, reviews: null };
            break;
        case LOAD_MOVIE_INFO_SUCCESS:
            state = { ...state, movieInfo: action.data };
            break;
        case LOAD_MOVIE_USERS_SUCCESS: {

            let data = action.data;
            if (!data) {
                data = [];
            }
            data.sort((a, b) => compareDates(b.changed, a.changed));

            state = { ...state, users: data };
            break;
        }
        case LOAD_MOVIE_LISTS_SUCCESS:
            state = { ...state, lists: action.data };
            break;
        case LOAD_MOVIE_REVIEWS_SUCCESS:

            action.data.forEach(review => {
                if (!review.score) {
                    review.score = 0;
                }
                if (!review.vote) {
                    review.vote = 0;
                }
                if (!review.comments) {
                    review.comments = [];
                }
            });

            state = { ...state, reviews: action.data };
            break;
        case LOAD_MOVIE_TRAILER_SUCCESS:
            state = { ...state, trailer: action.data };
            break;

        case USER_SIGNIN_SUCCESS:
            state = { ...state, currentUser: action.user };
            break;
        case USER_SIGNOUT:
            state = { ...state, currentUser: null };
            break;

        case UPDATE_MOVIE_INFO: {
            if (state.currentUser && ( (state.movieInfo === null) || action.movieId === state.movieInfo.id)) {

                if (state.users) 
                {
                   var index = state.users.findIndex(x => x.profile && x.profile.id === state.currentUser.id);

                    if (index < 0) {
                        var userInfo = updateUserInfo({ profile: state.currentUser }, action.update);

                        if (!isUserInfoEmpty(userInfo)) {
                            var newUserList = state.users.slice();
                            newUserList.push(userInfo);
                            
                            newUserList.sort((a, b) => compareDates(b.changed, a.changed));

                            state = { ...state, users: newUserList };
                        }
                    } else {
                        var userInfo = updateUserInfo(state.users[index], action.update);

                        var newUserList = state.users.slice();
                        if (isUserInfoEmpty(userInfo)) {
                            newUserList.splice(index, 1);
                        } else {
                            newUserList[index] = userInfo;
                            newUserList.sort((a, b) => compareDates(b.changed, a.changed));
                        }

                        state = { ...state, users: newUserList };
                    }
                }
            }
            break;
        }
        default:
    }

    if (state.movieId) {
        state = reduceReviewActions(state, action, review => review.movie_id === state.movieId);
    }

    return state;
}
