import React from 'react';

import Api from 'components/Api';
import UserProfile from 'components/UserProfile';

class User extends React.Component {
    render() {
        return (
            <UserProfile userId={Api.getNumericId(this.props.match.params.id)}/>
        );
    }
}

export default User;