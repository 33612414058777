import React from 'react';

import Movie from 'components/Movie';

import LazyImg from './Image';
import ActorInfo from './ActorInfo';
import ActionList from './ActionList';
import MovieOverlay from './MovieOverlay';
import withOverlay from './ItemWithOverlay';

class ListItem extends React.PureComponent {

    render() {

        let item = this.props.item;

        let overlay = undefined;
        let posterSrc = "/Images/no-poster-185.png";
        if (item.movie && item.movie.id !== 0) {
            posterSrc = Movie.poster(item.movie, 185);

            if (this.props.overlayVisible) {
                overlay = <MovieOverlay item={item} />;
            }
        }

        return (
            <React.Fragment>
                <LazyImg ref="movieImg" className="kk-nf-poster" defaultSrc="/Images/no-poster-185.png" src={posterSrc} fadeIn={500}/>
                <div className="kk-nf-placeholder">
                     {overlay}
                </div>
                <ActorInfo item={item} />
                <ActionList item={item} />
            </React.Fragment>
        );
    }
}

export default withOverlay(ListItem);