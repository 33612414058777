import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import Movie from 'components/Movie';
import RequireLogin from 'components/RequireLogin';
import ToggleMovieInfoButton from 'components/MovieDetails/ToggleMovieInfoButton';
import api from 'components/Api';
import { connect } from 'components/StoresContext';

import { updateMovieInfo, getMovieInfo } from 'actions/user';

import Ratee from './Ratee';

class MovieOverlay extends React.Component {

    constructor(props) {
        super(props);

        this._handleClick = this._handleClick.bind(this);
        this._navigateToList = this._navigateToList.bind(this);
        this._toggleKnown = this._toggleKnown.bind(this);
    }

    _toggleKnown() {
        RequireLogin.do(() => {
            var newValue = !this.props.known;
            var item = this.props.item;
            this.setState({known:newValue});

            this.props.updateMovieInfo(item.movie.id, item.movie.title, item.movie.poster, { known: newValue });
        });
    }

    _navigateToList(e) {
        e.preventDefault();
        e.stopPropagation();

        var item = this.props.item;

        this.props.history.push(api.getListUrl(item.list));
    }

    _handleClick(e) {
        e.stopPropagation();
        e.preventDefault();

        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {

        var item = this.props.item;
        var elems = [];

        if (item.actions && item.actions.length > 0 && item.list && (item.actions[0] === "votedOnList" || item.actions[0] === "addedMovieToList" || item.actions[0] === "createdList" || item.actions[0] === "likesList")) {
            elems.push(
                <div key="ListInfo" className="kk-nf-listitem" onClick={this._navigateToList}>
                    {item.list.name}
                    <div className="ab-dark" style={{ marginTop:"30px" }}>
                        <button className="btn btn-opaque">Mitmachen!</button>
                    </div>
                </div>               
            );
        } else {
            elems.push(
                <div key="Title" className="kk-nf-movietitle">{item.movie.title}</div>
            );

            elems.push((
                <div className="kk-nf-togglebox ab-dark" key="togglebox">
                    <ToggleMovieInfoButton 
                            checked={this.props.known} 
                            onToggle={this._toggleKnown}
                            icon="fa-check" 
                            btnColor="btn-opaque" 
                            btnActiveColor="btn-green"
                            btnSize="btn-xl"
                            caption="Kenn Ich"/>
                </div>));
            
            elems.push(<Ratee key="Rating" item={item}/>);
            elems.push(<div key="more" className="kk-nf-morebtn text-right ab-dark"> 
                            <span className="fa fa-arrow-right" style={{marginRight:"5px"}}></span>
                            mehr
                        </div>);
        }

        return (
            <Link to={Movie.url(this.props.item.movie)}>
                <div className="kk-nf-overlay" onClick={this._handleClick}>
                    {elems}
                </div>
            </Link>
        );
    }
}

function userKnows(userInfo)
{
    return userInfo ? userInfo.known : false;
}

export default withRouter(connect(MovieOverlay, (store, props) => ({
    known: userKnows(getMovieInfo(store, props.item.movie.id))
 }), (dispatch) => ({
    updateMovieInfo: (movieId, title, poster, update) => dispatch(updateMovieInfo(movieId, title, poster, update))
 })));