import Api from "components/Api";

export const GENRES_LOAD_SUCCESS = "GENRES_LOAD_SUCCESS";

export function loadGenres() {
    return function (dispatch, _) {
        Api.get("Genre").then(genres => {
            dispatch({ type: GENRES_LOAD_SUCCESS, genres });
        }).catch(_ => { });
    }
}