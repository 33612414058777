import cfg from 'Configuration.json';
import devel from 'Configuration.devel.json';

function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

function merge(target, source) {
    var result = {...target};

    for (var key in source) {
        if (isObject(source[key])) {
            if (source[key]) {
                if (!target.key) {
                    target[key] = {};
                }
                result[key] = merge(target[key], source[key]);
            } else {
                result[key] = target[key];
            }
        }
        else
        {
            result[key] = source[key] ? source[key] : target[key];
        }
    }
    
    return result;
}

export default merge(cfg, devel);

