import React from 'react';
import ClassNames from 'classnames';

// Translate button sizes into class names
var Sizes = { "xsmall": "btn-xs", "small": "btn-sm", "large": "btn-lg" };

class Button extends React.Component {

    constructor(props) {
        super(props);

        this._handleClick = this._handleClick.bind(this);
    }
    
    render() {

        var props = Object.assign({}, this.props);
        props.onClick = this._handleClick;

        var bsStyle = props.bsStyle;
        var bsSize = props.bsSize;

        var sizeClass = undefined;
        if (bsSize) {
            sizeClass = Sizes[bsSize];
        }

        var blockClass = undefined;
        if (this.props.block) {
            blockClass = "btn-block";
        }

        var disabledClass = this.props.disabled ? "disabled" : undefined;

        delete props.block;
        delete props.bsStyle;
        delete props.bsSize;
        delete props.bsRole;

        var cn = ClassNames(props.className, "btn", "btn-" + bsStyle, sizeClass, blockClass, disabledClass);
        delete props.className;

        if (this.props.href) {
            delete props.onClick;
            delete props.button;
            return <a className={cn} {...props}>{this.props.children}</a>;
        }

        return (
            <button className={cn} {...props}>
                {this.props.children}
            </button>
        );
    }

    _handleClick(e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.props.onClick) {
            this.props.onClick();
        }
    }
}

Button.defaultProps = { bsStyle: "default", type: "button" };

export default Button;
