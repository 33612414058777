import { UPDATE_WINDOW_SIZE } from 'actions/windowSize';

const initialState = {
    width: null,
    height: null,
    mobile: false,
    tablet: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_WINDOW_SIZE:
            if (action.width !== state.width || action.height !== state.height) {
                state = { width: action.width, height: action.height, mobile: action.width < 768, tablet: action.width < 1220 && action.width >= 768 };
            }
            break;
        default:
    }

    return state;
}