import React from 'react';

import RequireLogin from 'components/RequireLogin';
import { connect } from 'components/StoresContext';

import { updateMovieInfo, getMovieInfo } from 'actions/user';

import ToggleMovieInfoButton from './ToggleMovieInfoButton';

import './Toggles.css';

class Toggles extends React.PureComponent {

    constructor(props) {
        super(props);

        this._setKnown = this._setKnown.bind(this);
    }

    _setKnown(newState) {
        RequireLogin.do(() => {
            this.props.updateMovieInfo(this.props.movieId, this.props.title, this.props.poster, { known: newState });
        });
    }
        
    render() {
        return (
            <ToggleMovieInfoButton 
                checked={this.props.known} 
                onToggle={this._setKnown}
                icon="fa-check" 
                btnColor="btn-opaque" 
                btnActiveColor="btn-green"
                btnSize="btn-xl"
                caption="Meine Filme"/>
        );
    }        
}

export default connect(Toggles, (store, props) => ({
    known: getMovieInfo(store, props.movieId).known
}), (dispatch) => ({
    updateMovieInfo: (movieId, title, poster, update) => dispatch(updateMovieInfo(movieId, title, poster, update))
}));

