import React from 'react';

// A higher order component that handles mouse and touch events to display an overlay after tapping
// it or when the mouse enters it.
//
// Adds an additional property 'overlayVisible' to the wrapped component.

function withOverlay(Item) {
    return class extends React.Component {

        constructor(props) {
            super(props);
    
            this.state = { overlayVisible: false };
    
            this._handleMouseEnter = this._handleMouseEnter.bind(this);
            this._handleMouseLeave = this._handleMouseLeave.bind(this);
    
            this._handleTouchStart = this._handleTouchStart.bind(this);
            this._handleTouchMove = this._handleTouchMove.bind(this);
            this._handleTouchEnd = this._handleTouchEnd.bind(this);
    
            this.__hideOverlay = this.__hideOverlay.bind(this);
        }

        _handleTouchStart(e) {
            this._touch = true;
            this._touchMoved = false;
    
            this._touchStartPos = { x: e.touches[0].clientX, y: e.touches[0].clientY };
        }
    
        _handleTouchMove(e) {
            var pos = { x: e.touches[0].clientX, y: e.touches[0].clientY };
    
            this._touchMoved = Math.abs(this._touchStartPos.x - pos.x) > 2 || Math.abs(this._touchStartPos.y - pos.y) > 2;
        }
    
        _handleTouchEnd(e) {
            if (!this._touchMoved) {
                if (!this.state.overlayVisible) {
                    this._showOverlay();
                    e.preventDefault();
                }
            }
        }
    
        _handleMouseEnter() {
            if (this._touch) {
                return;
            }
            this._showOverlay();
        }
    
        _handleMouseLeave() {
            this._hideOverlay();
        }
    
        _showOverlay() {
            this.setState({ overlayVisible: true });
            this.props.onOverlayShown(this.__hideOverlay);
        }
    
        _hideOverlay() {
            this.__hideOverlay();
            this.props.onOverlayShown(null);
        }
    
        __hideOverlay() {
            this.setState({ overlayVisible: false });
        }    
    
        render() {
          // Wraps the input component in a container, without mutating it. Good!
          return (
                <div className="kk-nf-item" onMouseEnter={this._handleMouseEnter} onMouseLeave={this._handleMouseLeave}
                    onTouchStart={this._handleTouchStart} onTouchMove={this._handleTouchMove} onTouchEnd={this._handleTouchEnd}>
                    <Item overlayVisible={this.state.overlayVisible} {...this.props} />
                </div>
          );
        }
      }
}

export default withOverlay;