import { GENRES_LOAD_SUCCESS } from 'actions/genres';

const initialState = {
    list: null
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GENRES_LOAD_SUCCESS:
            state = { ...state, list: action.genres };
            break;
        default:
    }

    return state;
}
