import React, { Component } from 'react';

import Api from 'components/Api';
import UserMovies from 'components/UserMovies';

class Filme extends Component {
    render() {
      return (
        <UserMovies userId={Api.getNumericId(this.props.match.params.id)}/>
      );
    }
}

export default Filme;