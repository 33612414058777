import React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'components/StoresContext';

import DesktopMovies from './DesktopMovies';
import MovieListBox from 'components/Newsfeed/MovieListBox';
import Select from 'react-select';



import './UserMovies.css';
//import 'react-select/dist/react-select.css';
import { loadUser, getProfile, getMovies, getTags } from 'actions/otherUser';
import { getViewState, switchShowFilter, switchShowAddMovie, toggleFilter, switchShowAllValues, changeGroup, toggleExpandGroup } from 'actions/view';
import { addMovie } from 'actions/user';
import User from 'components/User';

import forky from 'images/forky.jpg';

// A movie matches a filter, if it contains all tags mentioned in the filter.
//
function movieMatchesFilter(movie, filter) {
    return filter.every(filterEntry => {
        if (filterEntry.dimension === "Genre") {
            return movie.genre && movie.genre.indexOf(filterEntry.value) >= 0;
        }
        else if (filterEntry.dimension === "Bewertet") {
            return Math.round(movie.rating) === filterEntry.value;
        }
        else {
            if (!movie.tags) {
                return false;
            }
            let tagDim = movie.tags.find(x => x.dimension === filterEntry.dimension);
            return tagDim && tagDim.values.indexOf(filterEntry.value) >= 0;
        }
    });
}

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

class UserMovies extends React.PureComponent {

    constructor(props) {
        super(props);

        this._toggleFilter = this._toggleFilter.bind(this);
        this._changeGroup = this._changeGroup.bind(this);
        this._toggleGroup = this._toggleGroup.bind(this);

        this._switchShowAllValues = this._switchShowAllValues.bind(this);
        this._switchShowAddMovie = this._switchShowAddMovie.bind(this);
        this._switchShowFilter = this._switchShowFilter.bind(this);

        this._countMovies = this._countMovies.bind(this);
        this._countMoviesVal = this._countMoviesVal.bind(this);
        this._hasFilter = this._hasFilter.bind(this);

        this._handleAddMovie = this._handleAddMovie.bind(this);

        this.movieRefs = {};
        this.scrollToMovie = null;
        this.state = { view: { grouping: { groupBy: null, collapsed: {} }, showFilter: false, showAddMovie: false, filter: [], showAllValues: [] }, filteredMovies: null };
    }

    componentWillMount() {
        this.props.loadUser(this.props.userId);
    }

    componentDidMount() {

    }

    componentDidUpdate() {
        if (this.scrollToMovie !== null) {
            var Dom = this.movieRefs[this.scrollToMovie];
            window.scrollTo({ left: 0, top: Dom.offsetTop, behavior: 'smooth' });
            this.scrollToMovie = null;
        }


    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.userId !== this.props.userId) {
            this.props.loadUser(nextProps.userId);
        }
    }

    _switchShowFilter() {
        //alert(JSON.stringify(this.movieRefs));
        this.props.switchShowFilter(this.props.userId);
    }

    _switchShowAddMovie() {
        this.props.switchShowAddMovie(this.props.userId);
    }

    _switchShowAllValues(dimension) {
        this.props.switchShowAllValues(this.props.userId, dimension);
    }

    _hasFilter(value, dimension) {
        return this.props.view.filter &&
            this.props.view.filter.findIndex(i => i.dimension === dimension && i.value === value) >= 0;
    }

    renderAddMovieBar() {
        return (
            <div className="col-6 text-center" style={{ paddingTop: "0px" }}>
                <div className="row">
                    <div className="col-10 text-left" style={{ padding: "0px" }} >
                        <MovieListBox placeholder="Film hinzufügen..." onSelected={this._handleAddMovie} />
                    </div>
                    <div className="col-2" style={{ padding: "0px", lineHeight: "1.15" }}>
                        <small>
                            <Link to="/Search">Erweiterte <br /> Suche</Link>
                        </small>
                    </div>
                </div>
            </div>
        )
    }

    renderActiveFilterBar() {

        if (this.props.view.filter || this.props.view.grouping.groupBy ) {
            return (
                <div className="col-6 text-center" style={{ paddingTop: "7px" }}>
                    {this.props.view.grouping.groupBy && 
                       <span style={{ cursor: "pointer", marginRight: "10px" }}
                            onClick={e => this._changeGroup(undefined)}>
                            {this.props.view.grouping.groupBy} <span className="fa fa-times-circle" />
                       </span>
                    }
                    {this.props.view.filter.map(filter =>
                        <span style={{ cursor: "pointer", marginRight: "10px" }}
                            onClick={e => this._toggleFilter(filter.value, filter.dimension)}>
                            {this.renderName(filter.dimension, filter.value)} <span className="fa fa-times-circle" />
                        </span>
                    )}
                </div>
            )
        }

    }

    _toggleFilter(value, dimension) {
        this.props.toggleFilter(this.props.userId, value, dimension);
    }

    _changeGroup(newGroup) {
        this.props.changeGroup(this.props.userId, newGroup);
    }

    _toggleGroup(key) {
        this.props.toggleExpandGroup(this.props.userId, key);
    }

    _countMoviesVal(movies, dimension, value) {

        if (movies) {
            if (dimension === "Genre") {
                return movies.filter((m) =>
                    m.genre.find(g => g === value) !== undefined).length;
            }

            else if (dimension === "Bewertet") {
                return movies.filter(m => Math.round(m.rating) === value).length;
            }
            else {
                return movies.filter((m) =>
                    m.tags.find(d => d.dimension === dimension) !== undefined &&
                    m.tags.find(d => d.dimension === dimension).values.find(v => v === value)).length;
            }
        } else {
            return 0;
        }
    }

    _countMovies(dimension) {
        // Use a set to avoid counting movies twice
        var uniqueMovies = new Set();
        if (this.props.movies && this.props.tags) {
            this.props.tags.find(d => d.name == dimension).values.forEach(tagVal => {
                var matchingMovies = this.props.movies.filter((m) =>
                    m.tags.find(d => d.dimension === dimension) !== undefined &&
                    m.tags.find(d => d.dimension === dimension).values.find(v => v === tagVal.name)
                );
                matchingMovies.forEach(function (i) {
                    uniqueMovies.add(i.id)
                });
            });
        }

        return uniqueMovies.size;
    }

    _filterMovies(movies, newFilter, newGrouping) {
        var result = movies.slice().filter(m => m.known);

        if (newFilter.length > 0) {
            result = movies.filter(m => movieMatchesFilter(m, newFilter));
        }
        /*
        if (newGrouping !== null) {
            if (newGrouping === "Bewertet") {
                result = result.filter ( i => i.rating > 0);
            } else {
                result = result.filter ( m => movieHasDimension(m, newGrouping));
            }
        }
        */

        return result;
    }

    renderGroupBar() {
        return (
            this.props.tags && this.props.movies &&
            <ul className="nav nav-pills flex-column">

                <li role="presentation" key="group_off" className="nav-item">
                    <a className={(this.props.view.grouping.groupBy == undefined) ? "active nav-link" : "nav-link"} href="##" onClick={e => this._changeGroup(e, undefined)}>
                        Alle <span className="pull-right">{this.props.movies.length}</span>
                    </a>
                </li>
                <li role="presentation" key="rated" className="nav-item">
                    <a className={(this.props.view.grouping.groupBy == "Bewertet") ? "active nav-link" : "nav-link"} href="##" onClick={e => this._changeGroup(e, "Bewertet")}>
                        Bewertet <span className="pull-right">{this.props.movies.filter(i => i.rating > 0).length}</span>
                    </a>
                </li>
                {this.props.tags.map(dimension => {
                    var count = this._countMovies(dimension.name);
                    if (count === 0) {
                        return null;
                    }

                    return (
                        <li role="presentation" key={"group_" + dimension.name} className="nav-item">
                            <a className={(this.props.view.grouping.groupBy === dimension.name) ? "active nav-link" : "nav-link"} href="##" onClick={e => this._changeGroup(e, dimension.name)}>
                                {dimension.name} {(count > 0) && <span className="pull-right">{count}</span>}
                            </a>
                        </li>
                    );
                })}

            </ul>

        )
    }

    renderName(dimension, value) {
        if (dimension === "Bewertet") {
            var stars = [];
            for (var i = 1; i <= value; i++) {
                stars.push(<span className="fa fa-star"></span>)
            }
            return stars;
        }
        else {
            return value;
        }
    }

    renderCheckboxes(dimension, v) {
        var values = v.slice();
        // Sort values by count descending and include only values with movies
        if (dimension === "Bewertet")
            values = values.sort(function (a, b) { return b.name - a.name });
        else
            values = values.sort(function (a, b) { return b.count - a.count });

        values = values.filter(i => i.count > 0);

        // Check if group is expanded
        let origLength = values.length;
        let expanded = this.props.view.showAllValues.indexOf(dimension) >= 0;

        // If not expanded cut list at first 5 elements
        if (expanded === false) {
            values = values.slice(0, 5);
        }

        // Generate checkboxes
        var checkboxes = values.map(item =>
            <div className="form-check col" style={{ paddingLeft: "10px", marginTop: "3px" }}>
                <input className="form-check-input" type="checkbox"
                    checked={this._hasFilter(item.name, dimension)}
                    onChange={e => this._toggleFilter(item.name, dimension)} />
                <label
                    className="form-check-label btn-link"
                    style={{ cursor: "pointer" }} >
                    {this.renderName(dimension, item.name)}
                    <small> ({item.count})</small>
                </label>
            </div>
        );

            return (
                <React.Fragment key={dimension}>
                    <div className="row" style={{ paddingRight: "0px" }}>
                        <div className="col-6" style={{ paddingLeft: "5px", paddingRight: "0px" }}>
                            <h6 style={{ marginTop: "10px", marginBottom: "5px" }}>{dimension}</h6>
                        </div>
                        {dimension !== "Genre" &&
                            <div className="col-6 text-right" style={{ paddingTop: "7px", paddingLeft: "0px", paddingRight: "0px", cursor: "pointer" }}>
                                <small className="btn-link" onClick={e => this._changeGroup(dimension)}>{this.props.view.grouping.groupBy === dimension ? "aufheben" : "Gruppe"}</small>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="form-check" >
                            {checkboxes}
                        </div>
                    </div>
                    {origLength > 5 &&
                        <small style={{ cursor: "pointer" }} onClick={e => this._switchShowAllValues(dimension)}>
                            {expanded === false ? "mehr anziegen" : "weniger anzeigen"}
                        </small>
                    }
                </React.Fragment>
            );
        
    }

    renderFilterBar(movies) {

        var ratingBoxes, ratings = [];
        for (var i = 0; i <= 5; i++) {
            ratings.push({ name: i, count: this._countMoviesVal(movies, "Bewertet", i) });
        }
        ratingBoxes = this.renderCheckboxes("Bewertet", ratings);

        var genreBoxes;

        if (this.props.genres) {
            var genres = this.props.genres.map(g => { return { name: g.name, count: this._countMoviesVal(movies, "Genre", g.name) } });
            genreBoxes = this.renderCheckboxes("Genre", genres);

        };


        return (
            <React.Fragment key="filterBar">
                {ratingBoxes}

                {this.props.tags && this.props.tags.map(dimension => {
                    if (dimension.values) {
                        var values = dimension.values.map(value => { return { name: value.name, count: this._countMoviesVal(movies, dimension.name, value.name) } });
                        return this.renderCheckboxes(dimension.name, values);
                    }
                })}

                {genreBoxes}
            </React.Fragment>

        )
    }


    _handleAddMovie(movieData) {
        this.scrollToMovie = movieData.id;
        this.props.addMovie(movieData);

    }

    renderHeader(movies) {
        var groupValues =  [];
        if (this.props.tags) {
            groupValues = this.props.tags.map( dimension => ({ value: dimension.name, label:dimension.name}));
        }

        groupValues.push( {value:"Bewertet", label:"Bewertung"});
        groupValues.push( {value:null, label:"Nicht gruppieren"});

        if (this.props.mobile) {
            return (
                
                <div className="row sticky-top" style={{ paddingTop: "5px", backgroundColor: "white" }}>
                    <div className="col">
                    <div className="row">
                        <div className="col-4">
                            <h5>
                                <Link to={User.url(this.props.user)}><img className="img img-circle img-small" src={User.img(this.props.user)}></img></Link>   <span className="badge badge-info">{movies.length}</span>
                            </h5>
                        </div>
                        <div className="col-8 text-right">
                            {this.props.currentUser && this.props.currentUser.id === this.props.user.id &&
                                <button className="btn btn-orange" onClick={this._switchShowAddMovie}><span className="fa fa-plus"></span> Hinzufügen</button>
                            }
                            <button className="btn btn-opaque" onClick={this._switchShowFilter} style={{ marginLeft: "5px", padding: "2px 7px 2px 7px" }}><span className="fa fa-sliders" style={{ fontSize: "24px" }}></span></button>
                
                        </div>
                    </div>
                
              
                    {this.props.view.showAddMovie && 
                        <div className="row" style={{paddingBottom:"3px"}}>
                            <div className="col">
                                <MovieListBox placeholder="Film hinzufügen..." onSelected={this._handleAddMovie} />
                            </div>
                        </div>
                    }
                    {this.props.view.showFilter && 
                        <div className="row" style={{paddingBottom:"3px"}}>
                            <div className="col">
                            { this.props.tags && 
                                <Select 
                                    style={{width:"100%"}}
                                    options={groupValues} 
                                    placeholder="Gruppieren nach"
                                    isClearable={true}                                    
                                    onChange={newDimension => this._changeGroup(newDimension ? newDimension.value : null)}
                                    value={groupValues.filter( i => i.value === this.props.view.grouping.groupBy)}
                                    />
                            }
                            </div>
                        </div>
                    }
                    </div>
                </div>
            )

        }
        else {
            return (
                this.props.tags && this.props.movies &&
                <div className="sticky-top row" style={{ paddingTop: "5px", backgroundColor: "white", boxShadow: "0 5px 5px -5px" }}>
                    <div className="col-3" style={{ marginBottom: "5px", paddingTop: "5px" }}>
                        <h5>Filme von {this.props.user && this.props.user.name}  <span className="badge badge-info">{movies.length}</span>
                        </h5>
                    </div>
                    {this.props.view.showAddMovie ?
                        this.renderAddMovieBar() :
                        this.renderActiveFilterBar()}
                    <div className="col-3 text-right" style={{ marginBottom: "5px" }}>
                        {this.props.currentUser && this.props.currentUser.id === this.props.user.id &&
                            <button className="btn btn-orange" onClick={this._switchShowAddMovie}><span className="fa fa-plus"></span> Hinzufügen</button>
                        }
                        {false && <button className="btn btn-opaque"><span className="fa fa-search"></span></button>}
                        <button className="btn btn-opaque" onClick={this._switchShowFilter} style={{ marginLeft: "5px", padding: "2px 7px 2px 7px" }}><span className="fa fa-sliders" style={{ fontSize: "24px" }}></span></button>
                    </div>
                </div>
            )
        }
    }

    renderMovies(movies) {
        // TODO: Change with correct grouping implementation with overlay... 
        if (!movies || !this.props.user) {
            return <span className="fa fa-spinner fa-pulse" style={{ marginLeft: "10px" }} />;
        } else {

            return (
                <div className="row">
                    <div className={this.props.view.showFilter && !this.props.mobile? "col-10" : "col-12"} style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "5px" }}>

                        <DesktopMovies 
                            ref={this.movieRefs} 
                            small={this.props.view.showFilter} 
                            mobile={this.props.mobile}
                            userId={this.props.userId} 
                            tags={this.props.tags} 
                            movies={movies} 
                            grouping={this.props.view.grouping} 
                            toggleGroup={this._toggleGroup} />                    

                        {movies.length == 0 && this.props.currentUser.id === this.props.user.id &&
                            <div className="text-center" style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                                <h5>Herzlich Willkommen!</h5>
                                <img src={forky} style={{ height: "200px" }}></img>

                                <h5 style={{ paddingTop: "10px" }}>Deine Sammlung ist leer.</h5>
                                {!this.props.mobile &&
                                    <button className="btn btn-opaque" onClick={this._switchShowAddMovie}>Filme hinzufügen</button>
                                }
                                {this.props.mobile &&
                                    <Link to="/Search" className="btn btn-opaque">Filme hinzufügen</Link>
                                }
                            </div>
                        }
                    </div>
                    {(this.props.view.showFilter && !this.props.mobile) && 
                        <div className="col-2" style={{ paddingRight: "5px" }}>
                            {this.renderFilterBar(movies)}
                        </div>
                    }
                </div>
            );
        }
    }

    render() {
        if (this.props.movies && this.props.view) {
            let movies = this._filterMovies(this.props.movies, this.props.view.filter, this.props.view.grouping.groupBy);
            return (
                <div className="container" style={{ scrollBehavior: "smooth" }}>
                    {this.renderHeader(movies)}
                    {this.renderMovies(movies)}
                </div>
            );
        } else {
            return null;
        }
    }
}

export default connect(UserMovies, (state, props) => ({
    currentUser: state.user.currentUser,
    user: getProfile(state, props.userId),
    movies: getMovies(state, props.userId),
    view: getViewState(state, props.userId),
    genres: state.genres.list,
    tags: getTags(state, props.userId),
    mobile: state.windowSize.mobile
}), dispatch => ({
    loadUser: userId => dispatch(loadUser(userId)),
    addMovie: movieInfo => dispatch(addMovie(movieInfo)),
    switchShowFilter: userId => dispatch(switchShowFilter(userId)),
    switchShowAddMovie: (userId) => dispatch(switchShowAddMovie(userId)),
    switchShowAllValues: (userId, dimension) => dispatch(switchShowAllValues(userId, dimension)),
    changeGroup: (userId, dimension) => dispatch(changeGroup(userId, dimension)),
    toggleFilter: (userId, value, dimension) => dispatch(toggleFilter(userId, value, dimension)),
    toggleExpandGroup: (userId, dimension) => dispatch(toggleExpandGroup(userId, dimension)),
}));
