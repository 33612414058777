import React from 'react';
import { withRouter } from 'react-router-dom';

import RelTime from 'components/RelTime';
import User from 'components/User';

import ActorImage from './ActorImage';

class ActorInfo extends React.PureComponent {

    constructor(props) {
        super(props);

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick() {
        var item = this.props.item;

        this.props.history.push(User.url(item.actor));
    }

    render() {
        var item = this.props.item;

        var onClick = item.actor ? this._handleClick : null;

        return (
            <div className="gradient-top kk-nf-actor" onClick={onClick}>
                { item.actor ? <ActorImage item={item}/> : null }
               
                <RelTime className="kk-nf-actor-ts" time={item.ts}/>
            </div>
        );
    }
}

export default withRouter(ActorInfo);
