import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'components/StoresContext';
import { confirmNewPassword, CONFIRM_PASSWORD_INPUT } from 'actions/confirmPasswordForm';

class CompleteNewPassword extends React.Component {

    constructor(props) {
        super(props);

        this._handleSubmit = this._handleSubmit.bind(this);
    }

    _handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();


        let userId = null;
        let token = null;

        if (this.props.match.params.params) {
            var vars = this.props.match.params.params.split('&');
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                if (decodeURIComponent(pair[0]) === 'userId') {
                    userId = decodeURIComponent(pair[1]);
                }
                if (decodeURIComponent(pair[0]) === 'token') {
                    token = decodeURIComponent(pair[1]);
                }
            }
        }

        this.props.confirmNewPassword(userId, token, () => this.context.router.history.push('/'));
    }

    render() {
        return (
            <div className="container">
                <div className="row" style={{display:"flex", alignItems:"center"}}>
                    <div className="col-md-6">
                        <h3>Neues Passwort festlegen</h3>
                        <p>Gib hier ein neues Passwort für deinen Account ein (und diesmal nicht mehr vergessen...).</p>
                        <form onSubmit={this._handleSubmit}>
                            <div className="form-group">
                                <label>Neues Passwort</label>
                                <input id="password" className="form-control" type="password" value={this.props.password} onChange={e => this.props.onChange("password", e.target.value)}/>
                            </div>
                            <button type="submit" className="btn btn-primary">Passwort ändern</button>
                        </form>
                        {this.props.errors && <div className="alert alert-danger" style={{marginTop:"10px"}}>
                            {this.props.errors.map((e, i) => <p key={i}>{e}</p>)}
                        </div>}
                    </div>
                    <div className="col-md-6 hidden-xs text-center" >
                        <img src="/Images/logo/Logo-128x128.png" alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}

// Needed for the login button to work.
CompleteNewPassword.contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.object.isRequired,
    }),
  };

export default connect(CompleteNewPassword, state => ({
    password: state.confirmPasswordForm.password,
    errors: state.confirmPasswordForm.errors
}), dispatch => ({
    onChange: (field, value) => dispatch({ type: CONFIRM_PASSWORD_INPUT, field, value }),
    confirmNewPassword: (userId, token, callback) => dispatch(confirmNewPassword(userId, token, callback))
}));
