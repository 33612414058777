// CssScaler.js
//
// This file contains code to scale absolute measures in CSS classes.

class CssScaler {
    constructor(properties) {
        this.styleSheet = undefined;
        this.cssProperties = properties;
    }

    scale(scaleFactor) {
        if (!this.styleSheet) {
            var styleElem = document.createElement("style");

            // for webkit
            styleElem.appendChild(document.createTextNode(""));

            document.body.appendChild(styleElem);

            this.styleSheet = styleElem.sheet;
        } else {
            while (this.styleSheet.cssRules.length > 0) {
                this.styleSheet.deleteRule(0);
            }
        }

        if (scaleFactor && scaleFactor !== 1.0) {
            for (var selector in this.cssProperties) {
                if (this.cssProperties.hasOwnProperty(selector)) {
                    this._addScaledClass(selector, this.cssProperties[selector], scaleFactor);
                }
            }
        }
    }

    destroy() {
        if (this.styleSheet) {
            this.styleSheet.disabled = true;
            document.body.removeChild(this.styleSheet.ownerNode);

            this.styleSheet = null;
        }
    }

    // Inserts a rule into a CSS style sheet
    _addCssRule(selector, styles, index) {
        if ("insertRule" in this.styleSheet) {
            this.styleSheet.insertRule(selector + "{" + styles + "}", index);
        }
        else if ("addRule" in this.styleSheet) {
            this.styleSheet.addRule(selector, styles, index);
        }
    }

    // Adds a scaled class into a CSS style sheet.
    _addScaledClass(selector, styles, scaleFactor) {

        var assignments = [];
        for (var property in styles) {
            if (styles.hasOwnProperty(property)) {

                var scaledValue = scaleFactor ? styles[property] * scaleFactor : styles[property];

                assignments.push(property + ":" + scaledValue + "px");
            }
        }

        this._addCssRule(selector, assignments.join(";"), 0);
    }
}

export default CssScaler;