
import { REGISTER_INPUT, REGISTER_VALIDATION_RESULT, REGISTER_CLEAR } from 'actions/register';
import { USER_REGISTRATION_SUCCESS, USER_REGISTRATION_FAILURE, USER_REGISTRATION_STARTED } from 'actions/user';

const initialState = {
    emailaddr: "",
    username: "",
    password: "",
    acceptAgb: false,
    allowEmails:false,
    errors: null,
    pending: false,
    loading: false
};

function registerFormReducer(state = initialState, action) {
    switch (action.type) {
        case REGISTER_INPUT:
            state = { ...state };
            state[action.field] = action.value;
            break;
        case REGISTER_CLEAR:
            state = { ...state, emailaddr: "", username: "", password: "", acceptAgb: false, allowEmails: false, pending: false, errors: null };
            break;
        case REGISTER_VALIDATION_RESULT:
            state = { ...state, errors: action.errors };
            break
        case USER_REGISTRATION_STARTED:
            state = { ...state, loading: true };
            break;
        case USER_REGISTRATION_SUCCESS:
            state = { ...state, loading: false, username: "", password: "", acceptAgb: false, allowEmails: false, pending: true, errors: null };
            break;
        case USER_REGISTRATION_FAILURE:
            state = { ...state, loading: false, password: "", errors: action.errors };
            break;
        default:
    }

    return state;
}

export default registerFormReducer;
