import React from 'react';
import SearchPage from 'components/Search';

class Search extends React.Component {
    render() {
        var key = this.props.match.params.query ? this.props.match.params.query : "empty"
        return (
            <SearchPage key={key} query={this.props.match.params.query}/>
        );
    }
}

export default Search;