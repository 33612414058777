import { LISTS_LOAD_FEATURED, LISTS_LOAD_FEATURED_SUCCESS, LISTS_LOAD_NEWEST, LISTS_LOAD_NEWEST_SUCCESS,
         LISTS_SET_QUERY, LISTS_CLEAR_QUERY, LISTS_SEARCH, LISTS_SEARCH_SUCCESS } from 'actions/lists';

const initialState = {
    loadingFeatured: false,
    featuredLists: [],

    loadingNew: false,
    newLists: [],

    loadingSearch: false,
    query: "",
    searchTerm: "",
    searchResult: null
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LISTS_LOAD_FEATURED:
            state = { ...state, loadingFeatured: true };
            break;
        case LISTS_LOAD_FEATURED_SUCCESS:
            state = { ...state, loadingFeatured: false, featuredLists: action.data };
            break;
        case LISTS_LOAD_NEWEST:
            state = { ...state, loadingNew: true };
            break;
        case LISTS_LOAD_NEWEST_SUCCESS:
            state = { ...state, loadingNew: false, newLists: action.data };
            break;
        
        case LISTS_SET_QUERY:
            state = { ...state, query: action.query };
            break;
        case LISTS_CLEAR_QUERY:
            state = { ...state, query: "", searchResult: null };
            break;
        case LISTS_SEARCH:
            state = { ...state, loadingSearch: true };
            break;
        case LISTS_SEARCH_SUCCESS:
            state = { ...state, loadingSearch: false, searchTerm: state.query, searchResult: action.data };
            break;
        default:
    }

    return state;
}