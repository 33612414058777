import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { BarLoader } from 'react-spinners';

import { register, registerInput, registerWithGoogle, registerWithFacebook, clearRegisterForm } from 'actions/register';
import { signIn, signInWithGoogle, signInWithFacebook } from 'actions/user';
import { loginInput, clearLoginForm } from 'actions/login';

import { connect } from 'components/StoresContext';

import Modal from 'components/UI/Modal';
import Api from 'components/Api';
import EventEmitter from 'events';

import buzz from 'images/buzz.png';
import wooody from 'images/woody.jpg';
import signInFacebook from 'images/signin_facebook.png';
import signInGoogle from 'images/signin_google.png';
import RegistrationForm from './RegistrationForm';
import LoginForm from './LoginForm';

// We want to be able to show the modal from everywhere. So we use a global
// object that determines the modal state. This state object is not visible
// to the outside.
class GlobalState extends EventEmitter {

    constructor() {
        super();

        this.show = false;
        this.mode = "register";

    }

    isRegisterModal() {
        return this.mode === "register";
    }

    _emitChanged() {
        this.emit('changed');
    }

    addChangeListener(listener) {
        this.addListener('changed', listener);
    }

    removeChangeListener(listener) {
        this.removeListener('changed', listener);
    }

    showModal() {
       this.showRegister();
    }

    showLogin() {
        this.show = true;
        this.mode = "login";
        this._emitChanged();
    }

    showRegister() {
        this.show = true;
        this.mode = "register";
        this._emitChanged();
    }

    closeModal() {
        this.show = false;
        this._emitChanged();
    }
}

var globalState = new GlobalState();



class PendingScreen_ extends React.PureComponent {

    constructor(props) {
        super(props);

        this._close = this._close.bind(this);
    }

    _close() {
        globalState.closeModal();
        this.props.clear();
    }

    render() {
        return (
            <Modal show={this.props.showModal} onClose={this._close} gaName="waitingForMail">
            <Modal.Header>
                <Modal.Title>Bitte bestätige deine eMail-Adresse</Modal.Title>
                <Modal.CloseButton onClick={this._close}/>
            </Modal.Header>
            <Modal.Body>
                <p>Wir haben eine Mail an {this.props.emailaddr} geschickt. Dein Account steht nach der Bestätigung der eMail-Adresse zur Verfügung.</p>
            </Modal.Body>
            <Modal.Footer>
                    <button className="btn btn-primary" onClick={this._close}>Ok</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const PendingScreen = connect(PendingScreen_, state => ({
    emailaddr: state.registerForm.emailaddr
}), dispatch => ({
    clear: () => dispatch(clearRegisterForm())
}));


class LoginScreen_ extends React.PureComponent {
    constructor(props) {
        super(props);

        this._close = this._close.bind(this);
        this._keyDown = this._keyDown.bind(this);

        this._showLogin = this._showLogin.bind(this);
        this._showRegister = this._showRegister.bind(this);

        this._update = this._update.bind(this);

        this._handleLoginSubmit = this._handleLoginSubmit.bind(this);
        this._handleRegisterSubmit = this._handleRegisterSubmit.bind(this);

        this._registerWithGoogle = this._registerWithGoogle.bind(this);
        this._registerWithFacebook = this._registerWithFacebook.bind(this);
        this.state={mode:globalState.mode, loading: false};
    }

    componentDidMount() {
        globalState.addChangeListener(this._update);
    }

    componentWillUnmount() {
        globalState.removeChangeListener(this._update);
    }

    _update() {
        this.setState({ mode: globalState.mode });
    }

    _showRegister() {
        globalState.showRegister();
    }

    _showLogin() {
        globalState.showLogin();
    }

    _handleLoginSubmit(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        // Try to sign in the user. 
        this.props.signIn(this.props.username, this.props.password, () => this._close());  
    }

    _handleRegisterSubmit(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        this.props.register();
    }

    _registerWithGoogle(e) {
        e.stopPropagation();

        this.props.registerWithGoogle(() => this.context.router.history.goBack());
    }

    _registerWithFacebook(e) {
        e.stopPropagation();

        this.props.registerWithFacebook(() => this.context.router.history.goBack());
    }

    _close() {
        globalState.closeModal();
        if (globalState.isRegisterModal()) 
        {
            this.props.clearRegisterForm();
        } else {
            this.props.clearLoginForm();
        }
        
    }

    render() {
        if (globalState.isRegisterModal()) {
            return this.renderRegister();
        } else {
            return this.renderLogin();
        }
    }

    _keyDown(e) {
        if(e.which == 13) {
            if (globalState.isRegisterModal()) {
                this._handleRegisterSubmit();
            } else {
                this._handleLoginSubmit();
            }
          }
    }

    renderLogin() {
        return (
            <form onSubmit={this._handleLoginSubmit} onKeyPress={this._keyDown}>
          
            <Modal size="medium" show={this.props.showModal} onClose={this._close} gaName="loginFence">
         
                <Modal.Header>
                    <Modal.Title>
                        <span className="d-none d-lg-block">Willkommen zurück, Cowboy!</span>
                        <span className="d-lg-none">Willkommen zurück!</span>
                    </Modal.Title>
                
                    <Modal.CloseButton onClick={this._close}/>
                </Modal.Header>

                <BarLoader
                        widthUnit={"%"}
                        width={100}
                        color={'#e81123'}
                        loading={this.props.loading}
                        />

                <Modal.Body>
                  
                    <div className="row">
                        <div className="col-lg-4 d-none d-lg-block text-center">
                            <img src={wooody} style={{height:"250px"}}></img>
                            <h5 style={{marginTop:"10px"}}>Anmelden mit</h5>
                            <img src={signInFacebook} style={{ cursor: "pointer" }} onClick={this._registerWithFacebook}/>
                            <img src={signInGoogle}  style={{ cursor: "pointer", marginLeft:"15px" }} onClick={this._registerWithGoogle}/>
                        
                        </div>
                        <div className="col-lg-8">
                        <p className="d-none d-lg-block">Melde dich an um deine Sammlung zu bearbeiten, Reviews zu schreiben oder für Listen abzustimmen. </p>
                            <div className="form-group">
                                <label>Benutzername oder E-Mail Adresse</label>
                                <input className="form-control" value={this.props.username} onChange={e => this.props.update("username", e.target.value)} type="text"/>
                            </div>
                            <div className="form-group">
                                <label>Passwort</label>
                                <input className="form-control" value={this.props.password} onChange={e => this.props.update("password", e.target.value)} type="password"/>
                            </div>
                            {this.props.errors && <div className="alert alert-danger">
                                {this.props.errors}
                            </div>}
                            <p style={{marginTop:"10px"}}>Passwort <Link to="NewPassword" onClick="this._close">vergessen?</Link></p>
                        </div>  
                    </div>
                    <div className="row d-lg-none">
                        <div className="col">
                            <h6>Oder anmelden mit</h6>
                            <img src={signInFacebook} style={{height:"35px"}} onClick={this._registerWithFacebook}/>
                            <img src={signInGoogle} onClick={this._registerWithGoogle} style={{marginLeft:"5px", height:"35px"}}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="pull-left d-none d-lg-inline-block">
                        Du hast noch keinen Account? hier <button className="btn btn-opaque" onClick={this._showRegister} style={{marginLeft:"10px"}}>Registrieren</button>   
                    </div>
                    <div className="pull-left d-lg-none">
                        <button className="btn btn-opaque" onClick={this._showRegister}>Registrieren</button>   
                    </div>
                
                    <button type="submit" className="btn btn-primary" onClick={this._handleLoginSubmit}>Anmelden</button>
                </Modal.Footer>
               
            </Modal>
            </form>
        );

    }

    renderRegister() {

        return (
            <form onSubmit={this._handleRegisterSubmit} onKeyDown={this._keyDown}>
           
            <Modal size="large" show={this.props.showModal} onClose={this._close} gaName="loginFence">
                     
                <Modal.Header>
                    <Modal.Title>
                        <span className="d-none d-lg-block">Bis zur Unendlichkeit...      gleich nach dem Registrieren!</span>
                        <span className="d-lg-none">Kostenlos registrieren!</span>
                    </Modal.Title>
                    <Modal.CloseButton onClick={this._close}/>
                </Modal.Header>

                <BarLoader
                        widthUnit={"%"}
                        width={100}
                        color={'#e81123'}
                        loading={this.props.loading}
                        />

                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-4 d-none d-lg-block text-center">
                            <img src={buzz} style={{height:"250px"}}></img>
                            <h5 style={{marginTop:"10px"}}>Oder registrieren mit</h5>
                            <img src={signInFacebook} style={{ cursor: "pointer" }} onClick={this._registerWithFacebook}/>
                            <img src={signInGoogle}  style={{ cursor: "pointer", marginLeft:"15px" }} onClick={this._registerWithGoogle}/>
                        
                        </div>
                        <div className="col-lg-8">
                                <div className="form-group">
                                    <label>E-Mail Adresse</label>
                                    <input id="mail" className="form-control" value={this.props.regEmailaddr} onChange={e => this.props.regUpdate("emailaddr", e.target.value)} type="text"/>
                                </div>
                                <div className="form-group">
                                    <label>Benutzername</label>
                                    <input id="name" className="form-control" value={this.props.regUsername} onChange={e => this.props.regUpdate("username", e.target.value)} type="text"/>
                                </div>
                                <div className="form-group">
                                    <label>Passwort</label>
                                    <input id="password" className="form-control" type="password" value={this.props.regPassword} onChange={e => this.props.regUpdate("password", e.target.value)}/>
                                </div>
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" checked={this.props.acceptAgb} onChange={e => this.props.regUpdate("acceptAgb", e.target.checked)}/> Ich akzeptiere die <Link to="/Datenschutzbestimmungen" onClick={this._close}>Datenschutzbedingungen</Link> und die <Link to="/AGB" onClick={this._close}>AGB</Link>
                                    </label>
                                </div>
                                <div className="checkbox">
                                    <label className="d-none d-lg-block"> 
                                        <input type="checkbox" checked={this.props.allowEmails} onChange={e => this.props.regUpdate("allowEmails", e.target.checked)}/> kinokicks darf mir E-Mails schicken. Welche E-Mails ich bekommen möchte, kann ich in den Einstellungen festlegen.
                                    </label>
                                    <label className="d-lg-none"> 
                                        <input type="checkbox" checked={this.props.allowEmails} onChange={e => this.props.regUpdate("allowEmails", e.target.checked)}/> kinokicks darf mir E-Mails schicken. 
                                    </label>
                                </div>
                            
                            {this.props.regErrors && <div className="alert alert-danger" style={{marginTop:"10px"}}>
                                {this.props.regErrors.map(e => <p>{e}</p>)}
                            </div>}
                        </div>  
                    </div>
                    <div className="row d-lg-none">
                        <div className="col">
                            <h6>Oder registrieren mit</h6>
                            <img src={signInFacebook} style={{height:"35px"}} onClick={this._registerWithFacebook}/>
                            <img src={signInGoogle} onClick={this._registerWithGoogle} style={{marginLeft:"5px", height:"35px"}}/>
                         </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="pull-left d-none d-lg-block">
                        Du hast schon einen Account? Dann hier<button className="btn btn-opaque" onClick={this._showLogin} style={{marginLeft:"10px"}}>Anmelden</button>   
                    </div>
                    <div className="pull-left d-lg-none">
                        <button className="btn btn-opaque" onClick={this._showLogin}>Anmelden</button>   
                    </div>
                
                    <button className="btn btn-primary" type="submit" onClick={this._handleRegisterSubmit}>Registrieren</button>
                </Modal.Footer>
                     
            </Modal>
            </form>
            
        );
    }
}

const LoginScreen = connect(LoginScreen_, store => ({
    username: store.loginForm.username,
    password: store.loginForm.password,
    errors: store.loginForm.errors,
    regEmailaddr: store.registerForm.emailaddr,
    regUsername: store.registerForm.username,
    regPassword: store.registerForm.password,
    acceptAgb: store.registerForm.acceptAgb,
    allowEmails: store.registerForm.allowEmails,
    regErrors: store.registerForm.errors,
    loading: store.registerForm.loading
}), dispatch => ({
    update: (field, value) => dispatch(loginInput(field, value)),
    regUpdate: (field, value) => dispatch(registerInput(field, value)),
 
    register: () => dispatch(register()),
    signIn: (username, password, callback) => dispatch(signIn(username, password, callback)),
    registerWithGoogle: callback => dispatch(registerWithGoogle(callback)),
    registerWithFacebook: callback => dispatch(registerWithFacebook(callback)),
    clearRegisterForm: () => dispatch(clearRegisterForm()),
    clearLoginForm: () => dispatch(clearLoginForm())
}));




// Use this component anywhere to provide the modal. Without an instance,
// the modals will not be shown.
class RequireLogin extends React.PureComponent {

    constructor(props) {
        super(props);

        this._update = this._update.bind(this);

        this.state = { showModal: globalState.show };
    }

    componentDidMount() {
        globalState.addChangeListener(this._update);
    }

    componentWillUnmount() {
        globalState.removeChangeListener(this._update);
    }

    showLogin() {
        globalState.showLogin();
    }

    showRegister() {
        globalState.showRegister();
    }
    _update() {
        this.setState({ showModal: globalState.show });
    }

    render() {
        return (
            <React.Fragment>
                {this.props.pending && <PendingScreen showModal={this.state.showModal}/>}
                {!this.props.pending && <LoginScreen showModal={this.state.showModal}/>}
                {this.props.children}
            </React.Fragment>
        );
    }
}

RequireLogin = connect(RequireLogin, state => ({
    pending: state.registerForm.pending
}))

// This component replaces/extends the Link component from react-router.
// It works as a normal Link only if the user is logged in. Otherwise
// it opens only the Login/Register modal.
class Link2 extends React.PureComponent {

    constructor(props) {
        super(props);

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick(e) {
        if (!Api.isSignedIn()) {
            e.preventDefault();
            e.stopPropagation();

            globalState.showModal();
        }
    }

    render() {
        return (
            <Link {...this.props} onClick={this._handleClick}>{this.props.children}</Link>
        );
    }
}

//const RequireLogin = withRouter(Inner);

// function login() {

//     //    history.push("/login");

//     var promise = new Promise((resolve, reject) => {
//         // if (!Api.isSignedIn()) {
//         //     reject();
//         // }
//         // var form = new FormData();
//         // form.append('username', username);
//         // form.append('password', password)

//         // this._post('Token', form)
//         //     .then(response => {
//         //         if (response.status === 200) {
//         //             response.json().then(data => {
//         //                 this._user  = data.user;
//         //                 this._token = data.token;
//         //                 this.emit('signInOut');
//         //                 resolve();
//         //             });
//         //         } else {
//         //             reject();
//         //         }
//         //     });
//         resolve();
//     });

//     return promise;
// }

RequireLogin = withRouter(RequireLogin);

RequireLogin.Link = Link2;

// Use this function to execute a function only if a user is logged in.
RequireLogin.do = function (action) {
    if (Api.isSignedIn()) {
        action();
    } else {
        globalState.showModal();
    }
}

RequireLogin.showLogin = function () {
    globalState.showLogin();
}

RequireLogin.showRegister = function () {
    globalState.showRegister();
}

export default RequireLogin;
