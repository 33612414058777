import React from 'react';

class Measured extends React.PureComponent {

    constructor(props) {
        super(props);

        this._handleResize = this._handleResize.bind(this);

        this.state = { width: undefined };
    }

    componentDidMount() {
        window.addEventListener("resize", this._handleResize, true);

        this._handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this._handleResize);
    }

    _handleResize()
    {
        if (!this._container) {
            return;
        }

        var newWidth = this._container.clientWidth;

        if (newWidth !== this.state.width) {
            this.setState({ width: newWidth, windowWidth: window.innerWidth });
        }
    }

    render() {
        // Only render the children when the width is known
        var children = null;

        if (this.state.width || this.props.renderChildrenAlways) {
            children = React.Children.map(this.props.children, child => React.cloneElement(child,
                { containerWidth: this.state.width, windowWidth: this.state.windowWidth }));
        }

        return (
            <div ref={elem => { this._container = elem; }} className={this.props.className} style={this.props.style}>
                {children}
            </div>
        );
    }
};

function withMeasure(WrappedComponent) {
    return class extends React.PureComponent {
        constructor(props) {
            super(props);
    
            this._handleResize = this._handleResize.bind(this);
    
            this.state = { width: undefined };
        }
    
        componentDidMount() {
            window.addEventListener("resize", this._handleResize, true);
    
            this._handleResize();
        }
    
        componentWillUnmount() {
            window.removeEventListener("resize", this._handleResize);
        }
    
        _handleResize()
        {
            if (!this._container) {
                return;
            }

            var newWidth = this._container.clientWidth;
    
            if (newWidth !== this.state.width) {
                this.setState({ width: newWidth, windowWidth: window.innerWidth });
            }

            return true;
        }
    
        render() {
            // Only render the children when the width is known
            var child = null;
    
            const { renderChildrenAlways, ...props } = this.props;

            if (this.state.width || renderChildrenAlways) {
                child = <WrappedComponent containerWidth={this.state.width} windowWidth={this.state.windowWidth} {...props}/>
            }
    
            return (
                <div ref={elem => { this._container = elem; }}>
                    {child}
                </div>
            );
        }            
    }
}

export { Measured, withMeasure };