import React from 'react';
import { withRouter } from 'react-router-dom';

import { Link } from 'react-router-dom';

import Api from 'components/Api';

import ReviewList from 'components/MovieDetails/ReviewList';
import Lists from 'components/MovieLists';
import RequireLogin from 'components/RequireLogin';
import { connect } from 'components/StoresContext';
import { getProfile, getMovies, getLists, getReviews, getFriends } from 'actions/otherUser';
import { createMovieList } from 'actions/movieList';

class Counter extends React.PureComponent {
    render() {
        return <span className="badge badge-info" style={{ display: "inline-block", marginLeft: "5px", verticalAlign: "middle" }}>{this.props.count}</span>
    }
};

class UserMovies extends React.PureComponent {

    render() {
        if (!this.props.movies) {
            return (
                <div key={this.props.header}>
                <h3>{this.props.header}
                    <span className="fa fa-spinner fa-pulse" style={{marginLeft:"10px"}}/>
                </h3>
                </div>
            )
        } else {
            if (this.props.movies.length > 0) {
                var content = this.props.movies.map((item, index) => {
                    if (item && index < 7) 
                    {
                        return (
                            <div key={item.id} style={{ display: "inline-block", paddingRight: "5px" } }>
                                <Link to={Api.getMovieUrl(item)}><img src={Api.getTmdbImage(item.poster, 45)} alt="" style={{ width: 45, height: 68 }}/></Link>
                            </div>
                        );
                    }

                    return undefined;
                });
            } else {
                content = (<span>{this.props.emptylabel}</span>);
            }

            return (
                <div key={this.props.header}>
                    <h3>{this.props.header} <Counter count={this.props.movies.length} /></h3>
                    {content}
                </div>
            );
        }
    }
}

class UserReviews extends React.PureComponent {

    render() {
        if (!this.props.items) {
            return null;
        }

        return <ReviewList reviews={this.props.items} mode="MovieAndTime" pageSize={5} currentUser={this.props.currentUser}/>
    }
}

class UserLists extends React.PureComponent {
    render() {
        if (!this.props.items) {
            return null;
        }

        return <Lists lists={this.props.items} pageSize="3" />
    }
}

class ProfileBody extends React.PureComponent {

    constructor(props) {
        super(props);

        this._handleCreateList = this._handleCreateList.bind(this);
    }

    renderUsers(users) {
        if (users === null || users === undefined)
            return null;
        else
            return users.map(function (user) {
                return (
                    <div key={user.id} className="text-center" style={{display:"inline-block", textAlign:"center", width:"78px", height:"100px", verticalAlign:"top", overflow:"hidden"}}>
                        <Link key={user.id} to={Api.getUserUrl(user)}>
                            <img src={Api.getProfileImageUrl(user.image)} alt="" className="img-medium img-circle" style={{ marginBottom: "4px" }}/><br/>
                            <span>{user.name}</span>
                        </Link>
                    </div>
                );
            });
    }

    _handleCreateList() {

        RequireLogin.do(() => {

            this.props.createList();

            this.props.history.push(`/Listen/new--1`);
        });
    }

    render() {

        var showButton = this.props.currentUser && this.props.user && this.props.currentUser.id === this.props.user.id;

        if (this.props.user === undefined || this.props.user === null) 
        {
            return (
                <div className="container" style={{ marginBottom: "20px"}}>
                     <div className="row">
                        <h3>
                            <span className="fa fa-spinner fa-pulse"/>
                        </h3>
                    </div>
                </div>
            )
        }
        return (
            <div className="container" style={{ marginBottom: "20px"}}>
                <div className="row" style={{marginTop:"10px"}}>
                    <div className="col-lg-5 text-left">
                        <div className="row">
                            <div className="col-12" style={{overflow:"hidden", whiteSpace:"nowrap"}}>
                                <UserMovies header="Filme" movies={this.props.movies} emptylabel={this.props.user.name+" hat noch keinen Film gecheckt"} />
                                <Link className="pull-right" to={Api.getUserUrl(this.props.user) + '/filme'}>
                                Alle anzeigen...
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 text-left">
                                <h3>Listen {this.props.lists && <Counter count={this.props.lists.length}/>} </h3>
                            </div>
                            {showButton &&
                                <div className="col-6 text-right" style={{ marginTop: "20px" } }>
                                        <button type="button" className="btn btn-orange" onClick={this._handleCreateList} style={{ display: "inline-block", marginLeft: "20px", width: "100px" }}>
                                            <span className="fa fa-plus"></span>                                      
                                            <span style={{ display: "inline-block", marginLeft: "5px" } }>Liste</span> 
                                        </button>
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <UserLists items={this.props.lists}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 text-left">
                        <div className="row">
                            <div className="col-12">
                                <h3>Reviews {this.props.reviews && <Counter count={this.props.reviews.length}/>} </h3>
                                <UserReviews currentUser={this.props.currentUser} items={this.props.reviews}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>Freunde {this.props.friends && <Counter count={this.props.friends.length}/>} </h3>
                        {this.renderUsers(this.props.friends)}
                    </div>
                </div>
            </div>
        );
    }
};

export default withRouter(connect(ProfileBody, (store, props) => ({
    currentUser: store.user.currentUser,

    user: getProfile(store, props.userId),
    movies: getMovies(store, props.userId),
    lists: getLists(store, props.userId),
    reviews: getReviews(store, props.userId),
    friends: getFriends(store, props.userId)
}), dispatch => ({
    createList: _ => dispatch(createMovieList())
})));