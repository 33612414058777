import React from 'react';
import { Link } from 'react-router-dom';
import FlipMove from 'react-flip-move';
import RequireLogin from 'components/RequireLogin';


import { load as loadHome } from 'actions/home';

import List from 'components/Lists/List';
import Api from 'components/Api';
import { connect } from 'components/StoresContext';

import './Home.css';
import { activateNewsUpdate, deactivateNewsUpdate } from 'actions/news';

class MovieBar_ extends React.Component 
{
  componentWillMount() {
    this.props.activateNewsUpdate();
  }

  componentWillUnmount() {
    this.props.deactivateNewsUpdate();
  }

  render() {
    var items = 
      this.props.items.filter(i => i.type === 'movie').slice(0, 30).map( 
        m => (
          <div key={m.ts} style={{display:"inline-block"}}>
            <Link to={Api.getMovieUrl(m.movie)}>
              <img style={{ width: 92, height: 138 }} src={Api.getTmdbImage(m.movie.poster,92)} alt=""/>
            </Link>
          </div>
        )
      );

      return (<div><FlipMove>{items}</FlipMove></div>);
    }
}

var MovieBar = connect(MovieBar_, store => ({
    items: store.newsStore.items
}), dispatch => ({
  activateNewsUpdate: () => dispatch(activateNewsUpdate()),
  deactivateNewsUpdate: () => dispatch(deactivateNewsUpdate())
}));

class Home extends React.PureComponent {

  componentWillMount() {
    this.props.load();
  }

  backgroundImage() {
    // Toy Story 4
    // return "https://image.tmdb.org/t/p/original/p3lkc1fDBeX9ZiIQVwRtOnXYENL.jpg";

    // Fast & Furious Hobbs and Shaw
    // return "https://image.tmdb.org/t/p/original/choLdSGqGpMazbNQgCgItvJ6rW.jpg";

    // Star Wars, the rise of Skywalker
    // return "https://image.tmdb.org/t/p/original/dCB7d4l0mfpsISZvr6aPE2z5QF6.jpg";

    // Bad Boys 
    return "https://image.tmdb.org/t/p/original/upUy2QhMZEmtypPW3PdieKLAHxh.jpg";
  }


    render() {
      return (
        <React.Fragment>
                <div className="container-fluid d-none d-lg-block" style={{backgroundColor:"rgb(25,25,25)", padding:"0px"}}>
                    <div className="container ab-dark" style={{padding:"0px", backgroundImage: "url("+this.backgroundImage()+")", backgroundSize:"100%", backgroundRepeat:"no-repeat" }}>
                        <div className="container-fluid" style={{padding:"0px", margin:"0px", marginRight:"-2px", backgroundSize:"cover", 
                            background:"-moz-linear-gradient(top, rgba(25,25,25,0) 0%, rgba(25,25,25,0.4) 60%, rgba(25,25,25,1.0) 80%)", 
                            background: "-webkit-linear-gradient(top, rgba(25,25,25,0) 0%,rgba(25,25,25,0.4) 60%,rgba(25,25,25,1.0) 80%)", 
                            background: "linear-gradient(to bottom, rgba(25,25,25,0.0) 0%,rgba(25,25,25,0.4) 60%, rgba(25,25,25,1.0) 80%)", 
                            filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#00191919', endColorstr='#191919',GradientType=0 )" }}>
                            <div className="container ab-dark" style={{marginLeft:"20px", marginRight:"20px", paddingBottom:"20px"}}>
                                <div className="row" style={{paddingTop:"150px"}}>
                                    <div className="col text-center">
                                        <img src="/Images/logo/Logo-128x128.png" alt=""/>
                                    </div>
                                </div>
                                <div className="row" style={{marginTop:"100px", marginBottom:"20px"}} >
                                  <div className="col text-center">
                                    <h1 style={{marginBottom:"60px"}}>Alle Deine Filme <br/>
                                      <small>Behalte den Überblick mit kinokicks.de</small>
                                    </h1>
                                    <button className="btn btn-orange btn-lg" onClick={RequireLogin.showRegister}>Ausprobieren</button>
                                    <h5 style={{paddingTop:"40px"}}>
                                      Lege dein kostenloses Profil an, oder<button className="btn btn-link" onClick={RequireLogin.showLogin}>melde dich an</button>wenn du schon Mitglied bist.
                                    </h5>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-lg-none" style={{backgroundColor:"rgb(25,25,25)", padding:"0px"}}>
                      <div className="container ab-dark" style={{paddingBottom:"20px"}}>                              <div className="row" style={{paddingTop:"50px"}}>
                            <div className="col text-center">
                                <img src="/Images/logo/Logo-128x128.png" alt=""/>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:"30px", marginBottom:"50px"}} >
                          <div className="col text-center">
                            <h1 style={{marginBottom:"30px"}}>Alle Deine Filme <br/>
                              <small>Behalte den Überblick mit kinokicks.de</small>
                            </h1>
                              <button className="btn btn-orange btn-lg" onClick={RequireLogin.showRegister}>Ausprobieren</button>
                            <h3><small>
                              Lege dein kostenloses Profil an, oder <button className="btn btn-link" onClick={RequireLogin.showLogin}>melde dich an</button> wenn du schon Mitglied bist.
                            </small></h3>
                            </div>
                        </div>
                    </div>
                </div>


            <div className="container-fluid rainbow" style={{height:"1px"}}>
            </div>

            <div className="container-fluid" style={{backgroundColor:"rgb(25,25,25)", minHeight:"140px", paddingLeft:"0px", paddingRight:"0px", paddingTop:"5px", paddingBottom:"5px", textOverflow:"clip", whiteSpace:"nowrap", overflow:"hidden"}}>
              <MovieBar/>
            </div>

            <div className="container-fluid rainbow" style={{height:"1px"}}>
            </div>

            <div className="container-fluid" style={{backgroundColor:"rgb(25,25,25)", height:"5px"}}>
            </div>


              <div className="container-fluid" style={{backgroundColor:"rgb(25,25,25)", paddingTop:"20px", paddingBottom:"50px"}}>
                  <div className="container ab-dark">
                      <div className="row">
                          <div className="col-md-6">
                            <h2>So funktioniert kinokicks</h2>
                          </div>
                        </div>
                      <div className="row"  style={{marginTop:"20px"}}>
                          <div className="col-md-4">
                              <h4 style={{marginRight:"20px"}}>
                                1. Markiere deine Filme
                              </h4>
                              <h4>
                                <small>Markiere mit einem Klick die Filme die du kennst oder noch sehen willst. Das geht entweder über die <Link to="/News">News</Link> oder komfortabel über sie <Link to="/Search">Suche</Link>. So hast du in <strong style={{color:"#ff8c00"}}>Nullkommanix</strong> alle deine Filme beinander, egal ob 10, 1.000 oder 10.000!</small>
                              </h4>
                          </div>
                          <div className="col-md-4">
                              <h4 style={{marginRight:"20px"}}>
                                2. Sortiere über Tags
                              </h4>
                              <h4>
                                <small>Ordne deine Filmsammlung. Wo hast du den Film gesehen? Mit wem? Was hat dir besonders gefallen? Welcher Film kommt auf deinen Merkzettel? Superschnell und einfach kannst du <strong style={{color:"#ff8c00"}}>eigene Kategorien</strong> vergeben und hast so immer den Überblick!</small>
                              </h4>
                          </div>
                          <div className="col-md-4">
                              <h4 style={{marginRight:"20px"}}>
                                3. Entdecke neue Filme
                              </h4>
                              <h4>
                                <small>Folge den Aktivitäten anderer Filmfans und lass dich von den <Link to="/Listen">Listen</Link> inspirieren. Bei den kinokicks Listen darf jeder mitmachen und abstimmen oder Filme ergänzen! Die <strong style={{color:"#ff8c00"}}>kinokicks Community</strong> findet immer die besten Filmperlen - garantiert!</small>
                              </h4>
                          </div>
                      </div>
                      <div className="row"  style={{marginTop:"50px"}}>
                        <div className="col text-center">
                                <button className="btn btn-opaque btn-lg" onClick={RequireLogin.showRegister}>Kostenloses Profil anlegen!</button>
                         
                          </div>
                      </div>
                  </div>
              </div>
              
              <div className="container-fluid" style={{backgroundColor:"rgb(25,25,25)", height:"5px"}}>
              </div>
              <div className="container-fluid rainbow" style={{height:"1px"}}>
              </div>

              <div className="container-fluid" style={{backgroundColor:"rgb(25,25,25)"}}>
                <div className="container ab-dark">
                  <div className="row"  style={{paddingTop:"40px"}}>
                    <div className="col-lg" style={{marginRight:"10px"}}>
                      <h3 style={{marginBottom:"30px"}}>Beliebte Listen</h3>
                      {!this.props.activeLists && 
                        <h3><span className="fa fa-spinner fa-spin"/></h3>
                      }
                      {this.props.activeLists &&
                        this.props.activeLists.map( list => <List key={list.id} list={list} maxMovies="3"/> )
                      }
                   </div>
                    <div className="col-lg" style={{marginLeft:"10px"}}> 
                      <h3 style={{marginBottom:"20px"}}>Aktive Nutzer</h3>
                      {!this.props.activeUsers && 
                        <h3><span className="fa fa-spinner fa-spin"/></h3>
                      }
                      {this.props.activeUsers &&
                        this.props.activeUsers.map( user => 
                          <div key={user.User.id} className="row" style={{marginBottom:"5px", paddingBottom:"5px", borderBottom:"1px solid #678"}}>
                            <div className="col-6">
                              <Link key={user.User.id} to={Api.getUserUrl(user.User)}>
                                  <img src={Api.getProfileImageUrl(user.User.image)} alt="" className="img-medium img-circle" style={{ marginRight: "10px" }}/>
                                  {user.User.name}
                              </Link>
                            </div>
                            <div className="col" style={{paddingTop:"15px"}}>
                              <span>{user.Movies} Filme</span>
                            </div>
                            <div className="col"  style={{paddingTop:"15px"}}>
                              <span>{user.Lists} Listen</span>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <div className="row"  style={{marginTop:"50px"}}>
                      <div className="col text-center">
                        {this.props.movieStats &&
                          <h5>{this.props.movieStats.checked.toLocaleString()} Filme, {this.props.movieStats.users} Nutzer</h5>
                        }
                      </div>
                  </div>
                    <div className="row"  style={{paddingTop:"20px", paddingBottom:"50px"}}>
                      <div className="col text-center">
                          <button className="btn btn-orange btn-lg" onClick={RequireLogin.showRegister}>Ich bin dabei!</button>
                        
                        </div>
                      </div>
                  </div>
              </div>
        </React.Fragment>
      );
    }
}

export default connect(Home, store => ({
    movieStats: store.homeStore.movieStats,
    activeLists: store.homeStore.activeLists,
    activeUsers: store.homeStore.activeUsers
}), (dispatch) => ({
    load: () => dispatch(loadHome())
}));