import React from 'react';
import DropZone from 'react-dropzone';
import ReactCrop from 'react-image-crop';

import Button from 'components/UI/Button';
import Modal from 'components/UI/Modal';

import 'react-image-crop/dist/ReactCrop.css';

function getCroppedImg(image, pixelCrop, fileName) {
    return new Promise((resolve, reject) => {

        const canvas = document.createElement('canvas');
        canvas.width = 160;
        canvas.height = 160;
        const ctx = canvas.getContext('2d');
      
        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            160,
            160
            );

        resolve(canvas.toDataURL('image/jpeg'));
    });
}

class ImageEditor extends React.PureComponent {

    constructor(props) {
        super(props);

        this._clear = this._clear.bind(this);
        this._selectFile = this._selectFile.bind(this);
        this._cancel = this._cancel.bind(this);
        this._commit = this._commit.bind(this);

        this._handleDrop = this._handleDrop.bind(this);

        this._handleImageLoaded = this._handleImageLoaded.bind(this);
        this._handleCropChanged = this._handleCropChanged.bind(this);
        this._handleCropComplete = this._handleCropComplete.bind(this);

        this.dropzone = null;

        this.state = { image: null, croppedImage: this.props.image, crop: null };
    }

    // Setup an initial crop area when the image was loaded into the cropper.
    _handleImageLoaded(image) {
        var imgRatio = image.width / image.height;
        var width = 100;
        var height = width * imgRatio;

        if (imgRatio > 1) {
            height = 100;
            width = height / imgRatio;
        }

        var x = (100 - width) / 2;
        var y = (100 - height) / 2;

        this._imageElem = image;

        // Make sure the preview image is also updated
        var pixelCrop = {
            x: image.naturalWidth * x / 100,
            y: image.naturalHeight * y / 100,
            width: image.naturalWidth * width / 100,
            height: image.naturalHeight * height / 100,
        };
        this._handleCropComplete(null, pixelCrop);

        this.setState({ crop: { x: x, y: y, width: width, height: height, aspect: 1 } });
    }

    _handleCropChanged(crop) {
        this.setState({ crop: crop });
    }

    _handleCropComplete(crop, pixelCrop) {
        getCroppedImg(this._imageElem, pixelCrop, "ProfileImage.jpg")
            .then(file => {
                this.setState({ croppedImage: file });
            });
    }

    _handleDrop(accepted) {
        if (accepted.length > 0) {
            var objectUrl = URL.createObjectURL(accepted[0]);
            this.setState({ image: objectUrl });
        }
    }

    _selectFile() {
        this.dropzone.open();
    }

    _clear() {
        this._imageElem = null;
        this.setState({ image: null, croppedImage: null, crop: null });
    }

    _cancel() {
        this.setState({ image: null, croppedImage: this.props.image }, this._closed);
    }

    _commit() {
        if (this.props.onImageSelected && this.state.croppedImage != this.props.image) {
            this.props.onImageSelected(this.state.croppedImage);
        }
        this.setState({ image: null }, this._closed);
    }

    _closed() {
        if (this.props.onClosed) {
            this.props.onClosed();
        }
    }

    render() {

        var prompt = undefined;
        var cropper = undefined;
        if (!this.state.image) {
            prompt = (
                <div style={{ position: "absolute", padding: "10px", top: 0, bottom: 0, left: 0, right: 0, color: "white", display: "table", width: "100%", height: "100%" }}>
                    <span id="textField" style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center" }}>Ziehe ein Bild auf diese Fläche oder lade eines mit Hilfe des Buttons unten hoch.</span>
                </div>
            );
        }
        else {
            cropper = <ReactCrop src={this.state.image} crop={this.state.crop}
                onChange={this._handleCropChanged}
                onComplete={this._handleCropComplete}
                onImageLoaded={this._handleImageLoaded}
                style={{ position: "absolute" }}/>
        }

        var preview = this.state.croppedImage;
        if (!preview) {
            preview = "/Images/unknown.png";
        }

        return (
            <Modal size="large" show={this.props.show} onClosed={this._cancel}>
                <Modal.Header>
                    <Modal.Title>Profilbild ändern</Modal.Title>
                    <Modal.CloseButton onClick={this._cancel}/>
   
                </Modal.Header>
                <Modal.Body>
                    <div className="row" style={{ padding: "10px" }}>
                        <div className="hidden-xs col-sm-4 col-md-3">
                            <img src={preview} style={{ borderRadius: "80px", boxShadow: "0 0 10px #888888" }} />
                        </div>
                        <div className="col-xs-12 col-sm-8 col-md-9">
                            <div style={{ position: "relative", width: "100%", borderRadius: "10px", backgroundColor: "black", padding: "10px" }}>
                                <DropZone ref={(e) => { this.dropzone = e; }} style={{ position: "relative", width: "100%", height: "300px", marginBottom: "33px", backgroundColor: "black" }} activeStyle={{ backgroundColor: "darkgrey" }}
                                          disableClick multiple={false} accept="image/*" onDrop={this._handleDrop}>
                                    {cropper}
                                    {prompt}
                                </DropZone>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-outline-secondary" onClick={this._cancel}>Abbrechen</Button>
                    <Button className="btn btn-outline-secondary" onClick={this._clear}>Bild entfernen</Button>
                    <Button className="btn btn-outline-secondary" onClick={this._selectFile}>Datei auswählen ...</Button>
                
                    <Button className="btn btn-primary" onClick={this._commit}>OK</Button>
                    
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ImageEditor;
