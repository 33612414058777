import React from 'react';
import Helmet from 'react-helmet';

import { connect } from 'components/StoresContext';

import Header from './Header';
import EditHeader from './EditHeader';
import Items from './Items';

import './MovieList.css';
import List from 'components/Lists/List';
import api from 'components/Api';
import Movie from 'components/Movie';
import RequireLogin from '../RequireLogin';
import { createMovieList } from 'actions/movieList';



class MovieList extends React.PureComponent {

    constructor(props) {
        super(props);

        this._handleCreateList = this._handleCreateList.bind(this);
    }

    _handleCreateList() {

        RequireLogin.do(() => {
            this.props.createList(url => this.props.history.push(url));
        });
    }

    render() {
        var name = this.props.name;
        var description = this.props.description;
        var movies = this.props.movies;
        var title = undefined;
        var list = this.props.list;
        var suggestions = this.props.suggestions;
        
        if (list && name && movies) 
        {
            var title = name + " (" + movies.length + " Filme)";
            title = (
                <Helmet>
                    <title>{name + " | kinokicks.de"}</title>

                    <meta name="description" content={description}/>
                    <meta property="og:title" content={name}/>
                    <meta property="og:url" content={"https://kinokicks.de" + api.getListUrl(this.props.list)}/>
                    <meta property="og:description" content={description}/>
                    {movies.length > 0 &&
                        <meta property="og:image" content={Movie.backdrop(movies[0].movie, 1280)}/>
                    }
                    <meta property="og:site_name" content="kinokicks.de"/>
                    <meta property="og:type" content="article"/>
                    <meta property="og:article:author" content={list.owner.name}/>
                </Helmet>
            );
        }

        return (
            <React.Fragment>
                 {title}
                {this.props.editing ? <EditHeader/> : <Header/>}
                {this.props.mobile && 
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-9" style={{paddingTop:"20px"}}>
                            <Items/>
                        </div>
                        <div className="col-lg-3" style={{paddingTop:"45px"}}>
                            
                            <h3>Weitere Listen</h3>
                            {suggestions && suggestions.map( list => <List key={list.id} list={list}/> )}
                        
                        </div>
                    </div>
                </div>
                }
                {!this.props.mobile && 
                    <div className="container-fluid ab-dark" style={{
                        background: "linear-gradient(90deg, rgba(255,255,255,100) 50%,  rgba(25,25,25,100) 50%)",
                    }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9" style={{paddingTop:"20px", paddingBottom:"30px", backgroundColor:"rgb(255,255,255"}}>
                                <Items/>
                            </div>
                            <div className="col-lg-3" style={{paddingTop:"45px", backgroundColor:"rgb(25,25,25)"}}>
                                
                                <h3>Weitere Listen</h3>
                                {suggestions && suggestions.map( list => <List small={true} key={list.id} list={list}/> )}
                            
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center ab-dark" style={{ paddingTop:"50px", paddingBottom:"50px", backgroundColor:"rgba(25,25,25,100) "}}>
                                <h3>Lust auf mehr?</h3>
                                <button className="btn btn-opaque" style={{marginTop:"20px"}} onClick={this._handleCreateList}>Liste anlegen</button>
                     
                            </div>
                        </div>
                    </div>
                </div>
                }
            </React.Fragment>
        );
    }
}

export default connect(MovieList, function (store) {

    var list = store.listStore.list;
    var suggestions = store.listStore.suggestions;

    return {
        mobile: store.windowSize.mobile,
        tablet: store.windowSize.tablet,
        list: list, 
        suggestions: suggestions,
        name: list ? list.name : null,
        description: list ? list.description : null,
        movies: list ? list.movies : null,
        editing: store.listStore.editing
    }},

    dispatch => ({
         createList: callback => dispatch(createMovieList(null, callback))
    })
);
