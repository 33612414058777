import React from 'react';
import { withRouter } from 'react-router-dom';

import LazyImg from './Image';
import ActorInfo from './ActorInfo';
import ActionList from './ActionList';
import MovieOverlay from './MovieOverlay';
import withOverlay from './ItemWithOverlay';

import Movie from 'components/Movie';

class MovieItem extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = { overlayVisible: false };

        this._handleOverlayClick = this._handleOverlayClick.bind(this);
    }

    _handleOverlayClick() {
        var movieItem = this.props.item;

        this.props.history.push(Movie.url(movieItem.movie));
    }

    render() {

        var item = this.props.item;

        var overlay = undefined;
        if (this.props.overlayVisible) {
            overlay = <MovieOverlay item={item} onClick={this._handleOverlayClick}/>;
        }

        var posterSrc = Movie.poster(item.movie, 185);

        // Render the movie title over the no-poster image
        if (!overlay && !item.movie.poster) {
            overlay = (
                <div className="kk-nf-overlay clear">
                    <div className="kk-nf-movietitle dim">{item.movie.title}</div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <LazyImg className="kk-nf-poster" defaultSrc="/Images/no-poster-185.png" src={posterSrc} fadeIn={500}/>
                <div className="kk-nf-placeholder">
                    {overlay}
                </div>
                <ActorInfo item={item} />
                <ActionList item={item}/>
            </React.Fragment>
        );
    }
}

export default withOverlay(withRouter(MovieItem));