import { CONFIRM_PASSWORD_INPUT, CONFIRM_PASSWORD_SUCCESS, CONFIRM_PASSWORD_FAILURE } from "actions/confirmPasswordForm";

const initialState = {
    password: "",
    errors: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CONFIRM_PASSWORD_INPUT:
            state = { ...state };
            state[action.field] = action.value;
            break;
        case CONFIRM_PASSWORD_SUCCESS:
            state = { ...state, password: "", errors: null };
            break;
        case CONFIRM_PASSWORD_FAILURE:
            state = { ...state, errors: action.errors };
            break;
        default:
    }

    return state;
}
