import api from 'components/Api';

function profile(people, size) {

    // Actual access to profile image disabled
    //var path = people.profile;
    var path = null;

    if (!path) {
        if (size >= 185) {
            return '/Images/no-poster-185.png';
        } else if (size === 92) {
            return '/Images/no-poster-92.png';
        } else {
            return '/Images/no-poster-45.png';
        }
    }

    if (path.startsWith('http://') || path.startsWith('https://')) {
        return path;
    }

    if (!path.startsWith('/')) {
        path = '/' + path;
    }

    return `https://image.tmdb.org/t/p/w${size}${path}`;
}


function poster(movie, size) {

    var path = movie.poster;

    if (!path) {
        if (size >= 185) {
            return '/Images/no-poster-185.png';
        } else if (size === 92) {
            return '/Images/no-poster-92.png';
        } else {
            return '/Images/no-poster-45.png';
        }
    }

    if (path.startsWith('http://') || path.startsWith('https://')) {
        return path;
    }

    if (!path.startsWith('/')) {
        path = '/' + path;
    }

    return `https://image.tmdb.org/t/p/w${size}${path}`;
}

function backdrop(movie, size) {

    var path = movie.backdrop;

    if (!path) {
        return null;
    }

    if (path.startsWith('http://') || path.startsWith('https://')) {
        return path;
    }

    if (!path.startsWith('/')) {
        path = '/' + path;
    }

    if (size) {
        return `https://image.tmdb.org/t/p/w${size}${path}`;
    }
    else{
        return `https://image.tmdb.org/t/p/original${path}`;
    }
    
}

// Returns the URL for the given user.
function url(movie) {
    return api.getMovieUrl(movie);
}

export default { poster: poster, url: url, backdrop: backdrop, profile: profile };