import React from 'react';

import './RatingHistogram.css';

var zeroHeight = 1;         // Height of column representing a count of zero
var histogramHeight = 30;   // Height of the biggest column in the histogram

class Bar extends React.PureComponent
{
    render() {
        return (
            <rect className="histogram-bar" x={this.props.x} y={histogramHeight - this.props.height} width={6} height={this.props.height}/>
        );
    }
};

class RatingHistogram extends React.Component{

    constructor(props) {
        super(props);
        this.state = { heights: this.props.counts.map(c => zeroHeight), totals: 0 };
    }

    componentDidMount() {
        setTimeout(function () { this.updateHeights(this.props.counts); }.bind(this), 1);
    }

    componentWillReceiveProps(nextProps) {
        this.updateHeights(nextProps.counts);
    }

    renderStars(count) {
        var result = [];
        for (var i = 0; i < count; i++) {
            result.push((<span key={i} className="fa fa-star" style={{ fontSize: "10px" }}></span>));
        };
        return result;
    }


    render() {

        var bars = this.state.heights.map( (h, i) => {
            return (
                <Bar key={"x" + i} x={1 + i * 7} height={h}/>
            );
        });

        return (
            <div>
                <div  className="row text-center">
                    <div className="col text-right" style={{paddingTop:"75px"}}>
                        <span className="fa fa-star-half-o" style={{ fontSize: "10px" }}></span>
                    </div>
                    <div className="col">
                    <svg viewBox={"0,0,70," + histogramHeight} preserveAspectRatio="xMidYMin" {...this.props}>
                       {bars}
                    </svg>
                    {this.state.total} Filme bewertet
                    </div>
                    <div className="col" style={{paddingTop:"75px"}}>
                        {this.renderStars(5)}
                    </div>
            
               </div>
            </div>
        );
    }

    updateHeights(counts) {
        var max = 1;
        var tot = 0;
        counts.forEach((v, i) => {
            if (v > max) {
                max = v;
            }
            tot += v;
        });

        this.setState({ heights: counts.map(c => zeroHeight + (histogramHeight - zeroHeight) * c / max), total: tot });
    }
};

export default RatingHistogram;
