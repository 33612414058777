import React from 'react';
import Select from 'react-select';

import { connect } from 'components/StoresContext';
import { setSearchFacet } from 'actions/search';

import MobileResults from './MobileResults';
import MovieResultItem from './MovieResultItem';

import './MovieResults.css';

class MovieResults extends React.PureComponent {

    constructor(props) {
        super(props);

        this._changeFilter = this._changeFilter.bind(this);
    }

    _changeFilter(newvalue, facet) {
        this.props.setFacet(facet, newvalue ? newvalue.value : null);
    }

    render() {
        
        const { result } = this.props;

        if (!result) {
            return null;
        }

        let countElement = result.total > 0 ? ` von ${result.total}` : "";
        let bottomRange = result.skipped + 1;
        let topRange = result.skipped + result.count;

        let resultsBlurb = (
            <div className="col-md-3 text-right" style={{paddingTop:"10px"}}>
                {bottomRange} - {topRange}{countElement}
            </div>
        );
        resultsBlurb = result.count > 0 ? resultsBlurb : null;

        var mobileMode = this.props.mobile;

        var renderedResults = null;
        if (result.hits) {
            if (mobileMode) {
                renderedResults = (
                    <MobileResults result={result}/>
                );
            } else {
                var items = result.hits.map(movie => {
                    return <MovieResultItem key={movie.id} id={movie.id} title={movie.title} year={movie.year} poster={movie.poster} description={movie.description} cast={movie.cast} crew={movie.crew} users={movie.users} rating={movie.rating} rating_count={movie.rating_count}/>
                });

                renderedResults = (
                    <div className="col-12">
                        {items}
                    </div>
                );
            }
        }
        else
        {
            renderedResults = (
                <div className="col-12">
                    <p>Leider haben wir zu diesem Suchbegriff in unserer Datenbank nichts gefunden.</p>
                    </div>
            );
        }

        var facetFilter;
        if (!mobileMode && result.count > 0 && result.facets)
        {
            var facets = result.facets;
            var germanNames = { type: "Typ", genre: "Genre", year: "Jahr", cast: "Schauspieler" };
            facetFilter = (
                <div className="col-md-9">
                    {
                        Object.keys(facets).map( facet => {
                            var label = germanNames[facet];
                            var options = facets[facet].map( o => { return { value: o.value, label: `${o.value}  (${o.count})` }});

                            if (facets[facet] && label) {
                                return (
                                    <div key={facet} style={{marginRight:"5px", display:"inline-block"}}>
                                        <Select 
                                            isClearable={true}
                                            options={options}
                                            placeholder={germanNames[facet]} 
                                            onChange={(newvalue) => this._changeFilter(newvalue, facet)}
                                            value={options.filter(({value}) => value === this.props.facetFilter[facet])}
                                            className="kk-dropdown-wrapper"/>
                                    </div>
                                );
                            }

                            return null;
                        })
                    }
                </div>
            )
        }

        return (
            <React.Fragment>
                <div className="row" style={{ marginTop: 0 }}>
                    {facetFilter}
                    {resultsBlurb}
                </div>
                <div className="row results" style={{ marginTop: 10 }}>
                    {renderedResults}
                </div>
            </React.Fragment>
        );
    }
}

export default connect(MovieResults, store => ({
    result: store.search.result,
    facetFilter: store.search.facetFilter,
    mobile: store.windowSize.mobile
}), (dispatch) => ({
    setFacet: (facet, value) => dispatch(setSearchFacet(facet, value))
}));