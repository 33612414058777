import OAuth from 'components/OAuth';

import { completeSignIn, USER_SIGNIN_FAILURE } from 'actions/user';

import { register as registerUser } from './user';

export const REGISTER_INPUT = 'REGISTER_INPUT';
export const REGISTER_VALIDATION_RESULT = 'REGISTER_VALIDATION_RESULT';
export const REGISTER_CLEAR = 'REGISTER_CLEAR';

export function registerInput(field, value) {
    return { type: REGISTER_INPUT, field, value };
}

export function clearRegisterForm() {
    return { type: REGISTER_CLEAR };
}

export function register() {
    return function (dispatch, getState) {
        var { username, emailaddr, password, acceptAgb, allowEmails } = getState().registerForm;

        var errors = [];
        if (!username || username === '') {
            errors.push("Benutzername fehlt.");
        }
        if (!emailaddr || emailaddr === '') {
            errors.push("E-Mail Adresse fehlt.");
        }
        if (!password || password === '') {
            errors.push("Passwort fehlt.");
        }
        if (!acceptAgb) {
            errors.push("Bitte akzeptiere die Datenschutzbestimmungen und AGB.");
        }

        // Store the validation result
        dispatch({ type: REGISTER_VALIDATION_RESULT, errors: errors.length > 0 ? errors : null });

        if (errors.length === 0) {
            // No validation problems execute the actual registration
            dispatch(registerUser(username, emailaddr, password, allowEmails));
        }
    }
}

function registerWithOAuth(provider, callback) {
    return function (dispatch, _) {
        OAuth.register(provider)
            .then(result => {
                dispatch(completeSignIn(result.token, result.user));
                callback();
            }).catch(errors => {
                dispatch({ type: USER_SIGNIN_FAILURE, errors: errors });
            });
    }
}

export function registerWithGoogle(callback) {
    return registerWithOAuth("google", callback);
}

export function registerWithFacebook(callback) {
    return registerWithOAuth("facebook", callback);
}