import React from 'react';
import { withRouter } from 'react-router-dom';

import AdaptiveBackground from 'components/AdaptiveBackground';
import Switch from 'components/UI/Switch';
import Button from 'components/UI/Button';
import { connect } from 'components/StoresContext';

import { updateListField, updateHeader, endEditList, publish, deleteList }  from 'actions/movieList';

class Actionbar1 extends React.PureComponent {

    constructor(props) {
        super(props);

        this._done = this._done.bind(this);
        this._publish = this._publish.bind(this);
        this._delete = this._delete.bind(this);
    }

    _publish() 
    {
        if (!this.props.published) {
            this.props.publish();
        }
    }

    _done() 
    {
        this.props.exitEditMode();
    }

    _delete() {
        this.props.deleteList();
        this.props.history.push("/");
    }

    render() {
        return (
            <div className="col-sm-3 text-center" style={{ paddingTop: 20 }}>
                { !this.props.published ? <Button bsStyle="primary" onClick={this._publish} block >Veröffentlichen</Button> : null }
                { this.props.published ? <Button bsStyle="primary" onClick={this._done} block>Fertig</Button> : null }
                { !this.props.published ? <Button bsStyle="primary" onClick={this._delete} block>Liste löschen</Button> : null }
            </div>
        );
    }
}

var Actionbar = withRouter(connect(Actionbar1, store => ({
    listId: store.listStore.list.id,
    listName: store.listStore.list.name,
    published: store.listStore.list && store.listStore.list.is_published
}), dispatch => ({
    publish: () => dispatch(publish()),
    exitEditMode: () => dispatch(endEditList()),
    deleteList: callback => dispatch(deleteList(callback))
})));

class EditHeader extends React.PureComponent {

    constructor(props) {
        super(props);

        this._handleNameChange = this._handleNameChange.bind(this);
        this._handleNameBlur = this._handleNameBlur.bind(this);
        this._handleDescriptionChange = this._handleDescriptionChange.bind(this);
        this._handleDescriptionBlur = this._handleDescriptionBlur.bind(this);
        this._handleUserCanAddMoviesChange = this._handleUserCanAddMoviesChange.bind(this);
    }

    _handleNameChange(e) {
        this.props.updateListField("name", e.target.value);
    }

    _handleNameBlur(e) {
        this.props.updateHeader();
    }

    _handleDescriptionChange(e) {
        this.props.updateListField("description", e.target.value);
    }

    _handleDescriptionBlur(e) {
        this.props.updateHeader();
    }

    _handleUserCanAddMoviesChange(e, newValue) {
        this.props.updateListField("users_can_add_movies", newValue);
        this.props.updateHeader();
    }

    render() {

        var list = this.props.list;
        if (!list) {
            return null;
        }

        return (
            <AdaptiveBackground className="container-fluid" style={{ marginTop: "-20px", paddingTop: "20px" }} image={this.props.listImage}>
                <div className="container">
                    <div className="row" style={{ paddingBottom: 20 }}>
                        <div className="col-sm-9 text-left" style={{ paddingTop: 20 }}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <input type="text" name="name" className="form-control" value={this.props.name} style={{ width: "80%", backgroundColor: "white", color: "black" }} onChange={this._handleNameChange} onBlur={this._handleNameBlur}/>
                                    <textarea name="description" className="form-control" style={{ marginTop: "8px", color: "black" }}
                                        rows={5}
                                        placeholder="Bitte beschreibe hier, worum es bei der Liste geht."
                                        onChange={this._handleDescriptionChange} onBlur={this._handleDescriptionBlur}
                                        value={this.props.description}>

                                    </textarea>
                                    <div style={{ marginTop: "10px" }}>
                                        Dürfen andere Nutzer Filme zur Liste ergänzen?<div style={{ width: "30px", display: "inline-block" }}/>
                                        <Switch bsSize="mini" value={this.props.users_can_add_movies} onText="ja" offText="nein" onChange={this._handleUserCanAddMoviesChange}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Actionbar/>
                    </div>
                </div>
            </AdaptiveBackground>
        );
    }
}

export default connect(EditHeader, function (store) {

    let list = store.listStore.list;
    let edit = store.listStore.edit;

    edit = edit ? edit : {};

    return {
        list: list,
        listImage: store.listStore.listImage,
        name: edit.name,
        description: edit.description,
        users_can_add_movies: edit.users_can_add_movies
    };
}, dispatch => ({
    updateListField: (field, value) => dispatch(updateListField(field, value)),
    updateHeader: () => dispatch(updateHeader())
}));