import React, { Component } from 'react';

import ScrollToTop from './ScrollToTop';
import RequireLogin from 'components/RequireLogin';

import Header from 'shared/Header';
import Footer from 'shared/Footer';
import Body from 'shared/Body';
import Feedback from 'shared/Feedback';

import 'App.css';
import 'css/Buttons.css';

import { WindowSizeWatcher } from 'components/WindowSize';

import Modal from 'components/UI/Modal';

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <WindowSizeWatcher/>
        <RequireLogin/>
        <ScrollToTop>
          <Header/>
          <Body/>
          <Footer/>          
          <Feedback/>
        </ScrollToTop>
        <Modal.Portal/>
      </React.Fragment>
    );
  }
}

export default App;
