import { NEWPASSWORD_INPUT, NEWPASSWORD_REQUEST_SUCCESS, NEWPASSWORD_REQUEST_FAILURE } from "actions/newPasswordForm";

const initialState = {
    userName: "",
    waiting: false,
    errors: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case NEWPASSWORD_INPUT:
            state = { ...state };
            state[action.field] = action.value;
            break;
        case NEWPASSWORD_REQUEST_SUCCESS:
            state = { ...state, userName: "", errors: null, waiting: true };
            break;
        case NEWPASSWORD_REQUEST_FAILURE:
            state = { ...state, errors: action.errors };
            break;
    }

    return state;
}
