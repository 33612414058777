import GoogleAnalytics from 'GoogleAnalytics';

import Api from 'components/Api';

export const CREATE_TAG_DIMENSION = "CREATE_TAG_DIMENSION";
export const DELETE_TAG_DIMENSION = "DELETE_TAG_DIMENSION";

export const CREATE_TAG_VALUE = "CREATE_TAG_VALUE";
export const CREATE_TAG_VALUE_SUCCESS = "CREATE_TAG_VALUE_SUCCESS";
export const UPDATE_TAG_VALUE = "UPDATE_TAG_VALUE";
export const DELETE_TAG_VALUE = "DELETE_TAG_VALUE";

export function createTagDimension(name) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "action", action: "add-dimension" });
        Api.postJson(`Tags`, { name, values: [] });
        dispatch({ type: CREATE_TAG_DIMENSION, name });
    }
}

export function deleteTagDimension(dimensionId) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "action", action: "delete-dimension" });
        dispatch({ type: DELETE_TAG_DIMENSION, dimensionId });
        Api.deleteJson(`Tags/${dimensionId}`);
    }
}

export function createTagValue(dimensionId, name) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "action", action: "add-value" });
        dispatch({ type: CREATE_TAG_VALUE, dimensionId, name });
        Api.postJson(`Tags/${dimensionId}/Values`, { name } ).then(data => {
            dispatch({ type: CREATE_TAG_VALUE_SUCCESS, dimensionId, id: data.value_id, name });
        });
    }
}

export function updateTagValue(dimensionId, valueId, newName) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "action", action: "update-value" });
        dispatch({ type: UPDATE_TAG_VALUE, dimensionId, valueId, newName });
        Api.putJson(`Tags/${dimensionId}/Values`,{ value_id: valueId, name: newName });
    };
}

export function deleteTagValue(dimensionId, valueId) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "action", action: "delete-value" });
        dispatch({ type: DELETE_TAG_VALUE, dimensionId: dimensionId, valueId: valueId });
        Api.deleteJson(`Tags/${dimensionId}/Values`,{ value_id: valueId });
    }
}