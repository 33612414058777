import Api from 'components/Api';

export const NEWS_LOAD = "NEWS_LOAD";
export const NEWS_LOAD_SUCCESS = "NEWS_LOAD_SUCCESS";

function loadItems(dispatch, getState, showIndicator, mode, count, olderThan, newerThan) {

    let state = getState().newsStore;
    
    var genreFilter = state.filter.genre;
    var tagFilter = state.filter.tag;
    var activityFilter = state.filter.activity;
    var peopleFilter = state.filter.people;
    var yearFilter = state.filter.year;
    var typeFilter;
    if (state.filter.type) {
        typeFilter = state.filter.type = "Serie" ? true : false;
    }

    dispatch({ type: NEWS_LOAD, mode, showIndicator, count });

    var query = {
        count: count,
        olderThan: olderThan,
        newerThan: newerThan,
        genre: genreFilter ? genreFilter : "",
        year: yearFilter,
        tvShows: typeFilter,
        activityFilter: activityFilter,
        onlyFriends: peopleFilter,
        tagFilter: tagFilter ? tagFilter : ""
    };

    return Api.get('news', query).then(data => {

        dispatch({ type: NEWS_LOAD_SUCCESS, mode, data });

        if (mode === "replace" && typeof(window) !== "undefined") {
            window.scrollTo(0, 0);
        }

        //LazyImg.updateViewport();

        return data;
    })
    .catch(_ => {});
}

export function initNews() {
    return function (dispatch, getState) {

        if (getState().newsStore.items.length === 0) {
            return loadItems(dispatch, getState, true, "replace", 30, null, null, null);
        }

        return Promise.resolve();
    }
}

export function loadMoreItems(itemsToLoad) {
    return function (dispatch, getState) {
        var state = getState().newsStore;

        if (state.loading || !state.haveMoreItems) {
            return;
        }

        var lastTimestamp = state.items[state.items.length - 1].ts;
        loadItems(dispatch, getState, true, "append", itemsToLoad, lastTimestamp);
    }
}

//let intervalId;
// function loadUpdate(dispatch, getState) {
// //         if (this._loading || !this._data) {
// //             return;
// //         }

// //         var firstTs = undefined;
// //         var feedData = this._data;
// //         if (feedData.items.length > 0) {
// //             firstTs = feedData.items[0].ts;
// //         }

// //         this._loadItems(false, "prepend", 30, null, firstTs);
// //     }

// }

export function activateNewsUpdate() {
    return function (dispatch, getState) {
        initNews()(dispatch, getState);
        // if (this._data.items.length == 0) {
        //     // Start an initial load
        //     this._reload();
        // }

        // Start the timer to refresh every 5 seconds
        //intervalId = setInterval(() => loadUpdate(dispatch, getState), 5000);
    }
}

export function deactivateNewsUpdate() {
    return function(dispatch, getState) {
        //clearInterval(intervalId);
        //intervalId = null;
    }
}



// updatePeopleFilter(newValue) {
//     this._filter = { activity: this._filter.activity, genre: this._filter.genre, people: newValue, year: this._filter.year, type:this._filter.type, tag: this._filter.tag }

//     this._reload();
// }

// updateGenreFilter(newGenre) {
//     this._filter = { activity: this._filter.activity, genre: newGenre, people: this._filter.people, year: this._filter.year, type:this._filter.type, tag: this._filter.tag }

//     this._reload();
// }

// updateActivityFilter(newActivity) {
//     this._filter = { activity: newActivity, genre: this._filter.genre, people: this._filter.people, year: this._filter.year, type:this._filter.type, tag: this._filter.tag }

//     this._reload();
// }

// updateYearFilter(newYear) {
//     this._filter = { activity: this._filter.activity, genre: this._filter.genre, people: this._filter.people, year: newYear, type:this._filter.type, tag: this._filter.tag }

//     this._reload();
// }

// updateTypeFilter(newType) {
//     this._filter = { activity: this._filter.activity, genre: this._filter.genre, people: this._filter.people, year: this._filter.year, type: newType, tag: this._filter.tag }

//     this._reload();
// }

// updateTagFilter(newTag) {
//     this._filter = { activity: this._filter.activity, genre: this._filter.genre, people: this._filter.people, year: this._filter.year, type: this._filter.type, tag: newTag }

//     this._reload();
// }

