import { LIST_LOAD, LIST_LOAD_SUCCESS, LIST_LOAD_SUGGESTIONS_SUCCESS, LIST_EDIT, LIST_END_EDIT, LIST_EDIT_FIELD, LIST_UPDATE_HEADER,
    LIST_ADD_MOVIE, LIST_REMOVE_MOVIE, LIST_VOTE_MOVIE, LIST_MOVE_MOVIE, LIST_CREATE_SUCCESS, LIST_PUBLISH, LIST_LIKE, LIST_UNLIKE,
    LIST_COMMENT_MOVIE, LIST_DELETE } from '../actions/movieList';

const emptyList = {
    id: null,
    name: "",
    description: "",
    created_at: null,
    is_liked_by_me: false,
    owner: {},
    movies: []
}

const initialState = {
    list: emptyList,
    suggestions: [],

    editing: false,
    edit: null
};

function sortMovies(movies, editing) {
    movies.sort((a, b) => {
        if (editing || a.score === b.score) {
            return a.orig_pos - b.orig_pos;
        } else {
            return b.score - a.score;
        }
    });
}

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case LIST_LOAD: {        
            state = { ...state, list: emptyList, suggestions: null, editing: false, edit: null };
            break;
        }
        case LIST_LOAD_SUCCESS: {
            let data = action.data;
            if (!data.movies) {
                data.movies = [];
            }
    
            // Fix missing default values
            data.movies.forEach(m => {
                if (!m.orig_pos) {
                    m.orig_pos = 0;
                }
                if (!m.score) {
                    m.score = 0;
                }
                if (!m.votes) {
                    m.votes = 0;
                }
                if (!m.vote) {
                    m.vote = 0;
                }
                if (!m.comments) {
                    m.comments = [];
                }
            });
    
            sortMovies(data.movies, false);

            state = { ...state, list: data };

            break;
        }
        case LIST_LOAD_SUGGESTIONS_SUCCESS: {
            state = { ...state, suggestions: action.data };
            break;
        }
        case LIST_CREATE_SUCCESS: {
            state = { ...state, list: action.list, suggestions: null, editing: true, edit: { name: action.list.name, description: action.list.description } };
            break;
        }
        case LIST_DELETE: {
            state = { ...state, list: emptyList };
            break;
        }
        case LIST_EDIT: {
            state = { ...state, editing: true, edit: { name: state.list.name, description: state.list.description, users_can_add_movies: state.list.users_can_add_movies }};
            break;
        }
        case LIST_END_EDIT: {
            state = { ...state, editing: false, edit: null };
            break;
        }
        case LIST_EDIT_FIELD: {
            let edit = { ...state.edit };
            edit[action.field] = action.value;

            state = { ...state, edit };
            break;
        }
        case LIST_UPDATE_HEADER: {
            state = { ...state, list: { ...state.list, ...action.update } };
            break;
        }
        case LIST_PUBLISH: {
            state = { ...state, list: { ...state.list, is_published: true}, editing: false, edit: null };
            break;
        }
        case LIST_ADD_MOVIE: {

            let movies = state.list.movies.concat([ {
                orig_pos: state.list.movies.length,
                movie: action.movie,
                score: 0,
                vote: 0,
                votes: 0,
                added_by: action.user,
                comments: [],
            }]);

            sortMovies(movies);

            state = { ...state, list: { ...state.list, movies } };
            break;
        }
        case LIST_REMOVE_MOVIE: {
            let index = state.list.movies.findIndex(item => item.movie.id === action.movieId);
            if (index >= 0) {
                let movies = state.list.movies.slice();
                movies.splice(index, 1);

                state = { ...state, list: { ...state.list, movies } };
            }
            break;
        }
        case LIST_MOVE_MOVIE: {
            let index = state.list.movies.findIndex(m => m.movie.id === action.movieId);
            if (index < 0) {
                break;
            }

            let item = state.list.movies[index];
            let oldPos = item.orig_pos;
            let newPos = oldPos + action.direction;
            let otherIndex = state.list.movies.findIndex(m => m.orig_pos === newPos);

            if (otherIndex >= 0) {
                let movies = state.list.movies.slice();

                movies[index] = { ...movies[index], orig_pos: newPos };
                movies[otherIndex] = { ...movies[otherIndex], orig_pos: oldPos};

                sortMovies(movies);

                state = { ...state, list: { ...state.list, movies } };
            }
            break;
        }
        case LIST_VOTE_MOVIE: {
            let vote = action.vote;
            let index = state.list.movies.findIndex(x => x.movie.id === action.movieId);
            if (index >= 0) {
                let movieItem = state.list.movies[index];

                if (action.vote < 0 && movieItem.vote < 0) {
                    break;
                }
                if (action.vote > 0 && movieItem.vote > 0) {
                    break;
                }

                let score = movieItem.score  + vote;
                vote = movieItem.vote + vote;

                let upvoted_by = movieItem.upvoted_by ? movieItem.upvoted_by.filter(user => user.id !== action.user.id) : [];
                let downvoted_by = movieItem.downvoted_by ? movieItem.downvoted_by.filter(user => user.id !== action.user.id) : [];

                if (vote > 0) {
                    upvoted_by.push(action.user);
                } else if (vote < 0) {
                    downvoted_by.push(action.user);
                }

                let total_votes = vote === 0 ? state.list.stats.total_votes - 1 : state.list.stats.total_votes + 1;
                let votes = vote === 0 ? movieItem.votes - 1 : movieItem.votes + 1;
                movieItem = { ...movieItem, score, vote, votes, upvoted_by, downvoted_by };

                let movies = state.list.movies.slice();
                movies[index] = movieItem;

                sortMovies(movies);

                state = { ...state, list: { ...state.list, movies, stats: { ...state.list.stats, total_votes } } };
            }
            break;
        }
        case LIST_COMMENT_MOVIE: {

            let movieIndex = state.list.movies.findIndex(m => m.movie.id === action.movieId);
            if (movieIndex < 0) {
                break;
            }

            let newComment = { id: action.id, author: action.user, created_at: new Date(), text: action.text };

            let movie = { ...state.list.movies[movieIndex], comments: state.list.movies[movieIndex].comments.concat([ newComment ])};

            let movies = state.list.movies.slice();
            movies.splice(movieIndex, 1, movie);

            state = { ...state, list: { ...state.list, movies } };
            break;
        }
        case LIST_LIKE: {
            state = { ...state, list: { ...state.list, is_liked_by_me: true } };
            break;
        }
        case LIST_UNLIKE: {
            state = { ...state, list: { ...state.list, is_liked_by_me: false } };
            break;
        }
        default:
    }
    return state;
}