import React from 'react';
import classnames from 'classnames';

import MovieListBox from 'components/Newsfeed/MovieListBox';
import RequireLogin from 'components/RequireLogin';

import { connect } from 'components/StoresContext';

import './AddItem.css';
import { addMovieToList } from 'actions/movieList';

class AddItem extends React.Component {

    constructor(props) {
        super(props);

        this._handleMovieSelected = this._handleMovieSelected.bind(this);
    }

    _handleMovieSelected(movieData) {

        RequireLogin.do(() => {
           
            this.props.addMovie(parseInt(movieData.id), movieData.poster, movieData.title, parseInt(movieData.year), movieData.backdrop);
        });
    }

    render() {

        var classes = classnames({ media: true, "row": true, "add": true, "first": this.props.isFirst});
        
        var mobileMode = this.props.mobile;

        if (mobileMode) {

                return (
                    <div className={classes}>
                        <div className="col-4" style={{ whiteSpace:"nowrap" }}>
                            <span style={{ display:"inline-block", backgroundColor: "#aaa", textAlign:"center", width:"92px", height: "140px", lineHeight:"140px", fontSize:"64px", verticalAlign:"central" }}>+</span>
                        </div>
                        <div className="col-8">
                              <MovieListBox placeholder="Film hinzufügen..." onSelected={this._handleMovieSelected}/>
                            <div style={{ marginTop:"10px" }}>
                                <p><strong>Mache mit und füge einen Film zu dieser Liste hinzu!</strong></p>
                                <p>
                                Du glaubst da fehlt noch ein Film auf der Liste? Kein Problem - füge ihn einfach hinzu! Die kinokicks
                                Listen leben vom Mitmachen und dein Film kann von unten durch Abstimmen bis ganz nach oben kommen!
                                </p>           
                            </div>
                        </div>
                    </div>
                );
        } else {
            return (
                <div
                    style={{height:"500px",  marginTop:"10px", backgroundSize:"100%", backgroundRepeat:"no-repeat" }}>
                      <div className="container-fluid" style={{height:"500px", padding:"0px", margin:"0px", marginRight:"-2px", backgroundSize:"cover", 
                            background:"-moz-linear-gradient(top, rgba(25,25,25,1.0) 0%, rgba(25,25,25,1.0) 50%, rgba(25,25,25,0.5) 80%)", 
                            background: "-webkit-linear-gradient(top, rgba(25,25,25,1.0) 0%,rgba(25,25,25,1.0) 50%,rgba(25,25,25,0.5) 80%)", 
                            background: "linear-gradient(to bottom, rgba(25,25,25,1.0) 0%, rgba(25,25,25,0.0) 30%, rgba(25,25,25,0.0) 70%, rgba(25,25,25,1.0) 100%)", 
                            filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#00191919', endColorstr='#191919',GradientType=0 )" }}>
                        <div className="row">
                            <div className="col-12">
                                <h3 className="text-center" style={{paddingTop:"5px", color:"white"}}>
                                    Neuer Film
                                </h3>
                            </div>
                        </div>
                        <div className="row" style={{ overflow: "visible", marginTop:"150px" }}>
                            <div className="col-3 text-center"  style={{color:"black", fontSize:"70px"}}>?</div>
                            <div className="col-6" style={{color:"black", marginTop:"25px"}}>
                                <MovieListBox placeholder="Film hinzufügen..." onSelected={this._handleMovieSelected}/>
                            </div>
                            <div className="col-3 text-center"  style={{color:"black", fontSize:"70px"}}>?</div>
                        </div>
                        <div className="row" style={{paddingTop:"150px"}}>
                            <div className="col-12 text-center" style={{color:"white"}}>
                               Du glaubst da fehlt noch ein Film auf der Liste? Kein Problem - füge ihn einfach hinzu!
                            </div>
                        </div>
        
                    </div>
                </div>
            )
        }
    }
}

export default connect(AddItem, state => ({
    mobile: state.windowSize.mobile
}), dispatch => ({
    addMovie: (movieIdd, poster, title, year, backdrop) => dispatch(addMovieToList(movieIdd, poster, title, year, backdrop))
}));
