import React from 'react';

import { connect } from 'components/StoresContext';
import { setSearchCurrentPage } from 'actions/search';

import Pager from './Pager';

// Links a pager to the search result
class PagerContainer extends React.Component {

    constructor(props) {
        super(props);

        this._pageSelected = this._pageSelected.bind(this);
    }

    _pageSelected(p) {
        this.props.setCurrentPage(p);
    }

    render() {
        if (this.props.pages === 0) {
            return null;
        }

        return <Pager currentPage={this.props.page} numberOfPages={this.props.pages} onPageSelected={this._pageSelected}/>
    }
}

export default connect(PagerContainer, store => ({
    page: store.search.page,
    pages: store.search.pages
}), (dispatch) => ({
    setCurrentPage: p => dispatch(setSearchCurrentPage(p))
}));
