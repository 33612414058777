import React from 'react';

import { Link } from "react-router-dom";
import Movie from 'components/Movie';
import User from 'components/User';
import RelTime from 'components/RelTime';
import api from 'components/Api';
import { connect } from 'components/StoresContext';


class List extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        var list = this.props.list;
        var mobileMode = this.props.mobile || this.props.small;
        
        var maxMovies = mobileMode ? 3 : 5;
        if (this.props.small)
            maxMovies = 2; 
        var matchingMovies = [];

        // Setting of maxMovies can be overwritten by property
        if (this.props.maxMovies) {
           maxMovies = this.props.maxMovies;   
        }

      

        if (list.movies)
        {
            if (this.props.query) {
                matchingMovies = list.movies.filter( m => m.movie.title && m.movie.title.toUpperCase().includes( this.props.query.toUpperCase() ) );
            }
    
            // Remove first n movies from matching movies since we just want to display additional ones
            list.movies.slice(0,maxMovies).forEach(elem => {
                var idx = matchingMovies.indexOf( elem );
                if (idx > -1) {
                    matchingMovies.splice( matchingMovies.indexOf( elem ),1);
                }      
            });

            var firstMovies = Math.max(maxMovies - matchingMovies.length,0);


            if (mobileMode) 
            {
                return (
                    <React.Fragment key={List.id}>
                        <div className="row" style={{marginTop:"10px"}}>
                            <div className="col">
                            <h5><Link to={api.getListUrl(list)} style={{color:"inherit"}}>{list.name}</Link></h5>
                       
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
    
                            {list.movies.slice(0,maxMovies).map( movie => 
                                <div style={{display:"inline-block", marginLeft:"5px"}}>
                                    <Link to={api.getListUrl(list)}>
                                        <img src={Movie.poster(movie.movie, 92)}/>
                                    </Link>
                                </div>
                            )}
                            </div>
                        </div>
                        <div className="row" style={{borderBottom:"1px solid #678"}}>
                            <div className="col">
                                <div style={{ marginBottom: "10px", marginTop:"5px", color:"#678"}}>
                                    {list.stats.total_movies} Filme, {list.stats.total_votes} Votes, {list.stats.total_comments} Comments, {list.stats.total_likes} Likes
                                </div>
                                {!this.props.small && 
                                    <div style={{ marginBottom: "10px", color:"#678"}}>
                                        <Link to={User.url(list.owner)}><img src={User.img(list.owner)} className="img-small img-circle"/> {list.owner.name}</Link> &nbsp;
                                        <RelTime time={list.created_at}/>
                                    </div>
                                }
                            </div>
                        </div>
                </React.Fragment>
                )
            }
            else
            {
                return (
                    <div className="row text-left" style={{marginBottom:"5px", paddingBottom:"5px", borderBottom:"1px solid #678"}}>
                        
                        <div className="col-sm-7">
                            {list.movies.slice(0,firstMovies).map( movie => 
                                <div key={movie.movie.id} style={{display:"inline-block", marginLeft:"5px"}}>
                                    <Link to={api.getListUrl(list)}>
                                        <img src={Movie.poster(movie.movie, 92)}/>
                                    </Link>
                                </div>
                            )}
                             {matchingMovies.slice(0, maxMovies - firstMovies).map( movie => 
                                <div key={movie.movie.id} style={{display:"inline-block", marginLeft:"5px"}}>
                                    <Link to={api.getListUrl(list)}>
                                        <img src={Movie.poster(movie.movie, 92)}/>
                                    </Link>
                                </div>
                            )}
                        </div>
                        <div className="col-sm-5" style={{paddingTop:"20px"}}>
                            <h5><Link to={api.getListUrl(list)} style={{color:"inherit"}}>{list.name}</Link></h5>
                            <div style={{ marginBottom: "10px", color:"#678"}}>
                                {list.stats.total_movies} Filme, {list.stats.total_votes} Stimmen, {list.stats.total_comments} Kommentare, {list.stats.total_likes} Likes
                            </div>
                            <div style={{ marginBottom: "10px", color:"#678"}}>
                                <Link to={User.url(list.owner)}><img src={User.img(list.owner)} className="img-small img-circle"/> {list.owner.name}</Link> &nbsp;
                                <RelTime time={list.created_at}/>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        else 
        {
            return <div></div>
        }
    }

}

export default connect(List, state => ({ mobile: state.windowSize.mobile }));
