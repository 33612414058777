import Api from 'components/Api';

export const SEARCH_SET_PAGESIZE = "SEARCH_SET_PAGESIZE";

export const SEARCH_UPDATE_SUGGESTIONS = "SEARCH_UPDATE_SUGGESTIONS";
export const SEARCH_UPDATE_SUGGESTIONS_SUCCESS = "SEARCH_UPDATE_SUGGESTIONS_SUCCESS";
export const SEARCH_UPDATE_SUGGESTIONS_FAILURE = "SEARCH_UPDATE_SUGGESTIONS_FAILURE";

export const SEARCH_SET_INPUT = "SEARCH_SET_INPUT";

export const SEARCH_EXEC = "SEARCH_EXEC";
export const SEARCH_EXEC_SUCCESS = "SEARCH_EXEC_SUCCESS";
export const SEARCH_EXEC_FAILURE = "SEARCH_EXEC_FAILURE";

export const SEARCH_SET_FACET = "SEARCH_SET_FACET";


export function search(pageNum = 1, pageSize)
{
    return function (dispatch, getState) {
        dispatch({ type: SEARCH_EXEC, page: pageNum });

        let state = getState().search;
        let filters = Object.keys(state.facetFilter).map(f => { return { name: f, value: state.facetFilter[f] } });
        let ctr = state.ctr;

        if (!pageNum) {
            pageNum = 1;
        }

        if (!pageSize) {
            pageSize = state.pageSize;
        }

        Api.postJson("_search", { "query": state.input, "skip": (pageNum - 1) * pageSize, "take": pageSize, "filters": filters }).then( result => {
            if (getState().search.ctr === ctr) {
                dispatch({ type: SEARCH_EXEC_SUCCESS, result });
            }
        }).catch(_ => {
            if (getState().search.ctr === ctr) {
                dispatch({ type: SEARCH_EXEC_FAILURE });
            }
        });
    }
}

export function setSearchInput(input) {
    return function (dispatch, getState) {
        dispatch({ type: SEARCH_SET_INPUT, input: input});

        return search()(dispatch, getState);
    }
}

export function setSearchPageSize(size) {
    return function (dispatch, getState) {
        let result = getState().search.result;
        if (result && result.count !== size) {
            dispatch(search(1, size));
        }
        dispatch({ type: SEARCH_SET_PAGESIZE, size });
    }
}

export function setSearchCurrentPage(page) {
    return function (dispatch, getState) {
        if (page >= 1 && page <= getState().search.pages) {

            return search(page)(dispatch, getState);
        }
    }
}

export function updateSearchSuggestions(input) {

    return function (dispatch, getState) {
        dispatch({ type: SEARCH_UPDATE_SUGGESTIONS, input });

        let state = getState().search;
        let ctr = state.ctr;

        Api.get("_autocomplete", { "q": input }).then( result => {
            if (getState().search.ctr === ctr) {
                dispatch({ type: SEARCH_UPDATE_SUGGESTIONS_SUCCESS, result})
            }
        }).catch(_ => {
            if (getState().search.ctr === ctr) {
                dispatch({ type: SEARCH_UPDATE_SUGGESTIONS_FAILURE });
            }
        });
    }
}

export function setSearchFacet(facet, value) {
    return function (dispatch, getState) {
        dispatch({ type: SEARCH_SET_FACET, facet, value });

        return search()(dispatch, getState);
    }
}