import { LOAD_OTHER_USER, LOAD_OTHER_PROFILE_SUCCESS, LOAD_OTHER_MOVIES_SUCCESS, LOAD_OTHER_MOVIES_FAILURE,
         LOAD_OTHER_REVIEWS_SUCCESS, LOAD_OTHER_REVIEWS_FAILURE, LOAD_OTHER_LISTS_SUCCESS, LOAD_OTHER_LISTS_FAILURE,
         LOAD_OTHER_FRIENDS_SUCCESS, LOAD_OTHER_FRIENDS_FAILURE, LOAD_OTHER_TAGS_SUCCESS, LOAD_OTHER_TAGS_FAILURE,
         VIEW_SHOW_FILTER, VIEW_SHOW_ADD
        } from 'actions/otherUser';

import reduceReviewActions from './reviewList';
import { ACCEPT_FRIEND_REQUEST, REMOVE_FRIEND } from 'actions/friends';
import { USER_ADD_MOVIE } from 'actions/user';

const initialState = {
    userId: null,
    loadingProfile: false,
    profile: null,
    loadingMovies: false,
    movies: null,
    loadingLists: false,
    lists: null,
    loadingReviews: false,
    reviews: null,
    loadingFriends: false,
    friends: null,
    loadingTags: false,
    tags: null,
    view: { 
        grouping: { groupBy: null, collapsed: {} }, 
        showFilter: false, 
        showAddMovie: false, 
        filter: [], 
        showAllValues: [] }
}

function reduceFriendActions(state, action) {
    switch (action.type) {
        case ACCEPT_FRIEND_REQUEST: {
            if (action.from.id === state.userId) {
                let friends = state.friends.slice();
                friends.push({
                    id: action.user.id,
                    name: action.user.name,
                    image: action.user.image,
                    confirmed: true
                });

                state = { ...state, friends };
            }
            break;
        }
        case REMOVE_FRIEND: {
            if (action.friend.id === state.userId) {
                let friendIndex = state.friends.findIndex(x => x.id === action.user.id);

                if (friendIndex >= 0) {
                    let friends = state.friends.slice();
                    friends.splice(friendIndex, 1);
    
                    state = { ...state, friends };
                }
            }
            break;
        }
        default:
    }

    return state;
}


export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOAD_OTHER_USER: {
            state = { ...state, userId: action.userId, loadingProfile: true, profile: null, loadingMovies: true, movies: null,
                loadingLists: true, lists: null, loadingReviews: true, reviews: null, loadingFriends: true, friends: null,
                loadingTags: true, tags: null };
            break;
        }
        case LOAD_OTHER_PROFILE_SUCCESS: {
            if (action.profile.id === state.userId) {
                state = { ...state, loadingProfile: false, profile: action.profile };
            }
            break;
        }

        case LOAD_OTHER_MOVIES_SUCCESS: {
            if (action.userId === state.userId) {
                state = { ...state, loadingMovies: false, movies: action.movies };
            }
            break;
        }
        case LOAD_OTHER_MOVIES_FAILURE: {
            if (action.userId === state.userId) {
                state = { ...state, loadingMovies: false, movies: [] };
            }
            break;
        }

        case LOAD_OTHER_REVIEWS_SUCCESS: {
            if (action.userId === state.userId) {
                state = { ...state, loadingReviews: false, reviews: action.reviews };
            }
            break;
        }
        case LOAD_OTHER_REVIEWS_FAILURE: {
            if (action.userId === state.userId) {
                state = { ...state, loadingReviews: false, reviews: [] };
            }
            break;
        }

        case LOAD_OTHER_LISTS_SUCCESS: {
            if (action.userId === state.userId) {
                state = { ...state, loadingLists: false, lists: action.lists };
            }
            break;
        }
        case LOAD_OTHER_LISTS_FAILURE: {
            if (action.userId === state.userId) {
                state = { ...state, loadingLists: false, lists: [] };
            }
            break;
        }

        case LOAD_OTHER_FRIENDS_SUCCESS: {
            if (action.userId === state.userId) {
                state = { ...state, loadingFriends: false, friends: action.friends };
            }
            break;
        }
        case LOAD_OTHER_FRIENDS_FAILURE: {
            if (action.userId === state.userId) {
                state = { ...state, loadingFriends: false, friends: [] };
            }
            break;
        }

        case LOAD_OTHER_TAGS_SUCCESS: {
            if (action.userId === state.userId) {
                state = { ...state, loadingTags: false, tags: action.tags };
            }
            break;
        }
        case LOAD_OTHER_TAGS_FAILURE: {
            if (action.userId === state.userId) {
                state = { ...state, loadingTags: false, tags: [] };
            }
            break;
        }
        case USER_ADD_MOVIE: {
            // { type: UPDATE_MOVIE_INFO, movieId, update }
            if (action.userId === state.userId) {
                state = { ...state };
            }
            break;
        }
      
        default:
    }

    if (state.userId) {
        state = reduceReviewActions(state, action, review => review.user_id === state.userId);
    }

    state = reduceFriendActions(state, action);

    return state;
}