import React from 'react';

class Content extends React.Component {

    constructor(props) {
        super(props);        
        this._expand = this._expand.bind(this);
        this._collapse = this._collapse.bind(this);
        this.state=({expand:false});
    }

    _expand() {
        this.setState({expand: true});
    }

    _collapse() {
        this.setState({expand: false});
    }

    render() {
        if (this.props.movie.description)
        {
            var maxWords = this.props.maxWords ? this.props.maxWords : 50;
            var words = this.props.movie.description.split(" ");
            var collapsedString = words.slice(0, maxWords);
            var showMore = words.length > maxWords;
            if (this.state.expand == false) {
                return (<h5>
                    Inhalt <small>{collapsedString.join(" ")}
                    {showMore && <a href="##" onClick={this._expand}> mehr...</a>}
                    </small></h5>)

            }
            else
            {
                return (
                    <h5>Inhalt <small>{this.props.movie.description}</small></h5>
                )
            };
        }
        else{
            return <div></div>
        }
    }
}

export default Content;
