import React, { Component } from 'react';
import Helmet from 'react-helmet';

import { Route, Switch } from 'react-router-dom';

import routes from 'shared/Routes';

import Lists from 'pages/Lists';
import Search from 'pages/Search';
import Settings from 'pages/Settings';
import Login from 'pages/Login';
import Register from 'pages/Register';
import NewPassword from 'pages/NewPassword';
import NotFound from 'pages/NotFound';
import CompleteRegistration from 'pages/CompleteRegistration';
import CompleteNewPassword from 'pages/CompleteNewPassword';
import AGB from 'components/Home/AGB';
import Datenschutzbestimmungen from 'components/Home/Datenschutzbestimmungen';
import Impressum from 'components/Home/Impressum';
import FriendRequests from 'pages/FriendRequests';

import { withTracker } from 'GoogleAnalytics';

class Body extends Component {
    render() {
      return (
        <React.Fragment>
          <Helmet>
            <title>kinokicks | Alle Deine Filme</title>
            <meta name="description" content="Alle deine Filme im Überblick. Markiere die Filme die du kennst, sortiere deine Sammlung nach eigenen Kategorien und entdecke neue Filme beim Stöbern durch die News und Listen. Jetzt kostenlos ausprobieren!"/>
            <meta property="fb:app_id" content="224166494375570"/>
          </Helmet>
          <Switch>
            <Route exact path="/search/:query?" component={withTracker(Search)}/>
            <Route path="/listen" component={withTracker(Lists)} exact={true}/>
            <Route path="/Settings" component={withTracker(Settings)}/>
            <Route path="/login" component={withTracker(Login)}/>
            <Route path="/register" component={withTracker(Register)}/>
            <Route path="/completeregistration/:params" component={withTracker(CompleteRegistration)}/>
            <Route path="/newpassword" component={withTracker(NewPassword)}/>
            <Route path="/completenewpassword/:params" component={withTracker(CompleteNewPassword)}/>
            <Route path="/agb" component={withTracker(AGB)}/>
            <Route path="/datenschutzbestimmungen" component={withTracker(Datenschutzbestimmungen)}/>
            <Route path="/impressum" component={withTracker(Impressum)}/>
            <Route path="/friendrequests" component={withTracker(FriendRequests)}/>
            {
              // Add routes with SSR support here
              routes.map(({ path, exact, component: C, ...rest }) => (
                <Route key={path} path={path} exact={exact} render={(props) => <C {...props} {...rest} />}/>
              ))}
            <Route component={withTracker(NotFound)} />
          </Switch>
        </React.Fragment>
      );
    }
}

export default Body;