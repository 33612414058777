// EndlessScroller
//
// Handles scroll and resize events and informs its child component about changes of the visible range to allow rendering only
// visible parts.
// Only supports vertical scrolling.
//
// Properties:
//
//    content          Required. A react component that will be rendered as the only child. This component will receive
//                     additional properties that describe the currently visible range. By default two properties are
//                     sent down. viewOffset and viewHeight describe the position and height of the visible area in pixels.
//                     You can change this behavior with the property viewPortToState.
//
//    contentProps     Optional. An object with properties for the content component.
//
//    viewPortToState  Optional. A function that takes the offset and height of the visible area (viewOffset and viewHeight) as arguments
//                     and should return an object with the properties to pass to the content component.
//    loadMoreItems    Optional. A function that loads more items. It is called when the scroll window reaches the end of the panel. Use this
//                     function to suppert endless scrolling.

import React from 'react';

class EndlessScroller extends React.PureComponent {

    constructor(props) {
        super(props);
        this._handleScroll = this._handleScroll.bind(this);

        this.state = { calculatedProps: this._calculateProps(null, null) };
    }

    componentDidMount() {
        window.addEventListener("scroll", this._handleScroll, false);
        window.addEventListener("resize", this._handleScroll, true);

        this._handleScroll();
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this._handleScroll);
        window.removeEventListener("resize", this._handleScroll);
    }

    _handleScroll() {

        if (!this._containerElem) {
            return;
        }

        var viewTop = window.scrollY;
        var viewBot = viewTop + window.innerHeight;

        var top = this._containerElem.offsetTop;
        var parent = this._containerElem.offsetParent;
        while (parent) {
            top += parent.offsetTop;
            parent = parent.offsetParent;
        }

        var height = this._containerElem.clientHeight;
        var bot = top + height;

        if (bot - viewBot < window.innerHeight / 2 && !this.props.loading && this.props.loadMoreItems) {
            this.props.loadMoreItems();
        }

        var viewOffset = Math.max(0, viewTop - top);
        var viewHeight = Math.min(height, viewBot - viewTop);

        this.setState({ calculatedProps: this._calculateProps(viewOffset, viewHeight) });
    }

    _calculateProps(viewOffset, viewHeight)
    {
        var calculatedProps;
        if (this.props.viewPortToState) {
            calculatedProps = this.props.viewPortToState(viewOffset, viewHeight);
        }
        else
        {
            calculatedProps = { viewOffset, viewHeight };
        }

        return calculatedProps;
    }

    render() {

        var Component = this.props.content;
        var contentProps = Object.assign({}, this.props.contentProps, this.state.calculatedProps);

        return (
            <div ref={(elem) => this._containerElem = elem}>
                <Component {...contentProps}/>
            </div>
        );
    }
}

export default EndlessScroller;
