import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { connect } from 'components/StoresContext';
import { signIn, signInWithGoogle, signInWithFacebook } from 'actions/user';

import signInFacebook from 'images/signin_facebook.png';
import signInGoogle from 'images/signin_google.png';
import { loginInput } from 'actions/login';

class LoginForm extends React.PureComponent {

    constructor(props) {
        super(props);

        this._signIn = this._signIn.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);
        this._signInWithGoogle = this._signInWithGoogle.bind(this);
        this._signInWithFacebook = this._signInWithFacebook.bind(this);
    }

    _handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        this._signIn();
    }

    _signIn() { 
        var username = this.props.username;
        var password = this.props.password;

        // Try to sign in the user. 
        this.props.signIn(username, password, () => this.context.router.history.goBack());
    }

    _signInWithGoogle() {
        this.props.signInWithGoogle(() => this.context.router.history.goBack());
    }

    _signInWithFacebook() {
        this.props.signInWithFacebook(() => this.context.router.history.goBack());
    }

    render() {
        return (
            <React.Fragment>
                        <p>Melde dich an um deine Sammlung zu bearbeiten, Reviews zu schreiben oder für Listen abzustimmen. </p>
                        <form onSubmit={this._handleSubmit}>
                            <div className="form-group">
                                <label>Benutzername oder E-Mail Adresse</label>
                                <input className="form-control" value={this.props.username} onChange={e => this.props.update("username", e.target.value)} type="text"/>
                            </div>
                            <div className="form-group">
                                <label>Passwort</label>
                                <input className="form-control" value={this.props.password} onChange={e => this.props.update("password", e.target.value)} type="password"/>
                            </div>
                         </form>
                        {this.props.errors && <div className="alert alert-danger">
                            {this.props.errors}
                        </div>}
            
          </React.Fragment>
      );
    }
}

export default connect(LoginForm, store => ({
    username: store.loginForm.username,
    password: store.loginForm.password,
    errors: store.loginForm.errors
}), dispatch => ({
    update: (field, value) => dispatch(loginInput(field, value)),
    signIn: (username, password, callback) => dispatch(signIn(username, password, callback)),
    signInWithGoogle: callback => dispatch(signInWithGoogle(callback)),
    signInWithFacebook: callback => dispatch(signInWithFacebook(callback))
}));
