// This component is the common part for RequireLogin and the Register page

import React from 'react';

import { registerInput, register } from 'actions/register';

import { Link } from 'react-router-dom';
import { connect } from 'components/StoresContext';


class RegistrationForm extends React.PureComponent {

    constructor(props) {
        super(props);

        this._handleSubmit = this._handleSubmit.bind(this);
    }

    _handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        this.props.register();
    }

    render() {
        return (
            <React.Fragment>
                <p>Registriere dich kostenlos und schnell um deine Sammlung zu bearbeiten, Reviews zu schreiben oder für Listen abzustimmen.
                {this.props.showLoginLink && <span>Wenn du schon einen Account hast, klicke <Link to="/Login">hier</Link> um dich anzumelden.</span>}</p>
                <form onSubmit={this._handleSubmit}>
                    <div className="form-group">
                        <label>E-Mail Adresse</label>
                        <input id="mail" className="form-control" value={this.props.emailaddr} onChange={e => this.props.update("emailaddr", e.target.value)} type="text"/>
                    </div>
                    <div className="form-group">
                        <label>Benutzername</label>
                        <input id="name" className="form-control" value={this.props.username} onChange={e => this.props.update("username", e.target.value)} type="text"/>
                    </div>
                    <div className="form-group">
                        <label>Passwort</label>
                        <input id="password" className="form-control" type="password" value={this.props.password} onChange={e => this.props.update("password", e.target.value)}/>
                    </div>
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" checked={this.props.acceptAgb} onChange={e => this.props.update("acceptAgb", e.target.checked)}/> Ich akzeptiere die <Link to="/Datenschutzbestimmungen">Datenschutzbedingungen</Link> und die <Link to="/AGB">AGB</Link>
                        </label>
                    </div>
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" checked={this.props.allowEmails} onChange={e => this.props.update("allowEmails", e.target.checked)}/> kinokicks darf mir E-Mails schicken. Welche E-Mails ich bekommen möchte, kann ich in den Einstellungen festlegen. 
                        </label>
                    </div>
                    {this.props.showButton && <button type="submit" className="btn btn-primary">Registrieren</button>}
                </form>

                {this.props.errors && <div className="alert alert-danger" style={{marginTop:"10px"}}>
                    {this.props.errors.map(e => <p>{e}</p>)}
                </div>}
            </React.Fragment>
        );
    }
}

export default connect(RegistrationForm, state => ({
    emailaddr: state.registerForm.emailaddr,
    username: state.registerForm.username,
    password: state.registerForm.password,
    acceptAgb: state.registerForm.acceptAgb,
    allowEmails: state.registerForm.allowEmails,
    errors: state.registerForm.errors
}), dispatch => ({
    update: (field, value) => dispatch(registerInput(field, value)),
    register: () => dispatch(register())
}));
