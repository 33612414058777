import React from 'react';

import Api from 'components/Api';
import MovieList from 'components/MovieList';

import { connect } from 'components/StoresContext';
import { loadList } from 'actions/movieList';

class Liste extends React.Component {

    componentDidMount() {
        var listId = Api.getNumericId(this.props.match.params.id);
        this.props.loadList(listId);
    }

    componentDidUpdate() {
        var listId = Api.getNumericId(this.props.match.params.id);
        this.props.loadList(listId);
    }

    render() {
        return (
            <MovieList/>
        );
    }
}

export default connect(Liste, _ => ({ }), dispatch => ({
    loadList: id => dispatch(loadList(id))
}));