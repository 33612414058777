import Api from 'components/Api';

export const LISTS_LOAD_FEATURED = "LISTS_LOAD_FEATURED";
export const LISTS_LOAD_FEATURED_SUCCESS = "LISTS_LOAD_FEATURED_SUCCESS";

export const LISTS_LOAD_NEWEST = "LISTS_LOAD_NEWEST";
export const LISTS_LOAD_NEWEST_SUCCESS = "LISTS_LOAD_NEWEST_SUCCESS";

export const LISTS_SET_QUERY = "LISTS_SET_QUERY";
export const LISTS_CLEAR_QUERY = "LISTS_CLEAR_QUERY";
export const LISTS_SEARCH = "LISTS_SEARCH";
export const LISTS_SEARCH_SUCCESS = "LISTS_SEARCH_SUCCESS";

export function load() {
    return function (dispatch) {

        dispatch({ type: LISTS_LOAD_FEATURED });
        Api.get(`Lists/featured`).then(data => {
            dispatch({ type: LISTS_LOAD_FEATURED_SUCCESS, data });
        }).catch(_ => {});

        dispatch({ type: LISTS_LOAD_NEWEST });
        Api.get(`Lists/new`).then(data => {
            dispatch({ type: LISTS_LOAD_NEWEST_SUCCESS, data });
        }).catch(_ => {});
    }
}

export function setQuery(query) {
    return { type: LISTS_SET_QUERY, query };
}

export function clearQuery() {
    return { type: LISTS_CLEAR_QUERY };
}

export function search() {
    return function (dispatch, getState) {
        dispatch({ type: LISTS_SEARCH });
        Api.get(`Lists/Search`, { query: getState().lists.query }).then(data => {
            dispatch({ type: LISTS_SEARCH_SUCCESS, data });
        }).catch(_ => {});
    }
}
