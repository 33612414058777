import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import windowSize from 'reducers/windowSize';
import movieStore from 'reducers/movie';
import listStore  from 'reducers/movieList';
import newsStore from 'reducers/news';
import homeStore from 'reducers/home';
import search from 'reducers/search';
import lists from 'reducers/lists';
import user from 'reducers/user';
import loginForm from 'reducers/loginForm';
import registerForm from 'reducers/registerForm';
import otherUser from 'reducers/otherUser';
import genres from 'reducers/genres';
import view from 'reducers/view';

import newPasswordForm from 'reducers/newPasswordForm';
import confirmPasswordForm from 'reducers/confirmPasswordForm';

// Enable the redux devtools when not in production
let devtools;
if (process.env.NODE_ENV !== "production") {
    devtools = typeof(window) !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
}

let reducer = combineReducers({ windowSize, movieStore, listStore, newsStore, homeStore, search, lists, user, loginForm, registerForm, otherUser, genres,
    newPasswordForm, confirmPasswordForm, view });

let middleware = applyMiddleware(thunk);
if (devtools) {
    middleware = compose(middleware, devtools);
}

export function createTheStore(initialState) {

    if (initialState) {
        return createStore(reducer, initialState, middleware);
    }

    return createStore(reducer, middleware);
}
