import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { connect } from 'components/StoresContext';
import { signIn, signInWithGoogle, signInWithFacebook } from 'actions/user';

import signInFacebook from 'images/signin_facebook.png';
import signInGoogle from 'images/signin_google.png';
import { loginInput } from 'actions/login';

class Login extends React.Component {

    constructor(props) {
        super(props);

        this._signIn = this._signIn.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);
        this._signInWithGoogle = this._signInWithGoogle.bind(this);
        this._signInWithFacebook = this._signInWithFacebook.bind(this);
    }

    _handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        this._signIn();
    }

    _signIn() { 
        var username = this.props.username;
        var password = this.props.password;

        // Try to sign in the user. 
        this.props.signIn(username, password, () => this.context.router.history.goBack());
    }

    _signInWithGoogle() {
        this.props.signInWithGoogle(() => this.context.router.history.goBack());
    }

    _signInWithFacebook() {
        this.props.signInWithFacebook(() => this.context.router.history.goBack());
    }

    render() {
        return (
            <div className="container">
                <div className="row" style={{display:"flex", alignItems:"center"}}>
                    <div className="col-md-6">
                        <h3>Anmelden</h3>
                        <p>Melde dich an um deine Sammlung zu bearbeiten, Reviews zu schreiben oder für Listen abzustimmen. Wenn du noch keinen Account hast, dann leg dir <Link to="/Register">hier</Link> einen an. Es ist kostenlos und dauert nur 30 Sekunden.</p>
                        <form onSubmit={this._handleSubmit}>
                            <div className="form-group">
                                <label>Benutzername oder E-Mail Adresse</label>
                                <input className="form-control" value={this.props.username} onChange={e => this.props.update("username", e.target.value)} type="text"/>
                            </div>
                            <div className="form-group">
                                <label>Passwort</label>
                                <input className="form-control" value={this.props.password} onChange={e => this.props.update("password", e.target.value)} type="password"/>
                            </div>
                            <button type="submit" className="btn btn-primary">Anmelden</button>
                        </form>
                        <p style={{marginTop:"10px"}}>Passwort <Link to="NewPassword">vergessen?</Link></p>
                        {this.props.errors && <div className="alert alert-danger">
                            {this.props.errors}
                        </div>}
                
                    </div>
                    <div className="col-md-6 hidden-xs text-center" >
                        <img src="/Images/logo/Logo-128x128.png" alt=""/>
                    </div>
                </div>
                <div className="row" style={{paddingBottom:"20px"}}>
                    <div className="col-md-6">
                        <h3>Oder anmelden mit</h3>
                        <img src={signInFacebook} alt="" onClick={this._signInWithFacebook} style={{ cursor: "pointer" }}/>
                        <img src={signInGoogle} alt="" onClick={this._signInWithGoogle} style={{ cursor: "pointer", marginLeft: 15 }}/>
                    </div>
                </div>
          </div>
      );
    }
}

// Needed for the login button to work.
Login.contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.object.isRequired,
    }),
  };

export default connect(Login, store => ({
    username: store.loginForm.username,
    password: store.loginForm.password,
    errors: store.loginForm.errors
}), dispatch => ({
    update: (field, value) => dispatch(loginInput(field, value)),
    signIn: (username, password, callback) => dispatch(signIn(username, password, callback)),
    signInWithGoogle: callback => dispatch(signInWithGoogle(callback)),
    signInWithFacebook: callback => dispatch(signInWithFacebook(callback))
}));
