import React from 'react';
import { BarLoader } from 'react-spinners';

import { loadMoreItems } from 'actions/news';

import { connect } from 'components/StoresContext';

import EndlessScroller from './EndlessScroller';
import VirtualPanel from './VirtualPanel';
import UserItem from './UserItem';
import MovieItem from './MovieItem';
import ReviewItem from './ReviewItem';
import ListItem from './ListItem';
import GhostItem from './GhostItem';

class Items extends React.PureComponent {

    constructor(props) {
        super(props);

        this._loadMore = this._loadMore.bind(this);
        this._renderItem = this._renderItem.bind(this);

        this._overlayShown = this._overlayShown.bind(this);
        this._convertViewport = this._convertViewport.bind(this);
    }

    _loadMore() {
        this.props.loadMore(30);
    }

    render() {

        var contentProps = { items: this.props.items, count: this.props.count, shuffle: this.props.shuffle,
            x0: this.props.x0, itemsPerRow: this.props.itemsPerRow,
            itemWidth: this.props.itemWidth, itemHeight: this.props.itemHeight, spacing: this.props.spacing, renderItem: this._renderItem };

        return (
            <React.Fragment>
                <EndlessScroller loadMoreItems={this._loadMore}
                    content={VirtualPanel} contentProps={contentProps}
                    viewPortToState={this._convertViewport}>
                </EndlessScroller>
                <BarLoader widthUnit={"%"} width={100} color={'#e81123'} loading={this.props.loading}/>
            </React.Fragment>
        );
    }

    // Used by the scroller to update the contents of the virtual panel. The panel is only rendered
    // if the visible row range changes, not on every small scroll position change.
    _convertViewport(offset, height) {

        if (!offset || !height) {
            return { firstVisibleRow: 0, lastVisibleRow: 20 };
        }

        var firstVisibleRow = Math.floor(offset / (this.props.itemHeight + this.props.spacing));
        var lastVisibleRow  = Math.floor((offset + height) / (this.props.itemHeight + this.props.spacing));

        return { firstVisibleRow, lastVisibleRow };
    }

    _renderItem(item) {

        var elem = null;
        if (!item) {
            elem = <GhostItem/>;
        }
        else if (item.type === "movie") {
            elem = <MovieItem item={item} onOverlayShown={this._overlayShown}/>;
        }
        else if (item.type === "comment") {
            elem = <ReviewItem item={item} onOverlayShown={this._overlayShown}/>;
        }
        else if (item.type === "user") {
            elem = <UserItem item={item} onOverlayShown={this._overlayShown}/>;
        }
        else if (item.type === "list") {
            elem = <ListItem item={item} onOverlayShown={this._overlayShown}/>;
        }

        return elem;
    }

    _overlayShown(hideFunc) {
        if (this._hideOverlayFunc) {
            this._hideOverlayFunc();
            this._hideOverlayFunc = null;
        }

        this._hideOverlayFunc = hideFunc;
    }
}

export default connect(Items, store => ({
    items: store.newsStore.items,
    shuffle: store.newsStore.lastUpdateMode === "replace",
    count: store.newsStore.itemCount,
    loading: store.newsStore.loading
}), dispatch => ({
    loadMore: n => dispatch(loadMoreItems(n))
}));
