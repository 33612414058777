import React, { Component } from 'react';

import ListOverview from 'components/Lists/ListsOverview';

class Lists extends Component {
    render() {
      return (
          <ListOverview/>
      );
    }
}

export default Lists;