import React, { Component } from 'react';
import UserSettings from 'components/UserSettings'

class Settings extends Component {
    render() {
      return (
            <UserSettings/>
          
      );
    }
}

export default Settings;