// Must import bootstrap.css first. Some local css files override definitions of this file.
import 'bootstrap/dist/css/bootstrap.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import { createTheStore } from 'store';

import App from 'App';

import 'index.css';
import { autoSignIn } from 'actions/user';
import { loadGenres } from 'actions/genres';

// If the server has already rendered the page, use ReactDOM.hydrate() instead of render() and take 
// over the server provided state.

let initialState = undefined;
let renderFunc = ReactDOM.render;
if (window.__DATA__)
{
    initialState = window.__DATA__;
    renderFunc = ReactDOM.hydrate;

    delete window.__DATA__;
}

let store = createTheStore(initialState);

store.dispatch(loadGenres());

// Automatically sign in the user if cookies are present
autoSignIn(store.dispatch);

renderFunc(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
    , document.getElementById('root'));
