import React from 'react';

import Raty from 'components/Raty';
import RequireLogin from 'components/RequireLogin';
import { connect } from 'components/StoresContext';

import { updateMovieInfo, getMovieInfo } from 'actions/user';

class Ratee extends React.Component {

    constructor(props) {
        super(props);

        this._rateMovie = this._rateMovie.bind(this);
    }

    _rateMovie(score, event) {
        event.preventDefault();
        event.stopPropagation();

        RequireLogin.do(() => {
            this.props.updateMovieInfo(this.props.item.movie.id, this.props.item.movie.title, this.props.item.movie.poster, { rating: score });
        });

        return false;
    }

    render() {
        return <Raty className="kk-nf-ratee" score={this.props.rating} setScore={this._rateMovie}/>;
    }
}

function getRating(store, movieId) {
    var userInfo = getMovieInfo(store, movieId);

    return userInfo ? userInfo.rating : 0;
}

export default connect(Ratee, (store, props) => ({
    rating: getRating(store, props.item.movie.id)
}), dispatch => ({
    updateMovieInfo: (movieId, title, poster, update) => dispatch(updateMovieInfo(movieId, title, poster, update))
}));
