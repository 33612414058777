import React from 'react';
import { Link } from 'react-router-dom';

import Movie from 'components/Movie';

class ReviewOverlay extends React.Component {

    constructor(props) {
        super(props);

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick(e) {
        e.stopPropagation();
        e.preventDefault();

        this.props.onClick();
    }

    render() {

        var item = this.props.item;

        return (
            <Link to={Movie.url(item.movie)}>
                <div className="kk-nf-overlay" onClick={this._handleClick}>
                    <div className="kk-nf-comment">{item.review}</div>
                </div>
            </Link>
        );
    }
}

export default ReviewOverlay;
