// <Raty>
//
// A very simple rating component that allows to select a rating between 0 and 5 in 0.5 steps. Inspired by jquery-raty but reduced
// functionality.
//
// Requires: font-awesome
//
// Properties:
//   className  string  Optional class name to assign to the generated div.
//   readOnly   bool    Optional. If true, the score cannot be changed by the user.
//   narrow     bool    Optional. If true, the stars are rendered without space between them.

import React from 'react';
import ReactDOM from 'react-dom';

class Star extends React.Component {

    constructor(props)
    {
        super(props);

        this._handleMouseMove = this._handleMouseMove.bind(this);
    }

    _handleMouseMove(e) {

        var dom = ReactDOM.findDOMNode(this);

        var rect = dom.getBoundingClientRect();
        var x = (e.pageX - rect.left) / rect.width;

        var newScore = this.props.score - (x < 0.5 ? 0.5 : 0);

        if (this.props.updateScore) {
            this.props.updateScore(newScore);
        }
    }

    render() {

        var cls = "fa " + this.props.icon;

        return (<i className={cls} onMouseMove={this._handleMouseMove }/>);
    }
}

class Symbols extends React.Component {

    constructor(props) {
        super(props);

        this.state = { score: this.props.score };

        this._handleClick = this._handleClick.bind(this);
        this._updateScore = this._updateScore.bind(this);
        this._handleMouseLeave = this._handleMouseLeave.bind(this);
    }

    // getDefaultProps: function () {
    //     return { readOnly: false }
    // },

    componentWillReceiveProps(nextProps) {
        this.setState({ score: nextProps.score });
    }

    _handleClick(event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.props.setScore) {
            this.props.setScore(this.state.score, event, this);
        }
    }

    _handleMouseLeave() {
        this.setState({ score: this.props.score });
    }

    _updateScore(newScore) {
        this.setState({ score: newScore });
    }

    render() {

        var readOnly = this.props.readOnly;
        var narrow = this.props.narrow;

        var updateScoreFunc = null;
        var style = null;
        if (!readOnly) {
            updateScoreFunc = this._updateScore;
            style = { cursor: "pointer" };
        }

        var score = this.state.score;
        if (!score) {
            score = 0;
        }
        
        var score2 = Math.round(score * 2);
        var symbols = [];
        for (var i = 0; i < 5; i++) {
            if (i > 0 && !narrow) {
                symbols.push(<span key={i + 10}>&nbsp;</span>);
            }
            if (score2 <= 0) {
                symbols.push(<Star key={i} score={i + 1} icon="fa-star-o" updateScore={updateScoreFunc}/>);
            }
            else if (score2 === 1) {
                symbols.push(<Star key={i} score={i + 1} icon="fa-star-half-o" updateScore={updateScoreFunc}/>);
            }
            else
            {
                symbols.push(<Star key={i} score={i + 1} icon="fa-star" updateScore={updateScoreFunc}/>);
            }
            score2 -= 2;
        }

        return <span style={style} onClick={this._handleClick} onMouseLeave={this._handleMouseLeave}>{symbols}</span>
    }
}

class Raty extends React.Component {

    render() {
        var className = this.props.className;
        var style = this.props.style;
        if (!style) {
            style = {}
        }
        style.display = "inline-block";

        return (<div className={className} style={style}><Symbols {...this.props}/></div>);
    }
}

export default Raty;
