import React from 'react';

import LazyImg from './Image';

// Generate a list of unique stable keys that is independent from the action list of each item
function _generateKeyMap(items) {
    var usedKeys = {};
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        var key = "";
        if (item.movie) {
            key = `M${item.movie.id}`;
        }
        if (item.actor) {
            key = `${key}U${item.actor.id}`;
        }

        if (usedKeys[key]) {
            var n = usedKeys[key];
            usedKeys[key] = n + 1;
            key = `${key}.${n}`;
        } else {
            usedKeys[key] = 1;
        }

        item.key2 = key;
    }
}

class VirtualPanel extends React.PureComponent {

    constructor(props) {
        super(props);

        _generateKeyMap(props.items);
        this.state = { lastStage: "idle", stage: "idle" };

        this._scale = true;
        this._renderedItems = [];
        this._oldPositions = {};
        this._activeAnimations = {};

        this._play = this._play.bind(this);
    }

    static getDerivedStateFromProps(props, state) {

        // var p2 = Object.assign({}, props);
        // p2.items = p2.items ? p2.items.length : 0;
        // console.log(` props=${JSON.stringify(p2)}`);

        // var s2 = Object.assign({}, state);
        // s2.items = s2.items ? s2.items.length : 0;
        // console.log(` state=${JSON.stringify(s2)}`);

        var stage = state.stage;
        var lastStage = stage;
        if (props.items !== state.items) {
            _generateKeyMap(props.items);

            if (props.shuffle && stage === "idle") {
                stage = "invert";
            }
        }

        return { items: props.items, stage: stage, lastStage: lastStage };
    }

    // Called after render
    componentDidUpdate() {

        if (this.state.stage === "idle") {
            var indexMap = {};
            this.props.items.forEach((item, index) => {
                indexMap[item.key2] = index;
            });
            this._oldPositions = indexMap;
        } else if (this.state.stage === "invert") {

            // Everything is set up for the transition. Start it by entering the play stage.
            // requestAnimationFrame makes sure that the call to _play happens after the
            // content is rendered. Without it, the animation does not work.
            window.requestAnimationFrame(() => window.requestAnimationFrame(this._play));
        } else if (this.state.stage === "play") {
            if (Object.keys(this._activeAnimations).length === 0) {
                // For some reason, we have no pending animations. Enter the idle stage immediately.
                this.setState({stage: "idle"});
            }
        }
    }

    _play() {
        this.setState({ stage: "play" });
    }

    render() {

       
        var itemCount = this.props.items.length;
        if (this.props.count && this.props.count > itemCount) {
            itemCount = this.props.count;
        }

        var firstRow = Math.max(0, this.props.firstVisibleRow);
        var lastRow  = Math.min(this.props.lastVisibleRow, Math.floor((itemCount - 1) / this.props.itemsPerRow));

        var startIndex = firstRow * this.props.itemsPerRow;
        var endIndex   = (lastRow + 1) * this.props.itemsPerRow;

        // The actual height needed to display the whole list
        var height = (1 + Math.floor((itemCount - 1) / this.props.itemsPerRow)) * (this.props.itemHeight + this.props.spacing) - this.props.spacing;

        var items = [];
        if (this.props.itemsPerRow) {
            if (this.state.stage === "invert") {

                // if (this.state.lastStage === "play") {
                //     console.log(`interrupting play animated=${Object.keys(this._activeAnimations).length}`);
                // }

                this._activeAnimations = {};

                // Collect the renderlist for the invert and play stage
                this._renderList = this._createRenderList(startIndex, endIndex);
            } else if (this.state.stage === "idle") {
                this._activeAnimations = {};

                // The render list for the idle stage is simple
                this._renderList = [];
                this._renderedItems = {};
                for (var i = startIndex; i < Math.min(this.props.items.length, endIndex); i++) {
                    var item = this.props.items[i];
                    this._renderList.push({ item: item, from: i, to: i });
                    this._renderedItems[item.key2] = { item: item, index: i };
                }
            } else if (this.state.stage === "play") {

                // TODO: Just reusing the render list created by the invert step is problematic if rendering is caused
                //       by scrolling during the play stage.
                //
                //       1. The item list might be already expanded. This means that for the new items added in the play
                //          state, neither the actual items nor ghost items are rendered. This becomes noticable when
                //          scrolling quickly down.
                //
                //       2. When scrolling up during an active play stage, the items becoming visible by scrolling up are
                //          also not rendered becaus the were not visible when the invert stage was originally rendered.
            }

            this._renderList.forEach((entry) => {
                if (entry.item) {
                    items.push(this._renderItem(entry.item, entry.to, entry.from));
                }
                else {
                    items.push(this._renderGhost(entry.to));
                }
            });

            var ghosts = undefined;
            if (itemCount > this.props.items.length) {

                ghosts = [];

                var j0 = Math.max(startIndex, this.props.items.length);
                var j1 = Math.min(endIndex, itemCount);
                for (var j = j0; j < j1; j++) {
                    ghosts.push(this._renderGhost(j));
                }
            }
        }

        return (
            <div style={{ height: height, position: "relative" }}>
                {ghosts}
                {items}
                {/* <div style={{ position: "fixed", x: 4, y: 4, background: "#FFFFFF", padding: "4px", opacity: 0.75 }}>
                    <p>x0: {Math.round(this.props.x0 * 10) / 10}px</p>
                    <p>itemsPerRow: {this.props.itemsPerRow}</p>
                    <p>visibleRows = [{this.props.firstVisibleRow} - {this.props.lastVisibleRow}]</p>
                    <p>startIndex: {startIndex}</p>
                    <p>endIndex: {endIndex}</p>
                    <p>itemCount: {this.props.items.length} {this.props.count}</p>
                    <p>stage: {this.state.stage}</p>
                    <p>animated: {Object.keys(this._activeAnimations).length}</p>
                </div> */}
            </div>
        );
    }

    _renderItem(item, index, oldIndex) {

        //console.log(`rendering ${item.key2} at ${index} (from ${oldIndex})`);

        var { x, y } = this._calcPosition(index);

        var style = {
            position: "absolute",
            left: x,
            top: y,
            width: this.props.itemWidth,
            height: this.props.itemHeight
        };

        var onTransitionEnd = undefined;

        if (this.state.stage === "invert") {
            // In the invert stage, the items are rendered at their old positions by translating them back
            if (oldIndex !== index) {
                var { x: oldx, y: oldy } = this._calcPosition(oldIndex);

                var dx = oldx - x;
                var dy = oldy - y;

                this._activeAnimations[item.key] = true;
                style.transform = `translate(${dx}px, ${dy}px)`;
            }
        } else if (this.state.stage === "play") {
            if (oldIndex !== index) {
                style.transform = "";
                style.transition = "transform 0.5s";
                onTransitionEnd = this._handleTransitionEnd.bind(this, item.key);
            }
        }

        var elem = this.props.renderItem(item);

        // Render a container div at the specified location
        return (
            <div key={item.key2} style={style} onTransitionEnd={onTransitionEnd}>
                {elem}
                {/* <div style={{ position: "absolute", left: 4, top: 4, background: "#FFFFFF", padding: "4px", opacity: 0.75 }}>
                    <p>{index}</p>
                </div> */}
            </div>);
    }

    _renderGhost(index) {
        var { x, y } = this._calcPosition(index);

        var style = {
            position: "absolute",
            left: x,
            top: y,
            width: this.props.itemWidth,
            height: this.props.itemHeight
        };

        var elem = this.props.renderItem(null);

        return <div key={`ghost_${index}`} style={style}>{elem}</div>;
    }

    _calcPosition(index) {
        var x = this.props.x0 + (index % this.props.itemsPerRow) * (this.props.itemWidth + this.props.spacing);
        var y = Math.floor(index / this.props.itemsPerRow) * (this.props.itemHeight + this.props.spacing);

        return { x: x, y: y };
    }

    _randomIndex(startIndex, endIndex) {
        var index;
        if (Math.random() >= 0.5) {
            index = startIndex - Math.floor((2 + Math.random()) * this.props.itemsPerRow);
        } else {
            index = endIndex + Math.floor((2 + Math.random()) * this.props.itemsPerRow);
        }

        return index;
    }

    // Creates the render list for the "invert" stage
    _createRenderList(startIndex, endIndex) {
        var renderList = [ ];
        var ghostList = [ ];
        for (var i = startIndex; i < Math.min(this.props.items.length, endIndex); i++) {

            var item = this.props.items[i];

            var oldIndex = this._oldPositions[item.key2];

            // If no old index was found, move them in from random positions outside the viewport
            if (typeof (oldIndex) === "undefined") {
                oldIndex = this._randomIndex(startIndex, endIndex);
            }

            // Render an additional ghost while items are shuffling into their place
            if (oldIndex !== i) {
                ghostList.push({ item: null, from: i, to: i });
            }

            // Render the new item
            renderList.push({ item: item, from: oldIndex, to: i });
            delete this._renderedItems[item.key2];
        }

        // Items remaining in _renderedItems were removed, move them out of the viewport in play stage
        Object.values(this._renderedItems).forEach(entry => {
            var targetIndex = this._randomIndex(startIndex, endIndex);
            renderList.push({ item: entry.item, from: entry.index, to: targetIndex });
        });

        // Ghosts are rendered first so that they never obscure items
        return ghostList.concat(renderList);
    }

    _handleTransitionEnd(key) {

        delete this._activeAnimations[key];

        if (Object.keys(this._activeAnimations).length === 0) {
            // When the play stage ended, the component enters the idle stage again
            this.setState({ stage: "idle" }, LazyImg.updateViewport);
        }
    }
}

export default VirtualPanel;
