import { VIEW_INITIALIZE, VIEW_SHOW_FILTER, VIEW_SHOW_ADD_MOVIE, VIEW_EXPAND_GROUP, VIEW_FILTER_SHOW_ALL, VIEW_TOGGLE_FILTER, VIEW_CHANGE_GROUP, VIEW_TAG_PAGING } from 'actions/view';

const initialState = {
    users: [],
    tagPage: 0
};

const initialUserState = {
        grouping: { groupBy: null, collapsed: { Undefined: true } }, 
        showFilter: false, 
        showAddMovie: false, 
        filter: [], 
        showAllValues: [],
        };


export default function reducer(state = initialState, action) {
    switch(action.type) {
        case VIEW_INITIALIZE: {
            let userIndex = state.users.findIndex(x => x.id === action.userId);

            // User already exists dont do anything
            if (userIndex >= 0)
            {
                state = { ...state};
            }
            else 
            {
                let users = state.users.slice();
                users.push( { id: action.userId, view: initialUserState });
                state = { ...state, users };
            }
            break;
        }
        case VIEW_SHOW_FILTER: {
            let userIndex = state.users.findIndex(x => x.id === action.userId);

            // Just do anything if view has been initialized
            if (userIndex >= 0) {

                let userState = state.users[userIndex].view;
                var showAddMovie = userState.showFilter === false ? false : userState.showAddMovie;
               
                userState = { ...userState, showAddMovie: showAddMovie, showFilter: !userState.showFilter };
                             
                let users = state.users.slice();
                users.splice(userIndex, 1, {id: action.userId, view: userState});

                state = { ...state, users };
             
            } else {
                state = { ...state };
            }
              
            break;
        }
        case VIEW_SHOW_ADD_MOVIE: {
            let userIndex = state.users.findIndex(x => x.id === action.userId);

            // Just do anything if view has been initialized
            if (userIndex >= 0) {

                let userState = state.users[userIndex].view;

                // Clear filter, hide filter pane and show add movies pane
                 userState= { ...userState, 
                    showFilter:false, 
                    grouping: {groupBy: null}, 
                    filter: [], 
                    showAddMovie: !userState.showAddMovie };
               
                let users = state.users.slice();
                users.splice(userIndex, 1, {id: action.userId, view: userState});

                state = { ...state, users };
             
            } else {
                state = { ...state };
            }
              
            break;
        }

        case VIEW_FILTER_SHOW_ALL: {
            let userIndex = state.users.findIndex(x => x.id === action.userId);

            // Just do anything if view has been initialized
            if (userIndex >= 0) {

                let userState = state.users[userIndex].view;
                let dimension = action.dimension;
                let showAllValues = userState.showAllValues.slice();
                let index = showAllValues.indexOf(dimension);
                if (index >= 0) 
                {
                    showAllValues.splice(index, 1);
                } 
                else 
                {
                    showAllValues.push(dimension);
                }
               
                userState = { ...userState, showAllValues: showAllValues };

                let users = state.users.slice();
                users.splice(userIndex, 1, {id: action.userId, view: userState});

                state = { ...state, users };
            
            }
              
            break;
        }

        case VIEW_TOGGLE_FILTER: {
            let userIndex = state.users.findIndex(x => x.id === action.userId);

            // Just do anything if view has been initialized
            if (userIndex >= 0) {

                let userState = state.users[userIndex].view;
                let dimension = action.dimension;
                let value = action.value;
                let filter = userState.filter.slice();
                let index = filter.findIndex(f => f.dimension === dimension && f.value === value);
                if (index >= 0) {
                    filter.splice(index, 1);
                } 
                else 
                {
                    filter.push({ dimension, value: value });
                }
                userState = { ...userState, filter: filter };

                let users = state.users.slice();
                users.splice(userIndex, 1, {id: action.userId, view: userState});

                state = { ...state, users };
            
            }
              
            break;
        }

        case VIEW_CHANGE_GROUP: {

            let userIndex = state.users.findIndex(x => x.id === action.userId);

            // Just do anything if view has been initialized
            if (userIndex >= 0) {

                let userState = state.users[userIndex].view;
                let dimension = action.dimension;

                // Make the group a toggle, e.g. if set again switch to no grouping
                if (userState.grouping.groupBy === dimension) {
                    dimension = null;
                }
        
                userState = { ...userState, grouping: { groupBy: dimension, collapsed: { Undefined: true } } };

                let users = state.users.slice();
                users.splice(userIndex, 1, {id: action.userId, view: userState});

                state = { ...state, users };
            
            }
            break;
        }
        case VIEW_EXPAND_GROUP: {

            let userIndex = state.users.findIndex(x => x.id === action.userId);

            // Just do anything if view has been initialized
            if (userIndex >= 0) {

                let userState = state.users[userIndex].view;
                let dimension = action.dimension;

                let collapsed = { ...userState.grouping.collapsed };

                if (collapsed[dimension]) {
                    delete collapsed[dimension];
                } else {
                    collapsed[dimension] = true;
                }
        
                userState = { ...userState, grouping: { ...userState.grouping, collapsed } };

                let users = state.users.slice();
                users.splice(userIndex, 1, {id: action.userId, view: userState});

                state = { ...state, users };
            }
            
            break;
        }
        case VIEW_TAG_PAGING:
        {
            state = { ...state, tagPage: action.newPage };
            break;
        }

        default: 

     }

    return state;
}