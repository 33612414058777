import React from 'react';
import AutoSuggest from 'react-autosuggest';
import { BarLoader } from 'react-spinners';
import { withRouter } from 'react-router-dom';

import './react-autosuggest.css';

import { load as loadLists, setQuery, clearQuery, search } from 'actions/lists';

import { connect } from 'components/StoresContext';

import List from './List';
import RequireLogin from '../RequireLogin';
import { createMovieList } from 'actions/movieList';

function parseTimestamp(str) {

    if (!str) {
        return null;
    }

    // Nothing to do if we already get a Date
    if (str instanceof Date)
        return str;

    if (typeof(str) === "string" && str.indexOf("/Date(") === 0) {
        var numberStr = str.substring(6, str.length - 2);
        return parseInt(numberStr, 10);
    }

    return new Date(str).getTime();
}

class ListsOverview extends React.PureComponent {

    constructor(props) {
        super(props);

        this._handleCreateList = this._handleCreateList.bind(this);
        this._handleSearch = this._handleSearch.bind(this);
        this._handleClearQuery = this._handleClearQuery.bind(this);
        this._handleInputChange = this._handleInputChange.bind(this);
        this._renderInputComponent = this._renderInputComponent.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);

        this.state={expandNew: false, expandFeatured: false}; 
        this._expandFeatured = this._expandFeatured.bind(this);
        this._expandNew = this._expandNew.bind(this);
    }

    _expandFeatured() {
        this.setState({expandFeatured: true });
    }

    _expandNew() {
        this.setState({expandNew: true });
    }

    _handleCreateList() {

        RequireLogin.do(() => {
            this.props.createList(url => this.props.history.push(url));
        });
    }

    _handleInputChange(changeEvent, newValue) {
        if (newValue.method === "up" || newValue.method === "down") {
            return;
        }

        this.props.setQuery(newValue.newValue);
    }

    _handleKeyDown(evt) {
        if (evt.key === "Enter") {
            this.props.search();
        }
    }

    _handleClearQuery() {
        this.props.clearQuery();
    }

    _handleSearch() {
        this.props.search();
    }

    _renderInputComponent(inputProps)
    {
        return (
            <div className="input-group mb-3">
                <input {...inputProps} className="form-control" type="search" placeholder="Suche nach Name, Autor oder Film ..." autoFocus></input>
                <span className="input-group-append input-group-text"  style={{cursor:"pointer"}} onClick={this._handleSearch}><span className="fa fa-search"></span></span>
            
            </div>
           );
    }

    componentWillMount() {
        this.props.load();
    }

    render() {
        const inputProps = {
            value: this.props.query,
            onChange: this._handleInputChange,
            onKeyPress: this._handleKeyDown
        };

        var newMovies = this.props.new.sort((a,b) => parseTimestamp( b.created_at ) - parseTimestamp( a.created_at ));
        var featuredMovies = this.props.featured.sort((a,b) => b.stats.total_comments - a.stats.total_comments );

        if (this.state.expandFeatured === false)
            featuredMovies = featuredMovies.slice(0,5);

        if (this.state.expandNew === false)
            newMovies = newMovies.slice(0,5);

        return (
        <div>
            
            <div className="container-fluid" style={{backgroundColor:"rgb(25,25,25)", paddingTop:"20px", paddingBottom:"40px"}}>
                <BarLoader
                    widthUnit={"%"}
                    width={100}
                    color={'#e81123'}
                    loading={this.props.loading}
                    />

                <div className="container ab-dark">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h4>Was sind die besten Filme? Stimme ab, oder starte deine eigene Liste! </h4> 
                            <button className="btn btn-opaque" style={{marginTop:"20px"}} onClick={this._handleCreateList}>Liste anlegen</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid rainbow" style={{height:"1px"}}>
            </div>

            <div className="container-fluid" style={{backgroundColor:"rgb(25,25,25)", paddingTop:"20px", paddingBottom:"40px"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h4 style={{color:"white"}}> Suche nach bestehenden Listen</h4> 
                      
                            <AutoSuggest
                                suggestions={[]}
                                onSuggestionsFetchRequested={() => { }}
                                onSuggestionsClearRequested={() => { }}
                                getSuggestionValue={(suggestion) => suggestion.text}
                                inputProps={inputProps}
                                renderInputComponent={this._renderInputComponent}
                                renderSuggestion={_ => null}
                                />
                      </div>
                    </div>
                </div>
                {this.props.searchResult &&
                    <div className="container ab-dark">
                        <div className="row" style={{marginTop:"20px"}}>
                            <div className="col-12">
                                {this.props.searchResult.map( list => <List key={list.id} list={list} query={this.props.searchTerm}/> )}
                            </div>
                        </div>
                    </div>
                }
             </div>

            <div className="container-fluid rainbow" style={{height:"1px"}}>
            </div>


            <div className="container-fluid" style={{backgroundColor:"rgb(25,25,25)", paddingTop:"20px", paddingBottom:"30px"}}>
                <div className="container ab-dark">
                    <div className="row">
                        <div className="col-12">
                            <h3>Tipps der Redaktion</h3>
                            {featuredMovies.map( list => <List key={list.id} list={list}/> )}
                            {this.state.expandFeatured === false && 
                                <button style={{marginTop:"5px"}} className="btn btn-opaque" onClick={this._expandFeatured}>Mehr anzeigen</button>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid rainbow" style={{height:"1px"}}>
            </div>

            <div className="container-fluid" style={{backgroundColor:"rgb(25,25,25)", paddingTop:"20px", paddingBottom:"30px"}}>
                <div className="container ab-dark">
                    <div className="row">
                        <div className="col-12">
                            <h3>Neue Listen</h3>
                            {newMovies.map( list => <List key={list.id} list={list}/> )}
                            {this.state.expandNew === false && 
                                <button style={{marginTop:"5px"}} className="btn btn-opaque" onClick={this._expandNew}>Mehr anzeigen</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>                
        )

    }
}

export default withRouter(connect(ListsOverview, state => ({
    featured: state.lists.featuredLists,
    new: state.lists.newLists,
    query: state.lists.query,
    searchResult: state.lists.searchResult,
    loading: state.lists.loadingNew || state.lists.loadingFeatured || state.lists.loadingSearch,
    searchTerm: state.lists.searchTerm
}), dispatch => ({
    load: () => dispatch(loadLists()),
    setQuery: query => dispatch(setQuery(query)),
    clearQuery: () => dispatch(clearQuery()),
    search: () => dispatch(search()),
    createList: callback => dispatch(createMovieList(null, callback))
})));