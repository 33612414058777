import React from 'react';

let analytics = {
  event: function (evt) { },
  modalview: function(id) { }
};

let withTracker = (Component) => {
  return props => <Component {...props}/>;
}

export default analytics;

export { withTracker };
