import React from 'react';
//import { Provider } from "react-redux";
//import * as URI from "urijs";

import { activateNewsUpdate, deactivateNewsUpdate } from 'actions/news';

import { connect } from 'components/StoresContext';
import { withMeasure } from 'components/Measure';

import Items from './Items';
import './Newsfeed.css';

// import { NewsFilterContainer } from "./NewFilterContainer";

// const userAgent = "AzSearchStore/Preview";

// function buildSearchURI(config, parameters) {
//     const { service, index } = config;
//     const apiVersion = parameters.searchParameters.apiVersion;
//     const uriTemplate = `https://${service}.search.windows.net/indexes/${index}/docs/search?api-version=${apiVersion}`;
//     let searchURI = URI(uriTemplate);
//     return searchURI.valueOf();
// }

// // Needed to change facets count to 100
// var searchCallback = function(state, postBody) {

//     const parameters = state.parameters;

//     postBody.facets = postBody.facets.map( x => x.replace("count:5","count:100") );
//     //postBody.facets[0] = postBody.facets[0].replace("sort:count","sort:-value");

//     const searchURI = buildSearchURI(state.config, parameters);
//     let headers = new Headers({
//         "api-key": state.config.queryKey,
//         "Content-Type": "application/json",
//         "User-Agent": userAgent,
//         "x-ms-client-user-agent": userAgent
//     });
//     const promise = 
//         fetch(searchURI, {
//             mode: "cors",
//             headers,
//             method: "POST",
//             body: JSON.stringify(postBody)
//         });
//     return promise;

//     // do something, maybe authentication?
//     // make an http call and return a promise
//     // promise must resolve with data in the same format returned by the search api
// };



// // create store
// var SearchStore = new AzSearchStore();

// // configuration
// SearchStore.setConfig({ index: "movies", queryKey: "D46B6383CE58F9FBE1C5F7D3EBF3A7B8", service: "kkvnext" });
// SearchStore.updateSearchParameters({ count: true, top: 10, searchMode: 'all' });
// SearchStore.setInput("");

// // Facets
// SearchStore.addCheckboxFacet("type","string");
// //SearchStore.addCheckboxFacet("year","string");
// SearchStore.addCheckboxFacet("genre_names","collection");
// SearchStore.addCheckboxFacet("tag_names","collection");
// SearchStore.setSearchCallback(searchCallback);


import CssScaler from '../CssScaler';

// Scales the feed to match the available screen size.
class Scaler_ extends React.PureComponent {

    constructor(props) {
        super(props);

        const propsToScale = {
            ".kk-nf-item": { width: 185, height: 305, "font-size": 14 },
            ".kk-nf-star": { "font-size": 14 },
            ".kk-nf-welcome": { bottom: 245, "font-size": 18 },
            ".kk-nf-user-img": { height: 185, top: 60 },
            ".kk-nf-user-name": { "font-size": 18, top: 245 },
            ".kk-nf-newuser-top": { height: 40, padding: 5 },
            ".kk-nf-logo": { width: 32, height: 32, "margin-right": 4 },

            ".kk-nf-actor": { height: 40, padding: 5 },

            ".kk-nf-actor-img": { width: 32, height: 32, "margin-right": 4 },
            ".kk-nf-actor-name": { "max-width": 65, "font-size": 12, "margin-left": 4 },
            ".kk-nf-actor-ts": { "font-size": 10 },
            ".kk-nf-poster": { width: 185, height: 265 },

            ".kk-nf-media-button": { "border-width": 1, "font-size": 12, "padding-left": 5, "padding-right": 5, "margin-left": 2, "line-height": 20, height: 20 },

            ".kk-nf-list-name": { "font-size": 16, height: 30, "line-height": 30, top: 242 },

            ".kk-nf-actions": { padding: 5 },

            ".kk-nf-action": { "margin-top": 6 },
            ".kk-nf-action-icon": { width: 24, height: 24, "font-size": 16, "line-height": 24, "border-radius": 4 },
            ".kk-nf-action-text": { "font-size": 12, "margin-left": 10 },

            ".kk-nf-ratee": { "margin-top": 15, "font-size": 24 },

            ".kk-nf-comment": { top: 50, left: 10, right: 10, height: 200, "font-size": 14, "line-height": 20 },

            ".kk-nf-listinfo": { "padding-top": 5, width: 155, height: 200, "margin-top": 20, "margin-left": 15, "font-size": 14 },

            ".kk-nf-movietitle": { height: 25, "font-size": 12, padding: 4 },

            ".kk-nf-overlay": { "padding-top": 50 },
            ".kk-nf-overlay-btn": { "border-radius": 8, "border-width": 2, width: 155, "margin-left": 15, height: 35, "line-height": 35 },
            ".kk-nf-button-text": { "margin-left": 10, height: 35, "line-height": 35, fontSize: 14 },
            ".kk-nf-button-icon": { "font-size": 24, "margin-top": 4, "margin-left": 8 },
            ".kk-nf-togglebox": { "margin-top": 20 },
            ".kk-nf-morebtn": { "margin-right": 30, "margin-top":10 }
        };

        this._scaler = new CssScaler(propsToScale);

        this.state = {};
    }

    componentWillUnmount()
    {
        this._scaler.destroy();
    }

    static _calcItemSize(props)
    {
        var newWidth = props.containerWidth;

        var itemWidth  = 185;
        var itemHeight = 305;
        var spacing    = 6;

        var scaleFactor = 1;
        var colWidth  = itemWidth + spacing;

        var itemsPerRow = (newWidth + spacing) / colWidth;

        if (true) {
            itemsPerRow = Math.round(itemsPerRow);

            var scaledWidth = (newWidth + spacing) / itemsPerRow - spacing;
            scaleFactor = scaledWidth / itemWidth;

            itemWidth  = scaledWidth;
            itemHeight = itemHeight * scaleFactor;

            colWidth = itemWidth + spacing;
            //rowHeight = itemHeight + this.props.spacing;

            // if (this.state.width !== newWidth) {
            //     this.props.updateScaleFactor(scaleFactor, itemWidth, itemHeight);
            // }
        // } else {
        //     itemsPerRow = Math.floor(itemsPerRow);
        }

        var x0 = (newWidth - itemsPerRow * colWidth + spacing) / 2

        return { scaleFactor, itemWidth, itemHeight, spacing, itemsPerRow, x0 }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.containerWidth === state.containerWidth) {
             return null;
        }

        var sz = Scaler_._calcItemSize(props);

        if (sz.scaleFactor === state.scaleFactor) {
             return null;
        }

        sz.scale = true;

        return sz;
    }

    render() {

        if (this.state.scale) {
            this._scaler.scale(this.state.scaleFactor);
        }

        return (
            <div>
                <Items x0={this.state.x0} itemWidth={this.state.itemWidth} itemHeight={this.state.itemHeight} spacing={this.state.spacing}
                    itemsPerRow={this.state.itemsPerRow}/>
            </div>
        );
    }
}

// withMeasure() injects the containerWidth property
const Scaler = withMeasure(Scaler_);


class Newsfeed extends React.PureComponent
{
    componentWillMount() {
        this.props.activateNewsUpdate();

        // // Trigger Search for all movies to get Facets
        // SearchStore.setInput("*");
        // SearchStore.setGlobalFilter("users","users gt 0")
        // SearchStore.search();
    }

    componentWillUnmount() {
        this.props.deactivateNewsUpdate();
    }

    render() {

        // No news filter in mobile mode (yet)
        //var mobileMode = this.props.windowSize.mobile;
        return (
            <div>
                {/* {mobileMode == false && <Provider store={SearchStore.store}>
                    <NewsFilterContainer/>
                </Provider>
                } */}
                <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
                    <div className="col-md-12 kk-nf-feedarea">
                        <Scaler/>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(Newsfeed, state => ({
    mobile: state.windowSize.mobile
}), (dispatch) => ({
    activateNewsUpdate: () => dispatch(activateNewsUpdate()),
    deactivateNewsUpdate: () => dispatch(deactivateNewsUpdate())
}));
