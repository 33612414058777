import Api from 'components/Api';

export const NEWPASSWORD_INPUT = "NEWPASSWORD_INPUT";
export const NEWPASSWORD_REQUEST_SUCCESS = "NEWPASSWORD_REQUEST_SUCCESS";
export const NEWPASSWORD_REQUEST_FAILURE = "NEWPASSWORD_REQUEST_FAILURE";

export function forgotPassword() {
    return function (dispatch, getState) {
        let userName = getState().newPasswordForm.userName;
        var errors = [];
        if (!userName || userName.trim() === "") {
            errors.push("Bitte gib einen Benutzernamen oder eine E-Mail-Adresse an.");
        }
        if (errors.length > 0) {
            dispatch({ type: NEWPASSWORD_REQUEST_FAILURE, errors });
        } else {
            Api.postForm("Token/new_password", { nameoremail: userName.trim() }).then(reply => {
                if (reply.errors && reply.errors.length > 0) {
                    dispatch({ type: NEWPASSWORD_REQUEST_FAILURE, errors: reply.errors });
                } else {
                    dispatch({ type: NEWPASSWORD_REQUEST_SUCCESS });
                }
            }).catch(errors => {
                dispatch({ type: NEWPASSWORD_REQUEST_FAILURE, errors: errors });
            });
        }
    }
}
