import React from 'react';
import { withRouter } from 'react-router-dom';

import RelTime from 'components/RelTime';
import User from 'components/User';

import ActionList from './ActionList';

class UserItem extends React.PureComponent {

    constructor(props) {
        super(props);

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick() {
        this.props.history.push(this.props.item.actor.url);
    }

    render() {

        var item = this.props.item;

        return (
            <div className="kk-nf-item" onClick={this._handleClick}>

                <div className="kk-nf-welcome">Willkommen!</div>
                <img className="kk-nf-user-img" src={User.img(item.actor)} alt=""/>
                <div className="kk-nf-user-name">{User.shortName(item.actor)}</div>

                <div className="gradient-top kk-nf-newuser-top">
                    <img className="kk-nf-logo" src="/Images/logo/Logo-128x128.png" srcSet="/Images/logo/Logo-032x032.png 1x, /Images/logo/Logo-064x064.png 2x" alt=""/>
                    <span className="kk-nf-actor-name">kinokicks</span>
                    <RelTime className="kk-nf-actor-ts" time={item.ts}/>
                </div>

                <ActionList item={item}/>
            </div>
        );
    }
}

export default withRouter(UserItem);
