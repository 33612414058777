import React from 'react';
import PropTypes from 'prop-types';

import Toggles from 'components/MovieDetails/Toggles';
import Rating from 'components/MovieDetails/Rating';


class MovieOverlay extends React.Component {

    constructor(props) {
        super(props);
        this._handleClick = this._handleClick.bind(this);
    }
    
    _handleClick(e) {
        e.stopPropagation();
        e.preventDefault();

        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {

        var item = this.props.item;
        var elems = [];

        elems.push(
            <div key="Title" className="kk-ml-movietitle">{item.title}</div>
        );

        elems.push((
            <div key="Toggles" className="kk-ml-togglebox">
                <Toggles spacing="5px" movieId={item.id} title={item.title} poster={item.poster}/>
            </div>
       ));

        elems.push(
            <div key="Rating" style={{ fontSize: "30px" } }>
                <Rating movieId={item.id} title={item.title} poster={item.poster}/>
            </div>);
        elems.push(<div key="more" className="kk-ml-morebtn text-right ab-dark"> 
                        <span className="fa fa-arrow-right" style={{marginRight:"5px"}}></span>
                        mehr
                    </div>);
        return (
            <div className="kk-ml-overlay" onClick={this._handleClick}>
                {elems}
            </div>
        );
    }
}

MovieOverlay.contextTypes = {
    router: PropTypes.object.isRequired
  };

export default MovieOverlay;