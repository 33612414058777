// This is a React wrapper around the bootstrap modal component. It allows to specify modals
// inside React components and open them by updating their "open" prop to true.

import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import GoogleAnalytics from 'GoogleAnalytics';

class Header extends React.PureComponent {
    render() {
        return <div className="modal-header">{this.props.children}</div>;
    }
}

class Title extends React.PureComponent {
    render() {
        return <h5 className="modal-title">{this.props.children}</h5>;
    }
}

class Body extends React.PureComponent {
    render() {
        return <div className="modal-body">{this.props.children}</div>;
    }
}

class Footer extends React.PureComponent {
    render() {
        return <div className="modal-footer">{this.props.children}</div>;
    }
}

class CloseButton extends React.PureComponent {
    render() {
        return <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" onClick={this.props.onClick}>&times;</span></button>;
    }
}

class Portal extends React.PureComponent {
    render() {
        return <div id="_modal_portal_"/>;
    }
}

class Modal extends React.PureComponent {

    constructor(props) {
        super(props);

        this._shown = false;
        this._instance = null;

        if (typeof document !== "undefined") {
            this._portalElement = document.createElement("div");
        }    

        this._handleBackgroundClick = this._handleBackgroundClick.bind(this);
        this._handleModalClick = this._handleModalClick.bind(this);
    }

    componentDidMount() {

        if (!this._portalContainer) {
            this._portalContainer = document.getElementById("_modal_portal_");
            if (!this._portalContainer) {
                this._portalContainer = document.body;
            }
        }

        this._portalContainer.appendChild(this._portalElement);
    }

    componentWillUnmount() {
        this._portalContainer.removeChild(this._portalElement);
    }

    _handleBackgroundClick(e) {
        e.stopPropagation();
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    _handleModalClick(e) {
        e.stopPropagation();
    }

    render() {

        var sizeClass = undefined;
        if (this.props.size === "small") {
            sizeClass = "modal-sm";
        } else if (this.props.size === "large") {
            sizeClass = "modal-lg";
        }

        var cls = "modal";
        var disp = "none";
        var backdrop = undefined;
        if (this.props.show) {
            cls = cls + " in";
            disp = "block";
            backdrop = <div className="modal-backdrop fade in"/>;
            GoogleAnalytics.modalview(this.props.gaName);
        }

        if (this.props.show) {
            return ReactDOM.createPortal(
                <div>
                    <div className={cls} style={{ display: disp }} tabIndex="-1" role="dialog" onMouseDown={this._handleBackgroundClick}>
                        <div className={classNames("modal-dialog", sizeClass)} role="document" onMouseDown={this._handleModalClick}>
                            <div className="modal-content" >
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                    {backdrop}
                </div>,
                this._portalElement);
        }
        else
        {
            return null; //ReactDOM.createPortal(null, this._portalElement);
        }
    }
}

Modal.Portal = Portal;
Modal.Header = Header;
Modal.Title = Title;
Modal.CloseButton = CloseButton;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
