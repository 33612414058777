import GoogleAnalytics from 'GoogleAnalytics';

import Api from 'components/Api';

export const SEND_FRIEND_REQUEST = "SEND_FRIEND_REQUEST";
export const ACCEPT_FRIEND_REQUEST = "ACCEPT_FRIEND_REQUEST";
export const DECLINE_FRIEND_REQUEST = "DECLINE_FRIEND_REQUEST";
export const REMOVE_FRIEND = "REMOVE_FRIEND";


export function sendFriendRequest(otherUser) {
    return function(dispatch, getState) {
        GoogleAnalytics.event({ category: "action", action: "friend-request-send" });
        let user = getState().user.currentUser;
        dispatch({ type: SEND_FRIEND_REQUEST, user, to: otherUser });
        Api.postJson(`User/${user.id}/friends`, { to: otherUser.id });
    }
}

export function acceptFriendRequest(otherUser) {
    return function(dispatch, getState) {
        GoogleAnalytics.event({ category: "action", action: "friend-request-accept" });
        let user = getState().user.currentUser;
        dispatch({ type: ACCEPT_FRIEND_REQUEST, user, from: otherUser })
        Api.putJson(`User/${user.id}/confirmrequest`, otherUser.id );
    }
}

export function declineFriendRequest(otherUser) {
    return function(dispatch, getState) {
        GoogleAnalytics.event({ category: "action", action: "friend-request-decline" });
        let user = getState().user.currentUser;
        dispatch({ type: DECLINE_FRIEND_REQUEST, user, from: otherUser })
        Api.deleteJson(`User/${user.id}/friends`, { otherUserId: otherUser.id });
    }
}

export function removeFriend(friend) {
    return function(dispatch, getState) {
        GoogleAnalytics.event({ category: "action", action: "friend-delete" });
        let user = getState().user.currentUser;
        dispatch({ type: REMOVE_FRIEND, user, friend })
        Api.deleteJson(`User/${user.id}/friends`, { otherUserId: friend.id });
    }
}