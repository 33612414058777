import React from 'react';

import Api from 'components/Api';

import LazyImg from './Image';

class ActorImage extends React.PureComponent {
    render() {
        var item = this.props.item;

        var defaultSrc = "/Images/unknown.png";
        var src = Api.getProfileImageUrl(item.actor.img);
        // if (src === "/Images/unknown.png") {
        //     src = undefined;
        // }
        return <LazyImg className="kk-nf-actor-img" defaultSrc={defaultSrc} src={src} fadeIn={500}/>;
    }
}

export default ActorImage;
