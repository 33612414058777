import React from 'react';

class GhostItem extends React.PureComponent {
    render() {
        return (
            <div className="kk-nf-item kk-nf-ghost">
            </div>
        );
    }
}

export default GhostItem;
