import React from 'react';

import Raty from 'components/Raty';
import RequireLogin from 'components/RequireLogin';
import { connect } from 'components/StoresContext';

import { updateMovieInfo, getMovieInfo } from 'actions/user';

class Rating extends React.PureComponent {

    constructor(props) {
        super(props);

        this._rateMovie = this._rateMovie.bind(this);
    }

    _rateMovie(score) {
        RequireLogin.do(() => {
            this.props.updateMovieInfo(this.props.movieId, this.props.title, this.props.poster, { rating: score });
        });
    }

    render() {
        return (
            <Raty score={this.props.rating} setScore={this._rateMovie } />
        );
    }
}

export default connect(Rating, function (store, props) {
    var userInfo = getMovieInfo(store, parseInt(props.movieId));

    return { rating: userInfo ? userInfo.rating : 0 };
}, (dispatch) => ({
    updateMovieInfo: (movieId, title, poster, update) => dispatch(updateMovieInfo(movieId, title, poster, update))
}));