import React from 'react';
import PropTypes from 'prop-types';

import AdaptiveBackground from 'components/AdaptiveBackground';
import User from 'components/User';
import RelTime from 'components/RelTime';
import Button from 'components/UI/Button';
import api from 'components/Api';
import { connect } from 'components/StoresContext';

import RatingHistogram from './RatingHistogram';
import ProfileImgEditor from './ImageEditor';
import { getProfile, getMovies, getLists, getReviews, getFriends } from 'actions/otherUser';
import { setProfileImage } from 'actions/user';
import { sendFriendRequest, acceptFriendRequest, removeFriend } from 'actions/friends';
import RequireLogin from 'components/RequireLogin';

class CounterLabel extends React.Component{
    render () {

        var caption;
        if (this.props.captionLineBreak) {
            caption = this.props.caption.split(' ').map(function (line) { return (<span>{ line }<br /></span> ) });
        } else {
            caption = this.props.caption;
        }

        var cursor = this.props.onClick ? "pointer": "default";

        return (
            <div className="text-center" style={{ display:"inline-block", cursor: cursor }} onClick={this.props.onClick}>
                <div style={{ fontSize:"28px", paddingLeft:"5px", paddingRight:"5px" }}>
                    {(this.props.items === undefined || this.props.items === null) ? (<span className="fa fa-spinner fa-pulse"/>) : this.props.items.length}
                </div>
                <div style={{lineHeight:"100%"}}>
                    {caption}
                </div>
            </div>
        );
    }
};

// props:
//   loading: true while still loading the data
//   me: true if a logged in user views his own profile
//   friend: true when the profile belongs to a friend or a request was sent to the user
//   pending: true when the friend requst was sent but not 

class FriendButton_ extends React.PureComponent {

    _sendRequest() {
        RequireLogin.do(() => {
            if (this.props.pending) {
                this.props.acceptFriendRequest(this.props.profileUser);
            }
            // If no send out a request 
            else {
                this.props.sendFriendRequest(this.props.profileUser)
            }
        });
    }

    _stopFriendship() {
        this.props.removeFriend(this.props.profileUser);
    }

    render() {
        if (this.props.loading) {
            return (<div><span className="fa fa-spinner fa-pulse"/></div>);
        }

        if (this.props.me) {
            return (<div></div>);
        }
        
        if (this.props.friend && !this.props.pending)
        {
            return (
                <div>
                    <button className="btn btn-orange" disabled><span className="fa fa-check"/> Freunde</button>
                    <button className="btn btn-opaque" style={{marginLeft:"10px"}} onClick={this._stopFriendship.bind(this)}>Freundschaft beenden</button>
                </div>
            );
        }

        if (this.props.friend && this.props.pending)
        {
            return (
                <button className="btn btn-opaque" disabled>Warte auf Antwort...</button>
            );
        }

        return (<button className="btn btn-orange" onClick={this._sendRequest.bind(this)}><span className="fa fa-plus"/> Freunde werden</button>);
    }
}

let FriendButton = connect(FriendButton_, function (store, props) {

    var currentUser  = store.user.currentUser;
    var profileUser  = getProfile(store, props.userId);

    var loading = !profileUser;

    if (!currentUser) {
        currentUser = { id: -1 };
    }
    if (!profileUser) {
        profileUser = { id: -2 };
    }

    var friendList   = store.user.friends;
    var friendStatus = friendList ? friendList.find(i => i.id === profileUser.id) : null;

    return {
        currentUser,
        profileUser,
        friend: !!friendStatus,
        me: currentUser.id == profileUser.id,
        pending: friendStatus && !friendStatus.confirmed,
        loading: loading
    }
}, dispatch => ({
    sendFriendRequest: other => dispatch(sendFriendRequest(other)),
    acceptFriendRequest: other => dispatch(acceptFriendRequest(other)),
    removeFriend: other => dispatch(removeFriend(other))
}));

class ProfileImage_ extends React.PureComponent {

    constructor(props) {
        super(props);

        this._changeImage = this._changeImage.bind(this);
        this._handleImgEditorClosed = this._handleImgEditorClosed.bind(this);
        this._handleNewImage = this._handleNewImage.bind(this);

        this.state = { showImgEditor: false };
    }

    _changeImage() {
        this.setState({ showImgEditor: true });
    }

    _handleImgEditorClosed() {
        this.setState({ showImgEditor: false });
    }

    _handleNewImage(imageUrl) {
        this.props.setProfileImage(imageUrl);
    }

    render() {
        return (
            <div className="col-md-3 text-center" style={{ paddingTop: 20, paddingBottom: 10 }}>
                {this.props.showEditButton && <ProfileImgEditor image={this.props.img} show={this.state.showImgEditor} onClosed={this._handleImgEditorClosed} onImageSelected={this._handleNewImage}/>}
                <div style={{ position: "relative", width: 160, height: 160, display: "inline-block" }}>
                    {this.props.img && <img className="img-circle" src={this.props.img} alt="" style={{ boxShadow: "0 0 10px #888888" }} />}
                    {this.props.showEditButton && <Button bsStyle="primary" style={{ marginTop: 5, width: 160 }} onClick={this._changeImage}>Bild ändern...</Button>}
                </div>
            </div>
        );
    }
}

let ProfileImage = connect(ProfileImage_, function (store, props) {

    var currentUser  = store.user.currentUser;
    var profileUser  = getProfile(store, props.userId);

    return {
        user: profileUser,
        img: User.img(profileUser),
        showEditButton: currentUser && profileUser && currentUser.id === profileUser.id
    };
}, (dispatch, _) => ({
    setProfileImage: image => dispatch(setProfileImage(image))
}));

class ProfileHeader extends React.PureComponent {
    
    constructor(props) {
        super(props);

        this._handleMoviesClicked = this._handleMoviesClicked.bind(this);
    }

    _handleMoviesClicked() {
        this.context.router.history.push(api.getUserUrl(this.props.user) + '/filme');
    }

    render() {

        var profile = [0,0,0,0,0,0,0,0,0,0,0];
        if (this.props.ratings) {
            this.props.ratings.forEach((m) => { 
                profile[(m.rating * 2)-1] = profile[(m.rating * 2)-1 ] + 1;
            });
        }

        var userImg = User.img(this.props.user);
        
        return (
            <AdaptiveBackground className="container-fluid" style={{ paddingTop: "10px" }} image={userImg}>
                <div className="container">
                    <div className="row" style={{ paddingBottom: "20px" }}>
                        <ProfileImage userId={this.props.user ? this.props.user.id : -1}/>
                        <div className="col-md-5 text-left" style={{ paddingLeft: "25px" }}>
                            <div className="row">
                                <div className="col-sm-12">
                                    {this.props.user && <h2>{this.props.user.name} <small>Mitglied <RelTime time={this.props.user.joined} prefix="seit " /></small></h2>}
                                    <div style={{ paddingTop: "15px" } }/>
                                    <FriendButton userId={this.props.userId}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center" style={{ paddingTop: "20px" }}>
                            <div>
                                <CounterLabel items={this.props.movies} caption="Filme" btnSize="btn-xl" onClick={this._handleMoviesClicked}/>
                                <div style={{ width: "15px", display: "inline-block" } }></div>
                                <CounterLabel items={this.props.reviews} caption="Reviews" btnSize="btn-xl" />
                                <div style={{ width: "15px", display: "inline-block" } }></div>
                                <CounterLabel items={this.props.lists} caption="Listen" btnSize="btn-xl" />
                                <div style={{ width: "15px", display: "inline-block" } }></div>
                                {this.props.user && <CounterLabel items={this.props.friends} caption="Freunde" btnSize="btn-xl" />}
                            </div>
                            <div style={{height:"10px"}} />
                            {this.props.ratings && <RatingHistogram width="180px" counts={profile} />}
                        </div>
                    </div>
                </div>
            </AdaptiveBackground>
        );
    }
};

ProfileHeader.contextTypes = {
    router: PropTypes.object.isRequired
};

export default connect(ProfileHeader, function (store, props) {

    var movies = getMovies(store, props.userId);

    return {
        user: getProfile(store, props.userId),
        movies: movies,
        ratings: movies ? movies.filter(m => m.rating > 0) : null,
        lists: getLists(store, props.userId),
        reviews: getReviews(store, props.userId),
        friends: getFriends(store, props.userId)
    };
});