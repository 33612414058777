import React, { Component } from 'react';

import { initNews } from 'actions/news';

import Newsfeed from 'components/Newsfeed';
import { connect } from 'components/StoresContext';

class News extends Component {

    constructor(props) {
        super(props);

        this.props.init();
    }

    render() {
        return (
            <div className="container">
                <div>
                    <Newsfeed/>
                </div>
            </div>
        );
    }
}

export default connect(News, _ => ({ }), (dispatch) => ({
    init: () => dispatch(initNews())
}));