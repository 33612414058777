import React from 'react';

const defaultCss = {
    pager__pageItem: "pager__pageItem page-item",
    pager__pageItemActive: "pager__pageItemActive page-item active",
    pager__pageItemDisabled: "pager__pageItemDisabled page-item disabled",
    pager__pageLink: "pager__pageLink page-link",
    pager__list: "pager__list pagination",
    pager__nav: "pager__nav",
    screenReaderOnly: "screenReaderOnly sr-only"
};

class Ellipsis extends React.PureComponent {
    render() {
        let css = defaultCss;

        return (
            <li className={css.pager__pageItemDisabled}>
                <a className={css.pager__pageLink}>...</a>
            </li>
        );
    }
}

class Page extends React.PureComponent {

    constructor(props) {
        super(props);

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick(e)
    {
        e.preventDefault();
        e.stopPropagation();

        this.props.onClick(this.props.page);
    }

    _getCssClass(isActive, isDisabled) {
        let css = defaultCss;
        let cssClass = isActive ? css.pager__pageItemActive : css.pager_pageItem;
        cssClass = isDisabled ? css.pager__pageItemDisabled : cssClass;
        return cssClass;
    }

    render()
    {
        let { page, active } = this.props;

        let css = defaultCss;

        let srItem = active ? <span className={css.screenReaderOnly}>(current)</span> : null;

        return (
            <li className={this._getCssClass(active, false)}>
                <a className={css.pager__pageLink} href="#" onClick={this._handleClick}>{page} {srItem}</a>
            </li>
        );
    }
}

// props:
//   numberOfPages
//   currentPage
class Pager extends React.PureComponent
{
    constructor(props) {
        super(props);

        this._pageDown = this._pageDown.bind(this);
        this._pageUp = this._pageUp.bind(this);
    }

    _pageDown(e)
    {
        e.preventDefault();
        e.stopPropagation();

        if (this.props.currentPage > 1) {
            this.props.onPageSelected(this.props.currentPage - 1);
        }
    }

    _pageUp(e)
    {
        e.preventDefault();
        e.stopPropagation();

        if (this.props.currentPage < this.props.numberOfPages) {
            this.props.onPageSelected(this.props.currentPage + 1);
        }
    }

    render() {
        let { currentPage, numberOfPages, onPageSelected } = this.props;
        let buttons = [];
        let css = defaultCss;

        // previous button
        buttons.push(
            <li key="prev" className={currentPage > 1 ? css.pager__pageItem : css.pager__pageItemDisabled}>
                <a className={css.pager__pageLink} href="#" aria-label="Previous" onClick={this._pageDown}>
                    <span aria-hidden="true">&laquo;</span>
                    <span className={css.screenReaderOnly}>Previous</span>
                </a>
            </li>
        );

        let firstVisiblePage = Math.max(1, currentPage - 1);
        let lastVisiblePage = Math.min(numberOfPages, currentPage + 1);

        if (firstVisiblePage === 2) {
            firstVisiblePage = 1;
        }

        if (firstVisiblePage > 1) {
            buttons.push(<Ellipsis key="e1"/>);
        }

        if (lastVisiblePage === numberOfPages - 2) {
            lastVisiblePage = numberOfPages - 1;
        }

        for (var i = firstVisiblePage; i <= lastVisiblePage; i++) {
            buttons.push(<Page key={i} page={i} active={i === currentPage} onClick={onPageSelected}/>);
        }

        if (lastVisiblePage < numberOfPages - 1) {
            buttons.push(<Ellipsis key="e2"/>)
        }

        if (lastVisiblePage < numberOfPages) {
            buttons.push(<Page key={numberOfPages} page={numberOfPages} active={numberOfPages === currentPage} onClick={onPageSelected}/>)
        }

        // next button
        buttons.push(
            <li key="next" className={currentPage < numberOfPages ? css.pager__pageItem : css.pager__pageItemDisabled}>
                <a className={css.pager__pageLink} href="#" aria-label="Next" onClick={this._pageUp}>
                    <span aria-hidden="true">&raquo;</span>
                    <span className={css.screenReaderOnly}>Next</span>
                </a>
            </li>
        );
        
        return (
            <nav aria-label="Page navigation" className={css.pager__nav}>
                <ul className={css.pager__list}>
                    {buttons}
                </ul>
            </nav>
        );
    }
}

export default Pager;
