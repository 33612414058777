import React from 'react';

class RelTime extends React.PureComponent {

    _parseTimestamp(str) {

        if (!str) {
            return null;
        }

        // Nothing to do if we already get a Date
        if (str instanceof Date)
            return str;

        if (typeof(str) === "string" && str.indexOf("/Date(") === 0) {
            var numberStr = str.substring(6, str.length - 2);
            return parseInt(numberStr, 10);
        }

        return new Date(str).getTime();
    }

    // Makes a nice string out of time stamps
    _displayTime(timeStamp, prefix) {

        if (!timeStamp) {
            return null;
        }

        if (prefix === undefined) {
            prefix = "vor ";
        }

        // The number of seconds since timeStamp
        var delta = (new Date().getTime() - timeStamp) / 1000;

        if (delta < 5) {
            return "gerade eben";
        }
        else if (delta < 60) {
            return prefix + Math.round(delta) + " Sek";
        }
        else if (delta < 3600) {
            return prefix + Math.round(delta / 60) + " Min";
        }
        else if (delta < 24 * 3600) {
            return prefix + Math.round(delta / 3600) + " Std";
        }
        else if (delta < 31 * 24 * 3600) {
            var nDays = Math.round(delta / 3600 / 24);

            return (nDays === 1) ? prefix + "1 Tag" : prefix + nDays + " Tagen";
        }
        else if (delta < 365 * 24 * 3600) {
            var nMonths = Math.round(delta / 31 / 24 / 3600);
            return (nMonths === 1) ? prefix + "1 Monat" : prefix + nMonths + " Monaten";
        }

        var nYears = Math.round(delta / 365 / 24 / 3600);
        return (nYears === 1) ? prefix + "1 Jahr" : prefix + nYears + " Jahren";
    }
    
    render() {
        var ts = this._parseTimestamp(this.props.time);

        return <span className={this.props.className} style={this.props.style}>{this._displayTime(ts, this.props.prefix)}</span>;
    }
}

export default RelTime;
