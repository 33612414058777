import GoogleAnalytics from 'GoogleAnalytics';

export const VIEW_INITIALIZE = "VIEW_INITIALIZE";

export const VIEW_SHOW_FILTER = "VIEW_SHOW_FILTER";
export const VIEW_SHOW_ADD_MOVIE = "VIEW_SHOW_ADD_MOVIE";
export const VIEW_EXPAND_GROUP = "VIEW_EXPAND_GROUP";
export const VIEW_FILTER_SHOW_ALL = "VIEW_FILTER_SHOW_ALL";
export const VIEW_TOGGLE_FILTER = "VIEW_TOGGLE_FILTER";
export const VIEW_CHANGE_GROUP = "VIEW_CHANGE_GROUP";

export const VIEW_TAG_PAGING = "VIEW_TAG_PAGING";

// Retrieves the profile for a given user from the state.
export function getViewState(state, userId) {
    var user = state.view.users.find( u => u.id === userId);
    if (user) 
        return user.view;
}

export function switchShowFilter(userId) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "view", action: "show-filter" });

        dispatch({ type: VIEW_SHOW_FILTER, userId });
    }
}

export function toggleFilter(userId, value, dimension) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "view", action: "toggle-filter", label: dimension, value: value });

        dispatch({ type: VIEW_TOGGLE_FILTER, userId, value, dimension });
    }
}

export function switchShowAddMovie(userId, callBack) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "view", action: "show-add-movie"});

        dispatch({ type: VIEW_SHOW_ADD_MOVIE, userId });
    }
}

export function switchShowAllValues(userId, dimension) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "view", action: "show-expand-filter", label: dimension});

        dispatch({ type: VIEW_FILTER_SHOW_ALL, userId, dimension });
    }
}

export function changeGroup(userId, dimension) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "view", action: "change-group", label: dimension});

        dispatch({ type: VIEW_CHANGE_GROUP, userId, dimension });
    }
}

export function toggleExpandGroup(userId, dimension) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "view", action: "expand-group", label: dimension});

        dispatch({ type: VIEW_EXPAND_GROUP, userId, dimension });
    }
}

export function setTagPage(newPage) {
    return function (dispatch, _) {
        dispatch({ type: VIEW_TAG_PAGING, newPage });
    }
}