import GoogleAnalytics from 'GoogleAnalytics';

import Api from 'components/Api';

export const REVIEW_CREATE = "REVIEW_CREATE";
export const REVIEW_VOTE = "REVIEW_VOTE";
export const REVIEW_DELETE = "REVIEW_DELETE";
export const REVIEW_ADD_COMMENT = "REVIEW_ADD_COMMENT";

export function createMovieReview(movieId, text) {
    return function (dispatch, getState) {
        GoogleAnalytics.event({ category: "action", action: "create-movie-review" });
        Api.postJson("Review", { movie_id: movieId, text }).then(review => {

            let state = getState();
            if (state.movieStore.movieId === movieId) {
                let movieInfo = state.movieStore.movieInfo;
                review.movie = {
                    id: movieInfo.id,
                    title: movieInfo.title,
                    year: movieInfo.release_date ? movieInfo.release_date.substring(0, 4) : null,
                    poster: movieInfo.poster,
                    backdrop: movieInfo.backdrop
                }
            }
            review.author = state.user.currentUser;
            review.score = 0;
            review.vote = 0;
            review.comments = [];

            dispatch({ type: REVIEW_CREATE, review });
        });
    }
}

export function voteForReview(reviewId, vote) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "action", action: "vote-review" });
        Api.postJson(`Review/${reviewId}/votes`, { vote });
        dispatch({ type: REVIEW_VOTE, reviewId, vote });
    }
}

export function deleteMovieReview(reviewId) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "action", action: "delete-movie-review" });

        Api.deleteJson(`Review/${reviewId}`);

        dispatch({ type: REVIEW_DELETE, reviewId });
    }
}

export function createReviewComment(reviewId, text) {
    return function (dispatch, getState) {

        GoogleAnalytics.event({ category: "action", action: "create-review-comment" });

        Api.postJson(`Review/${reviewId}/comments`, { text }).then(comment => {

            comment.author = getState().user.currentUser;

            dispatch({ type: REVIEW_ADD_COMMENT, reviewId, comment });
        });
    }
}