import React from 'react';
import PropTypes from 'prop-types';

import Api from 'components/Api';

import LazyImg from './Image';
import MovieOverlay from './MovieOverlay';

class MovieItem extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = { overlayVisible: false };

        this._handleOverlayClick = this._handleOverlayClick.bind(this);
        this._handleMouseEnter = this._handleMouseEnter.bind(this);
        this._handleMouseLeave = this._handleMouseLeave.bind(this);
    }

    _handleOverlayClick() {
        var movieItem = this.props.item;

        this.setState({ overlayVisible: false });

        this.context.router.history.push(Api.getMovieUrl(movieItem));
    }

    _handleMouseEnter() {
        if (this.props.showOverlay) {
            this.setState({ overlayVisible: true });
        }
    }

    _handleMouseLeave() {
        if (this.props.showOverlay) {
            this.setState({ overlayVisible: false });
        }
    }

    render() {

        var item = this.props.item;

        var overlay = undefined;
        if (this.state.overlayVisible) {
            overlay = <MovieOverlay item={item} onClick={this._handleOverlayClick}/>;
        }

        var size = this.props.mobile ? 154 : 185;
        var posterSrc = Api.getTmdbImage(item.poster, size);

        // Render the movie title over the no-poster image
        if (!overlay && !item.poster) {
            overlay = (
                <div className="kk-ml-overlay-clear">
                    <div className="kk-ml-movietitle dim">{item.title}</div>
                </div>
            );
        }

        return (
            <div ref={ref => { this.props.innerRef[item.id] = ref; }} className="kk-ml-item" data-item-key={item.key} onMouseEnter={this._handleMouseEnter} onMouseLeave={this._handleMouseLeave} onClick={this._handleOverlayClick}>
                <LazyImg className="kk-ml-poster" defaultSrc="/Images/no-poster-185.png" src={posterSrc} fadeIn={500}/>
                <div className="kk-ml-placeholder">
                    {overlay}
                </div>
            </div>
        );
    }
}

MovieItem.contextTypes = {
    router: PropTypes.object.isRequired
  };

export default React.forwardRef((props, ref) => <MovieItem 
  innerRef={ref} {...props}
/>);
