import React from 'react';
import { Link } from "react-router-dom";

import User from 'components/User';
import RelTime from 'components/RelTime';
import Button from 'components/UI/Button';
import RequireLogin from 'components/RequireLogin';
import ToggleMovieInfoButton from 'components/MovieDetails/ToggleMovieInfoButton';

import { connect } from 'components/StoresContext';
import { editList, likeList, unlikeList } from 'actions/movieList';

class Header extends React.PureComponent {

    constructor(props) {
        super(props);

        this._edit = this._edit.bind(this);
        this._handleLike = this._handleLike.bind(this);
    }

    _edit() {
        this.props.editList();
    }

    _handleLike(newState) {
        RequireLogin.do(() => {
            if (newState) {
                this.props.likeList()
            } else {
                this.props.unlikeList()
            }
        });
    }

    render() {

        var list = this.props.list;
        if (!list) {
            return null;
        }

        var editButton = undefined;
        if (this.props.currentUser && this.props.currentUser.id === list.owner.id && list.can_edit) {
            
            editButton = (
                <div className="row">
                    <div className="col-sm-12">
                        <Button bsStyle="primary" onClick={this._edit} style={{ marginRight: 10 }}>Liste editieren</Button>
                    </div>
                </div>
            );
        }

        return (
            <div className="container-fluid ab-light" style={{ backgroundColor:"rgb(25, 25, 25)" }} image={this.props.listImage}>
                <div className="container" style={{color:"rgb(220,220,220)"}}>
                    <div className="row" style={{ paddingBottom: "20px" }}>
                        
                        <div className="col-lg-9 text-left" style={{ paddingTop: 20 }}>
                            <div className="row">
                                <div className="col-12">
                                  
                                    <h3 style={{ marginTop: 0 }}>
                                        {list.name}
                                    </h3>
                                    <div style={{ marginTop: "5px", marginBottom: "10px" }}>
                                        {list.description}
                                    </div>
                                  
                                    <div>
                                        Veröffentlicht von <Link to={User.url(list.owner)}>{list.owner.name}</Link> &nbsp;
                                        <RelTime time={list.created_at}/>
                                        {editButton}
                                    </div>
                                    {list.stats && 
                                        <div style={{ marginTop: "10px", color:"#678", borderTop: "1px solid #678" }}>
                                            {list.stats.total_movies} Filme, {list.stats.total_votes} Votes, {list.stats.total_comments} Comments, {list.stats.total_likes} Likes
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            
                            <div style={{ height: "30px" }}/>
                            
                            <div className="text-center">
                                <ToggleMovieInfoButton checked={list.is_liked_by_me} onToggle={this._handleLike}
                                        icon="fa-heart-o" btnColor="btn-red" btnSize="btn-xl" 
                                        caption="Gefällt mir"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(Header, store => ({
    currentUser: store.user.currentUser,
    list: store.listStore.list,
    listImage: store.listStore.listImage,
    published: store.listStore.list && store.listStore.list.is_published
}), dispatch =>({
    editList: () => dispatch(editList()),
    likeList: () => dispatch(likeList()),
    unlikeList: () => dispatch(unlikeList())
}));