import Api from 'components/Api';

export const LOAD_MOVIE = "LOAD_MOVIE";

export const LOAD_MOVIE_INFO_SUCCESS = "LOAD_MOVIE_INFO_SUCCESS";
export const LOAD_MOVIE_USERS_SUCCESS = "LOAD_MOVIE_USERS_SUCCESS";
export const LOAD_MOVIE_TRAILER_SUCCESS = "LOAD_MOVIE_TRAILER_SUCCESS";
export const LOAD_MOVIE_LISTS_SUCCESS = "LOAD_MOVIE_LISTS_SUCCESS";
export const LOAD_MOVIE_REVIEWS_SUCCESS = "LOAD_MOVIE_REVIEWS_SUCCESS";

function loadTrailer(movieId, dispatch) {
    Api.get(`Movie/${movieId}/trailer`).then(data => {
        data.movieId = movieId;
        dispatch({ type: LOAD_MOVIE_TRAILER_SUCCESS, data });
    }).catch(() => {});
}

export function loadMovie(movieId) {
    return function (dispatch, getState) {
        let state = getState().movieStore;
        let movieInfo = state.movieInfo;
        if (movieInfo && movieInfo.id === movieId) {
            // This movie is already loaded. We're done.

            if (!state.trailer) {
                // The trailer was not loaded at server side to avoid long rendering time. Load it now.
                loadTrailer(movieId, dispatch);
            }

            return Promise.resolve();
        }

        dispatch({ type: LOAD_MOVIE, movieId });

        // Load the main movie information
        let movieInfoTask = Api.get(`Movie/${movieId}`).then(data => {

            // Sort the cast
            if (data.cast) {
                data.cast.forEach((c) => { if (!c.order) { c.order = 0;} });
                data.cast = data.cast.sort(function (a, b) { return a.order - b.order; });
            }

            dispatch({ type: LOAD_MOVIE_INFO_SUCCESS, data });
        });

        var movieUsersTask = Api.get(`Movie/${movieId}/users`).then(data => {
            dispatch({ type: LOAD_MOVIE_USERS_SUCCESS, data });
        });

        loadTrailer(movieId, dispatch);

        var movieListsTask = Api.get(`Movie/${movieId}/lists`).then(data => {
            dispatch({ type: LOAD_MOVIE_LISTS_SUCCESS, data });
        });

        var movieReviewsTasks = Api.get(`Movie/${movieId}/reviews`).then(data => {

            data.forEach(review => {
                if (!review.score) {
                    review.score = 0;
                }
            });

            dispatch({ type: LOAD_MOVIE_REVIEWS_SUCCESS, data });
        });

        // We are returning a promise that resolves as soon as all local KK data is available. We are
        // not waiting for the trailer but will include it if it is available.
        return Promise.all([ movieInfoTask, movieUsersTask, movieListsTask, movieReviewsTasks ]).then(_ => {
            return null;
        }).catch(() => {});
    }
}

export function reloadMovieUserList() {
    return function (dispatch, getState) {

        let movieId = getState().movieStore.movieId;
        if (!movieId)
        {
            return;
        }

        Api.get(`Movie/${movieId}/users`).then(data => {
            dispatch({ type: LOAD_MOVIE_USERS_SUCCESS, data });
        });
    }   
}