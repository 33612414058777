import Api from 'components/Api';

export const HOME_LOAD_MOVIESTATS_SUCCESS = "HOME_LOAD_MOVIESTATS_SUCCESS";
export const HOME_LOAD_ACTIVEUSERS_SUCCESS = "HOME_LOAD_ACTIVEUSERS_SUCCESS";
export const HOME_LOAD_ACTIVELISTS_SUCCESS = "HOME_LOAD_ACTIVELISTS_SUCCESS";

export function load() {
    return function (dispatch, getState) {

        let state = getState().homeStore;

        let statsTask = Promise.resolve();
        if (!state.movieStats) {
            statsTask = Api.get(`Movie/Stats`).then(data => {
                dispatch({ type: HOME_LOAD_MOVIESTATS_SUCCESS, data });
            }).catch(_ => { });
        }

        let listsTask = Promise.resolve();
        if (!state.activeLists) {
            // Load the active lists of the last 100 days
            listsTask = Api.get(`Lists/Trending?days=100`).then(data => {
                dispatch({ type: HOME_LOAD_ACTIVELISTS_SUCCESS, data });
            }).catch(_ => { });;
        }

        let usersTask = Promise.resolve();
        if (!state.activeUsers) {
            // Load the active users of the last 30 days
            usersTask = Api.get(`User/Active?days=30`).then(data => {
                dispatch({ type: HOME_LOAD_ACTIVEUSERS_SUCCESS, data });
            }).catch(_ => { });;
        }

        return Promise.all([ statsTask, listsTask, usersTask ]).then(_ => {
            return null;
        }).catch(() => {});
    }
}