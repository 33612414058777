import { SEARCH_EXEC, SEARCH_EXEC_SUCCESS, SEARCH_EXEC_FAILURE, SEARCH_SET_INPUT, SEARCH_SET_PAGESIZE, SEARCH_UPDATE_SUGGESTIONS, SEARCH_UPDATE_SUGGESTIONS_SUCCESS, SEARCH_SET_FACET } from 'actions/search';

// Controls the behavior of the search page.

// The initial state of the search
const initialState = {
    ctr: 0,
    input: "",
    suggestions: [],
    pageSize: 10,
    page: 1,
    pages: 0,
    totalResults: 0,
    result: null,
    facetFilter: { }
};

function searchReducer(state = initialState, action) {
    switch (action.type) {
        case SEARCH_EXEC:
            state = { ...state, ctr: state.ctr + 1, page: action.page ? action.page : 1, searchInProgress: true, result: null };
            break;
        case SEARCH_EXEC_SUCCESS:
            state = { ...state, pages: Math.ceil(action.result.total / state.pageSize), result: action.result, suggestions: [], searchInProgress: false };
            break;
        case SEARCH_EXEC_FAILURE:
            state = { ...state, page: 1, pages: 0, result: { total: 0 }, suggestions: [], searchInProgress: false };
            break;

        case SEARCH_SET_INPUT:
            state = { ...state, facetFilter: { }, input: action.input };
            break;

        case SEARCH_SET_PAGESIZE:
            if (state.pageSize != action.size) {
                state = { ...state, pageSize: action.size };
            }
            break;

        case SEARCH_UPDATE_SUGGESTIONS:
            state = { ...state, input: action.input, ctr: state.ctr + 1 };
            break;
        case SEARCH_UPDATE_SUGGESTIONS_SUCCESS:
            state = { ...state, suggestions: action.result };
            break;
        
        case SEARCH_SET_FACET:
            let facetFilter = { ...state.facetFilter };
            if (action.value) {
                facetFilter[action.facet] = action.value;
            } else {
                delete facetFilter[action.facet];
            }
            state = { ...state, facetFilter };
            break;
        default:
    }

    return state;
}

export default searchReducer;
