import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="container-fluid rainbow" style={{ height: "6px" }}>
        </div>
        <div className="container footer">
          <div className="row">
            <div className="col-sm-3">
              <div className="foot-header">
                Soziale Netwerke
              </div>
              <ul className="foot-links" style={{ listStyleType: "none", padding: "0px", lineHeight: "30px" }}>
                <li><a href="https://www.facebook.com/kinokicks" target="_blank"><span className="fa fa-facebook-square" style={{ verticalAlign: "middle", fontSize: "24px", marginRight: "5px", display: "inline-block" }}></span> Facebook</a><br /></li>
                <li><a href="https://twitter.com/kinokicks" target="_blank"><span className="fa fa-twitter-square" style={{ verticalAlign: "middle", fontSize: "24px", marginRight: "5px", display: "inline-block" }}></span> Twitter</a><br /></li>
              </ul>
            </div>
            <div className="col-sm-3">
              <div className="foot-header">
                Support
              </div>
              <ul className="foot-links" style={{listStyleType:"none", padding:"0px"}}>
                <li>
                  <a href="http://kinokicks.freeforums.net" target="_blank">Forum</a>
                </li>
                <li>
                  <a href="mailto:kontakt@kinokicks.de">Kontakt</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-3">
              <div className="foot-header">
                Rechtliches
              </div>
              <ul className="foot-links" style={{ listStyleType: "none", padding: "0px" }}>
                <li>
                  <Link to="/AGB">AGB</Link>
                </li>
                <li>
                  <Link to="/Datenschutzbestimmungen">Datenschutzbestimmungen</Link>
                </li>
                <li>
                  <Link to="/Impressum">Impressum</Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-3">
              <div style={{ height: "30px", lineHeight: "30px", verticalAlign: "middle", marginBottom: "5px", fontSize: "18px", color: "black" }}>
                <img src="/Images/logo/Logo-black-165x120.png" alt="" style={{ height: "30px" }} /> KINOKICKS
              </div>
              &copy; 2014-2018<br />
              This product uses the TMDb API but is not endorsed or certified by TMDb
            </div>
          </div>
        </div>
      </footer>
          );
        }
      }
      
export default Footer;