import React from 'react';
import FlipMove from 'react-flip-move';

import { connect } from 'components/StoresContext';

import AddItem from './AddItem';
import MovieItem from './MovieItem';

class MovieItem2 extends React.PureComponent
{
    render() {
        return <MovieItem {...this.props}/>;
    }
}

class AddItem2 extends React.PureComponent
{
    render() {
        return <AddItem {...this.props}/>;
    }
}

class Items extends React.PureComponent {

    render() {

        var movieElems = this.props.movies.map((item, i) => {
            return <MovieItem2 key={item.movie.id} index={i} item={item} isFirst={i === 0} edit={this.props.edit}/>;
        });

        var addElem = null;
        if (this.props.canAddMovie) {
            addElem = <AddItem2 key="add" index={this.props.movies.length + 1} listId={this.props.listId} isFirst={this.props.movies.length === 0}/>
        }

        return (
            <FlipMove enterAnimation="accordionVertical" leaveAnimation="accordionVertical">
                {movieElems}
                {addElem}
            </FlipMove>
        );
    }
}

export default connect(Items, store => ({
    listId: store.listStore.list.id,
    movies: store.listStore.list.movies,
    canAddMovie: store.listStore.list.users_can_add_movies || store.listStore.editing
}));