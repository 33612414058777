import Api from 'components/Api';
import GoogleAnalytics from 'GoogleAnalytics';


import { VIEW_INITIALIZE } from './view';


export const LOAD_OTHER_USER = "LOAD_OTHER_USER";
export const LOAD_OTHER_PROFILE_SUCCESS = "LOAD_OTHER_PROFILE_SUCCESS";
export const LOAD_OTHER_MOVIES_SUCCESS = "LOAD_OTHER_MOVIES_SUCCESS";
export const LOAD_OTHER_MOVIES_FAILURE = "LOAD_OTHER_MOVIES_FAILURE";
export const LOAD_OTHER_REVIEWS_SUCCESS = "LOAD_OTHER_REVIEWS_SUCCESS";
export const LOAD_OTHER_REVIEWS_FAILURE = "LOAD_OTHER_REVIEWS_FAILURE";
export const LOAD_OTHER_LISTS_SUCCESS = "LOAD_OTHER_LISTS_SUCCESS";
export const LOAD_OTHER_LISTS_FAILURE = "LOAD_OTHER_LISTS_FAILURE";
export const LOAD_OTHER_FRIENDS_SUCCESS = "LOAD_OTHER_FRIENDS_SUCCESS";
export const LOAD_OTHER_FRIENDS_FAILURE = "LOAD_OTHER_FRIENDS_FAILURE";
export const LOAD_OTHER_TAGS_SUCCESS = "LOAD_OTHER_TAGS_SUCCESS";
export const LOAD_OTHER_TAGS_FAILURE = "LOAD_OTHER_TAGS_FAILURE";

export const VIEW_SHOW_FILTER = "VIEW_SHOW_FILTER";
export const VIEW_SHOW_ADD = "VIEW_SHOW_ADD";

function compareStr(a, b) {
    return a < b ? 1 : (a > b ? -1 : 0);
}

export function loadUser(userId, enforce = false) {
    return function (dispatch, getState) {
        let state = getState();

        // Do nothing if we want the current user's profile.
        if (state.user.currentUser && userId === state.user.currentUser.id) {
            return Promise.resolve();
        }

        // If the user is already loaded, we are done
        if (!enforce && state.otherUser.userId === userId) {
            return Promise.resolve();
        }

        dispatch({ type: LOAD_OTHER_USER, userId });
        dispatch({ type: VIEW_INITIALIZE, userId });

        let userTask = Api.get(`User/${userId}`).then(profile => {
            dispatch({ type: LOAD_OTHER_PROFILE_SUCCESS, profile });
        }).catch(_ => { });

        let moviesTask = Api.get(`User/${userId}/movies`).then(movies => {
            // Restore empty lists to simplify access
            movies.forEach(m => {
                if (!m.tags) { m.tags = []; }
                if (!m.genre) { m.genre = []; }
                if (!m.title) { m.title = 'Fehler'; }
            });

            movies.sort((a, b) => a.title.localeCompare(b.title));

            dispatch({ type: LOAD_OTHER_MOVIES_SUCCESS, userId, movies });
        }).catch(_ => { 
            dispatch({ type: LOAD_OTHER_MOVIES_FAILURE, userId });
        });

        let listsTask = Api.get(`User/${userId}/lists`).then(lists => {
            dispatch({ type: LOAD_OTHER_LISTS_SUCCESS, userId, lists });
        }).catch(_ => {
            dispatch({ type: LOAD_OTHER_LISTS_FAILURE, userId });
        });

        let reviewsTask = Api.get(`User/${userId}/reviews`).then(reviews => {
            reviews.forEach(review => {
                if (!review.score) {
                    review.score = 0;
                }
            });

            reviews.sort((a, b) => {
                if (a.score === b.score) {
                    return compareStr(a.created_at, b.created_at);
                } else {
                    return b.score - a.score;
                }
            });

            dispatch({ type: LOAD_OTHER_REVIEWS_SUCCESS, userId, reviews });
        }).catch(_ => {
            dispatch({ type: LOAD_OTHER_REVIEWS_FAILURE, userId });
        });

        let tagsTask = Api.get(`User/${userId}/tags`).then(tags => {
            dispatch({ type: LOAD_OTHER_TAGS_SUCCESS, userId, tags });
        }).catch(_ => {
            dispatch({ type: LOAD_OTHER_TAGS_FAILURE, userId });
        });

        let friendsTask = Api.get(`User/${userId}/friends`).then(friends => {
            dispatch({ type: LOAD_OTHER_FRIENDS_SUCCESS, userId, friends });
        }).catch(_ => {
            dispatch({ type: LOAD_OTHER_FRIENDS_FAILURE, userId });
        });

        return Promise.all([ userTask, moviesTask, listsTask, reviewsTask, tagsTask, friendsTask ]);
    }
}

// Retrieves the profile for a given user from the state.
export function getProfile(state, userId) {
    if (state.user.currentUser && state.user.currentUser.id === userId) {
        return state.user.profile;        
    }

    return state.otherUser.profile;
}

export function getMovies(state, userId) {
    if (state.user.currentUser && state.user.currentUser.id === userId) {
        return state.user.movies;        
    }

    return state.otherUser.movies;
}

export function getMoviesViewState(state) {
    return state.otherUser.moviesViewState;
}

export function getLists(state, userId) {
    if (state.user.currentUser && state.user.currentUser.id === userId) {
        return state.user.lists;        
    }

    return state.otherUser.lists;
}

export function getReviews(state, userId) {
    if (state.user.currentUser && state.user.currentUser.id === userId) {
        return state.user.reviews;        
    }

    return state.otherUser.reviews;
}

export function getTags(state, userId) {
    if (state.user.currentUser && state.user.currentUser.id === userId) {
        return state.user.tags;        
    }

    return state.otherUser.tags;
}

export function getFriends(state, userId) {
    if (state.user.currentUser && state.user.currentUser.id === userId) {
        return state.user.friends;        
    }

    return state.otherUser.friends;
}

export function switchShowFilter(userId) {
    return function (dispatch, _) {
        GoogleAnalytics.event({ category: "view", action: "show-filter" });

        dispatch({ type: VIEW_SHOW_FILTER, userId });
    }
}
