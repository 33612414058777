import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Api from 'components/Api';
import { connect } from 'components/StoresContext'; 
import { acceptFriendRequest, declineFriendRequest } from 'actions/friends';


class FriendRequests extends Component {

    renderUsers(users) {
        if (users === null || users === undefined)
            return null;
        else
            return users.map((user) => {
                return (
                    <div style={{display:"inline-block", marginRight:"20px", marginTop:"20px"}}>
                        <div classname="text-center" style={{display:"inline-block", textAlign:"center"}}>
                            <Link key={user.id} to={Api.getUserUrl(user)}>
                                <img src={Api.getProfileImageUrl(user.image)} alt="" className="img-large img-circle" style={{ marginBottom: "4px" }}/><br/>
                            </Link>
                            <span>{user.name}</span>
                            <div style={{marginTop:"10px"}}>
                                <button className="btn btn-orange" onClick={i => this.props.acceptRequest(user)}><span className="fa fa-user-plus"/> Annehmen</button> <br/>
                                <button className="btn btn-link" style={{fontSize:"12px"}} onClick={i => this.props.declineRequest(user)}>Ignorieren</button>
                            </div>
                        </div>
                    </div>
                );
            });
    }

    render() {
      if (!this.props.currentUser) {
          return (
              <div className="container">
                  <div className="row">
                      <div className="col-md-12">
                            <h3>Du musst dich <Link to="/Login">anmelden</Link> um diese Seite zu sehen.</h3>
                      </div>
                  </div>
              </div>
          )
      }
      return (
          <div className="container">
              <div className="row">
                  <div className="col-md-6">
                      <h3>{this.props.friendRequests && 
                      <span>Nachrichten für {this.props.currentUser.name}</span>}
                      </h3>
                  </div>
              </div>
              <div className="row">
                  <div className="col-xs-12">
                    <h4>Freundschaftsanfragen 
                      {this.props.friendRequests && 
                          <span className="label label-info" style={{ display: "inline-block", marginLeft: "15px", verticalAlign: "middle" }}>{this.props.friendRequests.length}</span>
                      }
                    </h4>
                    {this.renderUsers(this.props.friendRequests)}
                  </div>
              </div>
          </div>        
      );
    }
}

export default connect(FriendRequests, store => ({
    currentUser: store.user.currentUser,
    friendRequests: store.user.friendRequests
}), dispatch => ({
    acceptRequest: from => dispatch(acceptFriendRequest(from)),
    declineRequest: from => dispatch(declineFriendRequest(from))
}));