import React from 'react';
import AutoSuggest from 'react-autosuggest';

import { connect } from 'components/StoresContext';
import { search, setSearchInput, updateSearchSuggestions } from 'actions/search';

import './react-autosuggest.css';

class SearchBox extends React.PureComponent {

    constructor(props)
    {
        super(props);

        this._handleInputChange = this._handleInputChange.bind(this);

        this._renderInputComponent = this._renderInputComponent.bind(this);
        this._renderSuggestion = this._renderSuggestion.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
    }

    _requestSuggestions(input) {
        this.props.updateSuggestions(input);
    }

    _handleInputChange(changeEvent, newValue) {
        if (newValue.method === "up" || newValue.method === "down") {
            return;
        }

        const input = newValue.newValue;
        if (newValue.method === "click" || newValue.method === "enter") {
            this.props.setInput(input);
            return;
        }

        this.props.updateSuggestions(input);
    }

    _handleKeyDown(evt) {
        if (evt.key === "Enter") {
            this.props.search();
        }
    }

    _renderInputComponent(inputProps)
    {
        return (
            <div className="input-group mb-3">
                <input {...inputProps} className="form-control" type="search" placeholder="Suche nach Titel oder Person ..." autoFocus></input>
                <span className="input-group-append input-group-text"  style={{cursor:"pointer"}} onClick={this.props.search}><span className="fa fa-search"></span></span>
            </div>
           );
    }

    _renderSuggestion(suggestion) {
        return (
            <span dangerouslySetInnerHTML={{ __html: suggestion.highlighted }} />
        );
    }

    render() {
        // input props
        const inputProps = {
            value: this.props.input,
            onChange: this._handleInputChange,
            onKeyPress: this._handleKeyDown
        };

        return (
            <div className="row">
                <div className="col-12">
                    <AutoSuggest
                        suggestions={this.props.suggestions}
                        onSuggestionsFetchRequested={() => { }}
                        onSuggestionsClearRequested={() => { }}
                        inputProps={inputProps}
                        getSuggestionValue={(suggestion) => suggestion.text}
                        renderInputComponent={this._renderInputComponent}
                        renderSuggestion={this._renderSuggestion}
                    />
                </div>
            </div>
        );
    }
}

export default connect(SearchBox, store => ({
    input: store.search.input,
    suggestions: store.search.suggestions
}), (dispatch) => ({
    updateSuggestions: input => dispatch(updateSearchSuggestions(input)),
    setInput: input => dispatch(setSearchInput(input)),
    search: () => dispatch(search())
}));

