import Configuration from 'components/Configuration';

let messageListener;

function receiveMessageFromPopup(popupWindow, resolve, reject, e) {

    window.removeEventListener("message", messageListener);

    let expectedDomain = Configuration.OAuth.Domain || document.location.origin;
    let origin = e.origin;
    if (origin !== expectedDomain) {
        console.log(`expected domain is ${expectedDomain}`);
        reject([ `Technischer Fehler. Falsche Domäne ${e.origin}.` ] );
        return;
    }

    let result = JSON.parse(decodeURIComponent(e.data));
    popupWindow.close();

    if (result.token) {
        resolve(result);
    } else {
        reject(result.errors);
    }
}

function signIn(providerId) {
    return new Promise((resolve, reject) => {
        let popupWindow = window.open(`${Configuration.OAuth.LoginUri}?provider=${providerId}`, "loginWindow");

        messageListener = receiveMessageFromPopup.bind(null, popupWindow, resolve, reject);
        window.addEventListener("message", messageListener);
    });
}

function register(providerId) {
    return new Promise((resolve, reject) => {    
        let popupWindow = window.open(`${Configuration.OAuth.RegisterUri}?provider=${providerId}`, "loginWindow");

        messageListener = receiveMessageFromPopup.bind(null, popupWindow, resolve, reject);
        window.addEventListener("message", messageListener);
    });
}

export default { signIn: signIn, register: register };