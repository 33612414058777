import React from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import Switch from 'react-switch';
import PropTypes from 'prop-types';

import { signOut } from 'actions/user';
import { loadProfile, updateSettings } from 'actions/user';

import Modal from 'components/UI/Modal';
import Api from 'components/Api';
import { connect } from 'components/StoresContext'; 

import TagPanel from './TagPanel';

import './UserSettings.css';

class UserSettings extends React.Component {

    constructor(props) {
        super(props);

        this._signOut = this._signOut.bind(this);
        this._changeCollectionVisibility = this._changeCollectionVisibility.bind(this);
        this._changeTagVisibility = this._changeTagVisibility.bind(this);
        this._changeNewsletter = this._changeNewsletter.bind(this);
        this._deleteAccount = this._deleteAccount.bind(this);
        this._showModal = this._showModal.bind(this);
        this._hideModal = this._hideModal.bind(this);

        this.state = { showModal: false };
    }

    componentWillMount() {
        this.props.load();
    }

    _showModal() {
        this.setState({showModal:true, deleting: false});
    }

    _hideModal() {
        this.setState({showModal:false});
    }

    _signOut() {
        this.props.signOut();
        this.context.router.history.push('/');
    }

    _changeCollectionVisibility(newValue) {
        var profile = this.props.userProfile;
        this.props.updateSettings(newValue.value, profile.tags, profile.newsletter);
    }

    _changeTagVisibility(newValue) {
        var profile = this.props.userProfile;
        this.props.updateSettings(profile.collection, newValue.value, profile.newsletter);
    }

    _changeNewsletter(newValue) {
        var profile = this.props.userProfile;
        this.props.updateSettings(profile.collection, profile.tags, newValue);
    }

    _deleteAccount() {
        var confirm = this._textField.value;
        if (confirm !== this.props.userProfile.name) {
            this.setState({message:"Der Benutzername stimmt nicht."});
        } 
        else {
            this.setState({deleting:true});
            Api.putJson('Token/DeleteAccount',null).then( i => {
                this.props.signOut();
                this.props.history.push(`/`);
            });
        }
    }


    render() {
        if (!this.props.userProfile || !this.props.availableTags) {
            return (<div><h3><span className="fa fa-spinner fa-pulse"></span></h3></div>);
        }
        else
        {
            var options = [
                {value:"Public", label:"Jeder"},
                {value:"Community", label:"Alle Mitglieder"},
                {value:"Friends", label:"Nur meine Freunde"},
                {value:"Private", label:"Niemand außer mir"}
            ];

            var modal =  
            <Modal size="medium" show={this.state.showModal} onClose={this._hideModal} gaName="deleteAccount">
              <Modal.Header>
                  <Modal.Title>Account dauerhaft löschen</Modal.Title>
                  <Modal.CloseButton onClick={this._hideModal}/>
     
              </Modal.Header>
              <Modal.Body>
                  {!this.state.deleting && 
                  <div>
                        <p>Bitte bestätige die dauerhafte und endgültige Löschung deines Accounts in dem du deinen Nutzernamen hier eingibst.</p>
                        <div className="form-group">
                            <input type="text" ref={e => this._textField = e } className="form-control" name="confirm" placeholder="Nutzername eingeben"></input>
                        </div>
                        {this.state.message &&
                            <div className="alert alert-danger">
                                {this.state.message}
                            </div>
                        }
                  </div>
                  }
                  {this.state.deleting && 
                        <div className="text-center">
                            <span className="fa fa-spinner fa-pulse" style={{fontSize:"48px"}}></span>
                        </div>
                  }
              </Modal.Body>
              {!this.state.deleting &&
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-secondary" onClick={this._hideModal}>Abbrechen</button>
                    <button type="button" className="btn btn-danger" onClick={this._deleteAccount}>Löschen</button>
                </Modal.Footer>
              }
          </Modal>

            return (
                <div className="container">
                    {modal}
                    <div className="row" style={{marginTop:"10px"}}>
                        <div className="col-sm">
                            <h3>{this.props.userProfile && 
                            <span>Einstellungen von {this.props.userProfile.name}</span>}
                            </h3>
                        </div>
                        <div className="col-sm">
                            <div className="btn btn-outline-secondary" onClick={this._signOut}>Abmelden</div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:"10px"}}>
                        <div className="col-12">
                           <h4>Privatsphäre</h4>
                            <p>Deine Privatsphäre liegt uns am Herzen. Deshalb kannst du kontrollieren, wer deine Filme und deine Kategorien sehen darf.</p>
                            <form className="form-horizontal">
                            <div className="form-group">
                                <label className="col-2 control-label">
                                    Filme
                                </label>
                                <div className="col-10">
                                    <Select 
                                        key="Collection"
                                        options={options}
                                        placeholder="Auswählen"
                                        onChange={this._changeCollectionVisibility}
                                        value={options.filter(({value}) => value === this.props.userProfile.collection)}
                                        clearable={false}
                                    className="kk-dropdown-wrapper-large"/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-2 control-label">
                                    Eigene Kategorien
                                </label>
                                <div className="col-10">
                                    <Select 
                                        key="Categories"
                                        options={options}
                                        placeholder="Auswählen"
                                        onChange={this._changeTagVisibility}
                                        value={options.filter(({value}) => value === this.props.userProfile.tags)}
                                        clearable={false}
                                    className="kk-dropdown-wrapper-large"/>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                           <h4>Benachrichtigungen</h4>
                            <p>Hier kannst du kontrollieren welche Benachrichtigungen du von uns bekommen willst.</p>
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <label htmlFor="newsletter-switch" className="col-sm-2 control-label">
                                        Newsletter
                                    </label>
                                    <div className="col-10">
                                        <Switch
                                            onChange={this._changeNewsletter}
                                            checked={this.props.userProfile.newsletter}
                                            id="newsletter-switch"
                                            /> 
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-12">
                            <TagPanel availableTags={this.props.availableTags}/>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom:"10px"}}>
                        <div className="col-12">
                           <h4>Account löschen</h4>
                           <p>Du willst uns verlassen? Sehr schade. Einmal hier klicken und alle deine Filme, Reviews, Listen und Kommentare sind weg. Also gut überlegen.</p>
                           <button className="btn btn-outline-danger" onClick={this._showModal}>Account löschen</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

UserSettings.contextTypes = {
    router: PropTypes.object.isRequired
  };

export default withRouter(connect(UserSettings, store => ({
    availableTags: store.user.tags,
    userProfile: store.user.profile
}), dispatch => ({
    load: () => dispatch(loadProfile()),
    updateSettings: (collectionVisibility, tagVisibility, wantNewsletter) => dispatch(updateSettings(collectionVisibility, tagVisibility, wantNewsletter)),
    signOut: () => dispatch(signOut())
})));
