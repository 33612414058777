import { NEWS_LOAD, NEWS_LOAD_SUCCESS } from 'actions/news';

const initialState = {
    loading: false,
    itemCount: 30,
    filter: {},
    haveMoreItems: true,
    items: [],
    lastUpdateMode: "replace"
}

export default function reducer(state = initialState, action) {

    switch (action.type) {

        case NEWS_LOAD:
            if (action.showIndicator) {
                state = { ...state, loading: true, itemCount: action.mode === "append" ? state.items.length + action.count : state.itemCount };
            }
            break;

        case NEWS_LOAD_SUCCESS:

            let mode = action.mode;
            let data = action.data;
            if (!data.items) {
                data.items = [];
            }

            if (state.loading) {
                state = { ...state, loading: false };
            }

            if (mode === "replace") {
                state ={ ...state, lastUpdateMode: mode, haveMoreItems: data.haveMoreItems, items: data.items };
            } else if (mode === "append" && data.items.length > 0) {
                let items = state.items.concat(data.items);
                state = { ...state, lastUpdateMode: mode, itemCount: items.length, haveMoreItems: data.haveMoreItems, items };
            } else if (mode === "prepend" && data.items.length > 0) {
                let items = state.items.concat(data.items);
                state = { ...state, lastUpdateMode: mode, itemCount: items.length, haveMoreItems: data.haveMoreItems, items };
            }
            break;
        default:
    }

    return state;
}