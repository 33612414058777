import React from 'react';

class NotFound extends React.Component {

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <h3>Diese Seite gibt es leider nicht.</h3>
                    </div>
                </div>
            </div>
        )
    }
}


export default NotFound;
