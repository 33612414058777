import React, { Component } from 'react';

class Impressum extends Component {
    render() { return ( 
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>Impressum</h1><p>
                        Andreas Kohlmaier, Peter Schrey, Siegmar Buß KinoKicks GbR <br />
                        Klara-Ziegler-Bogen 169<br />
                        81739
                        München<br />
                        E-Mail webmaster@kinokicks.de<br />
                        Tel 089/51668102 <br />
                    </p>
                    vertretungsberechtigter Gesellschafter
                    <p>
                        Andreas Kohlmaier<br />Peter Schrey<br />
                        Siegmar Buß<br />
                    </p><strong>
                        Haftung für Inhalte
                    </strong><p>
                        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
                        Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
                        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
                        nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
                        jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen
                        oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                        Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
                        der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                        Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                    </p><strong>
                        Datenschutz
                    </strong><p>
                        Die Nutzung unserer Webseite ist in der Regel ohne eine Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adresse) erhoben werden, erfolgt dies – soweit es möglich ist– immer auf freiwilliger Basis. Wir geben Ihre Daten ohne Ihre ausdrückliche Zustimmung nicht an Dritte weiter. Außerdem weisen wir Sie darauf hin, dass die Datenübertragung im Internet (wie beispielsweise bei der Kommunikation über E-Mail) Sicherheitslücken aufweisen kann. Denn ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Wir widersprechen hiermit ausdrücklich der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien. Die Betreiber dieser Seiten behalten sich ausdrücklich vor, im Fall der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, rechtliche Schritte einzuleiten.
                    </p><strong>
                        Google AdSense
                    </strong><p>
                        Diese Website benutzt Google Adsense, einen Webanzeigendienst der Google Inc., USA ("Google").
                        Google Adsense verwendet sog. "Cookies" (Textdateien), die auf Ihrem Computer gespeichert werden
                        und die eine Analyse der Benutzung der Website durch Sie ermöglicht.
                        Google Adsense verwendet auch sog. "Web Beacons" (kleine unsichtbare Grafiken) zur Sammlung von Informationen.
                        Durch die Verwendung des Web Beacons können einfache Aktionen wie der Besucherverkehr auf der Webseite a
                        ufgezeichnet und gesammelt werden. Die durch den Cookie und/oder Web Beacon erzeugten Informationen über
                        Ihre Benutzung diese Website (einschließlich Ihrer IP-Adresse) werden an einen Server von Google in den USA
                        übertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website im
                        Hinblick auf die Anzeigen auszuwerten, um Reports über die Websiteaktivitäten und Anzeigen für die Websitebetreiber
                        zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen.
                        Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben
                        oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit
                        anderen Daten der Google in Verbindung bringen. Das Speichern von Cookies auf Ihrer Festplatte und die Anzeige
                        von Web Beacons können Sie verhindern, indem Sie in Ihren Browser-Einstellungen "keine Cookies akzeptieren"
                        wählen (Im MS Internet-Explorer unter "Extras > Internetoptionen > Datenschutz > Einstellung";
                        im Firefox unter "Extras > Einstellungen > Datenschutz > Cookies"); wir weisen Sie jedoch darauf hin,
                        dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll umfänglich nutzen können.
                        Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google
                        in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.
                    </p><strong>
                        Google Analytics
                    </strong><p>
                        Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (''Google'').
                        Google Analytics verwendet sog. ''Cookies'', Textdateien, die auf Ihrem Computer gespeichert werden
                        und die eine Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den Cookie erzeugten
                        Informationen über Ihre Benutzung diese Website (einschließlich Ihrer IP-Adresse) wird an einen Server
                        von Google in den USA übertragen und dort gespeichert. Google wird diese Informationen benutzen,
                        um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber
                        zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene
                        Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen,
                        sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten.
                        Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google in Verbindung bringen.
                        Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software
                        verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                        Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären
                        Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art
                        und Weise und zu dem zuvor benannten Zweck einverstanden.
        
                        Sie können der Erhebung und Speicherung Ihrer Daten jederzeit <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="blank">widersprechen</a>.
                    </p><i><a href="http://www.agb.de">Gratis Impressum</a> von agb.de</i>
                </div>
            </div>
        </div>
        
    )}
};

export default Impressum;