import Configuration from 'components/Configuration';

// Allows use on server side.
import 'isomorphic-fetch';

class Api {

    constructor() {
        this._baseUrl = Configuration.Api.BaseUrl;

        // Allow to override the base URL with an environment variable...
        // This is needed for SSR support where the node server must access the matching
        // API service.
        if (process.env.API) {
            console.log(`Using ${process.env.API} as API base URL.`);
            this._baseUrl = process.env.API;
        }

        // The public URL is used when API URLs are generated and sent to the browser.
        // This allows to access the API e.g. via localhost which would not work from the
        // browser.
        // Defaults to be the same as the base URL, can be overridden by setting the env. variable
        // API_PUB.
        this._publicUrl = this._baseUrl;
        if (process.env.API_PUB) {
            this._publicUrl = process.env.API_PUB;
        }

        this._token = null;
    }

    // Returns true if a user is currently signed in.
    isSignedIn() {
        return this._token ? true : false;
    }

    setAccessToken(token) {
        this._token = token;
    }

    postForm(url, data) {

        let form = new FormData();
        Object.keys(data).forEach(key => {
            form.append(key, data[key]);
        });

        var headers = new Headers();
        headers.append('Accept', 'application/json');
        if (this._token) {
            headers.append('Authorization', `Bearer ${this._token}`);
        }

        return this._processJsonReply(fetch(`${this._baseUrl}${url}`, { method: 'POST', headers: headers, body: form }));
    }

    postJson(url, data) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if (this._token) {
            headers.append('Authorization', `Bearer ${this._token}`);
        }

        return this._processJsonReply(fetch(`${this._baseUrl}${url}`, { method: 'POST', headers: headers, body: JSON.stringify(data) }));
    }

    putJson(url, data) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if (this._token) {
            headers.append('Authorization', `Bearer ${this._token}`);
        }

        return this._processJsonReply(fetch(`${this._baseUrl}${url}`, { method: 'PUT', headers: headers, body: JSON.stringify(data) }));
   }

    deleteJson(url, data) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if (this._token) {
            headers.append('Authorization', `Bearer ${this._token}`);
        }

        return this._processStatusReply(fetch(`${this._baseUrl}${url}`, { method: 'DELETE', headers: headers, body: data ? JSON.stringify(data) : null }));
    }

    patchJson(url, data) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if (this._token) {
            headers.append('Authorization', `Bearer ${this._token}`);
        }

        return this._processJsonReply(fetch(`${this._baseUrl}${url}`, { method: 'PATCH', headers: headers, body: JSON.stringify(data) }));
    }

    get(url, params) {

        var headers = new Headers();
        headers.append('Accept', 'application/json');
        if (this._token) {
            headers.append('Authorization', `Bearer ${this._token}`);
        }

        if (params) {
            url += (url.indexOf('?') === -1 ? '?' : '&') + this._queryParams(params);
        }

        return this._processJsonReply(fetch(`${this._baseUrl}${url}`, { method: 'GET', headers: headers }));
    }

    // Processes a fetch that should result in a JSON document.
    // Returns a promise that is rejected in case of any problems or resolved to the parsed reply.
    _processJsonReply(fetchPromise) {
        return new Promise((resolve, reject) => {
            fetchPromise.then(response => {
                if (response.ok) {
                    response.json()
                    .then(data => resolve(data))
                    .catch(_ => {
                        let err = "Technischer Fehler beim API-Aufruf. Die Antwort ist kein JSON."; 
                        console.error(err)
                        reject([ err ]);
                    });
                } else {
                    let err = `Technischer Fehler beim API-Aufruf. ${response.status} - ${response.statusText}`;
                    console.error(err)
                    reject([ err ]);
                }
            }).catch(_ => {
                let err = "Kommunikationsfehler beim API-Aufruf.";
                console.error(err)
                reject([ err ]);
            });
        });
    }

    _processStatusReply(fetchPromise) {
        return new Promise((resolve, reject) => {
            fetchPromise.then(response => {
                if (response.ok) {
                    resolve();
                } else {
                    let err = `Technischer Fehler beim API-Aufruf. ${response.status} - ${response.statusText}`;
                    console.error(err)
                    reject([ err ]);
                }
            }).catch(_ => {
                let err = "Kommunikationsfehler beim API-Aufruf.";
                console.error(err)
                reject([ err ]);
            });
        });
    }

    getProfileImageUrl(img) {
        if (!img) {
            return '/Images/unknown.png';
        }

        if (img.startsWith('/ProfileImage/')) {
            return this._publicUrl + img.substring(1);
        }

        return img;
    }

    getMovieUrl(movie) {
        var title = movie.title.replace(/[^a-zA-Z0-9äÄöÖüÜß]/g, '_');
        return "/Filme/"+title + "-" + movie.id;
    }

    getListUrl(list) {
        var title = list.name.replace(/[^a-zA-Z0-9äÄöÖüÜß]/g, '_');
        return "/Listen/"+title + "-" + list.id;
    }

    getUserUrl(user) {
        var title = user.name.replace(/[^a-zA-Z0-9äÄöÖüÜß]/g, '_');
        return "/Nutzer/"+title + "-" + user.id;
    }

    getTmdbImage(path, size) {

        if (!path) {
            return "/Images/no-poster-"+size+".png";
        }
    
        return path.startsWith("/") ? 'https://image.tmdb.org/t/p/w'+size+path : 'https://image.tmdb.org/t/p/w'+size+'/'+path;
        
    }

    _queryParams(params) {
        return Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
    }

    // Extracts the numeric ID out of a string that contains a name and the id.
    getNumericId(idStr) {
        var sepIndex = idStr.lastIndexOf("-");
        if (sepIndex >= 0) {
            // Since tv shows have a negative index we need to handle the case that two -- are next to each other
            if (sepIndex > 0 && idStr[sepIndex - 1] === "-") {
                sepIndex = sepIndex -1;
            }

            idStr = idStr.substring(sepIndex + 1);
        }
        return parseInt(idStr, 10);
    }
}

var api = new Api();

export default api;