import React from 'react';
import { Link } from 'react-router-dom';

import Api from 'components/Api';
import { completeSignIn } from 'actions/user';
import { connect } from 'components/StoresContext';

import ente from 'images/ente.png';


class CompleteRegistration extends React.Component {

    constructor(props) {
        super(props);
        this.state = { done: false, errors: null };
    }

    componentDidMount() {
        if (this.props.match.params.params)
        {
            var vars = this.props.match.params.params.split('&');
            var userId, token;
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                if (decodeURIComponent(pair[0]) === 'userId') {
                    userId = decodeURIComponent(pair[1]);
                }
                if (decodeURIComponent(pair[0]) === 'token') {
                    token = decodeURIComponent(pair[1]);
                }
            }

            if (userId && token) {
                Api.postForm("Token/confirm_registration", { userId, token }).then(reply => {
                    if (reply.errors) {
                        this.setState({ done: true, errors: reply.errors, userId: userId });
                    } else {
                        this.props.dispatch(completeSignIn(reply.token, reply.user));
                        this.setState({ done: true, error: null, userId: userId });
                    }
                }).catch(errors => {
                    this.setState({ done: true, errors });
                });
            } else {
                this.setState=({ done: true, errors: [ "Keine gültige Confirmation- URL" ] });
            }
        }
    }

    render() {
        if (!this.state.done) {
            return null;
        }

        return (
            <div className="container">
                <div className="row" style={{marginTop:"10px"}}>
                    <div className="col-12">
                        { this.state.errors &&
                            <div className="alert alert-danger">
                                {this.state.errors}
                            </div>
                         }
                         {!this.state.errors &&
                         <div className="text-center">
                             <h5>
                                 Herzlich Willkommen! <br/>
                                 <small>Dein Account wurde erfolgreich aktiviert.</small>
                             </h5>
                        
                            <img src={ente} style={{ height: "300px" }}></img>

                       
                            <h5 style={{marginTop:"10px", marginBottom:"20px"}}><Link className="btn btn-opaque" to={"/Nutzer/" + this.state.userId + "/Filme"}>Sammle</Link> deinen ersten Film!</h5>
                          </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(CompleteRegistration, _ => ({}));