// This file defines the routes to individual pages

import { withTracker } from 'GoogleAnalytics';

import { initNews } from 'actions/news';
import { loadMovie } from 'actions/movie';
import { loadList } from 'actions/movieList';
import { load as loadHome } from 'actions/home';

import Api from 'components/Api';

import Film from 'pages/Film';
import Liste from 'pages/Liste';
import News from 'pages/News';
import User from 'pages/User';
import Filme from 'pages/Filme';
import Home from 'pages/Home.jsx';  // Extension avoids importing the .css file

import { loadUser } from 'actions/otherUser';

function fetchMovie(_, params, store)
{
    var movieId = Api.getNumericId(params["id"]);
    return loadMovie(movieId)(store.dispatch, store.getState);
}

function fetchList(_, params, store)
{
    var listId = Api.getNumericId(params["id"]);
    return loadList(listId)(store.dispatch, store.getState);
}

function fetchNews(_, _params, store)
{
    return initNews()(store.dispatch, store.getState);
}

function fetchUser(_, params, store) {
    let userId = Api.getNumericId(params["id"]);

    return loadUser(userId)(store.dispatch, store.getState);
}

function fetchHomeData(_, _params, store) {
    return loadHome()(store.dispatch, store.getState);
}

var routes = [
    { path: "/", exact: true, component: withTracker(Home), fetchData: fetchHomeData },

    { path: "/filme/:id", component: withTracker(Film), fetchData: fetchMovie },
    { path: "/listen/:id", component: withTracker(Liste), fetchData: fetchList },
    { path: "/news", component: withTracker(News), fetchData: fetchNews },

    { path: "/nutzer/:id", exact: true, component: withTracker(User), fetchData: fetchUser },
    { path: "/nutzer/:id/filme", component: withTracker(Filme), fetchData: fetchUser },

    // For compatibility
    { path: "/Lists/Show/:id", component: withTracker(Liste), fetchData: fetchList }
];

export default routes;
