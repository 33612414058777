import React from 'react';
import { updateWindowSize } from 'actions/windowSize';

import { connect } from 'react-redux';

class WindowSizeWatcher_ extends React.PureComponent {

    constructor(props) {

        super(props);

        this._handleWindowResize = this._handleWindowResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this._handleWindowResize, true);

        this._handleWindowResize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this._handleWindowResize);
    }

    _handleWindowResize() {
        this.props.dispatch(updateWindowSize(window.innerWidth, window.innerHeight));
    }

    render() {
        return null;
    }
}

let WindowSizeWatcher = connect(_ => ({}))(WindowSizeWatcher_);

export {  WindowSizeWatcher };