import React from 'react';
import Helmet from 'react-helmet';
import YouTube from 'react-youtube';

import AdaptiveBackground from 'components/AdaptiveBackground';
import Movie from 'components/Movie';
import { connect } from 'components/StoresContext';

import ReviewList from './ReviewList';
import MovieLists from './MovieLists';
import Toggles from './Toggles';
import Rating from './Rating';
import Content from './Content';
import Cast from './Cast';
import TagPanel from './TagPanel';

import Modal from 'components/UI/Modal';
import ActivityList from './ActivityList';
import { getMovieInfo } from 'actions/user';

import './MovieDetails.css';


function userKnows(userInfo)
{
    return userInfo ? userInfo.known : false;
}

class AspectRatio extends React.Component {
    render() {
        var relHeight = this.props.relHeight ? this.props.relHeight + "%" : "100%";
        var className = this.props.className;

        var outerStyle = { position: "relative", paddingBottom: relHeight };

        var style = this.props.style;
        if (!style) {
            style = {};
        }

        style.position = "absolute";
        style.top = 0;
        style.bottom = 0;
        style.left = 0;
        style.right = 0;

        outerStyle.margin = style.margin;
        delete style.margin;
        outerStyle.marginTop = style.marginTop;
        delete style.marginTop;

        return (
            <div style={outerStyle}>
                <div className={className} style={style}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

class TrailerButton extends React.Component {

    constructor(props) {
        super(props);

        this._open = this._open.bind(this);
        this._close = this._close.bind(this);

        this.state = { open: false };
    }

    _open() {
        this.setState({ open: true })
    }

    _close() {
        this.setState({ open: false })
    }

    render() {

        var modal = undefined;
        if (this.props.trailer && this.props.trailer.key) {
            modal = (
                <Modal size="large" show={this.state.open} onClose={this._close} gaName="Trailer">
                    <Modal.Header>
                        <Modal.Title>{this.props.trailer.name}</Modal.Title>
                        <Modal.CloseButton onClick={this._close} />
                    </Modal.Header>
                    <Modal.Body>
                        <AspectRatio relHeight={56.25}>
                            <YouTube videoId={this.props.trailer.key} containerClassName="youtube-container" opts={{ width: "100%", height: "100%", playerVars: { autoplay: 1 } }} />
                        </AspectRatio>
                    </Modal.Body>
                </Modal>
            );
        }

        var buttonVisibility = undefined;
        if (!this.props.trailer) {
            buttonVisibility = "hidden";
        }

        return (
            <div style={{ color: "rgb(200,200,200)" }}>
                {modal}
                <span  style={{ color: "inherit", cursor: "pointer", visibility: buttonVisibility }} onClick={this._open}>
                    <span className="fa fa-youtube-play fa-fw" aria-hidden="true" style={{ display: "inline-block", verticalAlign: "middle", fontSize: "40px" }}></span>
                    <span style={{ display: "inline-block", verticalAlign: "middle" }}><strong>Trailer ansehen</strong></span>
                </span>
               
            </div>
        );
    }
}
class MoviePanel_ extends React.PureComponent {

    renderCast() {
        var movie = this.props.movie;
      
        return (
            <React.Fragment key="cast">
                <div className="container-fluid rainbow" style={{height:"1px"}}>
                </div>

                <div className="container-fluid" style={{backgroundColor:"rgb(25,25,25)"}}>
                    <div className="container ab-dark">
                          <div className="row" style={{paddingTop:"10px", paddingBottom:"0px"}}>
                             <Cast cast={movie.cast}/>
                        </div>  
                    </div>
                </div>
           
                <div className="container-fluid rainbow" style={{height:"1px"}}>
                </div>

                <div className="container-fluid" style={{backgroundColor:"rgb(25,25,25)", height:"10px"}}>
                </div>

            </React.Fragment>
        )
    }

    renderTags() {
        var movie = this.props.movie;
      
        return (
            <React.Fragment key="tags">
                      
                <div className="container-fluid rainbow" style={{height:"1px"}}>
                </div>

                <div className="container-fluid" style={{backgroundColor:"rgb(25,25,25)"}}>
                    <div className="container ab-dark">
                          <div className="row" style={{paddingTop:"10px", paddingBottom:"0px"}}>
                             <TagPanel availableTags={this.props.availableTags} movieId={movie.id}/>  
                        
                        </div>  
                    </div>
                </div>
           
               </React.Fragment>
        )
    }


    render() {
        var movie = this.props.movie;
        var mobileMode = this.props.mobile;
        var releaseDate = undefined;
        if (movie.release_date) {
            var year = movie.release_date;
            var sep = year.indexOf("-");
            if (sep > 0) {
                year = year.slice(0, sep);
            }
            releaseDate = <small>({year})</small>;
        }

        // Skip backdrop in mobile mode
        if (mobileMode) {
            var backgroundImage = this.props.backdropMobile ? `url(${this.props.backdropMobile})` : null;

            return (
                <React.Fragment key="detail">
                <div className="container-fluid" style={{ backgroundColor: "rgb(25,25,25)", padding: "0px" }}>
                    <div className="container ab-dark" style={{ padding: "0px", backgroundImage: backgroundImage, backgroundSize: "100%", backgroundRepeat: "no-repeat" }}>
                        <div className="container-fluid" style={{
                            padding: "0px", margin: "0px", marginRight: "-2px", backgroundSize: "cover",
                            background: "-moz-linear-gradient(top, rgba(25,25,25,0) 0%,  rgba(25,25,25,0.5) 40%, rgba(25,25,25,0.9) 55%, rgba(25,25,25,1.0) 100%)",
                            background: "-webkit-linear-gradient(top, rgba(25,25,25,0) 0%, rgba(25,25,25,0.5) 40%, rgba(25,25,25,0.9) 55%, rgba(25,25,25,1.0) 100%)",
                            background: "linear-gradient(to bottom, rgba(25,25,25,0.0) 0%, rgba(25,25,25,0.5) 40%, rgba(25,25,25,0.9) 55%, rgba(25,25,25,1.0) 100%)",
                            filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#00191919', endColorstr='#191919',GradientType=0 )"
                        }}>
                            <div className="container ab-dark" style={{ paddingTop: "95px" }}>
                               
                                <div className="row ">
                                    <div className="col text-center">
                                        <h3>{movie.title} {releaseDate}<br /></h3>
                                    </div>
                                </div>
                                <div className="row" style={{marginTop:"10px"}}>
                                    <div className="col text-center">
                                        <Toggles movieId={movie.id} title={movie.title} poster={movie.poster}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col text-center" style={{ fontSize: "24px", letterSpacing: "-1px", color: "rgb(200,200,200)" }}>
                                        <Rating movieId={movie.id} title={movie.title} poster={movie.poster}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container ab-dark">
                       
                            <div className="row" style={{ paddingTop: "30px", paddingBottom:"15px" }}>
                                <div className="col-4 text-center" style={{paddingTop:"5px"}}>
                                    <img style={{ boxShadow: "0px 0px 10px #888888" }} src={Movie.poster(movie, 92)} alt="" />
                                </div>
                                <div className="col-8">
                                    <Content movie={movie} maxWords="30"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-center" style={{paddingBottom: "10px"}}>
                                    <TrailerButton trailer={this.props.trailer} />
                                </div>
                            </div>
                        </div>
                        
                        <div className="container-fluid rainbow" style={{height:"1px"}}>
                        </div>

                        <div className="container ab-dark">
                            <div className="row" style={{paddingTop:"10px", paddingBottom:"0px"}}>
                                <Cast small={true} cast={movie.cast}/>
                            </div>  
                        </div>
                    </div>
                </div>
                    {this.props.known &&        
                        <TagPanel mobile={true} availableTags={this.props.availableTags} movieId={movie.id}/>  
                    }

                
               </React.Fragment>
            );
        }
        else {
            var backgroundImage = this.props.backdrop ? `url(${this.props.backdrop})` : null;

            return (
                <div>
                <div className="container-fluid" style={{ backgroundColor: "rgb(25,25,25)", padding: "0px" }}>
                    <div className="container ab-dark" style={{ padding: "0px", backgroundImage: backgroundImage, backgroundSize: "100%", backgroundRepeat: "no-repeat" }}>
                        <div className="container-fluid" style={{
                            padding: "0px", margin: "0px", marginRight: "-2px", backgroundSize: "cover",
                            background: "-moz-linear-gradient(top, rgba(25,25,25,0) 0%,  rgba(25,25,25,0.3) 40%, rgba(25,25,25,0.9) 55%, rgba(25,25,25,1.0) 100%)",
                            background: "-webkit-linear-gradient(top, rgba(25,25,25,0) 0%, rgba(25,25,25,0.3) 40%, rgba(25,25,25,0.9) 55%, rgba(25,25,25,1.0) 100%)",
                            background: "linear-gradient(to bottom, rgba(25,25,25,0.0) 0%, rgba(25,25,25,0.3) 40%, rgba(25,25,25,0.9) 55%, rgba(25,25,25,1.0) 100%)",
                            filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#00191919', endColorstr='#191919',GradientType=0 )"
                        }}>
                            <div className="container ab-dark" style={{ paddingTop: "200px" }}>
                                <div className="row" style={{ paddingTop: "30px", paddingBottom:"15px" }}>
                                    <div className="col-3 text-center" style={{paddingTop:"20px"}}>
                                        <img style={{ boxShadow: "0px 0px 10px #888888" }} src={Movie.poster(movie, 154)} alt="" />
                                    </div>
                                    <div className="col-6 text-center">
                                        <div className="row ">
                                            <div className="col">
                                                <h3>{movie.title} {releaseDate}<br /></h3>
                                            </div>
                                        </div>
                                         <div className="row text-justify" style={{minHeight:"145px", overflow:"hidden"}}>
                                            <div className="col">
                                                 <Content movie={movie} maxWords="75"/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col text-center" style={{paddingTop: "15px"}}>
                                            <TrailerButton trailer={this.props.trailer} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 text-center" style={{paddingTop:"75px"}}>
                                       <div className="row">
                                            <div className="col">
                                                <Toggles movieId={movie.id} title={movie.title} poster={movie.poster}/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col text-center" style={{ fontSize: "24px", letterSpacing: "-1px", color: "rgb(200,200,200)" }}>
                                                <Rating movieId={movie.id} title={movie.title} poster={movie.poster}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.known &&        
                    <TagPanel availableTags={this.props.availableTags} movieId={movie.id}/>  
                }

                {this.renderCast()}

            </div>
            );
        }
    }
}

var MoviePanel = connect(MoviePanel_, function (store) {
    var movie = store.movieStore.movieInfo;

    return { 
        movie: movie, 
        poster: Movie.poster(movie, 185), 
        backdrop: Movie.backdrop(movie, 1280), 
        backdropMobile: Movie.backdrop(movie, 780), 
      
        trailer: store.movieStore.trailer,
        availableTags: store.user.tags,
 
        mobile: store.windowSize.mobile,
        known: userKnows(getMovieInfo(store, store.movieStore.movieInfo.id)) };
});

class MovieReviews_ extends React.PureComponent {

    render() {
        if (!this.props.items) {
            return null;
        }

        return <ReviewList reviews={this.props.items} mode="AuthorAndTime" pageSize={5} movieId={this.props.movie.id} movieTitle={this.props.movie.title} currentUser={this.props.user} />
    }
}

var MovieReviews = connect(MovieReviews_, function (store) {
    var movie = store.movieStore.movieInfo;
    return {
        movie: movie,
        items: store.movieStore.reviews,
        user: store.user.currentUser,
        trailer: store.movieStore.trailer
    };
});

class MovieDetails extends React.PureComponent {

    render() {

        var movie = this.props.movie;

        var title = undefined;
        if (movie && movie.title) {
            var year = undefined;
            if (movie.release_date) {
                year = movie.release_date;
                var sep = year.indexOf("-");
                if (sep > 0) {
                    year = year.slice(0, sep);
                }
                year = ` (${year})`;
            }

            title = (
                <Helmet>
                    <title>{movie.title + " | kinokicks.de"}</title>
                    <meta name="description" content={movie.description} />

                    <meta property="og:type" content="video.movie" />
                    <meta property="og:title" content={movie.title + year} />
                    <meta property="og:url" content={"https://kinokicks.de" + Movie.url(movie)} />
                    <meta property="og:description" content={movie.description} />
                    <meta property="og:site_name" content="kinokicks.de" />
                    <meta property="og:image" content={Movie.backdrop(movie, 1280)} />

                </Helmet>
            );
        }

        return (
            <div>
                {title}
                <MoviePanel />
                <div className="container-fluid">
                    <div className="container" style={{ marginBottom: "20px" }}>
                    <div className="row">
                        <div className="col-lg-5 text-left">
                            <div className="row">
                                <MovieLists />
                            </div>
                            <div className="row">
                                <ActivityList />
                            </div>
                        </div>
                        <div className="col-lg-7 text-left">
                            <MovieReviews />
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default connect(MovieDetails, store => ({
    movie: store.movieStore.movieInfo
}));
