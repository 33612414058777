import React from 'react';
import RequireLogin from 'components/RequireLogin';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { connect } from 'components/StoresContext';

import './Header.css';

class Header extends React.PureComponent {

    constructor(props) {
        super(props);

        this._signIn = this._signIn.bind(this);
        this._register = this._register.bind(this);
        this._toggleMenu = this._toggleMenu.bind(this);
        this._linkClicked = this._linkClicked.bind(this);

        this.state = { menuState: "collapsed" };
    }

    _signIn() {
        // Open the login page...
        RequireLogin.showLogin();
       
    }

    _register() {
        RequireLogin.showRegister();
    }

    _toggleMenu() {
        if (this.state.menuState === "collapsed") {
            this.setState({ menuState: "expanded" });
        } else if (this.state.menuState === "expanded") {
            this.setState({ menuState: "collapsed" });
        }
    }

    _linkClicked() {
        this.setState({ menuState: "collapsed" });
    }

    render() {

        var menuClass = "navbar-collapse collapse show"; 
        switch (this.state.menuState) {
            case "collapsed":
                menuClass = "navbar-collapse collapse";
                break;
            case "expanding":
                menuClass = "collapsing navbar-collapse";
                break;
            case "expanded":
                menuClass = "navbar-collapse collapse show";
                break;
            case "collapsing":
                menuClass = "collapsing navbar-collapse";
                break;
            default:
        }

        var newsClass = classnames("nav-item", "c1", this.props.location.pathname === "/News" ? "active_c1" : null);
        var listsClass = classnames("nav-item", "c2", this.props.location.pathname === "/Listen" ? "active_c2" : null);
        var moviesClass = classnames({
            "nav-item": true,
            "c3": true,
            "active_c3": this.props.currentUser &&  this.props.location.pathname === `/Nutzer/${this.props.currentUser.id}/Filme`
        });
        var profileClass = classnames({
            "nav-item": true,
            "c4": true,
            "active_c4": this.props.currentUser &&  this.props.location.pathname === `/Nutzer/${this.props.currentUser.id}`
        });
        var settingsClass = classnames("nav-item", "c9", this.props.location.pathname === "/Settings" ? "active_c9" : null);

        return (
            <div className="container-fluid ab-dark" style={{ backgroundColor: "rgb(25, 25, 25" }}>
            <div className="container" style={{padding:"0px"}}>
              
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark" style={{paddingRight:"0px"}}>

                    <Link to="/" className="navbar-brand" style={{ marginRight: "10px" }}>
                        <img src="/Images/logo/Logo-white-138x100.png" alt="" style={{ height: "25px" }} />
                    </Link>
                    <Link to="/" className="navbar-brand">KINOKICKS</Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" onClick={this._toggleMenu} aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={menuClass}>
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/Search" onClick={this._linkClicked}>
                                <span className="fa fa-search"></span>
                            </Link>
                        </li>
                        <li className={newsClass}>
                            <Link className="nav-link" to="/News" onClick={this._linkClicked}>NEWS</Link>
                        </li>
                        <li className={listsClass}>
                             <Link className="nav-link" to="/Listen" onClick={this._linkClicked}>LISTEN</Link>
                        </li>
                        {this.props.currentUser && (
                            <li className={moviesClass}>
                                <Link className="nav-link" to={"/Nutzer/" + this.props.currentUser.id + "/Filme"} onClick={this._linkClicked}>MEINE FILME</Link>
                            </li>
                        )}

                        {this.props.currentUser && (
                            <li className={profileClass}>
                                <Link className="nav-link" to={"/Nutzer/" + this.props.currentUser.id} onClick={this._linkClicked}>PROFIL</Link>
                            </li>
                        )}

                        {this.props.currentUser &&
                            <li className={settingsClass}>
                                <Link className="nav-link" to="/Settings" onClick={this._linkClicked} >
                                    <span className="fa fa-cog" />
                                </Link>
                            </li>
                        }

                        {!this.props.currentUser &&
                            <div className="ab-dark">
                                <button style={{ marginLeft: "10px" }} className="btn btn-orange navbar-btn" type="button" onClick={this._register}>
                                    REGISTRIEREN
                                    </button>
                                <button style={{ marginLeft: "10px" }} className="btn btn-opaque navbar-btn" type="button" onClick={this._signIn}>
                                    ANMELDEN
                                    </button>
                            </div>
                        }

                        {this.props.currentUser && this.props.friendRequests && this.props.friendRequests.length > 0 &&

                            <Link className="nav-item nav-link c9" to="/FriendRequests" onClick={this._linkClicked} >
                                <span className="fa fa-envelope-o" />
                                <span className="badge" style={{ marginLeft: "-2px", marginTop: "-12px" }}>{this.props.friendRequests.length}</span>
                            </Link>

                        }
                    </ul>
                    </div>
                </nav>
                </div>
            </div>
         );
    }
}

// Needed for the login button to work.

export default withRouter(connect(Header, store => ({
    currentUser: store.user.currentUser,
    friendRequests: store.user.friendRequests
})));
