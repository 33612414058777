import { HOME_LOAD_MOVIESTATS_SUCCESS, HOME_LOAD_ACTIVEUSERS_SUCCESS, HOME_LOAD_ACTIVELISTS_SUCCESS } from 'actions/home';

const initialState = {
    movieStats: null,
    activeLists: null,
    activeUsers: null
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case HOME_LOAD_MOVIESTATS_SUCCESS:
            state = { ...state, movieStats: action.data };
            break;
        case HOME_LOAD_ACTIVEUSERS_SUCCESS:
            state = { ...state, activeUsers: action.data };
            break;
        case HOME_LOAD_ACTIVELISTS_SUCCESS:
            state = { ...state, activeLists: action.data };
            break;
    }

    return state;
}
