import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import classnames from 'classnames';

import AdaptiveBackground from 'components/AdaptiveBackground';

import Api from 'components/Api';

import {VoteBox, VoteUp, VoteDown} from 'components/VoteBox';
import Movie from 'components/Movie';

import CommentList from 'components/CommentList';
import CommentForm from 'components/CommentForm';
import RequireLogin from 'components/RequireLogin';

import { connect } from 'components/StoresContext';
import { removeMovieFromList, voteMovieOnList, moveMovieOnList, commentMovieOnList } from 'actions/movieList';

// Props:
//   index: the index number to show
//   poster: the poster image to show
class MovieImage1 extends React.PureComponent {

    constructor(props) {
        super(props);

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick() {
        this.props.history.push(this.props.url);
    }

    render() {
        var mobileMode = this.props.mobile;
        return (
            <div className={mobileMode ? null : "media-left"} style={{ cursor:"pointer", whiteSpace:"nowrap" }} onClick={this._handleClick}>
                <div className="d-none d-lg-block text-right" style={{ display:"inline-block", fontSize:"28px", marginRight:"10px", width:"40px"}}>
                    {this.props.index}
                </div>
                <img src={this.props.poster} alt="" style={{ width: "92px" }} />
            </div>
        );
    }
}

var MovieImage = withRouter(MovieImage1);

class UserImg extends React.PureComponent {
    render() {
        var i = this.props.user;
        return (
            <Link to={Api.getUserUrl(i)}>
                <img 
                    key={i.id}
                    src={Api.getProfileImageUrl(i.img)} 
                    alt={i.name} 
                    style={{marginRight:"5px"}}
                    className="img-circle img-small"/>
            </Link>
        );    
    }
}

class MovieItem extends React.PureComponent {

    constructor(props) {
        super(props);

        this._handleNewComment = this._handleNewComment.bind(this);
        this._handleVote = this._handleVote.bind(this);
        this._handleDelete = this._handleDelete.bind(this);
        this._toggleComments = this._toggleComments.bind(this);
        this.state = { showComments: false };
    }

    _handleDelete(e) {
        e.stopPropagation();
        e.preventDefault();

        this.props.removeMovie(this.props.item.movie.id);
    }

    _handleVote(vote) {
        RequireLogin.do(() => {
            if (this.props.editing) {
                this.props.moveMovie(this.props.item.movie.id, -vote);
            } else {
                this.props.voteMovie(this.props.item.movie.id, vote);
            }
        });
    }

    _handleNewComment(text) {
        RequireLogin.do(() => {
            this.props.addMovieComment(this.props.item.movie.id, text);
        });
    }

    _toggleComments() {
        this.setState({ showComments: !this.state.showComments });
    }

    render() {

        var item = this.props.item;

        var releaseDate = undefined;
        if (item.movie.year) {
            releaseDate = <small>&nbsp;({item.movie.year})</small>;
        }

           
        var index = this.props.index + 1;

        var score = item.score;
        var vote = item.vote;
        var user = this.props.currentUser;

        // Delete button only when editing or when movie was added by user and does not yet have activity
        var showDeleteBtn = (this.props.editing || (user && this.props.item.added_by && this.props.item.comments && this.props.item.added_by.id === user.id && this.props.item.votes === 0 && this.props.item.comments.length === 0)); 
        
        if (this.props.editing) {
            vote = undefined;
        }

        var mobileMode = this.props.mobile;
        var commentCount = item.comments ? item.comments.length : 0;
        
        if (mobileMode) {
            return this._renderMobile(index, item, releaseDate, showDeleteBtn, commentCount, score, vote, user);
        }
        else
        {
            return this._renderDesktop(index, item, releaseDate, showDeleteBtn, commentCount, score, vote, user);
        }
    }

    _renderMobile(index, item, releaseDate, showDeleteBtn, commentCount, score, vote, user) {
        var upVotes = this.props.item.upvoted_by ? this.props.item.upvoted_by.length : 0;
        var downVotes = this.props.item.downvoted_by ? this.props.item.downvoted_by.length : 0;

        return (
        
            <div className={classnames({ "first": this.props.isFirst})} style={{marginBottom:"10px", paddingBottom:"10px", borderBottom:"1px solid #678"}}>
                <div className="row" >
                    <div className="col-4">
                        <MovieImage index={index} poster={Movie.poster(this.props.item.movie, 92)} url={Movie.url(this.props.item.movie)} mobile={this.props.mobile}/>
                    </div>
                    <div className="col-8">
                        <div className="row" style={{height:"105px"}}>
                            <div className="col">
                                <h5>
                                    {item.movie.title}
                                    {releaseDate}
                                    {showDeleteBtn && <small className="pull-right"><a href="##" onClick={this._handleDelete}>Löschen</a></small>}
                                </h5>
                            </div>
                        </div>
                        <div className="row" style={{marginLeft:"0px", marginRight:"0px", marginTop:"2px"}}>
                            <div className="col-6 text-center" style={{paddingLeft:"0px", paddingRight:"0px", borderRight:"1px solid rgb(128,128,128)"}}>
                                    <span style={{fontSize:"30px", color: "#13ba00"}} className="align-middle fa fa-caret-up"></span>
                                    <span className="align-middle" style={{marginLeft:"8px"}}>{upVotes}</span>
                                    <span style={{fontSize:"30px", color:"#f00", marginLeft:"10px"}} className="align-middle fa fa-caret-down"></span>
                                    <span className="align-middle" style={{marginLeft:"8px"}}>{downVotes}</span>
                            </div>
                            <div className="col-6 text-center" style={{paddingTop:"3px"}}>
                                  <span style={{fontSize:"20px"}} className="align-middle fa fa-comment-o"></span>
                                  <span className="align-middle" style={{marginLeft:"8px"}}>{commentCount}</span>
                                
                            </div>
                        </div>
                        
                       
                    </div>
    
                </div>
              
                        <div className="row" style={{marginLeft:"0px", marginRight:"0px", marginTop:"2px", border:"1px solid rgb(128,128,128)"}}>
                               <div className="col-6 text-left" style={{paddingTop:"8px", paddingLeft:"20px", paddingRight:"0px"}}>
                                Deine Stimme
                            </div>
                            <div className="col-6 text-right" style={{marginTop:"-8px", paddingLeft:"0px", paddingRight:"20px", height:"44px"}}>
                                    <VoteUp voting={vote} onVote={this._handleVote}/>
                                    <div style={{display:"inline-block", width:"10px"}}></div>
                                    <VoteDown voting={vote} onVote={this._handleVote}/>
                              </div>
                   
                        </div>
                <div className="row">
                    <div className="col-12" style={{marginTop:"10px"}}>
                        <CommentList comments={this.props.item.comments} />
                        <CommentForm currentUser={user} onSubmitted={this._handleNewComment} prompt="Dein Kommentar"/>
                    </div>
                </div>
            </div>
        );
    }

    _renderDesktop(index, item, releaseDate, showDeleteBtn, commentCount, score, vote, user) {

        var backdrop = Movie.backdrop(this.props.item.movie);
        var poster = Movie.poster(this.props.item.movie, 500);
        var bgImage  = backdrop ? `url(${backdrop})` : `url(${poster})`;
        var height = this.props.tablet ? "370px" : "450px";
        var marginTop = this.props.tablet ? "280px" : "360px";
        var upVotes = this.props.item.upvoted_by ? this.props.item.upvoted_by.length : 0;
        var downVotes = this.props.item.downvoted_by ? this.props.item.downvoted_by.length : 0;

        return (
            <div>
                <div className={classnames({ "ab-dark": true, "first": this.props.isFirst, "movielistitem-desktop-image": true })}
                     style={{ backgroundImage: bgImage, height: height }}>
                    <div className="container-fluid movielistitem-desktop-overlay" style={{height: height}}>
                        <div className="row" style={{marginLeft:"0px", marginRight:"0px"}}>
                            <div className="col-1"></div>
                            <div className="col-10 ">
                                <h3 className="text-center" style={{paddingTop:"20px"}}>
                                    <Link to={Movie.url(this.props.item.movie)} style={{color:"inherit"}}> 
                                        {item.movie.title}
                                    </Link>
                                </h3>
                            </div>
                            {showDeleteBtn &&
                                <div className="col-1 text-right" style={{cursor:"pointer"}} onClick={this._handleDelete}>
                                <h3 style={{ marginTop:"20px", marginRight:"25px" }}><span className="fa fa-trash-o"/></h3>
                                </div>
                            }
                        </div>
                        {false && 
                        <div className="row" style={{marginLeft:"0px", marginRight:"0px"}}>
                           <div className="col-1" style={{backgroundColor:"rgba(0,0,0,0.5)", marginTop:"90px" }}>
                                <h3 style={{paddingTop:"50px"}}>
                                    {index}
                                </h3>
                            </div>
                            <div className="col-10">

                            </div>
                            <div className="col-1" style={{backgroundColor:"rgba(0,0,0,0.5)", marginTop:"90px"}}>
                                <VoteBox score={score} voting={vote} onVote={this._handleVote}/>
                            </div>
                        </div>
    }   
                        <div className="row" style={{marginLeft:"0px", marginRight:"0px", marginTop: marginTop, borderBottom:"1px solid  rgb(200,200,200)"}}>
                            <div className="col-4">
                                <span style={{fontSize:"30px", color: "#13ba00"}} className="align-middle fa fa-caret-up"></span>
                                <span className="align-middle" style={{marginLeft:"3px"}}>{upVotes}</span>
                                <span style={{fontSize:"30px", color:"#f00", marginLeft:"10px"}} className="align-middle fa fa-caret-down"></span>
                                <span className="align-middle" style={{marginLeft:"3px"}}>{downVotes}</span>
                            </div>
                            <div className="col-4 text-center" style={{borderLeft:"1px solid  rgb(200,200,200)", borderRight:"1px solid rgb(200,200,200)"}}>
                                <span>{commentCount} Kommentare</span>
                            </div>
                            <div className="col-4 text-right" style={{marginTop:"-15px"}}>
                                <div className="align-top" style={{display:"inline-block", paddingTop:"15px", paddingRight:"20px"}}>
                                    <span>Deine Stimme</span>
                                </div>
                             
                                <VoteUp voting={vote} onVote={this._handleVote}/>
                                <div style={{display:"inline-block", width:"10px"}}></div>
                                <VoteDown voting={vote} onVote={this._handleVote}/>
                
                            </div>
                            {false && 
                                <div className="col text-right" style={{cursor:"pointer"}} onClick={this._toggleComments}>
                                    <h3  style={{ marginTop:"0px", marginRight:"7px" }}> 
                                        {!this.state.showComments &&
                                            <span className="fa fa-angle-down"></span>
                                        }
                                        {this.state.showComments &&
                                            <span className="fa fa-angle-up"></span>
                                        }
                                    </h3>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {true &&
                    <AdaptiveBackground image={Movie.backdrop(this.props.item.movie)}>
                        
                        <div className="container-fluid movielistitem-desktop-comments">
                            
                            <div className="media" style={{ marginTop:"0px", paddingTop: "10px", paddingBottom:"10px"}}>
                                <div className="col">
                                    <CommentList comments={this.props.item.comments} />
                                    <CommentForm currentUser={user} onSubmitted={this._handleNewComment} />
                                </div>
                            </div>
                            {false && 
                            <div className="row">
                                <div className="col">
                                    {item.upvoted_by && item.upvoted_by.length > 0 && 
                                        <span style={{ display:"inline-block", marginLeft:"15px", marginRight:"10px"}}>Dafür </span>
                                    }
                                    {item.upvoted_by && item.upvoted_by.map( i => <UserImg key={i.id} user={i}/> )}
                                </div>
                                <div className="col">
                                    {item.downvoted_by && item.downvoted_by.length > 0 && 
                                        <span style={{ display:"inline-block", marginLeft:"15px", marginRight:"10px"}}>Dagegen </span>
                                    }   
                                    {item.downvoted_by && item.downvoted_by.map( i => <UserImg key={i.id} user={i}/> )}
                                </div>
                            </div>
                             }
                        </div>
                        
                    </AdaptiveBackground>
                }
            </div>
        );
    }
}

export default connect(MovieItem, state => ({
    currentUser: state.user.currentUser,
    mobile: state.windowSize.mobile,
    tablet: state.windowSize.tablet,
    editing: state.listStore.editing
}), dispatch => ({
    moveMovie: (movieId, direction) => dispatch(moveMovieOnList(movieId, direction)),
    voteMovie: (movieId, vote) => dispatch(voteMovieOnList(movieId, vote)),
    addMovieComment: (movieId, text) => dispatch(commentMovieOnList(movieId, text)),
    removeMovie: movieId => dispatch(removeMovieFromList(movieId))
}));
