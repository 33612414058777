import React from 'react';
import { Link } from 'react-router-dom';
import FlipMove from 'react-flip-move';
import classNames from 'classnames';
 
import RelTime from 'components/RelTime';
import VoteBox from 'components/VoteBox';
import Modal from 'components/UI/Modal';
import Api from 'components/Api';
import User from 'components/User';
import CommentList from 'components/CommentList';
import CommentForm from 'components/CommentForm';
import RequireLogin from 'components/RequireLogin';
import { connect } from 'components/StoresContext';

import './ReviewList.css';
import { voteForReview, createReviewComment, deleteMovieReview, createMovieReview } from 'actions/reviews';

class Item extends React.Component {

    constructor(props) {
        super(props);

        this._handleCommentSubmitted = this._handleCommentSubmitted.bind(this);
        this._handleDelete = this._handleDelete.bind(this);
        this._handleVote = this._handleVote.bind(this);
    }

    _handleVote(vote) {
        this.props.onVote(this.props.review, vote);
    }

    _handleCommentSubmitted(comment) {
        this.props.onCommentSubmitted(this.props.review, comment);
    }

    _handleDelete(e) {
        e.stopPropagation();
        e.preventDefault();
        this.props.onDeleteReview(this.props.review);
    }

    _renderDeleteButton() {
        if (this.props.currentUser && this.props.review.author && this.props.currentUser.id === this.props.review.author.id) {
            return (<span>&nbsp;(<a href="##" onClick={this._handleDelete}>Löschen</a>)</span>);
        } else {
            return(<span></span>);
        }
    }

    _renderPicture() {
        if (this.props.mode === "AuthorAndTime") {

            var user = this.props.review.author;

            var userUrl = User.url(user);
            var userImg = User.img(user);

            return (
                <Link to={userUrl}>
                    <img src={userImg} alt="" className="img-medium img-circle"/>
                </Link>
            );
        } else {
            return (
                <Link to={Api.getMovieUrl(this.props.review.movie)}>
                    <img src={Api.getTmdbImage(this.props.review.movie.poster,92)} alt="" style={{ width: "92px" }}/>
                </Link>
            );
        }
    }

    _renderTitle() {
        if (this.props.mode === "AuthorAndTime") {
            return (<Link to={User.url(this.props.review.author)}>{this.props.review.author.name}</Link>);
        } else {
            return (<Link to={Api.getMovieUrl(this.props.review.movie)}>{this.props.review.movie.title}</Link>);
        }
    }

    render() {
        var userLabel = undefined;
        if (this.props.mode === "MovieAndAuthor")
        {
            userLabel = (<span style={{ display: "inline-block", paddingLeft: "5px" } }>von&nbsp;
                            <Link to={this.props.review.author.url}>{this.props.review.author.name}</Link>
                        </span>);
        }

        var timeLabel = undefined;
        if (this.props.mode === "MovieAndTime" || this.props.mode === "AuthorAndTime") {
            timeLabel = <RelTime style={{ display: "inline-block", paddingLeft: "5px" }} time={this.props.review.created_at}/>;
        }

        var cname = classNames({"media item": true, "first": this.props.index === 0 });

        return (
            <div className={cname}>
                <div className="media-left" style={{paddingRight:"10px"}}>
                    {this._renderPicture()}
                </div>
                <div className="media-body">
                    <div className="media-heading">
                        <h6 style={{ marginTop:"0" }}>{this._renderTitle()}<small> {timeLabel}{userLabel}{this._renderDeleteButton()}</small></h6>
                    </div>
                    <div style={{ marginBottom:"10px"}}>
                        {this.props.review.text}
                    </div>
                    <CommentList comments={this.props.review.comments}/>
                    <CommentForm onSubmitted={this._handleCommentSubmitted} currentUser={this.props.currentUser} prompt="Dein Kommentar zu diesem Review" />                    
                </div>
                <VoteBox score={this.props.review.score} voting={this.props.review.vote} onVote={this._handleVote}/>
            </div>
        );
    }
}

class CreateReviewModal_ extends React.PureComponent {

    constructor(props) {
        super(props);

        this._publish = this._publish.bind(this);
    }

    _publish() {
        this.props.createMovieReview(this.props.movieId, this._textField.value.trim());
        this.props.onClose();
    }

    render() {

        var placeholder = `Deine Kurzkritik zu ${this.props.movieTitle}`;

        return (
            <Modal size="large" show={this.props.show} onClose={this.props.onClose} gaName="newReview">
                <Modal.Header>
                    <Modal.Title>Review verfassen</Modal.Title>
                    <Modal.CloseButton onClick={this.props.onClose}/>
  
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <textarea ref={e => this._textField = e } className="form-control" rows="5" name="comment" placeholder={placeholder}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-secondary" onClick={this.props.onClose}>Abbrechen</button>
                    <button type="button" className="btn btn-primary" onClick={this._publish}>Veröffentlichen</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const CreateReviewModal = connect(CreateReviewModal_, _ => ({}), (dispatch, store) => ({
    createMovieReview: (movieId, text) => dispatch(createMovieReview(movieId, text))
}))

/// Displays a list of reviews with comments on them.
///
/// Properties:
///
/// reviews: The list of reviews.
/// currentUser: The currently logged in user. Needed to support voting and addition of comments
/// mode: The display mode for the reviews. "AuthorAndTime" means to show the pic and name of the author plus the creation time. "MovieAndTime" means to show the pic and title of the movie plus the
///       creation time. "MovieAndAuthor" means to show the pic and title of the movie plus the author's name.
/// hideHeader:
/// className:
/// style:
/// pageSize:
/// onCreateReview:
/// onVote:
/// onDeleteReview:
///
class ReviewList extends React.Component {

    constructor(props) {
        super(props);

        this._handleCreateReview = this._handleCreateReview.bind(this);
        this._handleDeleteReview = this._handleDeleteReview.bind(this);
        this._handleVote = this._handleVote.bind(this);
        this._handleCommentSubmitted = this._handleCommentSubmitted.bind(this);
        this._closeModal = this._closeModal.bind(this);
        this._showMore = this._showMore.bind(this);

        this.state = { maxCount: this.props.pageSize, showCreateModal: false };
    }

    _showMore(e) {
        e.preventDefault();

        this.setState({ maxCount: this.state.maxCount + this.props.pageSize });
    }

    _handleVote(review, vote) {
        RequireLogin.do(() => {
            this.props.voteReview(review.id, vote);
        });
    }
        
    _handleCommentSubmitted(review, comment) {
        RequireLogin.do(() => {
            this.props.createReviewComment(review.id, comment);
        })
    }

    _handleDeleteReview(review) {
        RequireLogin.do(() => {
            this.props.deleteMovieReview(review.id)
        });
    }

    _handleCreateReview() {
        RequireLogin.do(() => {
            this.setState({ showCreateModal: true });
        });
    }

    _closeModal() {
        this.setState({ showCreateModal: false });
    }

    _renderMoreButton() {
        if (this.props.reviews.length > this.state.maxCount) {
            return (
                <div className="text-right" style={{ marginTop: "5px" }}>
                    <a href="##" onClick={this._showMore}>Mehr anzeigen ...</a>
                </div>
            );
        }
        else {
            return null;
        }
    }

    _renderItems() {

        var reviewNodes = this.props.reviews.slice(0, this.state.maxCount).map((entry, index) => {
            return (
                <Item key={entry.id} index={index} review={entry} mode={this.props.mode} onVote={this._handleVote} 
                      currentUser={this.props.currentUser} onCommentSubmitted={this._handleCommentSubmitted} onDeleteReview={this._handleDeleteReview}/>
            );
        });

        if (reviewNodes.length > 0) {
            return reviewNodes;
        } else {
            if (this.props.mode === "AuthorAndTime") {
                return (
                    <div>
                        Sei der erste und schreibe ein Review zu diesem Film!<br/>
                    </div>
                );
            }
        }

        return null;
    }

    render() {
        var header = undefined;

        if (!this.props.hideHeader)
        {
            var count = undefined;
            if (this.props.reviews && !this.props.hideCount) {
                count = <span className="badge badge-info" style={{ "display":"inline-block", "marginLeft":"5px", "verticalAlign":"middle"}}>{this.props.reviews.length}</span>;
            }
            if (this.props.mode === "AuthorAndTime") {
                header = (<div className="row" style={{marginTop:"10px"}}>
                            <div className="col-6 text-left">
                                <h3 style={{display:"inline-block"}}>Reviews{count}</h3>
                            </div>
                            <div className="col-6 text-right">
                                  <button type="button" className="btn btn-orange" onClick={this._handleCreateReview} style={{ display:"inline-block",  marginLeft: "20px", width:"100px" } }>
                                      <span className="fa fa-plus"></span>                                      
                                      <span style={{ display:"inline-block", marginLeft: "5px" }}>Review</span> 
                                  </button>
                            </div>
                            </div>);
            }
            else if (this.props.title) {
                header = (
                    <h3>{this.props.title} {count}</h3>
                );
            }
        }

        var items = (
            <FlipMove className="items" enterAnimation="accordionVertical" leaveAnimation="accordionVertical">
                {this._renderItems()}
            </FlipMove>
        );
        var moreButton = this._renderMoreButton();

        return (
            <div className={this.props.className} style={this.props.style}>
                <CreateReviewModal show={this.state.showCreateModal} movieId={this.props.movieId} movieTitle={this.props.movieTitle} onClose={this._closeModal}/>
                {header}
                {items}
                {moreButton}
            </div>
        );
    }
}

export default connect(ReviewList, _ => ({}), dispatch => ({
    voteReview: (reviewId, vote) => dispatch(voteForReview(reviewId, vote)),
    createReviewComment: (reviewId, comment) => dispatch(createReviewComment(reviewId, comment)),
    deleteMovieReview: reviewId => dispatch(deleteMovieReview(reviewId))
}));
