import React from 'react';

import Raty from 'components/Raty';
import CssScaler from 'components/CssScaler';
import { withMeasure } from 'components/Measure';

import MovieItem from './MovieItem';


function calculateTiles(availableWidth, targetWidth, spacing)
{
    // The number of tiles per row
    let n = Math.round((availableWidth + spacing) / (targetWidth + spacing));

    if (n < 3) {
        n = 3;
    }

    const tileWidth = (availableWidth - (n-1) * spacing) / n;

    return { tilesPerRow: n, tileWidth: tileWidth, scaleFactor: tileWidth / targetWidth };
}


class GroupHeader extends React.PureComponent {

    constructor(props) {
        super(props);
        this._toggleIsCollapsed = this._toggleIsCollapsed.bind(this);
    }

    _toggleIsCollapsed() {
        this.props.toggle(this.props.id);
    }

    render() {
        var title = this.props.title;
        var stars = this.props.stars;
        var count = this.props.count;
        var collapsed = this.props.collapsed;

        if (count > 1) {
            count = count + " Filme";
        }
        else {
            count = "1 Film";
        }

        var titleElem = undefined;
        if (stars) {
            titleElem = <Raty className="kk-ml-groupheader-name" readOnly={true} score={stars} />;
        }
        else if (title) {
            titleElem = <span className="kk-ml-groupheader-name">{title}</span>;
        }
    
        var icon = collapsed ? "fa fa-fw fa-caret-left" : "fa fa-fw fa-caret-down";

        return (
            <div style={{ overflow: "auto", float: "left", width: "100%" }}>
                <div className="kk-ml-groupheader" onClick={this._toggleIsCollapsed} style={{ cursor: "pointer" }}>
                    <h4>
                        {titleElem}
                        <span className="kk-ml-groupheader-btn"><i className={icon}></i></span>
                        <span className="kk-ml-groupheader-count">{count}</span>
                    </h4>
                    <div className="kk-ml-groupheader-line"></div>
                </div>
            </div>
        );
    }
};

class Items__ extends React.PureComponent {

    constructor(props) {
        super(props);

        if (this.props.mobile) {
            this._scaler = new CssScaler({
                ".kk-ml-item": { width: 154, height: 231 },
                ".kk-ml-item img": { width: 154, height: 231 }
            });
        }
    }

    componentWillUnmount() {
        if (this._scaler) {
            this._scaler.destroy();
        }
    }

    render() {

        if (this._scaler) {
            var tiling = calculateTiles(this.props.containerWidth, 154, 6);

            this._scaler.scale(tiling.scaleFactor);
        }

        let { showOverlay, innerRef, list } = this.props;

        var elemList = list.map(item => {
            return (
                <MovieItem ref={innerRef} key={item.id} item={item} showOverlay={showOverlay} mobile={this.props.mobile}/>
            );
        });

        return <div style={{marginRight:-7}}>{elemList}</div>;
    }
}

const Items_ = withMeasure(Items__);

let Items = React.forwardRef((props, ref) => <Items_ innerRef={ref} {...props}/>)

class DesktopMovies extends React.PureComponent {

    _renderItems(list) {
        return <Items showOverlay={this.props.showOverlay} ref={this.props.innerRef} list={list} mobile={this.props.mobile}/>
    }

    _renderGroup(key, title, movies, stars) {
        var elems = [];

        var isCollapsed = this.props.grouping.collapsed[key];

        elems.push(<GroupHeader key={"HDR" + key} id={key} title={title} stars={stars} count={movies.length} collapsed={isCollapsed} toggle={this.props.toggleGroup}/>);

        if (!isCollapsed) {
            elems = elems.concat(<div key={key}>{this._renderItems(movies)}</div>);
        }

        return elems;
    }

    render() {
        var elems = [];
        var keys = {};
        var list = this.props.movies;

        if (this.props.grouping.groupBy) {
            if (this.props.grouping.groupBy === "Bewertet") {
                for (let rating = 0.0; rating <= 5.0; rating = rating + 0.5) {
                    var matchingMovies = list.filter(i => i.rating === rating);
                    if (matchingMovies.length > 0) {
                        elems = elems.concat(this._renderGroup(""+rating, ""+rating, matchingMovies, rating, keys));
                    }
                }
            } else {
                var moviesWithTag = {};
                var remainingMovies = this.props.movies.slice();

                this.props.tags.find((d) => { return d.name === this.props.grouping.groupBy }).values.forEach(tagVal => {
                    var matchingMovies = list.filter((m) =>
                        m.tags.find((d) => { return d.dimension === this.props.grouping.groupBy }) !== undefined &&
                        m.tags.find((d) => { return d.dimension === this.props.grouping.groupBy }).values.find(v => v === tagVal.name)
                    );

                    if (matchingMovies.length > 0) {
                        matchingMovies.forEach(function (item) {
                            moviesWithTag["x" + item.id] = true;
                            remainingMovies.splice( remainingMovies.indexOf(item), 1 );
                           
                        });

                        elems = elems.concat(this._renderGroup(tagVal.name, tagVal.name, matchingMovies, null, keys));
                    }
                });

                elems = elems.concat(this._renderGroup("Undefined", "Nicht zugeordnet", remainingMovies, null, keys));
                
            }
        } else {
            elems = elems.concat(this._renderItems(list));
        }

        return (
            <React.Fragment key="movies">
                 {elems}     
            </React.Fragment>          
        );
    }
}


export default React.forwardRef((props, ref) => <DesktopMovies 
  innerRef={ref} {...props}
/>);