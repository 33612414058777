import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Modal from 'components/UI/Modal';
import Api from 'components/Api';
import { connect } from 'components/StoresContext';

import './Feedback.css';

class Feedback extends Component {
  constructor(props) {
    super(props);
    this._showModal = this._showModal.bind(this);
    this._hideModal = this._hideModal.bind(this);
    this._sendFeedback = this._sendFeedback.bind(this);
    this.state={showModal:false};
  }

  _showModal() {
    this.setState({showModal:true});
  }

  _hideModal() {
    this.setState({showModal:false});
  }

  _sendFeedback() {
    Api.postJson('token/feedback', { url:this.props.location.pathname, feedback:this._textField.value.trim()});
    this.setState({showModal:false});
    this._textField.value = "";
  }



  render() {
    var modal =  
        <Modal size="large" show={this.state.showModal} onClose={this._hideModal} gaName="giveFeedback">
          <Modal.Header>
              <Modal.CloseButton onClick={this._hideModal}/>
              <Modal.Title>Sag uns deine Meinung</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p>Hilf uns kinokicks besser zu machen und sag uns was dir gut gefällt und was dir nicht gefällt.</p>
              <div className="form-group">
                  <textarea ref={e => this._textField = e } className="form-control" rows="5" name="comment" placeholder="Meine Meinung"></textarea>
              </div>
          </Modal.Body>
          <Modal.Footer>
              <button type="button" className="btn btn-default" onClick={this._hideModal}>Abbrechen</button>
              <button type="button" className="btn btn-primary" onClick={this._sendFeedback}>Senden</button>
          </Modal.Footer>
      </Modal>

    return (
      <div>
        {modal}
        {this.props.isSignedIn && !this.state.showModal && 
          <div className="feedback" onClick={this._showModal}>
          <span style={{verticalAlign:"middle", fontSize:"30px"}} className="fa fa-comments"></span>
          <span className="d-none d-sm-inline-block" style={{verticalAlign:"middle", marginLeft:"10px"}}>
            Feedback
          </span>
        </div>
        }
      </div>
    )
  }
}

export default withRouter(connect(Feedback, store => ({
  isSignedIn: !!store.user.currentUser
})));