import React from 'react';
import FlipMove from 'react-flip-move';
import { Link } from 'react-router-dom';

import RelTime from 'components/RelTime';
import User from 'components/User';

class CommentList extends React.Component {
    render() {

        var comments = this.props.comments;
        if (!comments) {
            comments = [];
        }

        var commentElems = comments.map(function(comment) {

            var url = User.url(comment.author);
            var img = User.img(comment.author);

            return (
                <div key={comment.id} className="item media" style={{marginBottom:"5px"}}>
                    <div className="mr-3">
                        <img src={img} alt="" className="img-circle img-small"/>
                    </div>
                    <div className="media-body">
                        <Link to={url} style={{ fontSize: "12px" }}>{comment.author.name}</Link>: &nbsp;
                    
                        {comment.text}&nbsp;
                        <RelTime style={{ fontSize:"12px", color:"#999"}} time={comment.created_at}/>
                    </div>
                </div>
            );
        });

        return (
            <div className="commentList">
                 <FlipMove enterAnimation="accordionVertical" leaveAnimation="accordionVertical">
                     {commentElems}
                 </FlipMove>
            </div>
        );
    }
}

export default CommentList;
