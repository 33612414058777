import { USER_PROFILE_LOAD_SUCCESS, USER_SETTINGS_UPDATE, USER_SIGNOUT, USER_SIGNIN_SUCCESS, USER_SIGNIN_FAILURE, USER_LOAD_MOVIES, USER_LOAD_MOVIES_SUCCESS, USER_LOAD_MOVIES_FAILURE, USER_LOAD_LISTS, USER_LOAD_LISTS_SUCCESS, USER_LOAD_LISTS_FAILURE, USER_LOAD_REVIEWS, USER_LOAD_REVIEWS_SUCCESS, USER_LOAD_REVIEWS_FAILURE, USER_LOAD_FRIENDS, USER_LOAD_FRIENDS_SUCCESS, USER_LOAD_FRIENDS_FAILURE, USER_SET_PROFILE_IMAGE, USER_LOAD_TAGS, USER_LOAD_TAGS_SUCCESS, USER_LOAD_TAGS_FAILURE, USER_LOAD_FRIEND_REQUESTS, USER_LOAD_FRIEND_REQUESTS_SUCCESS, USER_LOAD_FRIEND_REQUESTS_FAILURE, UPDATE_MOVIE_TAGS, UPDATE_MOVIE_INFO, USER_ADD_MOVIE } from "actions/user";

import reduceReviewActions from './reviewList';
import { CREATE_TAG_DIMENSION, DELETE_TAG_DIMENSION, CREATE_TAG_VALUE, CREATE_TAG_VALUE_SUCCESS, DELETE_TAG_VALUE, UPDATE_TAG_VALUE } from "actions/tags";
import { SEND_FRIEND_REQUEST, ACCEPT_FRIEND_REQUEST, REMOVE_FRIEND, DECLINE_FRIEND_REQUEST } from "actions/friends";
import { LIST_CREATE_SUCCESS, LIST_DELETE, LIST_UPDATE_HEADER } from "actions/movieList";

const initialState = {
    currentUser: null,
    profile: null,
    loadingMovies: false,
    movies: null,
    loadingLists: false,
    lists: null,
    loadingReviews: false,
    reviews: null,
    loadingFriends: false,
    friends: null,
    loadingFriendRequests: false,
    friendRequests: null,
    loadingTags: false,
    tags: null
}

function compareStr(a, b) {
    return a < b ? 1 : (a > b ? -1 : 0);
}

function sortReviews(reviews) {
    reviews.sort((a, b) => {
        if (a.score === b.score) {
            return compareStr(a.created_at, b.created_at);
        } else {
            return b.score - a.score;
        }
    });
}

function addElement(list, elem) {
    return list.concat([ elem ]);
}

// Returns a list without the element
function removeElement(list, predicate) {
    let index = list.findIndex(predicate);
    if (index < 0) {
        return list;
    }

    list = list.slice();
    list.splice(index, 1);

    return list;
}

function updateElement(list, predicate, updateFunc) {
    // Find the correct element
    let index = list.findIndex(predicate);
    if (index < 0) {
        return list;
    }
    
    // Create a shallow copy of the element and modify it
    let elem = { ...list[index] };
    updateFunc(elem);

    // Return a list with the element replaced
    list = list.slice();
    list.splice(index, 1, elem);

    return list;
}

function reduceTagActions(state, action) {
    switch (action.type) {
        case CREATE_TAG_DIMENSION: {
            let tags = addElement(state.tags, { name: action.name, value: [] });
            state = { ...state, tags };
            break;
        }
        case DELETE_TAG_DIMENSION: {
            let tags = removeElement(state.tags, dim => dim.dimension_id === action.dimensionId);
            state = { ...state, tags };
            break;
        }
        case CREATE_TAG_VALUE: {
            let tags = updateElement(state.tags, d => d.dimension_id === action.dimensionId, dim => {
                dim.values = addElement(dim.values, { value_id: null, name: action.name });
            });
            state = { ...state, tags };
            break;
        }
        case CREATE_TAG_VALUE_SUCCESS: {
            let tags = updateElement(state.tags, i => i.dimension_id === action.dimensionId, dim => {
                dim.values = updateElement(dim.values, v => v.name === action.name, value => {
                    value.value_id = action.id;
                })
            });
            state = { ...state, tags };
            break;
        }
        case DELETE_TAG_VALUE: {
            let tags = updateElement(state.tags, i => i.dimension_id === action.dimensionId, dim => {
                dim.values = removeElement(dim.values, v => v.value_id === action.valueId);
            });
            state = { ...state, tags };
            break;
        }
        case UPDATE_TAG_VALUE: {
            let tags = updateElement(state.tags, i => i.dimension_id === action.dimensionId, dim => {
                dim.values = updateElement(dim.values, v => v.value_id === action.valueId, value => {
                    value.name = action.newName;
                });
            });
            state = { ...state, tags };
            break;
        }
        default:
    }

    return state;
}

function reduceFriendActions(state, action) {
    switch (action.type) {
        case SEND_FRIEND_REQUEST: {
            let friends = state.friends.slice();

            friends.push({
                id: action.to.id,
                name: action.to.name,
                image: action.to.image,
                confirmed: false
            });

            state = { ...state, friends };
            break;
        }
        case ACCEPT_FRIEND_REQUEST: {
            let requestIndex = state.friendRequests.findIndex(x => x.id === action.from.id);

            if (requestIndex >= 0) {
                let friendRequests = state.friendRequests.slice();
                friendRequests.splice(requestIndex, 1);

                let friends = state.friends.slice();
                friends.push({
                    id: action.from.id,
                    name: action.from.name,
                    image: action.from.image,
                    confirmed: true
                });

                state = { ...state, friends, friendRequests };
            }
            break;
        }
        case DECLINE_FRIEND_REQUEST: {
            let requestIndex = state.friendRequests.findIndex(x => x.id === action.from.id);

            if (requestIndex >= 0) {
                let friendRequests = state.friendRequests.slice();
                friendRequests.splice(requestIndex, 1);

                state = { ...state, friendRequests };
            }
            break;
        }
        case REMOVE_FRIEND: {
            let friendIndex = state.friends.findIndex(x => x.id === action.friend.id);

            if (friendIndex >= 0) {
                let friends = state.friends.slice();
                friends.splice(friendIndex, 1);

                state = { ...state, friends };
            }

            break;
        }
        default:
    }

    return state;
}

function reduceListActions(state, action) {
    switch (action.type) {
        case LIST_CREATE_SUCCESS: {
            let lists = state.lists;
            if (lists) {
                lists = lists.slice();
                lists.push(action.list);
                state = { ...state, lists };
            }
            break;
        }
        case LIST_DELETE: {
            let lists = state.lists;
            if (lists) {
                let index = state.lists.findIndex(x => x.id === action.listId);
                if (index >= 0) {
                    lists = lists.slice();
                    lists.splice(index, 1);                    
                    state = { ...state, lists };
                }
            }
            break;
        }
        case LIST_UPDATE_HEADER: {
            let lists = state.lists;
            if (lists) {
                let index = state.lists.findIndex(x => x.id === action.listId);
                if (index >= 0) {
                    let list = lists[index];

                    list = { ...list, ...action.update };

                    lists = lists.slice();
                    lists.splice(index, 1, list);                    
                    state = { ...state, lists };
                }
            }
            break;
        }
        default:
    }

    return state;
}

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case USER_SIGNOUT: {
            state = { ...state, currentUser: null, profile: null, movies: null };
            break;
        }
        case USER_SIGNIN_SUCCESS: {
            state = { ...state, currentUser: action.user };
            break;
        }
        case USER_SIGNIN_FAILURE: {
            state = { ...state, currentUser: null, profile: null, movies: null };
            break;
        }

        case USER_LOAD_MOVIES: {
            state = { ...state, loadingMovies: true, movies: null };
            break;
        }
        case USER_LOAD_MOVIES_SUCCESS: {
            state = { ...state, loadingMovies: false, movies: action.movies };
            break;
        }
        case USER_LOAD_MOVIES_FAILURE: {
            state = { ...state, loadingMovies: false, movies: [] };
            break;
        }

        case USER_LOAD_LISTS: {
            state = { ...state, loadingLists: true, lists: null };
            break;
        }
        case USER_LOAD_LISTS_SUCCESS: {
            state = { ...state, loadingLists: false, lists: action.lists };
            break;
        }
        case USER_LOAD_LISTS_FAILURE: {
            state = { ...state, loadingLists: false, lists: [] };
            break;
        }

        case USER_LOAD_FRIENDS: {
            state = { ...state, loadingFriends: true, friends: null };
            break;
        }
        case USER_LOAD_FRIENDS_SUCCESS: {
            state = { ...state, loadingFriends: false, friends: action.friends };
            break;
        }
        case USER_LOAD_FRIENDS_FAILURE: {
            state = { ...state, loadingFriends: false, friends: [] };
            break;
        }

        case USER_LOAD_FRIEND_REQUESTS: {
            state = { ...state, loadingFriendRequests: true, friendRequests: null };
            break;
        }
        case USER_LOAD_FRIEND_REQUESTS_SUCCESS: {
            state = { ...state, loadingFriendRequests: false, friendRequests: action.requests };
            break;
        }
        case USER_LOAD_FRIEND_REQUESTS_FAILURE: {
            state = { ...state, loadingFriendRequests: false, friendRequests: [] };
            break;
        }

        case USER_LOAD_REVIEWS: {
            state = { ...state, loadingReviews: true, reviews: null };
            break;
        }
        case USER_LOAD_REVIEWS_SUCCESS: {

            action.reviews.forEach(review => {
                if (!review.score) {
                    review.score = 0;
                }
                if (!review.vote) {
                    review.vote = 0;
                }
                if (!review.comments) {
                    review.comments = [];
                }
            });

            sortReviews(action.reviews);

            state = { ...state, loadingReviews: false, reviews: action.reviews };
            break;
        }
        case USER_LOAD_REVIEWS_FAILURE: {
            state = { ...state, loadingReviews: false, reviews: [] };
            break;
        }

        case USER_LOAD_TAGS: {
            state = { ...state, loadingTags: true, tags: null };
            break;
        }
        case USER_LOAD_TAGS_SUCCESS: {
            state = { ...state, loadingTags: false, tags: action.tags };
            break;
        }
        case USER_LOAD_TAGS_FAILURE: {
            state = { ...state, loadingTags: false, tags: [] };
            break;
        }

        case USER_PROFILE_LOAD_SUCCESS: {
            state = { ...state, profile: action.data };
            break;
        }
        case USER_SETTINGS_UPDATE: {
            state = { ...state, profile: { ...state.profile, collection: action.collectionVisibility, tags: action.tagVisibility, newsletter: action.wantNewsletter } };
            break;
        }
        case USER_SET_PROFILE_IMAGE: {
            state = { ...state, profile: { ...state.profile, image: action.image } };
            break;
        }
        case UPDATE_MOVIE_INFO:
        case USER_ADD_MOVIE: {
            // { type: UPDATE_MOVIE_INFO, movieId, update }

          let movieIndex = state.movies.findIndex(x => x.id === action.movieId);

            // Movie already exists, so replace old element with new one
            if (movieIndex >= 0) {
                let movieInfo = { ...state.movies[movieIndex], ...action.update };
                let movies = state.movies.slice();
                // If new movie is still known (but with different tags), replace it
                if (movieInfo.known === true) {
                    movies.splice(movieIndex, 1, movieInfo);
                }
                // if it is no longer known, just remove it
                else {
                    movies.splice(movieIndex, 1);
                }

                state = { ...state, movies };
            }
            // Add new movie
            else 
            {
                
                let movies = state.movies.slice();

                let movieInfo = action.movieInfo ? action.movieInfo : { id: action.movieId, title:action.title, poster:action.poster, tags:[], ...action.update };
               
                movies.push(movieInfo);
                movies.sort((a, b) => a.title.localeCompare(b.title));      
                state = { ...state, movies };

            }
            break;
        }
        case UPDATE_MOVIE_TAGS: {
            // { type: UPDATE_MOVIE_TAGS, movieId, dimensionId, values }

            let movieIndex = state.movies.findIndex(x => x.id === action.movieId);

            if (movieIndex >= 0) {
                let newDim = { dimension: action.dimensionId, values: action.values };
                let tags = state.movies[movieIndex].tags.slice();

                let dimIndex = state.movies[movieIndex].tags.findIndex(x => x.dimension == action.dimensionId);
                if (dimIndex >= 0) {
                    tags.splice(dimIndex, 1, newDim);
                } else {
                    tags.push(newDim);
                }

                let movieInfo = { ...state.movies[movieIndex], tags };

                let movies = state.movies.slice();
                movies.splice(movieIndex, 1, movieInfo);

                state = { ...state, movies };
            }
            break;
        }

        default:
    }

    state = reduceReviewActions(state, action);

    state = reduceTagActions(state, action);

    state = reduceFriendActions(state, action);

    state = reduceListActions(state, action);

    return  state;
}
