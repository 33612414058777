import React from 'react';
import Helmet from 'react-helmet';

import { connect } from 'components/StoresContext';

import ProfileHeader from './ProfileHeader';
import ProfileBody from './ProfileBody';

import { loadUser, getProfile } from 'actions/otherUser';

class UserProfile extends React.PureComponent {

    componentWillMount() {
        this.props.loadUser(this.props.userId);
    }

    componentWillReceiveProps (nextProps) {
        if(nextProps.userId !== this.props.userId) {
            var userId = nextProps.userId;
            this.props.loadUser(userId);
        }
    }

    render() {
        return (
            <div>
                {this.props.user &&
                    <Helmet>
                        <title>{this.props.user.name + " | kinokicks.de"}</title>
                    </Helmet>
                }
                <ProfileHeader userId={this.props.userId}/>
                <ProfileBody userId={this.props.userId}/>
            </div>
        );
    }
}

export default connect(UserProfile, (store, props) => ({
    user: getProfile(store, props.userId)
}), dispatch => ({
    loadUser: userId => dispatch(loadUser(userId))
}));
