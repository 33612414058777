import React from 'react';
import FlipMove from  'react-flip-move';
import { Link } from 'react-router-dom';

import Raty from 'components/Raty';
import RelTime from 'components/RelTime';
import User from 'components/User';

import { connect } from 'components/StoresContext';

import './ActivityList.css';

class InfoButton extends React.Component {
    render() {

        var className = "btn btn-xs btn-" + this.props.color + " disabled active";
        var spanClassName = "fa fa-fw " + this.props.icon;

        return (
            <div className={className}>
                <span className={spanClassName}></span>
            </div>
        );
    }
}

class Item extends React.Component {
    render() {

        var item = this.props.item;

        var rating = undefined;
        if (item.rating > 0) {
            rating = (
                <div className="pull-right" style={{ fontSize: "18px", color: "#999" } }>
                    <Raty score={item.rating} narrow={true} readOnly={true }/>
                </div>
            );
        }

        var img = undefined;
        var name = undefined;
        var userTag = undefined;
        var timeTag = undefined;
        var delim = undefined;
        var userUrl = User.url(item.profile);
        var userImg = User.img(item.profile);

        if (this.props.showUserTag) {
            if (userUrl) {
                userTag = (<span style={{ display: "inline-block", paddingLeft: "5px" } }>von <Link to={userUrl}>{item.profile.name}</Link></span>);
            } else {
                userTag = (<span style={{ display: "inline-block", paddingLeft: "5px" } }>von Jemand</span>);
            }
            delim = ",";
        }

        if (item.changed && this.props.showTimeTag) {
            timeTag = <RelTime style={{ display: "inline-block", paddingLeft: "5px" } } time={item.changed}/>;
            delim = ",";
        }

        if (this.props.mode === "user") {
            if (userUrl) {
                img = (
                        <Link to={userUrl}><img src={userImg} alt="" className="img-small img-circle"/></Link>
                );
                name = (<Link to={userUrl}>{User.shortName(item.profile)}</Link>);
            } else {
                img = (
                        <img src={userImg} alt="" className="img-small img-circle"/>
                );
                name = (<span>Jemand</span>);
            }
        } 
        // else if (this.props.mode === "movie") {
        //     img = (
        //         <div className="media-left">
        //             <a href={item.movie.url}><img src={item.movie.poster}/></a>
        //         </div>
        //     );
        //     name = (<a href={item.movie.url}>{item.movie.title}</a>);
        // }

        return (
            <div className="media align-items-center" style={{marginTop:"5px"}}>
                <div className="media-left text-center" style={{ minWidth: "58px" }}>
                   {img}
                </div>
                <div className="media-body">
                
                <div className="media-heading">
                    <h6 style={{ marginTop:"0px"}}>{name}{delim}<small>{timeTag}{userTag}</small></h6>
                </div>
                </div>
                <div className="meadia-right">
                    {rating}
                </div>
            </div>
        );
    }
}

class Header extends React.Component {
    render() {

        var count = this.props.count;

        return (
            <h3>Leute<span className="badge badge-info" style={{ display: "inline-block", marginLeft:"5px", verticalAlign:"middle"}}>{count}</span></h3>
        );
    }
}

class More extends React.Component {

    constructor(props) {
        super(props);

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.onShowMore();
    }

    render() {
        return (
            <div className="text-right" style={{ marginTop:"5px" }}>
                <a href="##" onClick={this._handleClick}>mehr anzeigen</a>
            </div>
        );
    }
}

class ActivityList extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = { maxCount: this.props.maxCount };

        this._handleShowMore = this._handleShowMore.bind(this);
    }

    _handleShowMore() {
        this.setState({ maxCount: this.state.maxCount + 10 });
    }

    render() {

        var items = this.props.items;

        if (!items) {
            return null;
        }

        var mode = this.props.mode;
        var showTimeTag = this.props.showTimeTag;
        var showUserTag = this.props.showUserTag;

        var maxCount = this.state.maxCount;

        var header;
        if (!this.props.hideHeader) {
            header = (<Header key="header" count={items.length}/>);
        }

        var elems;
        
        if (items.length > 0) {
            elems = items.slice(0, maxCount).map(function (item, index) {
                return <Item  mode={mode} key={index} item={item} showTimeTag={showTimeTag} showUserTag={showUserTag }/>;
            });

            if (items.length > maxCount) {
                elems.push(<More key="more" onShowMore={this._handleShowMore}/>);
            }
        }
        else {
            elems = <span>Sei der erste, der diesen Film bei kinokicks gesammelt oder gesehen hat!</span>;
        }

        return (
            <FlipMove className={this.props.className} style={this.props.style} enterAnimation="accordionVertical" leaveAnimation="accordionVertical" staggerDelayBy={100}>
                {header}
                {elems}
            </FlipMove>
        );
    }
}

class MovieActivityList extends React.PureComponent {
    render() {
        return <ActivityList className="col-12" mode="user" maxCount={6} showTimeTag items={this.props.items} />;
    }
}

export default connect(MovieActivityList, store => ({
    items: store.movieStore.users
}));