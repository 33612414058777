import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'components/StoresContext';
import { NEWPASSWORD_INPUT, forgotPassword } from 'actions/newPasswordForm';

class NewPassword extends React.Component {

    constructor(props) {
        super(props);

        this._handleSubmit = this._handleSubmit.bind(this);
    }

    _handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        this.props.forgotPassword();
    }

    render() {
        return (
            <div style={{marginBottom:"15px"}}>
            {this.props.waiting && 
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <h3>Schau in dein Postfach</h3>
                            <p>Wir haben dir eine E-Mail geschickt, mit der du ein neues Passwort vergeben kannst.</p>
                        </div>
                    </div>
                </div>
            }
            {!this.props.waiting && 
                <div className="container">
                    <div className="row" style={{display:"flex", alignItems:"center"}}>
                        <div className="col-md-6">
                            <h3>Passwort vergessen</h3>
                            <p>Gib hier deinen Benutzernamen oder deine E-Mail Adresse ein, um dein Passwort zurückzusetzen.</p>
                            <p>Wir schicken dann an die bei uns hinterlegte E-Mail-Adresse eine Mail mit einem Bestätigungs-Link. Dieser Link
                            führt dich auf eine Seite, auf der Du ein neues Passwort für deinen Account festlegen kannst.</p>
                            <form onSubmit={this._handleSubmit}>
                                <div className="form-group">
                                    <label>Benutzername oder E-Mail Adresse</label>
                                    <input id="name" className="form-control" type="text" value={this.props.userName} onChange={e => this.props.onChange("userName", e.target.value)}/>
                                </div>
                                <button type="submit" className="btn btn-primary">Neues Passwort anfordern</button>
                            </form>
                            {this.props.errors && <div className="alert alert-danger" style={{marginTop:"10px"}}>
                                {this.props.errors.map((e, i) => <p key={i}>{e}</p>)}
                            </div>}
                        </div>
                        <div className="col-md-6 hidden-xs text-center" >
                            <img src="/Images/logo/Logo-128x128.png" alt=""/>
                        </div>
                    </div>
                </div>
            }
        </div>
      );
    }
}

// Needed for the login button to work.
NewPassword.contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.object.isRequired,
    }),
  };

export default connect(NewPassword, state => ({
    userName: state.newPasswordForm.userName,
    waiting: state.newPasswordForm.waiting,
    errors: state.newPasswordForm.errors
}), dispatch => ({
    forgotPassword: _ => dispatch(forgotPassword()),
    onChange: (field, value) => dispatch({ type: NEWPASSWORD_INPUT, field, value })
}));
