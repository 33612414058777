import React from 'react';
import { Link } from 'react-router-dom';

import User from 'components/User';

// Props:
//   users: List of users that participated
//   limit: Max. number of user to display
//   className: The class name for the outer div
//   style: The style for the outer div
class Participants extends React.PureComponent {

    render() {

        var userList = this.props.users.slice(0, this.props.limit).map(user => {

            var userUrl = User.url(user);
            var userImg = User.img(user);

            return (
                <Link key={user.id} to={userUrl}><img src={userImg} alt="" className="img-small img-circle" style={this.props.imgStyle}/></Link>
            );
        });

        var more = null;
        if (this.props.users.length > this.props.limit) {
            more = <h5><small> und {this.props.users.length - this.props.limit} weitere</small></h5>;
        }

        return (
            <div className={this.props.className} style={this.props.style}>
                {this.props.children}
                {userList}
                {more}
            </div>
        );
    }
}

export default Participants;
