import React from 'react';
import { Link } from 'react-router-dom';


import RequireLogin from 'components/RequireLogin';
import { connect } from 'components/StoresContext'
import Modal from 'components/UI/Modal';

import { updateMovieTags, getMovieInfo } from 'actions/user';
import { setTagPage } from 'actions/view';
import { createTagDimension, createTagValue } from 'actions/tags';

import './TagPanel.css';

class TagButtons_ extends React.Component {

    constructor(props) {
        super(props);

        this._toggleValue = this._toggleValue.bind(this);
        this._handleAddValue = this._handleAddValue.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this._handleNewValueChange = this._handleNewValueChange.bind(this);
        this._enterEditMode = this._enterEditMode.bind(this);
        this._confirmChanges = this._confirmChanges.bind(this);
        this._discardChanges = this._discardChanges.bind(this);

        this.state = { editMode: false, newValue: undefined, open: false };
    }

    _enterEditMode() {
        this.setState({ editMode: true });
    }

    _handleKeyDown(e) {
        if (e.key === 'Enter') {
          this._confirmChanges();
        }
        if (e.key === 'Escape') {
            this._discardChanges();
        }
    }

    _confirmChanges() {
        this.props.createValue(this.props.dimension.dimension_id, this.state.newValue);
        this.setState({ editMode: false, newValue: undefined });
    }

    _discardChanges() {
        this.setState({ editMode: false, newValue: undefined });
    }

    _toggleValue (value) {
        RequireLogin.do(() => {
            var newValues = this.props.selectedValues ? this.props.selectedValues.slice() : [];

            if (newValues.indexOf(value) > -1)
                newValues.splice( newValues.indexOf(value), 1);
            else
                newValues.push(value);

            this.props.updateTags(this.props.movieId, this.props.dimension.name, newValues);
        });
    }
    
    _handleNewValueChange (event) {
        this.setState({ newValue:event.target.value });
    }
    
    _handleAddValue () {
        RequireLogin.do(() => {
            this.setState({ editMode: true });
        });
    }

    render() {

        var  buttons, content;
        var selectedValues = this.props.selectedValues;
        

        // Initialize empty values
        if (!this.props.dimension.values)
            this.props.dimension.values = [];

        buttons = this.props.dimension.values.map( v  => 
            <button 
                className={ (selectedValues && selectedValues.indexOf(v.name) > -1) ? "btn btn-opaque active" : "btn btn-opaque "}
                onClick={ (e) => this._toggleValue(v.name) }
                key={v.name}
                style={{marginRight:"5px", marginBottom:"5px"}}>{v.name}
            </button>
        );
        content = 
        (
            <div>
                <h5 style={{marginBottom:"5px"}}>{this.props.dimension.name} 
                </h5>
                <div style={{marginBottom:"10px"}}>
                    {buttons}
                    {this.state.editMode === true &&
                        <div style={{display:"inline-block"}}>
                            <input style={{}} autoFocus name="newValue" key="newValue" type="text" value={this.state.newValue} onKeyDown={this._handleKeyDown} onChange={this._handleNewValueChange}/>
                            <span style={{marginLeft:"10px", cursor:"pointer"}} className="fa fa-check ab-dark" onClick={this._confirmChanges}/>
                            <span style={{marginLeft:"10px", cursor:"pointer"}} className="fa fa-times ab-dark" onClick={this._discardChanges}/>
                        </div>
                    }
                    {this.state.editMode === false &&
                        <button className="btn btn-opaque" style={{marginRight:"5px", marginBottom:"5px"}} onClick={this._handleAddValue} ><span className="fa fa-plus"/></button>
                    }
                </div>
            </div>
        )

        return content;
    }
}

let TagButtons = connect(TagButtons_, function (store, props) {
    var movieInfo = getMovieInfo(store, props.movieId);
    var dimension = movieInfo.tags.find( (i) => { return i.dimension === props.dimension.name });
    var selectedValues = dimension ? dimension.values : [];
    
    return { selectedValues: selectedValues };
}, (dispatch) => ({
    createValue: (dimensionId, newName) => dispatch(createTagValue(dimensionId, newName)),
    updateTags: (movieId, dimensionId, values) => dispatch(updateMovieTags(movieId, dimensionId, values))
}));

class TagPanel extends React.Component {
    constructor(props) {
        super(props);        

        this._addCategory = this._addCategory.bind(this);
        this._open = this._open.bind(this);
        this._close = this._close.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this._newCategoryChange = this._newCategoryChange.bind(this);
        this._pageRight = this._pageRight.bind(this);
        this._pageLeft = this._pageLeft.bind(this);

        this.state= { open: false };
    }
    
    _handleKeyDown(e)  {
        if (e.key === 'Enter') {
          this._addCategory();
        }
        if (e.key === 'Escape') {
            this._close();
        }
    }

    _addCategory() {
        if (this.state.newCategory === undefined || this.state.newCategory.trim() === "") {
            this.setState({error:"Die Kategorie muss einen Namen bekommen."});
        }
        else if (this.props.availableTags.find( (d) => d.name === this.state.newCategory )) {
            this.setState({error:"Der Name ist bereits vergeben. Bitte wähle einen anderen."});
        }
        else {
            this.setState({open: false });
            this.props.setTagPage( this.props.availableTags.length )
            this.props.createDimension(this.state.newCategory);
        }

    }

    _open() {
        RequireLogin.do(() => {
            this.setState({ open: true, error: undefined, newCategory: undefined });
        });
    }

    _close() {
        this.setState({ open: false })
    }

    _newCategoryChange(e) {
        this.setState({ newCategory: e.target.value });
    }

    _pageRight() {
        var newPage = this.props.tagPage < this.props.availableTags.length ? this.props.tagPage + 1 : 0;
        this.props.setTagPage( newPage );
    }
    
    _pageLeft() {
        var newPage = this.props.tagPage == 0 ? this.props.availableTags.length : this.props.tagPage - 1;
        this.props.setTagPage( newPage );
    }
    
    render() {
        var modal = undefined;
            modal = (
                <Modal size="medium" show={this.state.open} onClose={this._close}>
                    <Modal.Header>
                        <Modal.Title>Neue Kategorie anlegen</Modal.Title>
                        <Modal.CloseButton onClick={this._close}/>
                     
                    </Modal.Header>
                    <Modal.Body>
                        <strong>Name der Kategorie</strong>
                        <input autoFocus name="newCategory" style={{width:"80%"}} key="newCategory" onKeyDown={this._handleKeyDown} value={this.state.newCategory} onChange={this._newCategoryChange}/>
                        <p style={{marginTop:"10px"}}>
                            Kategorien bringen Ordnung in deine Sammlung. Damit kannst du z.B. nach "Platz im Regal", nach "Verliehen an" oder nach was ganz anderem sortieren. 
                        </p>
                        <p style={{marginTop:"10px"}}>
                            Mit dem Plus kannst du später Werte in der Kategorie eingeben, wie z.B. "Vorne Links" oder "An Paul". 
                        </p>
                        <p style={{color:"red", marginTop:"10px"}}>
                            {this.state.error}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={this._addCategory}>Ok</button>
                        <button className="btn" onClick={this._close}>Cancel</button>
                    </Modal.Footer>
                </Modal>
            );
       
        if (this.props.availableTags) {
            let dimensions = this.props.availableTags.map( (dimension) => {
                return <TagButtons key={dimension.name} dimension={dimension} movieId={this.props.movieId}/>; 
            });

            if (this.props.mobile) {
                return(
                <React.Fragment key="dimensions">
                     {modal}

                     <div className="container-fluid rainbow" style={{height:"1px"}}>
                    </div>

                    <div className="container-fluid ab-dark"  style={{backgroundColor:"rgb(25,25,25)"}}>
                        <div className="row" style={{paddingTop:"10px", paddingBottom:"0px"}}>
                            <div className="col">
                                {dimensions}
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid rainbow" style={{height:"1px"}}>
                    </div>

                    <div className="container-fluid ab-dark"  style={{backgroundColor:"rgb(25,25,25)"}}>
                 
                        <div className="row" style={{paddingTop:"10px", paddingBottom:"10px"}}>
                            <div className="col">
                                <h5>Legen Sie eigene Kategorien an, um Filme nach ihrem Geschmack zu sortieren.</h5>
                                <button type="button" onClick={this._open} className="btn btn-orange">Neue Kategorie</button>
                                <Link style={{paddingLeft:"10px"}} to="/Settings/">
                                    <button  type="button" className="btn btn-opaque">Kategorien verwalten</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                )
            }
            else 
            {
                return (
                    <React.Fragment key="tags">
                        {modal}
        
                    <div className="container-fluid rainbow" style={{height:"1px"}}>
                    </div>

                    <div className="container-fluid ab-dark"  style={{backgroundColor:"rgb(25,25,25)"}}>
                        <div className="row">

                            <div className="col-1 text-left" onClick={this._pageLeft} style={{fontSize:"60px", marginTop:"-5px", cursor:"pointer"}} >
                                <span className="fa fa-angle-left"/>
                            </div>
            
                            <div className="col-10">
                                <div className="container ab-dark">
                                    <div className="row" style={{paddingTop:"10px", paddingBottom:"0px"}}>
                                        <div className="col-12">
                                        {this.props.tagPage <= this.props.availableTags.length &&
                                         dimensions[this.props.tagPage]}
                                        
                                        {this.props.tagPage == this.props.availableTags.length &&
                                            <div>
                                                <h5>Legen Sie eigene Kategorien an, um Filme nach ihrem Geschmack zu sortieren.</h5>
                                                <button type="button" onClick={this._open} className="btn btn-orange">Neue Kategorie</button>
                                                <Link style={{paddingLeft:"10px"}} to="/Settings/">
                                                <button  type="button" className="btn btn-opaque">Kategorien verwalten</button>
                                                </Link>
                                            </div>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-1 text-right" onClick={this._pageRight} style={{fontSize:"60px", marginTop:"-5px", cursor:"pointer"}}>
                                <span className="fa fa-angle-right"/>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                );
            }
        }
    }
}

export default connect(TagPanel, store => ({
    availableTags: store.user.tags,
    currentUser: store.user.currentUser,
    tagPage: store.view.tagPage
}), (dispatch) => ({
    createDimension: name => dispatch(createTagDimension(name)),
    setTagPage: (newPage) => dispatch(setTagPage(newPage))
}));
