import React from 'react';

import Modal from 'components/UI/Modal';
import { connect } from 'components/StoresContext';

import { createTagDimension, deleteTagDimension, createTagValue, updateTagValue, deleteTagValue } from 'actions/tags';

import './TagPanel.css';

class TagButtons extends React.Component {

    constructor(props) {
        super(props);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this._deleteDimension = this._deleteDimension.bind(this);
        this._handleNewValueChange = this._handleNewValueChange.bind(this);
        this._enterEditMode = this._enterEditMode.bind(this);
        this._confirmChanges = this._confirmChanges.bind(this);
        this._discardChanges = this._discardChanges.bind(this);
        this.state={ currentEdit:undefined, newValue: undefined, open:false };
    }

    _enterEditMode(valueId, currentValue) {
        this.setState({ currentEdit: valueId, newValue: currentValue });
    }

    _handleKeyDown(e)  {
        if (e.key === 'Enter') {
          this._confirmChanges();
        }
        if (e.key === 'Escape') {
            this._discardChanges();
        }
    }

    _deleteDimension() {
        this.props.deleteDimension(this.props.dimension.dimension_id);
    }

    _deleteValue(valueId) {
        this.props.deleteValue(this.props.dimension.dimension_id, valueId);
    }

    _confirmChanges() {
        // Update existing value
        if (this.state.currentEdit > -1) 
        {
            this.props.updateValue(this.props.dimension.dimension_id, this.state.currentEdit, this.state.newValue);
        }

        // Add new value
        else
        {
            this.props.createValue(this.props.dimension.dimension_id, this.state.newValue);
        }

        this.setState({ currentEdit: undefined, newValue:undefined });
    }

    _discardChanges() {
        this.setState({ currentEdit: undefined, newValue:undefined });
    }
    
    _handleNewValueChange (event) {
        this.setState({ newValue: event.target.value });
    }

    render() {
        var  buttons, content, button, delBtn;
        var currentEdit = this.state.currentEdit;
        // Initialize empty values
        if (!this.props.dimension.values)
            this.props.dimension.values = [];

        buttons = this.props.dimension.values.map( (v) => {

                if (v.value_id !== undefined && currentEdit === v.value_id) 
                {
                    button = (
                        <div>
                            <input autoFocus name="newValue" key="newValue" type="text" value={this.state.newValue} onKeyDown={this._handleKeyDown} onChange={this._handleNewValueChange}/>
                            <span style={{marginLeft:"10px", cursor:"pointer"}} className="fa fa-check" onClick={this._confirmChanges}/>
                            <span style={{marginLeft:"10px", cursor:"pointer"}} className="fa fa-times" onClick={this._discardChanges}/>
                        </div>
                    );
                }
                else
                {
                    button = (
                        <div style={{marginBottom:"3px"}}>
                            <button 
                                key={v.name}
                                className={ "btn btn-opaque " }
                                style={{marginRight:"5px"}}>{v.name}
                            </button>
                            {v.owner !== -1 &&
                                <span style={{marginLeft:"10px", cursor:"pointer"}} className="fa fa-pencil" 
                                onClick={(e) => this._enterEditMode(v.value_id, v.name)}/>
                            }
                            {v.owner !== -1 &&
                                <span style={{marginLeft:"10px", cursor:"pointer"}} className="fa fa-trash" 
                                onClick={(e) => this._deleteValue(v.value_id)}/>
                            }
                        </div>
                    );
                }
                return button;
            }
        );
        if (buttons.length === 0) {
            delBtn = (
                <button className="btn btn-opaque" style={{marginLeft:"5px"}} onClick={this._deleteDimension}>{this.props.dimension.name} löschen.</button>
            )
        };
        content = 
        (
            <div>
                <h4 style={{marginBottom:"5px"}}>{this.props.dimension.name} 
                </h4>
                <div style={{marginBottom:"10px"}}>
                    {buttons}
                    {this.state.currentEdit === -1 &&
                        <div>
                            <input autoFocus name="newValue" key="newValue" type="text" value={this.state.newValue} onKeyDown={this._handleKeyDown} onChange={this._handleNewValueChange}/>
                            <span style={{marginLeft:"10px", cursor:"pointer"}} className="fa fa-check" onClick={this._confirmChanges}/>
                            <span style={{marginLeft:"10px", cursor:"pointer"}} className="fa fa-times" onClick={this._discardChanges}/>
                        </div>
                    }
                    {!this.state.currentEdit &&
                        <div style={{marginBottom:"3px"}}>
                            <button className="btn btn-opaque" onClick={(e) => this._enterEditMode(-1, "")}><span className="fa fa-plus"/></button>
                            {delBtn}
                        </div>
                    }
                    
                </div>
            </div>
        )

        return content;
    }
}

class TagPanel extends React.Component {
    constructor(props) {
        super(props);

        this._addCategory = this._addCategory.bind(this);
        this._open = this._open.bind(this);
        this._close = this._close.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this._newCategoryChange = this._newCategoryChange.bind(this);

        this.state= { open: false, error: undefined };
    }

    _handleKeyDown(e)  {
        if (e.key === 'Enter') {
          this._addCategory();
        }
        if (e.key === 'Escape') {
            this._close();
        }
    }

    _addCategory() {
        if (this.state.newCategory === undefined || this.state.newCategory.trim() === "") {
            this.setState({error:"Der Kategorie muss einen Namen bekommen."});
        }
        else if (this.props.availableTags.find( (d) => d.name === this.state.newCategory )) {
            this.setState({error:"Der Name ist bereits vergeben. Bitte wähle einen anderen."});
        }
        else {
            this.setState({open: false});
            this.props.createDimension(this.state.newCategory);
        }

    }

    _open() {
        this.setState({ open: true, error: undefined, newCategory: undefined })
    }

    _close() {
        this.setState({ open: false })
    }

    _newCategoryChange(e) {
        this.setState({ newCategory: e.target.value });
    }
    

    render() {
        var dimensions;
        var modal = (
            <Modal size="medium" show={this.state.open} onClose={this._close} gaName="newCategoryFromSettings">
                <Modal.Header>
                    <Modal.Title><span>Neue Kategorie anlegen</span></Modal.Title>
                    <Modal.CloseButton onClick={this._close}/>
                    
                </Modal.Header>
                <Modal.Body>
                    <input autoFocus name="newCategory" style={{width:"80%"}} key="newCategory" onKeyDown={this._handleKeyDown} value={this.state.newCategory} onChange={this._newCategoryChange}/>
                    <p style={{marginTop:"10px"}}>
                        Kategorien bringen Ordnung in deine Sammlung. Damit kannst du z.B. nach "Platz im Regal", nach "Familienmitglied" oder nach was ganz anderem sortieren. 
                    </p>
                    <p style={{marginTop:"10px"}}>
                        Mit dem Plus kannst du dann Werte in der Kategorie eingeben, wie z.B. "Vorne Links" oder "Film für Mama". 
                    </p>
                    <p style={{color:"red", marginTop:"10px"}}>
                        {this.state.error}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn btn-outline-secondary" onClick={this._close}>Cancel</div>
                    <div className="btn btn-primary" onClick={this._addCategory}>Ok</div>
                
                </Modal.Footer>
            </Modal>
        );

        var header = (
            <div className="row" style={{marginTop:"10px"}}>
                <div className="col-6 text-left">
                    <h3 style={{display:"inline-block"}}>Kategorien 
                    </h3>
                </div>
                <div className="col-6 text-left" >
                    {modal}
                        <button type="button" className="btn btn-orange" onClick={this._open} style={{ display:"inline-block",  marginLeft: "20px", width:"100px" } }>
                            <span className="fa fa-plus"></span>                                      
                            <span style={{ display:"inline-block", marginLeft: "5px" }}>Kategorie</span> 
                        </button>
                </div>
            </div>
        );

        if (this.props.availableTags) {
           dimensions = this.props.availableTags.map( (dimension) => {
               return <TagButtons key={dimension.dimension_id} deleteDimension={this.props.deleteDimension} deleteValue={this.props.deleteValue} updateValue={this.props.updateValue} createValue={this.props.createValue} dimension={dimension}/>
            });
        }

        return (
            <div>
                {header}
                <div className="row" >
                    <div className="col-12">
                        {dimensions}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(TagPanel, store => ({
    availableTags: store.user.tags
}), (dispatch) => ({
    createDimension: name => dispatch(createTagDimension(name)),
    deleteDimension: dim => dispatch(deleteTagDimension(dim)),
    createValue: (dimensionId, newValue) => dispatch(createTagValue(dimensionId, newValue)),
    updateValue: (dimensionId, valueId, newName) => dispatch(updateTagValue(dimensionId, valueId, newName)),
    deleteValue: (dimensionId, valueId) => dispatch(deleteTagValue(dimensionId, valueId))
}));
