
import React from 'react';
import FlipMove from 'react-flip-move';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import RelTime from 'components/RelTime';
import Api from 'components/Api';

import Movie from 'components/Movie';
import User from 'components/User';

import Participants from 'components/MovieList/Participants';

import './MovieLists.css';

// Display the statistics for a single list (# of movies, # of votes, # of participants, # of comments)
class Statistics extends React.Component {

    render() {
        var stats = this.props.list.stats;

        var iconStyle = { display: "inline-block", marginRight: "10px", fontSize: "12px" };
        var tdStyle = { paddingTop: "3px", paddingBottom: "3px", border: "none" };
        return (
            <table className="table" style={{ marginTop: "10px", marginBottom: "5px", fontSize: "12px" } }>
                <tbody>
                    <tr>
                        <td style={tdStyle}>
                            <span className="glyphicon glyphicon-facetime-video" style={iconStyle}/>{stats.total_movies}
                        </td>
                        <td style={tdStyle}>
                            <span className="glyphicon glyphicon-thumbs-up" style={iconStyle}/>{stats.total_votes}
                        </td>
                        <td style={tdStyle}>
                            <span className="fa fa-user-plus" style={iconStyle}/>{stats.total_participants}
                        </td>
                        <td style={tdStyle}>
                            <span className="fa fa-pencil" style={iconStyle}/>{stats.total_comments}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

// Displays the first 6 movies in the list with their posters
class Movies extends React.Component {
    render() {
        var movieItems = this.props.list.movies.slice(0, 6).map(movie => {
            return (
                <span key={movie.movie.id} style={{ "display": "inline-block" } }>
                    <Link to={Movie.url(movie.movie)} style={{ "marginRight": "10px" } }>
                        <img src={Movie.poster(movie.movie, 45)} alt="" style={{ width: "45px", height: "68px" }} />
                    </Link>
                </span>
            );
        });

        return <div style={{ "whiteSpace": "nowrap", "overflow": "hidden" } }>{movieItems}</div>;
    }
}

class Item extends React.Component {
    render() {

        var list = this.props.list;

        var listUrl = Api.getListUrl(list);
        var listImage = '/Images/no-poster-185.png';
        if (list.image_type === 'Owner') {
            listImage = User.img(list.owner);
        } else if (list.image_type === 'FirstMovie') {
            listImage = Movie.poster({ poster: list.image }, 45);
        } else if (list.image_type === 'Custom') {
            listImage = list.image;
        }

        var image = undefined;
        if (this.props.display.showImage) {
            image = (
                <div className="media-left" style={{ minWidth: "58px" }}>
                    <Link to={listUrl}><img src={listImage} alt="" style={{ width: "48px" }}/></Link>
                </div>
            );
        }

        var description = undefined;
        if (this.props.display.showDescription) {
            description = <div>{list.description}</div>;
        }

        var participants = undefined;
        if (list.participants && list.participants.length > 0 && this.props.display.showParticipants) {
            participants = <Participants style={{ marginTop: "10px" }} users={list.participants} limit={5} imgStyle={{ marginRight: "2px" }}/>;
        }

        var movies = undefined;
        if (list.movies && list.movies.length > 0 && this.props.display.showMovies) {
            movies = <Movies list={list}/>;
        }

        var stats = undefined;
        if (this.props.display.showStats) {
            stats = <Statistics list={list}/>;
        }

        var time = undefined;
        if (this.props.display.showTime && list.created_at) {
            time = <small> <RelTime time={list.created_at}/></small>
        }
        var owner = undefined;
        if (this.props.display.showOwner) {
            owner = <small> von <Link to={User.url(list.owner)}>{list.owner.name}</Link></small>;
        }

        var body = (
            <div className="media-body">
                <div className="media-heading">
                    <h6 style={{ marginTop: "0px" } }><Link to={listUrl}>{list.name}</Link>{owner}{time}</h6>
                </div>
                {description}
                {participants}
                {movies}
                {stats}
            </div>
        );        

        return (
            <div className={classnames({ media: true, "list-item": true, last: this.props.isLast})}>
                {image}
                {body}
            </div>
        );
    }
}

class Placeholder extends React.Component {
    render() {
        return (
            <div>
                Sei der erste und lege eine Liste mit diesem Film an!<br/>
            </div>
        );
    }
}

class Header extends React.Component {

    constructor(props) {
        super(props);

        this._handleListCreated = this._handleListCreated.bind(this);
    }

    _handleListCreated(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        if (this.props.onCreateList) {
            this.props.onCreateList();
        }
    }

    render() {
        var count = undefined;
        if (this.props.lists) {
            count = <span className="badge badge-info" style={{ "display": "inline-block", "marginLeft": "5px", "verticalAlign": "middle" } }>{this.props.lists.length}</span>;
        };

        return (
            <div className="row" style={{marginTop:"10px"}}>
                <div className="col-6 text-left">
                    <h3 style={{ display: "inline-block" } }>Listen{count}</h3>
                </div>
                <div className="col-6 text-right">
                        <button type="button" className="btn btn-orange" onClick={this._handleListCreated} style={{ display: "inline-block", marginLeft: "20px", width: "100px" }}>
                            <span className="fa fa-plus"></span>                                      
                            <span style={{ display: "inline-block", marginLeft: "5px" } }>Liste</span> 
                        </button>
                </div>
            </div>
        );
    }
}

class Lists extends React.Component {

    constructor(props) {
        super(props);

        this.state = { maxCount: this.props.pageSize };

        this._showMore = this._showMore.bind(this);
    }

    _showMore(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ maxCount: this.state.maxCount + this.props.pageSize });
    }

    render() {

        var header = undefined;
        if (this.props.showHeader) {
            header = <Header lists={this.props.lists} onCreateList={this.props.onCreateList}/>
        }

        var content = undefined;
        if (this.props.lists.length === 0 && this.props.showHeader) {
            content = <Placeholder/>;
        } else {
            var lists = this.props.lists;

            if (this.state.maxCount) {
                lists = this.props.lists.slice(0, this.state.maxCount);
            }

            content = lists.map((list, idx) => <Item key={list.id} list={list} display={this.props.display} isLast={idx === lists.length - 1}/>);

            if (lists.length < this.props.lists.length) {
                content.push(
                    <div key="more" className="text-right" style={{ marginTop: "5px"}}><a href="##" onClick={this._showMore}>Mehr anzeigen ...</a></div>
                );
            }
        }

        return (
            <div className={this.props.className} style={this.props.style}>
                {header}
                <FlipMove className="items lists" enterAnimation="accordionVertical" leaveAnimation="accordionVertical" staggerDelayBy={100}>
                    {content}
                </FlipMove>
            </div>
        );
    }
}

Lists.defaultProps = {
    display: { showImage: true, showDescription: true, showParticipants: true, showMovies: false, showStats: false, showOwner: true, showTime: false }
}

export default Lists;
